import InputAdornment from '@material-ui/core/InputAdornment';
import React from "react";
import NumberFormat from 'react-number-format';
import { ValueField, ValueFieldProps } from './valuefield';



interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
            fixedDecimalScale
            decimalScale={2}
        />
    );
}

export function CurrencyField(props: ValueFieldProps) {
    const ensureNumeric = (value: string) => {
        const parsed = Number(value);
        if (isNaN(parsed) || value.endsWith(".")) {
            return false;
        }

        return true;
    };

    return <ValueField {...props}
        isValidValue={ensureNumeric}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        inputComponent={NumberFormatCustom}
        labelWidth={60} />
}