/**
 * BitwiseSet implements a simple Bitwise Set.
 */
export class BitwiseSet {
  constructor(public encoded: number) {}

  public has(value: number): boolean {
    return (this.encoded & value) > 0;
  }

  static fromString(value: string | number | null | undefined) {
    if (value === null || value === undefined) {
      return new BitwiseSet(0);
    }

    let parsed = parseInt(value.toString() || "0");
    if (isNaN(parsed) || typeof parsed !== "number") {
      parsed = 0;
    }
    return new BitwiseSet(parsed);
  }

  static encodedHas(encodedValues: string | number, value: number) {
    return BitwiseSet.fromString(encodedValues).has(value);
  }
}
