import { ApolloError, LazyQueryHookOptions, MutationFunctionOptions, MutationHookOptions, OperationVariables, useLazyQuery, useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import React, { useEffect } from "react";
import { useAlert } from "../../components/AlertProvider";

export type FixedQueryOptions<TData, TVariables = OperationVariables> = LazyQueryHookOptions<TData, TVariables> & {
    skipIf?: boolean
    autoHandleErrors?: boolean
};

export type ManagedQueryOptions<TData, TVariables = OperationVariables> = FixedQueryOptions<TData, TVariables> & {
    autoHandleErrors?: boolean
};

export interface ManagedQuery {
    gql: DocumentNode
    workingMessage: string
    errorMessage: string
}

export type ManagedMutation = ManagedQuery & {
    refetchQueries?: string[]
}

export interface ManagedQueryState<TData> {
    data: TData | undefined
    query: ManagedQuery
    loading: boolean
    isMounted: boolean
    apolloError: ApolloError | undefined
}

/**
 * MutationError represents an error raised during a mutation.
 * 
 * NOTE: These errors representing user errors and are distinct from
 * Apollo errors, which usually indicate an application error rather
 * than a user error.
 */
export interface MutationError {
    code: string
    message: string
}

/**
 * WithErrors represents the possible error data returning in a mutation.
 */
export interface WithErrors {
    status: boolean
    errors: MutationError[]
}

/**
 * WITH_ERRORS_FRAGMENT contains the fields expected on any mutations.
 */
export const WITH_ERRORS_FRAGMENT = `
    status
    errors {
        code
        message
    }
  `;

type WithNestedErrors = { [key: string]: WithErrors }

type ManagedMutationOptions<TData extends WithNestedErrors, TVariables = OperationVariables> = MutationHookOptions<TData, TVariables> & {
    autoHandleErrors?: boolean
};

interface ManagedMutationState {
    loading: boolean
    called: boolean
    apolloError: ApolloError | undefined
}

export type Fetcher<TData, TVariables> = (options?: MutationFunctionOptions<TData, TVariables>) => Promise<TData | undefined | null>;

/**
 * useManagedMutation is a wrapper around useMutation with the addition of (optional) built in error handling.
 * NOTE: Unlike useManagedQuery, the error handling in useManagedMutation is on by default.
 */
export function useManagedMutation<TData extends WithNestedErrors = WithNestedErrors, TVariables = OperationVariables>(mm: ManagedMutation, options?: ManagedMutationOptions<TData, TVariables>): [Fetcher<TData, TVariables>, ManagedMutationState] {
    const [internalInvokeMutation, { loading, error, called }] = useMutation<TData, TVariables>(mm.gql, mm);
    const { showAlert } = useAlert();

    const wrappedInvokeMutation = async (invokeOptions?: MutationFunctionOptions<TData, TVariables>) => {
        try {
            const refetchQueries = options?.refetchQueries ?? mm.refetchQueries;
            const invokeData = await internalInvokeMutation({
                ...invokeOptions,
                refetchQueries: refetchQueries
            });

            if (invokeData && invokeData.data && Object.keys(invokeData.data).length === 1) {
                const result = Object.values(invokeData.data)[0];
                if (!result.status) {
                    if (options?.autoHandleErrors !== false) {
                        showAlert({
                            'title': mm.errorMessage,
                            'content': <div>{result.errors[0].message}</div>,
                            'buttonTitle': 'Okay',
                        });
                    }
                }
            }
            return invokeData.data;
        } catch (e) {
            if (options?.autoHandleErrors !== false) {
                showAlert({
                    'title': mm.errorMessage,
                    'content': <div>{e.toString()}</div>,
                    'buttonTitle': 'Okay',
                });
            }
        }
        return undefined;
    };

    const state = {
        loading: loading,
        called: called,
        apolloError: error,
    };
    return [wrappedInvokeMutation, state];
}


/**
 * useManagedQuery is a wrapper around a fixed query with the addition of (optional) built in error handling.
 */
export function useManagedQuery<TData = any, TVariables = OperationVariables>(mq: ManagedQuery, options?: ManagedQueryOptions<TData, TVariables>) {
    const state = useFixedQuery<TData, TVariables>(mq.gql, options)
    const { showAlert } = useAlert();
    const { error } = state

    if (error && options?.autoHandleErrors === true) {
        showAlert({
            'title': mq.errorMessage,
            'content': <div>{error.message}</div>,
            'buttonTitle': 'Okay',
        });
    }
    return {
        data: state.data,
        loading: state.loading,
        query: mq,
        isMounted: state.isMounted,
        apolloError: error,
    };
}

/**
 * useFixedQuery is a wrapper around useQuery that fixes the issue associated with de-registration
 * of queries currently in Apollo. Instead of using useQuery, useFixedQuery makes use of
 * useLazyQuery, which is invoked via a useEffect hook as soon as the component is mounted, and we
 * check if the component is still mounted before any additional calls.
 */
export function useFixedQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode, options?: FixedQueryOptions<TData, TVariables>) {
    const [loadData, { loading, error, data, fetchMore, refetch }] = useLazyQuery(query, options);

    // NOTE: Changed to use lazyQuery based on: https://github.com/apollographql/react-apollo/issues/3635#issuecomment-621070899
    let isMounted = true;
    useEffect(() => {
        if (isMounted && (options === undefined || options.skipIf !== true)) {
            loadData();
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isMounted = false;
        };
    }, []);

    return { loading, error, data, fetchMore, refetch, isMounted }
};