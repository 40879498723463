import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { LinkProps as RouterLinkProps, NavLink } from 'react-router-dom';
import { SideDrawerOpenContext } from './SideDrawer';

/**
 * Defines the properties for the ListItemNavLink.
 */
export interface ListItemNavLinkProps {
    /**
     * The icon to include in the ListItemNavLink, if any.
     */
    icon?: React.ReactElement;

    /**
     * The primary text for the ListItemNavLink. Should describe the section.
     */
    primary: string;

    /**
     * The route path to which clicking the ListItemNavLink should navigate.
     */
    to: string;
}

// Based on: https://material-ui.com/guides/composition/#link

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItemText: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        listItemIcon: {
        },
        iconContainer: {
            width: '21px',
            textAlign: 'center'
        },
        navActive: {
            backgroundColor: theme.palette.primary.main,
            '& $listItemText': {
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText,
            },
            '& $listItemIcon path': {
                fill: theme.palette.primary.contrastText,
            },
            '&:hover $listItemText': {
                color: theme.palette.text.primary,
            },
            '&:hover $listItemIcon path': {
                fill: theme.palette.text.primary,
            },
        },
    }))

/**
 * ListItemNavLink defines a styled NavLink that is placed in a side drawer
 * and navigates to a specific route when clicked. The link is also auto-styled
 * when the current route matches the nav link.
 * 
 * @param props The properties for the ListItemNavLink.
 * @example <ListItemNavLink icon={<Dashboard />} primary="Dashboard" to="/dashboard" />
 */
export default function ListItemNavLink(props: ListItemNavLinkProps) {
    const classes = useStyles();
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <NavLink exact to={to} ref={ref} activeClassName={classes.navActive}  {...itemProps} />
            )),
        [to, classes],
    );

    return (
        <SideDrawerOpenContext.Consumer>
            {(isOpen: boolean) => {
                const item = <ListItem button component={renderLink}>
                    {icon ? <ListItemIcon className={classes.listItemIcon}><div className={classes.iconContainer}>{icon}</div></ListItemIcon> : null}
                    <ListItemText classes={{ 'primary': classes.listItemText }} primary={primary} />
                </ListItem>;

                return isOpen ? item : <Tooltip title={props.primary} placement="right-end">
                    {item}
                </Tooltip>;
            }}
        </SideDrawerOpenContext.Consumer>
    );
}