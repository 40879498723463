import { useAuth, useUser } from "@clerk/clerk-react";
import { User } from "../types/firmmember";

/**
 * useAuthenticationService defines functions for interacting with the authn service.
 */
export function useAuthenticationService() {
    const { getToken, signOut } = useAuth();
    const { user, isSignedIn } = useUser();

    const currentUser: User | undefined = isSignedIn ? {
        id: user!.id,
        email: user!.primaryEmailAddress?.emailAddress ?? '',
        name: user!.fullName || user!.username || undefined,
        avatarUrl: user!.profileImageUrl,
    } : undefined;

    return {
        getToken: () => {
            return getToken()
        },
        signOut: () => {
            signOut();
        },
        currentUser: currentUser,
    }
}