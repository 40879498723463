import { useContext, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ensureIsSelectQuery, IDed, PreparedQuery } from "../sql";
import { DatabaseContext } from "./context";
import { MutableRow } from "./interfaces";
import { ChangeFieldMutation, Mutation, MutationKind } from "./mutations";

/**
 * State holds the state used in the useQuery system.
 */
interface State<TResult extends IDed = IDed> {
  /**
   * loading indicates whether the query is currently loading.
   */
  loading: boolean;

  /**
   * results holds the results of the select query.
   */
  results: MutableRow<TResult>[] | undefined;

  /**
   * currentMutationIndex holds the current mutation index.
   */
  currentMutationIndex: number;
}

interface UseQueryOptions {
  fieldNames?: string[];
}

/**
 * useQuery executes a prepared select query and updates it for each revision of the
 * database.
 * @param query The query to execute. Must be a select query.
 * @example const { loading, results } = useQuery<SomeRecord>(sql`select * from ${'sometable'} where field >= 0`);
 */
export function useQuery<TResult extends IDed = IDed>(
  query: PreparedQuery,
  options: UseQueryOptions | undefined = undefined
) {
  ensureIsSelectQuery(query);

  const database = useContext(DatabaseContext);
  const [state, setState] = useState<State<TResult>>({
    loading: true,
    results: undefined,
    currentMutationIndex: database
      ? database.transaction.currentMutationIndex()
      : -1,
  });

  // NOTE: We make use of useDeepCompareEffect here to ensure that changes to the query
  // or the database are used to re-execute the effect, but that *no other changes* cause
  // it.
  useDeepCompareEffect(() => {
    if (database === undefined) {
      return;
    }

    const newState = {
      loading: false,
      results: database.selectAllResults<TResult>(query),
      currentMutationIndex: database?.transaction.currentMutationIndex(),
    };
    setState(newState);

    const handle = database.registerMutatedCallback(
      (tableName?: string, mutation?: Mutation) => {
        if (tableName === query.tableName) {
          if (
            mutation &&
            (mutation.kind() !== MutationKind.CHANGE_FIELD ||
              (options?.fieldNames &&
                options.fieldNames.includes(
                  (mutation as ChangeFieldMutation).fieldName
                )))
          ) {
            // This is an update.
            setState({
              loading: false,
              results: database.selectAllResults<TResult>(query),
              currentMutationIndex:
                database?.transaction.currentMutationIndex(),
            });
          }
        } else if (!tableName) {
          // This is an overall update.
          setState({
            loading: false,
            results: database.selectAllResults<TResult>(query),
            currentMutationIndex: database?.transaction.currentMutationIndex(),
          });
        }
      }
    );

    return () => {
      database.unregisterMutatedCallback(handle);
    };
  }, [database, query]);

  return state;
}

export interface QueryToWatch {
  query: PreparedQuery;
  options: UseQueryOptions | undefined;
}

/**
 * useWatchQueries is a hook which re-renders whenever any of the given queries change.
 */
export function useWatchQueries(queries: QueryToWatch[]) {
  queries.forEach((q) => {
    ensureIsSelectQuery(q.query);
  });

  const database = useContext(DatabaseContext);
  const [, setChangeIndex] = useState(0);

  // NOTE: We make use of useDeepCompareEffect here to ensure that changes to the query
  // or the database are used to re-execute the effect, but that *no other changes* cause
  // it.
  useDeepCompareEffect(() => {
    if (database === undefined || queries.length === 0) {
      return;
    }

    const handle = database.registerMutatedCallback(
      (tableName?: string, mutation?: Mutation) => {
        if (!tableName) {
          // This is an overall update.
          setChangeIndex(Date.now());
          return;
        }

        queries.forEach((q) => {
          if (tableName === q.query.tableName) {
            if (
              mutation &&
              (mutation.kind() !== MutationKind.CHANGE_FIELD ||
                (q.options?.fieldNames &&
                  q.options.fieldNames.includes(
                    (mutation as ChangeFieldMutation).fieldName
                  )))
            ) {
              // This is an update.
              setChangeIndex(Date.now());
              return;
            }
          }
        });
      }
    );

    return () => {
      database.unregisterMutatedCallback(handle);
    };
  }, [queries, database]);
}
