import { DatabaseMigration } from "../../database/schema";
import { CaseTemplate } from "../interfaces";
import * as dataAccess from "./dataaccess.json";
import { constructDocumentData } from "./documentdata";
import { initialData } from "./initialdata";
import * as migrations from "./migrations.json";
import * as schema from "./schema.json";
import { LitigationPanels } from "./ui";

/**
 * Defines a Litigation flow.
 */
export const LitigationTemplateDefinition: CaseTemplate = {
  id: "litigation",
  title: "Litigation Contract",
  schema: {
    $schema: schema.$schema,
    databaseSchemaRevision: schema.databaseSchemaRevision,
    definitions: schema.definitions,
    initialData: initialData,
    migrations: migrations["migrations"] as DatabaseMigration[],
  },
  description: "Litigation Contract",
  panels: LitigationPanels,
  documentDataConstructor: constructDocumentData,
  dataAccess: dataAccess["dai"],
};
