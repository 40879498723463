import { faFileWord, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ColorHash from "color-hash";
import React from "react";
import { CreatedCaseDocument } from "../../types/generateddoc";

interface StyleProps {
    docKindColor: string
    isLatestDocumentRevision: boolean
    compact: boolean
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: (props: StyleProps) => props.docKindColor,
            color: (props: StyleProps) => props.isLatestDocumentRevision ? theme.palette.getContrastText(props.docKindColor) : theme.palette.getContrastText(theme.palette.getContrastText(props.docKindColor)),
            border: '1px solid transparent',
            borderColor: theme.palette.divider,
            position: 'relative',
            width: (props: StyleProps) => props.compact ? '26px' : '40px',
            height: (props: StyleProps) => props.compact ? '26px' : '40px',
        },
        history: {
            position: 'absolute',
            bottom: '4px',
            right: '4px',
            color: (props: StyleProps) => theme.palette.getContrastText(props.docKindColor),
        }
    }));

/**
 * DocumentIcon displays an icon for the document.
 */
export function DocumentIcon(props: { document: CreatedCaseDocument, size?: 'sm' | 'lg', compact?: boolean }) {
    const colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    const docKindColor = colorHash.hex(props.document.title);
    const classes = useStyles({ compact: props.compact ?? false, docKindColor: docKindColor, isLatestDocumentRevision: props.document.isLatestDocumentRevision });
    return <Avatar variant="square" className={classes.root}>
        <FontAwesomeIcon icon={faFileWord} size={props.size ?? 'sm'} />
        {!props.document.isLatestDocumentRevision && <FontAwesomeIcon className={classes.history} icon={faHistory} size="xs" />}
    </Avatar>
}