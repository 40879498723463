import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable, { Icons } from 'material-table';
import React, { forwardRef, useMemo, useState } from "react";
import { AddLicenseData, ADD_LICENSE, RemoveLicenseData, REMOVE_LICENSE } from '../queries/firm';
import { useManagedMutation } from '../queries/lib/hooks';
import { AuthenticatedUser } from '../queries/user';
import { StateAttorneyLink, StateTitle, STATE_MAP } from '../services/states';
import { AttorneyLicense } from '../types/license';
import { useConfirmDialog } from './ConfirmDialogProvider';
import ExternalLink from './ExternalLink';
import { LicenseDisplay } from './LicenseDisplay';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stateIcon: {
            '& svg': {
                display: 'inline-block',
                maxHeight: '3em',
                width: '3em',
                verticalAlign: 'middle',
                '& path': {
                    fill: theme.palette.text.primary,
                }
            }
        },
        removeButton: {
            padding: '4px',
            minWidth: '1rem'
        },
        formControl: {
            marginBottom: theme.spacing(2)
        },
        buttonBar: {
            display: 'flex',
            '& button': {
                marginLeft: theme.spacing(2)
            }
        }
    }));

/**
 * LicenseEditor provides an editor for the attorney licenses for the current user,
 * if any.
 */
export default function LicenseEditor(props: { user: AuthenticatedUser }) {
    const classes = useStyles();
    const { showConfirm } = useConfirmDialog();

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const [removeLicense, { loading: removing }] = useManagedMutation<any, RemoveLicenseData>(REMOVE_LICENSE);

    const askDeleteLicense = (license: AttorneyLicense) => {
        (async () => {
            const [result] = await showConfirm({
                'title': 'Remove attorney license?',
                'content': <div>
                    Are you sure you want to remove your license information for <strong>{StateTitle(license.stateId)}?</strong>
                    <div><br /></div>
                    <div>You will no longer be able to sign documents for this state.</div>
                </div>,
                'buttons': [
                    { 'title': 'Cancel', 'value': undefined },
                    { 'title': `Remove License For ${license.stateId.toUpperCase()}`, 'value': 'remove', 'variant': 'contained', 'color': 'secondary' },
                ]
            });

            if (result !== 'remove') {
                return;
            }

            await removeLicense({
                variables: {
                    stateId: license.stateId
                }
            })
        })();
    };

    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [createStateID, setCreateStateID] = useState('');
    const [createAttorneyID, setCreateAttorneyID] = useState('');

    const states = useMemo(() => {
        return Object.keys(STATE_MAP).map((stateId: string) => {
            return <MenuItem key={stateId} value={stateId}>
                {StateTitle(stateId)}
            </MenuItem>;
        });
    }, []);

    const askRegisterLicense = () => {
        setCreateStateID('');
        setCreateAttorneyID('');
        setIsCreateOpen(true);
    };

    const [addLicense, { loading: adding }] = useManagedMutation<any, AddLicenseData>(ADD_LICENSE);

    const handleAddLicense = () => {
        (async () => {
            const result = await addLicense({
                variables: {
                    stateId: createStateID,
                    attorneyId: createAttorneyID
                }
            });
            if (result) {
                setIsCreateOpen(false);
            }
        })();
    };

    const table = useMemo(() => {
        return <MaterialTable
            icons={tableIcons}
            columns={[
                {
                    title: "State", field: "stateId", render: (data: AttorneyLicense) => {
                        return <LicenseDisplay license={data} />;
                    },
                    customFilterAndSearch: (term: string, data: AttorneyLicense) => {
                        return StateTitle(data.stateId).toLowerCase().includes(term.toLowerCase());
                    },
                    width: '800px'
                },
                {
                    title: "Attorney ID", field: "attorneyId", render: (data: AttorneyLicense) => {
                        const attorneyLink = StateAttorneyLink(data.stateId, data.attorneyId);
                        if (attorneyLink) {
                            return <code><ExternalLink to={attorneyLink}>{data.attorneyId}</ExternalLink></code>;
                        } else {
                            return <code>{data.attorneyId}</code>;
                        }
                    },
                    width: '200px'
                },
                {
                    title: "", field: "actions", render: (license: AttorneyLicense) => {
                        return <Tooltip title="Remove" aria-label="remove">
                            <span>
                                <Button disabled={removing} className={classes.removeButton} onClick={() => askDeleteLicense(license)}><DeleteForeverIcon /></Button>
                            </span>
                        </Tooltip>
                    },
                    width: '1em'
                },
            ]}
            // See: https://stackoverflow.com/questions/59648434/material-table-typeerror-cannot-add-property-tabledata-object-is-not-extensibl
            data={props.user.licenses.map((o: AttorneyLicense) => ({ ...o }))}
            title={
                <div className={classes.buttonBar}>
                    <Button variant="contained" color="primary" onClick={askRegisterLicense}>Add License</Button>
                </div>}
        />;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.licenses, removing, askDeleteLicense]);

    return <div>
        {table}
        <Dialog
            open={isCreateOpen}
            onClose={() => setIsCreateOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Register License</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please choose the state and enter the attorney ID for your license:
                </DialogContentText>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel>State:</InputLabel>
                    <Select
                        disabled={adding}
                        value={createStateID}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => setCreateStateID(event.target.value as string)}
                    >
                        {states}
                    </Select>
                </FormControl>
                <TextField fullWidth disabled={adding} label="Attorney ID" value={createAttorneyID} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCreateAttorneyID(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsCreateOpen(false)} color="primary">
                    Cancel
                </Button>
                <Button disabled={adding || !createStateID || !createAttorneyID} color="primary" variant="contained" onClick={handleAddLicense}>
                    Add License
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
}