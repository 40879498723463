import toordinaleng from 'integer-to-ordinal-english';
import numberToWords from 'number-to-words';
import ordinal from 'ordinal';
import { FieldSwitch } from "./docx";

export function runNodeSwitch(stringValue: string, fieldSwitch: FieldSwitch): string {
    switch (fieldSwitch.control) {
        case '\\*':
            return generalFormatField(stringValue, fieldSwitch);

        case '\\#':
            return numericFormatField(stringValue, fieldSwitch);

        case '\\@':
            // TODO: date/time handlers not supported.
            return stringValue;
    }

    return stringValue;
}

function ensureNumber(stringValue: string, wrapper: (value: number) => string): string {
    const parsed = parseFloat(stringValue);
    if (Number.isNaN(parsed)) {
        return `ERROR: "${stringValue}" is not a number!`
    }
    return wrapper(parsed);
}

function numericFormatField(stringValue: string, fieldSwitch: FieldSwitch): string {
    return ensureNumber(stringValue, (n) => {
        // Handle positive;negative;(;zero) formatting.
        if (fieldSwitch.format.indexOf(';') > 0) {
            const formats = fieldSwitch.format.split(';');
            if (formats.length === 3) {
                const [positive, negative, zero] = formats;
                if (n > 0) {
                    return processNumericFormat(n, positive);
                } else if (n < 0) {
                    return processNumericFormat(n, negative);
                } else {
                    return processNumericFormat(n, zero);
                }
            } else if (formats.length === 2) {
                const [positive, negative] = formats;
                if (n >= 0) {
                    return processNumericFormat(n, positive);
                } else {
                    return processNumericFormat(n, negative);
                }
            } else {
                return stringValue;
            }
        }

        return processNumericFormat(n, fieldSwitch.format);
    });
}

function processNumericFormat(n: number, format: string): string {
    // Collect the number of # or 0 *before* the decimal point, and use that as the starting
    // position.
    let insideTextForFilter = false;
    let position = Array.from(format.split('.').slice(0, 1).join('')).filter((p) => {
        if (p === "'") {
            insideTextForFilter = !insideTextForFilter;
        }

        if (insideTextForFilter) {
            return false;
        }

        return p === '#' || p === '0';
    }).length;
    let afterDecimal = false;

    const handlePositionalDigit = (def: string) => {
        if (afterDecimal) {
            const pieces = n.toString().split('.', 2);
            if (pieces.length === 1) {
                position++;
                return def;
            }

            const [, decimal] = pieces;
            if (position >= decimal.length) {
                return def;
            }
            return decimal[position++];
        }

        const characters = Math.abs(Math.floor(n)).toString().split('').reverse();
        position--;
        if (position >= characters.length) {
            return def;
        }

        if (characters[position] === undefined) {
            throw Error('broken position')
        }

        return characters[position];
    };

    let insideText = false;

    return Array.from(format).map((p: string) => {
        if (insideText && p !== "'") {
            return p;
        }

        switch (p) {
            case "'":
                insideText = !insideText;
                return '';

            case '+':
                return n > 0 ? '+' : ' ';

            case '-':
                return n < 0 ? '-' : ' ';

            case '.':
                afterDecimal = true;
                position = 0;
                return '.';

            case '#':
                return handlePositionalDigit(' ');

            case '0':
                return handlePositionalDigit('0');

            default:
                return p;
        }
    }).join('');
}

function generalFormatField(stringValue: string, fieldSwitch: FieldSwitch): string {
    // Reference: http://officeopenxml.com/WPgeneralFieldSwitches.php
    switch (fieldSwitch.format) {
        case 'Arabic':
            return ensureNumber(stringValue, (n) => n.toString());

        case 'CardText':
            return ensureNumber(stringValue, numberToWords.toWords);

        case 'DollarText':
            return ensureNumber(stringValue, (n) => {
                const words = numberToWords.toWords(n);
                const remainder = n - Math.floor(n);
                if (remainder === 0) {
                    return words;
                }
                return `${words} and ${Math.round(remainder * 100)}/100`
            });

        case 'Ordinal':
            return ensureNumber(stringValue, ordinal);

        case 'OrdText':
            return ensureNumber(stringValue, (n) => {
                const r = Math.round(n);
                return toordinaleng(r).toLowerCase().replaceAll(', ', ' ').replaceAll(' and ', ' ');
            });

        case 'Roman':
            return ensureNumber(stringValue, (n) => {
                return romanize(Math.round(n));
            });

        case 'roman':
            return ensureNumber(stringValue, (n) => {
                return romanize(Math.round(n)).toLowerCase();
            });

        case 'Caps':
            return stringValue.replace(/\w\S*/g, function (txt: string) {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            });

        case 'FirstCap':
            return stringValue.replace(/\w\S*/g, function (txt: string, index: number) {
                if (index > 0) {
                    return txt;
                }
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
            });

        case 'Lower':
            return stringValue.toLowerCase();

        case 'Upper':
            return stringValue.toUpperCase();
    }

    return stringValue;
}


// From: https://stackoverflow.com/a/9083076/15918573
export function romanize(num: number): string {
    if (isNaN(num)) {
        return '';
    }

    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--) {
        roman = (key[+digits.pop()! + (i * 10)] || "") + roman;
    }
    return Array(+digits.join("") + 1).join("M") + roman;
}