import { debounce } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DatabaseOptions } from "../hooks";
import { DatabaseSchemaDefinition } from "../schema";
import { useVersionedDatabase, VersionedState } from "../versioned";
import { DiffableDatabaseImpl } from "./database";
import { DiffableDatabase, MutationTransaction } from "./interfaces";


export type DiffableVersionedState = VersionedState & {
    diffableDatabase: DiffableDatabase | undefined
}

/**
 * useDiffableVersionedDatabase is a React hook which loads a diffable versionined SQLite database
 * in-memory and provides a nice accessor around it.
 * @example const { loading, database } = useDiffableVersionedDatabase(theSchemaDefinition);
 *          <DatabaseContext.Provider value={database}>...</DatabaseContext>
 */
export function useDiffableVersionedDatabase(schema: DatabaseSchemaDefinition, options: DatabaseOptions): DiffableVersionedState {
    const databaseState = useVersionedDatabase(schema, options);
    const [diffableDatabase, setDiffableDatabase] = useState<DiffableDatabase | undefined>(undefined);

    useEffect(() => {
        if (!databaseState.loading && diffableDatabase === undefined) {
            setDiffableDatabase(new DiffableDatabaseImpl(databaseState.database!));
        }

        // NOTE: We don't want the effect to run again if we set the diffable database.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databaseState]);

    return {
        ...databaseState,
        diffableDatabase: diffableDatabase
    };
}

/**
 * MutationTransactionState holds the current state of the mutation transaction.
 */
export interface MutationTransactionState {
    mutationIndex: number
    currentTransaction: MutationTransaction
}

/**
 * useMutationWatcher is a React hook which will be invoked on any mutations to the database.
 * @param database The database to watch for mutations.
 */
export function useMutationWatcher(database: DiffableDatabase, debounceRate?: number) {
    const [mutationState, setMutationState] = useState({
        mutationIndex: -1,
        currentTransaction: database.transaction,
    })

    useEffect(() => {
        let callback = () => {
            setMutationState({
                mutationIndex: database.transaction.currentMutationIndex(),
                currentTransaction: database.transaction
            })
        };
        if (debounceRate) {
            callback = debounce(callback, debounceRate);
        }

        const callbackHandle = database.registerMutatedCallback(callback);
        return () => {
            database?.unregisterMutatedCallback(callbackHandle);
        };
    }, [database, debounceRate]);
    return mutationState;
}