import { ApolloProvider } from '@apollo/client';
import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut, useUser } from '@clerk/clerk-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfirmProvider } from 'material-ui-confirm';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { BrowserRouter, Route, useHistory } from "react-router-dom";
import './App.css';
import { AlertProvider } from './components/AlertProvider';
import AppLanding from './components/AppLanding';
import AuthedAppBar from './components/AuthedAppBar';
import CaseLayout from './components/CaseLayout';
import { ConfirmDialogProvider } from './components/ConfirmDialogProvider';
import { CurrentUserContext } from './components/CurrentContext';
import { FirmManagementPanel } from './components/firm/FirmManagementPanel';
import LoadingView from './components/LoadingView';
import { NoFirmLanding } from './components/NoFirmLanding';
import { SideDrawerOpenContext } from './components/SideDrawer';
import { UserIssueDisplay } from './components/UserIssueDisplay';
import { UserSettingsView } from './components/UserSettingsView';
import { useFixedQuery } from './queries/lib/hooks';
import { LOOKUP_USER, UserData } from './queries/user';
import buildClient from './services/apollo';
import { useAuthenticationService } from './services/authentication';
import { useApplicationConfig } from './services/confighook';
import { useMixpanel } from './services/mixpanel';
import Themed from './themed';
import { Firm } from './types/firm';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    userError: {
      display: 'block',
      width: '100%',
      padding: theme.spacing(5),
      textAlign: 'center'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    caselessContent: {
      flexGrow: 1,
    },
    toolbarSpacer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    notVerified: {
      textAlign: 'center'
    }
  }));


/**
 * App is the entrypoint for the application.
 */
export default function App() {
  const appConfig = useApplicationConfig();
  if (appConfig === undefined) {
    return <Themed><LoadingView message="Initialized" /></Themed>;
  }

  if (appConfig.sentry.dsn) {
    Sentry.init({
      dsn: appConfig.sentry.dsn || undefined,
      release: "frontend@" + process.env.npm_package_version,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
  };

  return <BrowserRouter>
    <UnderRouter />
  </BrowserRouter>;
}

function UnderRouter() {
  const history = useHistory();
  const appConfig = useApplicationConfig()!;

  return <ConfirmProvider>
    <ClerkProvider frontendApi={appConfig.clerk.frontend_api_key} navigate={(to) => history.push(to)}>
      <SignedIn>
        <SignedInApp />
      </SignedIn>
      <SignedOut>
        Redirecting...
        <RedirectToSignIn />
      </SignedOut>
    </ClerkProvider>
  </ConfirmProvider>;
}

function SignedInApp() {
  const classes = useStyles();
  const { user, isLoaded, isSignedIn } = useUser();
  const { getToken } = useAuthenticationService();

  const appConfig = useApplicationConfig();
  const mixPanel = useMixpanel(appConfig?.mixpanel.token);

  const email = user?.primaryEmailAddress?.emailAddress ?? '(unknown)';
  const name = user ? user.fullName || user.username || email : undefined;

  useEffect(() => {
    if (isSignedIn && user) {
      Sentry.setUser({ email: email, name: user.username, id: user.id });

      mixPanel.identifyUser(email);
      mixPanel.setUserProperties({
        id: user.id,
        $email: email,
        $name: name,
        $avatar: user.profileImageUrl,
        name: name,
      })
    }

    // Mixpanel is merely a reference.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoaded, isSignedIn]);

  if (!isLoaded || appConfig === undefined) {
    return <div>Loading ...</div>;
  }

  if (user?.primaryEmailAddress?.verification.status !== 'verified') {
    return <Themed>
      <div className={classes.notVerified}>
        <UserIssueDisplay severity="info">
          Your e-mail address <code>{user?.primaryEmailAddress?.emailAddress}</code> is not verified. Please check your inbox or spam folder for an e-mail titled <code>Verify your email</code> and click the link within, before refreshing here.
        </UserIssueDisplay>
      </div>
    </Themed>
  }

  return <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
    <Themed>
      <div className={classes.userError}>
        <Alert severity="error">You have encountered an error. Please report this to support.</Alert>
        <details>
          <summary>Error Information</summary>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
        </details>
      </div>
    </Themed>
  )} dialogOptions={{
    user: {
      email: email,
      name: name,
    },
    title: 'It looks like something went wrong',
    subtitle2: 'Please describe what you were doing so we can address the problem',
  }} showDialog>
    <ApolloProvider client={buildClient(appConfig.endpoint, getToken)}>
      <AuthedApp />
    </ApolloProvider>
  </Sentry.ErrorBoundary>;
}

function AuthedApp() {
  const { loading: userIsLoading, error: hasUserError, data: userData } = useFixedQuery<UserData>(LOOKUP_USER);

  if (hasUserError) {
    return <Themed>
      <UserIssueDisplay severity="error">
        An error occurred when trying to load your account. Please refresh and try again. If the error still appears, please contact support as your account may be disabled.
      </UserIssueDisplay>
    </Themed>;
  }

  if (userIsLoading || !userData) {
    return <Themed><LoadingView message="Signing in..." /></Themed>;
  }

  return <WithCurrentUser userData={userData} />;
}

function WithCurrentUser(props: { userData: UserData }) {
  const classes = useStyles();
  const firm = props.userData.authenticatedUser.firm;

  return <Themed color={firm?.color || undefined}>
    <div>
      <AlertProvider>
        <CurrentUserContext.Provider value={props.userData.authenticatedUser}>
          <ConfirmDialogProvider>
            {!!firm && <Route path="/firm" exact>
              <AppLayout firm={firm}>
                <div className={classes.caselessContent}>
                  <div className={classes.toolbarSpacer} />
                  <FirmManagementPanel firm={firm} />
                </div>
              </AppLayout>
            </Route>}
            <Route path="/user" exact>
              <AppLayout firm={firm}>
                <div className={classes.caselessContent}>
                  <div className={classes.toolbarSpacer} />
                  <UserSettingsView currentUser={props.userData.authenticatedUser} />
                </div>
              </AppLayout>
            </Route>
            <Route path="/c/:caseid" render={(routeProps) => (
              <CaseAppLayout firm={firm} caseId={routeProps.match.params.caseid} />
            )} />
            <Route path="/" exact>
              <AppLayout firm={firm}>
                <div className={classes.caselessContent}>
                  <div className={classes.toolbarSpacer} />
                  {firm && <AppLanding currentUser={props.userData?.authenticatedUser} />}
                  {!firm && <NoFirmLanding currentUser={props.userData?.authenticatedUser} />}
                </div>
              </AppLayout>
            </Route>
          </ConfirmDialogProvider>
        </CurrentUserContext.Provider>
      </AlertProvider>
    </div>
  </Themed>
}

function CaseAppLayout(props: { caseId: string, firm: Firm | null | undefined }) {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const handleToggleDrawer = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const { currentUser } = useAuthenticationService();

  return <>
    <SideDrawerOpenContext.Provider value={sideBarOpen}>
      <AuthedAppBar
        toggleDrawer={handleToggleDrawer}
        isSideBarOpen={sideBarOpen}
        user={currentUser!}
        firm={props.firm}
        currentCaseId={props.caseId} />
      <CaseLayout caseId={props.caseId} isSideBarOpen={sideBarOpen} toggleDrawer={handleToggleDrawer} />
    </SideDrawerOpenContext.Provider>
  </>
}

function AppLayout(props: PropsWithChildren<{ firm: Firm | null | undefined }>) {
  const { currentUser } = useAuthenticationService();

  return <>
    <SideDrawerOpenContext.Provider value={false}>
      <AuthedAppBar
        toggleDrawer={() => null}
        isSideBarOpen={false}
        user={currentUser!}
        firm={props.firm}
        currentCaseId={''} />
      {props.children}
    </SideDrawerOpenContext.Provider>
  </>
}