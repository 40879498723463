import { createStyles, FormControl, InputLabel, makeStyles, Theme } from "@material-ui/core";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import Alert from "@material-ui/lab/Alert";
import clsx from 'clsx';
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        imagePreview: {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            '& img': {
                maxHeight: '200px',
                border: `1px solid ${theme.palette.divider}`
            },
        },
        field: {
            padding: theme.spacing(1)
        },
        normal: {
            position: 'relative',
            '& > fieldset': {
                borderColor: '',
                borderWidth: 1,
            },
            '&:hover > fieldset': {
                borderColor: theme.palette.text.primary,
            }
        },
        editing: {
            '& > fieldset': {
                borderColor: theme.palette.primary.main + ' !important',
                borderWidth: '2px !important',
            }
        },
        edited: {
            '& > fieldset': {
                borderColor: theme.palette.warning.main,
                borderWidth: 2,
            }
        },
        outline: {
            borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            borderRadius: 4
        },
        control: {
            padding: theme.spacing(1.5),
            '& .react-tel-input .form-control': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                border: 0,
                fontSize: '100%',
                width: '100%'
            },
            '& .react-tel-input .flag-dropdown': {
                borderColor: theme.palette.divider,
                borderWidth: 0,
                borderRightWidth: 1,
            },
            '& .react-tel-input .flag-dropdown.open': {
                backgroundColor: theme.palette.background.default,
            },
            '& .react-tel-input .flag-dropdown.open .selected-flag': {
                backgroundColor: theme.palette.primary.dark,
            },
            '& .react-tel-input .country-list': {
                backgroundColor: theme.palette.background.paper,
                borderColor: 'transparent',
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
            },
            '& .react-tel-input .country-list .country:hover': {
                backgroundColor: theme.palette.action.hover
            },
            '& .react-tel-input .country-list .country.highlight': {
                backgroundColor: theme.palette.action.hover
            },
        }
    }));


export function CasolioPhoneInput(props: { label: string, value: string, onChange: (value: string) => void }) {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    return <FormControl fullWidth>
        <div
            className={clsx(classes.normal, {
                [classes.editing]: isEditing,
            })}
        >
            <InputLabel style={{ position: 'absolute' }} shrink={true} variant="outlined">
                {props.label}
            </InputLabel>
            <NotchedOutline className={classes.outline} labelWidth={props.label.length * 14} /*label={props.title}*/ notched={true} />
            <div className={classes.control}>
                <PhoneInput
                    country={'us'}
                    value={props.value}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    onChange={(phone, country, e, formattedValue) => props.onChange(formattedValue)}
                />
            </div>
        </div>
    </FormControl>
}


export function CasolioColorInput(props: { label: string, value: string, onChange: (value: string) => void }) {
    const classes = useStyles();

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    };

    return <FormControl fullWidth>
        <div
            className={clsx(classes.normal)}
        >
            <InputLabel style={{ position: 'absolute' }} shrink={true} variant="outlined">
                {props.label}
            </InputLabel>
            <NotchedOutline className={classes.outline} labelWidth={props.label.length * 14} /*label={props.title}*/ notched={true} />
            <div className={classes.control}>
                <input type="color" value={props.value} onChange={handleChanged} />
            </div>
        </div>
    </FormControl>
}

export function CasolioImageInput(props: { label: string, value: string | undefined | null, maxByteSize?: number, bgColor: string, onChange: (value: string | undefined) => void }) {
    const classes = useStyles();
    const [error, setError] = useState<string | undefined>(undefined);

    const handleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length !== 1) {
            props.onChange(undefined);
            return;
        }

        const reader = new FileReader();

        reader.addEventListener("load", function () {
            if (reader.result) {
                const dataUrl = reader.result.toString();
                console.log(dataUrl.length)
                console.log(props.maxByteSize)
                if (props.maxByteSize !== undefined && dataUrl.length > props.maxByteSize) {
                    setError(`Provided ${props.label} is too large. Please try a smaller image.`)
                    return
                }

                setError(undefined);
                props.onChange(dataUrl.substring('data:'.length));
            }
        }, false);

        reader.readAsDataURL(e.target.files[0]);
    };

    return <FormControl fullWidth>
        <div
            className={clsx(classes.normal)}
        >
            <InputLabel style={{ position: 'absolute' }} shrink={true} variant="outlined">
                {props.label}
            </InputLabel>
            <NotchedOutline className={classes.outline} labelWidth={props.label.length * 14} /*label={props.title}*/ notched={true} />
            <div className={classes.control} >
                {error && <Alert severity="error">{error}</Alert>}
                {!!props.value && <div className={classes.imagePreview}>
                    <img src={`data:${props.value}`} alt={props.label} style={{ backgroundColor: props.bgColor }} />
                </div>}
                <input type="file" onChange={handleChanged} accept="image/*" />
            </div>
        </div>
    </FormControl>
}
