import {
  faBalanceScale,
  faFileContract,
  faFunnelDollar,
  faPercent,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import React, { useContext, useState } from "react";
import stc from "string-to-color";
import { AddressEditorField } from "../../components/fields/AddressField";
import { AutocompleteField } from "../../components/fields/AutocompleteField";
import { BooleanField } from "../../components/fields/BooleanField";
import { CurrencyField } from "../../components/fields/CurrencyField";
import { DateField } from "../../components/fields/DateField";
import { DocumentDateField } from "../../components/fields/DocumentDateField";
import { EmailField } from "../../components/fields/EmailField";
import { ListField } from "../../components/fields/ListField";
import { MultiEnumField } from "../../components/fields/MultiEnumField";
import { PhoneNumberField } from "../../components/fields/PhoneNumberField";
import { RadioBooleanField } from "../../components/fields/RadioBooleanField";
import {
  levelStringForDepth,
  RecursiveField,
} from "../../components/fields/RecursiveField";
import { SelectRowField } from "../../components/fields/SelectRowField";
import { SSNField } from "../../components/fields/SSNField";
import { ValueField } from "../../components/fields/valuefield";
import { FilterBox } from "../../components/FilterBox";
import { QueryRenderer, RowRenderer } from "../../database/diffable/components";
import { DatabaseContext } from "../../database/diffable/context";
import { useMutationWatcher } from "../../database/diffable/hooks";
import {
  DiffableDatabase,
  MutableRow,
  RowType,
} from "../../database/diffable/interfaces";
import { useQuery } from "../../database/diffable/query";
import { sql } from "../../database/sql";
import { Address } from "../../sharedschema/address";
import { Case } from "../../types/case";
import { BitwiseSet } from "../../util/bitwiseset";
import { Panel, useFlowStyles } from "../uibuilder";
import {
  ChildBequest,
  Party,
  PartyRelation,
  PartyRelationDescription,
  WillDetails,
} from "./model";

export const WillPanels: Panel[] = [
  {
    path: "basic",
    title: "Basic Information",
    icon: faFileContract,
    renderer: BasicInfoEditor,
    tableBindings: [sql`select * from ${"willdetails"}`],
  },
  {
    path: "parties",
    title: "Parties",
    icon: faUsers,
    renderer: PartyEditor,
    tableBindings: [sql`select * from ${"party"}`],
  },
  {
    path: "bequests",
    title: "Bequests",
    icon: faPercent,
    renderer: BequestEditor,
    tableBindings: [sql`select * from ${"party"}`],
  },
  {
    path: "poa",
    title: "Power of Attorney",
    icon: faBalanceScale,
    renderer: POAEditor,
    tableBindings: [sql`select * from ${"party"}`],
  },
  {
    path: "trust",
    title: "Information for Trust",
    icon: faFunnelDollar,
    renderer: TrustEditor,
    tableBindings: [sql`select * from ${"party"}`],
  },
];

function BasicInfoEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faFileContract} size="lg" />
        Basic Will Information
      </Typography>

      <Paper className={classes.paper}>
        <RowRenderer<WillDetails> query={sql`select * from ${"willdetails"}`}>
          {(row: WillDetails) => {
            return (
              <fieldset className={classes.fieldset}>
                <DocumentDateField
                  column="willDate"
                  fullWidth
                  title="Will Date"
                />
              </fieldset>
            );
          }}
        </RowRenderer>
      </Paper>
    </div>
  );
}

function PartyIcon(props: { party: MutableRow<Party>; small?: boolean }) {
  const partyColor = stc(props.party.getField("name"));
  let style: React.CSSProperties = { backgroundColor: partyColor };
  if (props.small) {
    style = {
      ...style,
      width: "1.5em",
      height: "1.5em",
    };
  }

  return <Avatar style={style}>{props.party.getField("name")[0]}</Avatar>;
}

function PartyEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const [filter, setFilter] = useState("");

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header} variant="h5">
          <FontAwesomeIcon icon={faUsers} size="lg" />
          Parties
        </Typography>
        <FilterBox
          filter={filter}
          onFilterChanged={setFilter}
          placeholder="John Done"
          label="Filter by Party Name"
        />
      </div>

      <ListField<Party>
        collapsableItems
        itemFilter={filter}
        tableName="party"
        rowTitle="Party"
        empty={{
          name: "",
        }}
        addDescription="Added new party"
        removeDescription="Removed party"
        filterFunction={(
          row: MutableRow<Party>,
          index: number,
          itemFilter: string
        ) => {
          const name = row.getField("name");
          return (
            !name || name.toLowerCase().indexOf(itemFilter.toLowerCase()) >= 0
          );
        }}
        describeRow={(row: MutableRow<Party>, index: number) => {
          if (row.getField("name")) {
            return (
              <h3 className={classes.subHeader} style={{ marginLeft: "10px" }}>
                <PartyIcon party={row} small />
                <span>
                  {row.getField("name")} - Party #{index + 1}
                </span>
              </h3>
            );
          }
          return undefined;
        }}
      >
        {(row: MutableRow<Party>, index: number) => {
          const loadAddressForPreviousParty = (database: DiffableDatabase) => {
            const results = database.selectAllResults<Party>(
              sql`select * from ${"party"} where id < ${row.id()} order by id`
            );
            const previousParty = results[results.length - 1];
            return previousParty as MutableRow<Address>;
          };

          return (
            <fieldset className={classes.fieldset}>
              <ValueField
                column="name"
                fullWidth
                title={`Party ${index + 1} Name`}
                placeholder="Full Name"
              />
              <MultiEnumField<PartyRelation>
                values={PartyRelationDescription}
                fullWidth
                column="willRelation"
                title={`Party ${index + 1} Relation to Matter`}
                placeholder="Relation"
              />
              <AddressEditorField
                title={`Party ${index + 1} Address`}
                placeholder="Address"
                copyFromTitle={
                  index > 0 ? "Copy From Previous Beneficiary" : undefined
                }
                copyFrom={(database: DiffableDatabase) =>
                  loadAddressForPreviousParty(database)
                }
              />
              <EmailField
                fullWidth
                column="emailAddress"
                title={`Party ${index + 1} E-mail Address`}
                placeholder="E-mail address"
              />
              <PhoneNumberField
                fullWidth
                column="phoneNumber"
                title={`Party ${index + 1} Phone Number`}
                placeholder="Phone number"
              />
              <DateField
                column="dob"
                fullWidth
                title={`Party ${index + 1} Date of Birth`}
                placeholder="Date of Birth"
              />
              <SSNField
                column="ssn"
                fullWidth
                title={`Party ${index + 1} Social Security Number`}
                placeholder="Social Security Number"
              />
              <AutocompleteField
                column="estateSettlorRelation"
                fullWidth
                title={`Party ${index + 1} Relation to Estate Settlor`}
                placeholder="Relation to Estate Settlor (select or enter custom)"
                prefilledOptions={[
                  "son",
                  "daughter",
                  "husband",
                  "wife",
                  "nephew",
                  "niece",
                  "cousin",
                  "step son",
                  "step daughter",
                  "uncle",
                  "aunt",
                  "father",
                  "mother",
                  "self",
                  "grandchild",
                ]}
              />
              <BooleanField
                column="isMinor"
                fullWidth
                title={`Party ${index + 1} is a minor`}
                localTitle="Party is a minor"
              />
            </fieldset>
          );
        }}
      </ListField>
    </div>
  );
}

type BeneficiaryRowType = { residuaryEstateBequest: string } & RowType;

function useResiduary<P extends BeneficiaryRowType>(
  beneficiaries: MutableRow<P>[] | undefined
) {
  const database = useContext(DatabaseContext)!;

  // Ensure we get updated on database mutations.
  useMutationWatcher(database!, 50);

  const residuaries = beneficiaries
    ?.map((beneficiary: MutableRow<P>) => {
      const residuary = beneficiary.getField("residuaryEstateBequest");
      if (!residuary || !residuary.endsWith("%")) {
        return undefined;
      }

      if (residuary.toLowerCase() === "all") {
        return 100;
      }

      return parseFloat(residuary);
    })
    .filter((residuary: number | undefined) => !!residuary);

  if (!residuaries?.length) {
    return undefined;
  }

  return residuaries?.reduce(
    (
      totalResiduary: number | undefined,
      currentResiduary: number | undefined
    ) => (totalResiduary ?? 0) + (currentResiduary ?? 0)
  );
}

function TotalResiduaryRemaining<P extends BeneficiaryRowType>(props: {
  beneficiaries: MutableRow<P>[] | undefined;
  total?: string;
}) {
  const totalResiduary = useResiduary<P>(props.beneficiaries);
  let totalAllowed = 100;
  if (props.total && props.total.endsWith("%")) {
    totalAllowed = parseFloat(props.total);
  }

  return (
    <div>
      {totalResiduary !== undefined && totalResiduary < totalAllowed && (
        <Alert severity="info" style={{ marginBottom: "20px" }}>
          {totalAllowed - totalResiduary}% of residuary remaining
        </Alert>
      )}
      {totalResiduary !== undefined && totalResiduary > totalAllowed && (
        <Alert severity="warning" style={{ marginBottom: "20px" }}>
          {totalResiduary}% of residuary is above {totalAllowed}%
        </Alert>
      )}
    </div>
  );
}

function BequestEditor(props: { case: Case }) {
  const classes = useFlowStyles();

  const { results } = useQuery<Party>(sql`select * from ${"party"}`);

  const beneficiaries = results?.filter((party: MutableRow<Party>) => {
    const willRelation = party.getField("willRelation");
    return BitwiseSet.encodedHas(willRelation, PartyRelation.BENEFICIARY);
  });

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faPercent} size="lg" />
        Bequests
      </Typography>

      <TotalResiduaryRemaining<Party> beneficiaries={beneficiaries} />

      {beneficiaries?.length === 0 && (
        <Paper className={classes.captionDisplay}>
          <Typography color="textSecondary">
            No Beneficiaries Established
          </Typography>
        </Paper>
      )}

      <QueryRenderer query={sql`select * from ${"party"}`}>
        {(result: MutableRow<Party>) => {
          const willRelation = result.getField("willRelation");
          if (!BitwiseSet.encodedHas(willRelation, PartyRelation.BENEFICIARY)) {
            return <div></div>;
          }

          return (
            <BeneficiaryEditor
              beneficiary={result}
              beneficiaries={beneficiaries}
            />
          );
        }}
      </QueryRenderer>
    </div>
  );
}

function ResiduaryField<P extends BeneficiaryRowType>(props: {
  btitle: string;
  beneficiary: MutableRow<P>;
  beneficiaries: MutableRow<P>[] | undefined;
  total?: string;
}) {
  const totalResiduary = useResiduary(props.beneficiaries);
  let totalAllowed = 100;
  if (props.total && props.total.endsWith("%")) {
    totalAllowed = parseFloat(props.total);
  }

  return (
    <ValueField
      column="residuaryEstateBequest"
      fullWidth
      title={`${props.btitle} Residuary Estate Bequest`}
      placeholder="Residuary Estate Bequest"
      clickToFill={
        !props.beneficiary.getField("residuaryEstateBequest") &&
        totalResiduary &&
        totalResiduary < totalAllowed
          ? {
              title: `Add remaining ${
                totalAllowed - totalResiduary
              }% residuary`,
              value: `${totalAllowed - totalResiduary}%`,
            }
          : undefined
      }
    />
  );
}

function BeneficiaryEditor(props: {
  beneficiary: MutableRow<Party>;
  beneficiaries: MutableRow<Party>[] | undefined;
}) {
  const classes = useFlowStyles();

  const beneficiary = props.beneficiary;
  const btitle = beneficiary.getField("name") || "Unnamed Beneficiary";

  return (
    <Paper
      className={classes.paper}
      style={{
        borderLeft: `1px solid ${stc(beneficiary.getField("name") || "")}`,
      }}
    >
      <Typography className={classes.subHeader} variant="h6">
        <PartyIcon party={props.beneficiary} small />
        {btitle}
      </Typography>
      <fieldset className={classes.fieldset}>
        <RadioBooleanField
          column="isResiduaryIfNoSurvivingBeneficiaries"
          fullWidth
          title={`${btitle} is Residuary If No Surviving Beneficiaries`}
          placeholder="Residuary If No Surviving Beneficiaries"
        />
        <BooleanField
          column="tangiblePersonalProperty"
          fullWidth
          title={`${btitle} Tangible Personal Property`}
          localTitle="Tangible Personal Property"
        />
        <ResiduaryField
          btitle={btitle}
          beneficiary={beneficiary}
          beneficiaries={props.beneficiaries}
        />

        <ValueField
          column="specificBequest"
          fullWidth
          title={`${btitle} Specific Bequest`}
          placeholder="Specific Bequest (Optional)"
        />

        <RecursiveField<ChildBequest, Party>
          rootRow={beneficiary}
          recursiveTableName="childbequest"
          rootFieldName="rootPartyId"
          parentFieldName="parentChildBequestId"
          rowTitle="Bequest"
          maximumCount={20}
          maximumDepth={3}
          currentDepth={0}
          empty={{
            bequestPartyId: null,
          }}
          nestedTitle={(
            row: MutableRow<ChildBequest> | undefined,
            depth: number,
            index: number
          ) => {
            return `${levelStringForDepth(depth)} Beneficiary #${index + 1}`;
          }}
          conditionalToggle={(
            nestedTitle: string | undefined,
            update: (value: boolean) => any
          ) => {
            return (
              <BooleanField
                column="isPerStirpes"
                fullWidth
                title={`${nestedTitle} Per stirpes`}
                localTitle="Per stirpes"
                onChange={(value: boolean) => update(!value)}
              />
            );
          }}
          conditionalStartingValue={(row: MutableRow<ChildBequest>) => {
            return row.getField("isPerStirpes") !== 1;
          }}
          header={(results: MutableRow<ChildBequest>[]) => {
            return (
              <div>
                <TotalResiduaryRemaining<ChildBequest>
                  beneficiaries={results}
                  total={props.beneficiary.getField("residuaryEstateBequest")}
                />
              </div>
            );
          }}
        >
          {(
            row: MutableRow<ChildBequest>,
            currentDepth: number,
            rows: MutableRow<ChildBequest>[]
          ) => {
            const description = levelStringForDepth(currentDepth);
            return (
              <fieldset className={classes.nestedFieldset}>
                <SelectRowField<Party>
                  fullWidth
                  column="bequestPartyId"
                  tableName="party"
                  title={`${description} bequest for beneficiary ${btitle}`}
                  placeholder={`${description} Beneficiary`}
                  filter={(party: MutableRow<Party>) => {
                    return (
                      party.getField("name") &&
                      party.getField("willRelation") ===
                        PartyRelation.BENEFICIARY &&
                      party.id() !== beneficiary.id()
                    );
                  }}
                  render={(party: MutableRow<Party>) => {
                    return party.getField("name");
                  }}
                  describe={(party: MutableRow<Party>) => {
                    return party.getField("name")!;
                  }}
                />
                <ResiduaryField<ChildBequest>
                  beneficiary={row}
                  beneficiaries={rows}
                  btitle={description}
                  total={props.beneficiary.getField("residuaryEstateBequest")}
                />
              </fieldset>
            );
          }}
        </RecursiveField>
      </fieldset>
    </Paper>
  );
}

function POAEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const { results } = useQuery<Party>(sql`select * from ${"party"}`);

  const poas = results?.filter((party: MutableRow<Party>) => {
    const willRelation = party.getField("willRelation");
    return (
      BitwiseSet.encodedHas(willRelation, PartyRelation.POA_PRINCIPAL) ||
      BitwiseSet.encodedHas(willRelation, PartyRelation.POA_AGENT) ||
      BitwiseSet.encodedHas(willRelation, PartyRelation.POA_MEDICAL_AGENT)
    );
  });

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faBalanceScale} size="lg" />
        Power of Attorney
      </Typography>

      <Paper className={classes.captionDisplay}>
        {poas?.length === 0 && (
          <Typography color="textSecondary">
            No Power of Attorney Parties Established
          </Typography>
        )}
        {poas?.map((poa: MutableRow<Party>) => {
          const willRelation = poa.getField("willRelation");
          if (
            BitwiseSet.encodedHas(willRelation, PartyRelation.POA_PRINCIPAL)
          ) {
            return <div>POA Principal: {poa.getField("name")}</div>;
          }

          if (BitwiseSet.encodedHas(willRelation, PartyRelation.POA_AGENT)) {
            return <div>POA Agent: {poa.getField("name")}</div>;
          }

          if (
            BitwiseSet.encodedHas(willRelation, PartyRelation.POA_MEDICAL_AGENT)
          ) {
            return <div>POA Medical Agent: {poa.getField("name")}</div>;
          }

          return undefined;
        })}
      </Paper>

      {poas !== undefined && poas.length > 0 && (
        <div>
          <RowRenderer<WillDetails> query={sql`select * from ${"willdetails"}`}>
            {(row: WillDetails) => {
              return (
                <div>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h6">
                      Power of Attorney Details
                    </Typography>
                    <fieldset className={classes.fieldset}>
                      <DocumentDateField
                        fullWidth
                        column="poaDate"
                        title="POA Date"
                      />
                      <ValueField
                        fullWidth
                        column="poaRealEstate"
                        title="POA Real Estate"
                      />
                      <CurrencyField
                        fullWidth
                        column="poaRealEstateSalePrice"
                        title="POA Real Estate Sale Price"
                      />
                    </fieldset>
                  </Paper>
                </div>
              );
            }}
          </RowRenderer>
        </div>
      )}
    </div>
  );
}

function TrustEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const { results } = useQuery<Party>(sql`select * from ${"party"}`);

  const trustees = results?.filter((party: MutableRow<Party>) => {
    const willRelation = party.getField("willRelation");
    return (
      BitwiseSet.encodedHas(willRelation, PartyRelation.PRIMARY_TRUSTEE) ||
      BitwiseSet.encodedHas(willRelation, PartyRelation.SECONDARY_TRUSTEE)
    );
  });

  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faFunnelDollar} size="lg" />
        Trust Information
      </Typography>

      <Paper className={classes.captionDisplay}>
        {trustees?.length === 0 && (
          <Typography color="textSecondary">
            No Trustee Parties Established
          </Typography>
        )}
        {trustees?.map((trustee: MutableRow<Party>) => {
          const willRelation = trustee.getField("willRelation");
          if (
            BitwiseSet.encodedHas(willRelation, PartyRelation.PRIMARY_TRUSTEE)
          ) {
            return (
              <div key={trustee.id()}>
                Primary Trustee: {trustee.getField("name")}
              </div>
            );
          }

          if (
            BitwiseSet.encodedHas(willRelation, PartyRelation.SECONDARY_TRUSTEE)
          ) {
            return (
              <div key={trustee.id()}>
                Secondary Trustee: {trustee.getField("name")}
              </div>
            );
          }

          return undefined;
        })}
      </Paper>

      {trustees !== undefined && trustees.length > 0 && (
        <div>
          <RowRenderer<WillDetails> query={sql`select * from ${"willdetails"}`}>
            {(row: WillDetails) => {
              return (
                <div>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h6">
                      Trust Details
                    </Typography>
                    <fieldset className={classes.fieldset}>
                      <DocumentDateField
                        fullWidth
                        column="trustDate"
                        title="Trust Date"
                      />
                    </fieldset>
                  </Paper>
                </div>
              );
            }}
          </RowRenderer>
        </div>
      )}
    </div>
  );
}
