import { faCog, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Chip, FormControlLabel, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { DeleteCaseDocumentData, DELETE_CASE_DOCUMENT, LOOKUP_CASE_DOCUMENTS } from '../../queries/documents';
import { useManagedMutation } from '../../queries/lib/hooks';
import { Case } from '../../types/case';
import { CreatedCaseDocument } from '../../types/generateddoc';
import BoundTable from '../BoundTable';
import { useConfirmDialog } from '../ConfirmDialogProvider';
import UserView from '../UserView';
import { DocumentIcon } from './DocumentIcon';
import { DocumentStarControl } from './DocumentStarControl';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.primary,
            display: 'block',
        },
        toolbar: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            alignItems: 'center',
        }
    }));


/**
 * GeneratedDocumentsPane is a pane showing all the generated documents for a case.
 */
export function GeneratedDocumentsPane(props: { case: Case }) {
    const classes = useStyles();
    const { showConfirm } = useConfirmDialog();

    const [deleteCaseDocument] = useManagedMutation<
        any,
        DeleteCaseDocumentData
    >(DELETE_CASE_DOCUMENT);

    const deleteDocument = (document: CreatedCaseDocument) => {
        (async () => {
            await deleteCaseDocument({
                variables: {
                    caseId: props.case.id,
                    documentId: document.id
                }
            })
        })();
    };

    const handleAskDelete = (document: CreatedCaseDocument) => {
        (async () => {
            const [result] = await showConfirm({
                'title': `Delete document ${document.title}?`,
                'content': `Are you sure you want to delete document ${document.title}?`,
                'buttons': [
                    {
                        'title': 'Cancel',
                        'value': undefined
                    },
                    {
                        'title': 'Delete Document',
                        'color': 'secondary',
                        'variant': 'contained',
                        'value': 'delete'
                    }
                ]
            });
            if (result === 'delete') {
                deleteDocument(document);
            }
        })();
    };

    const [includeOlderRevisions, setIncludeOlderRevisions] = useState(false);
    const handleChangeOlderRevisions = () => {
        setIncludeOlderRevisions(!includeOlderRevisions)
    };
    const theme = useTheme();

    const [checkedDocuments, setCheckedDocuments] = useState<CreatedCaseDocument[]>([]);
    const handleDocumentToggle = (doc: CreatedCaseDocument) => {
        const updated = Array.from(checkedDocuments);
        const index = checkedDocuments.indexOf(doc);
        if (index >= 0) {
            updated.splice(index, 1)
        } else {
            updated.push(doc)
        }
        setCheckedDocuments(updated)
    };

    const handleAskMultipleDelete = () => {
        (async () => {
            const [result] = await showConfirm({
                'title': `Delete selected documents?`,
                'content': `Are you sure you want to delete the selected ${checkedDocuments.length} document(s)?`,
                'buttons': [
                    {
                        'title': 'Cancel',
                        'value': undefined
                    },
                    {
                        'title': `Delete ${checkedDocuments.length} Documents`,
                        'color': 'secondary',
                        'variant': 'contained',
                        'value': 'delete'
                    }
                ]
            });
            if (result === 'delete') {
                for (const document of checkedDocuments) {
                    await deleteCaseDocument({
                        variables: {
                            caseId: props.case.id,
                            documentId: document.id
                        }
                    })
                }
                setCheckedDocuments([]);
            }
        })();
    };

    return <BoundTable<CreatedCaseDocument>
        emptyMessage="No created documents matching the criteria were found"
        query={{
            gql: LOOKUP_CASE_DOCUMENTS.gql,
            variables: {
                caseId: props.case.id,
                includePreviousRevision: includeOlderRevisions,
            },
            recordsKey: ['caseById', 'generatedDocuments'],
        }}
        toolbar={
            <div className={classes.toolbar}>
                {checkedDocuments.length > 0 ?
                    <Button variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={handleAskMultipleDelete}
                    >Delete Selected Documents</Button>
                    : <span />
                }
                <span />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={includeOlderRevisions}
                            onChange={handleChangeOlderRevisions}
                            color="primary"
                        />
                    }
                    label="Include Older Revisions of Documents"
                />
            </div>
        }
        searchQueryField="filter"
        columns={[
            {
                id: "checkbox",
                title: "",
                isSortable: false,
                cellStyle: {
                    width: '0.5rem',
                },
                render: (data: CreatedCaseDocument) => {
                    return <Checkbox onChange={() => handleDocumentToggle(data)} checked={checkedDocuments.indexOf(data) >= 0} />;
                }
            },
            {
                id: "star",
                title: "",
                isSortable: false,
                cellStyle: {
                    width: '0.5rem',
                },
                render: (data: CreatedCaseDocument) => {
                    return <DocumentStarControl document={data} case={props.case} />;
                }
            },
            {
                id: "icon",
                title: "",
                isSortable: false,
                cellStyle: {
                    width: '1rem',
                },
                render: (data: CreatedCaseDocument) => {
                    return <DocumentIcon document={data} />;
                }
            },
            {
                id: "title",
                title: "Title",
                isSortable: true,
                cellStyle: {
                    width: '100%',
                },
                render: (data: CreatedCaseDocument) => {
                    return <div>
                        <Link className={classes.link} to={`/c/${props.case.id}/document/${data.id}`}>{data.title}</Link>
                        {!!data.note && <Typography variant="caption">{data.note}</Typography>}
                    </div>
                }
            },
            includeOlderRevisions ? {
                id: "islatest",
                title: "Is Latest Version",
                cellStyle: {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                },
                isSortable: false,
                render: (data: CreatedCaseDocument) => {
                    const bgColor = data.isLatestDocumentRevision ? theme.palette.success.main : theme.palette.warning.main;
                    return <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <Chip
                            style={{
                                backgroundColor: bgColor,
                                color: theme.palette.getContrastText(bgColor),
                            }}
                            label={data.isLatestDocumentRevision ? "Yes" : "No"}
                        />
                    </div>
                }
            } : undefined,
            includeOlderRevisions ? {
                id: "revision",
                title: "Data Used Was Saved At",
                cellStyle: {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                },
                isSortable: false,
                render: (data: CreatedCaseDocument) => {
                    return <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                        <span>{new Date(data.caseRevision!.createdAt).toLocaleDateString()}</span>,
                        &nbsp;<span>{new Date(data.caseRevision!.createdAt).toLocaleTimeString()}</span>
                    </div>
                }
            } : undefined,
            {
                id: "createdOn",
                title: "Created On",
                cellStyle: {
                    whiteSpace: 'nowrap',
                },
                isSortable: true,
                render: (data: CreatedCaseDocument) => {
                    return <span>{new Date(data.createdOn).toLocaleDateString()}</span>
                }
            },
            {
                id: "createdBy",
                title: "Created By",
                cellStyle: {
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                },
                isSortable: true,
                render: (data: CreatedCaseDocument) => {
                    return <div style={{ textAlign: 'center' }}>
                        <UserView user={data.createdBy} hideEmail hideUsername />
                    </div>
                }
            },
            {
                id: "actions",
                title: "",
                isSortable: false,
                cellStyle: {
                    width: '1rem',
                },
                render: (data: CreatedCaseDocument) => {
                    return <DocumentSettings document={data} handleAskDelete={handleAskDelete} />
                }
            },
        ]}
    />;
}

function DocumentSettings(props: {
    document: CreatedCaseDocument,
    handleAskDelete: (document: CreatedCaseDocument) => void
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <FontAwesomeIcon icon={faCog} size="sm" />
        </IconButton>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => props.handleAskDelete(props.document)}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary="Delete Document" />
            </MenuItem>
        </Menu>
    </div>;
}