import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from 'react-router-dom';
import { Firm } from "../types/firm";

/**
 * Defines the properties for the FirmView.
 */
interface FirmViewProps {
    firm: Firm
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        firmView: {
            height: '36px',
            textDecoration: 'none',
        },
        firmLogo: {
            height: '36px',
        }
    }));

/**
 * FirmView displays a reference to a firm. If the firm has a logo, it is used.
 */
export default function FirmView(props: FirmViewProps) {
    const classes = useStyles();
    return <Link to={`/firm`} className={classes.firmView}>{props.firm.logoData ? <img alt={props.firm.title} className={classes.firmLogo} src={`data:${props.firm.logoData}`} /> : <Typography variant="subtitle2">{props.firm.title}</Typography>}</Link>;
}
