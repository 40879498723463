import { UserProfile } from "@clerk/clerk-react";
import { faFlagUsa, faIdBadge, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, createStyles, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import clsx from 'clsx';
import React from "react";
import { AuthenticatedUser } from "../queries/user";
import useHistoricalTabs from "./historicaltabs";
import LicenseEditor from "./LicenseEditor";
import TabLabel from "./TabLabel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@global": {
            '.cl-themed-card': {
                backgroundColor: `${theme.palette.background.paper} !important`,
            },
            '.cl-list-item:hover': {
                backgroundColor: `${theme.palette.background.default} !important`,
            },
        },
        root: {
        },
        clerkView: {
            '& input': {
                color: 'black'
            },
        },
        licenses: {
        }
    }));

export function UserSettingsView(props: { currentUser: AuthenticatedUser }) {
    const { currentTabName, handleChangeTab } = useHistoricalTabs(['account', 'licenses', 'security']);
    const classes = useStyles();
    return <div className={classes.root}>
        <AppBar position="static" color="default">
            <Tabs value={currentTabName} onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary">
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faIdBadge} size="lg" />} title="Account Settings" />} value="account" />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faFlagUsa} size="lg" />} title="Licensed States" />} value="licenses" />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faUserShield} size="lg" />} title="Security" />} value="security" />
            </Tabs>
        </AppBar>

        {currentTabName === 'account' && <div className={classes.clerkView}>
            <UserProfile only="account" hideNavigation />
        </div>}

        {currentTabName === 'licenses' && <div>
            <div className="cl-component cl-user-profile">
                <div className={clsx('cl-main', classes.licenses)}>
                    <div className="cl-page-heading">
                        <div className="cl-text-container">
                            <h2 className="cl-title" style={{ margin: '0' }}>State Licenses</h2>
                            <p className="cl-subtitle">Indicate the states in which you are licensed to practice law</p>
                        </div>
                    </div>
                    <LicenseEditor user={props.currentUser} />
                </div>
            </div>
        </div>}

        {currentTabName === 'security' && <div className={classes.clerkView}>
            <UserProfile only="security" hideNavigation />
        </div>}
    </div>
}