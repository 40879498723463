import { ReactComponent as AL_STATE_ICON } from '../states/Alabama.svg';
import { ReactComponent as AK_STATE_ICON } from '../states/Alaska.svg';
import { ReactComponent as AZ_STATE_ICON } from '../states/Arizona.svg';
import { ReactComponent as AR_STATE_ICON } from '../states/Arkansas.svg';
import { ReactComponent as CA_STATE_ICON } from '../states/California.svg';
import { ReactComponent as CO_STATE_ICON } from '../states/Colorado.svg';
import { ReactComponent as CT_STATE_ICON } from '../states/Connecticut.svg';
import { ReactComponent as DE_STATE_ICON } from '../states/Delaware.svg';
import { ReactComponent as FL_STATE_ICON } from '../states/Florida.svg';
import { ReactComponent as GA_STATE_ICON } from '../states/Georgia.svg';
import { ReactComponent as HI_STATE_ICON } from '../states/Hawaii.svg';
import { ReactComponent as ID_STATE_ICON } from '../states/Idaho.svg';
import { ReactComponent as IL_STATE_ICON } from '../states/Illinois.svg';
import { ReactComponent as IA_STATE_ICON } from '../states/Iowa.svg';
import { ReactComponent as KS_STATE_ICON } from '../states/Kansas.svg';
import { ReactComponent as KY_STATE_ICON } from '../states/Kentucky.svg';
import { ReactComponent as LA_STATE_ICON } from '../states/Louisiana.svg';
import { ReactComponent as MA_STATE_ICON } from '../states/Maine.svg';
import { ReactComponent as ME_STATE_ICON } from '../states/Maryland.svg';
import { ReactComponent as MI_STATE_ICON } from '../states/Michigan.svg';
import { ReactComponent as MN_STATE_ICON } from '../states/Minnesota.svg';
import { ReactComponent as MS_STATE_ICON } from '../states/Mississippi.svg';
import { ReactComponent as MO_STATE_ICON } from '../states/Missouri.svg';
import { ReactComponent as MT_STATE_ICON } from '../states/Montana.svg';
import { ReactComponent as NE_STATE_ICON } from '../states/Nebraska.svg';
import { ReactComponent as NV_STATE_ICON } from '../states/Nevada.svg';
import { ReactComponent as NH_STATE_ICON } from '../states/New Hampshire.svg';
import { ReactComponent as NJ_STATE_ICON } from '../states/New Jersey.svg';
import { ReactComponent as NM_STATE_ICON } from '../states/New Mexico.svg';
import { ReactComponent as NY_STATE_ICON } from '../states/New York.svg';
import { ReactComponent as NC_STATE_ICON } from '../states/North Carolina.svg';
import { ReactComponent as ND_STATE_ICON } from '../states/North Dakota.svg';
import { ReactComponent as OH_STATE_ICON } from '../states/Ohio.svg';
import { ReactComponent as OK_STATE_ICON } from '../states/Oklahoma.svg';
import { ReactComponent as OR_STATE_ICON } from '../states/Oregon.svg';
import { ReactComponent as PA_STATE_ICON } from '../states/Pennsylvania.svg';
import { ReactComponent as RI_STATE_ICON } from '../states/Rhode Island.svg';
import { ReactComponent as SC_STATE_ICON } from '../states/South Carolina.svg';
import { ReactComponent as SD_STATE_ICON } from '../states/South Dakota.svg';
import { ReactComponent as TN_STATE_ICON } from '../states/Tennessee.svg';
import { ReactComponent as TX_STATE_ICON } from '../states/Texas.svg';
import { ReactComponent as UT_STATE_ICON } from '../states/Utah.svg';
import { ReactComponent as VT_STATE_ICON } from '../states/Vermont.svg';
import { ReactComponent as VA_STATE_ICON } from '../states/Virginia.svg';
import { ReactComponent as WA_STATE_ICON } from '../states/Washington.svg';
import { ReactComponent as WV_STATE_ICON } from '../states/West Virginia.svg';
import { ReactComponent as WI_STATE_ICON } from '../states/Wisconsin.svg';
import { ReactComponent as WY_STATE_ICON } from '../states/Wyoming.svg';


const STATE_ICON_MAP: Record<string, any> = {
    "AL": AL_STATE_ICON,
    "AK": AK_STATE_ICON,
    "AZ": AZ_STATE_ICON,
    "AR": AR_STATE_ICON,
    "CA": CA_STATE_ICON,
    "CO": CO_STATE_ICON,
    "CT": CT_STATE_ICON,
    "DE": DE_STATE_ICON,
    "FL": FL_STATE_ICON,
    "GA": GA_STATE_ICON,
    "HI": HI_STATE_ICON,
    "ID": ID_STATE_ICON,
    "IL": IL_STATE_ICON,
    "IA": IA_STATE_ICON,
    "KS": KS_STATE_ICON,
    "KY": KY_STATE_ICON,
    "LA": LA_STATE_ICON,
    "MA": MA_STATE_ICON,
    "ME": ME_STATE_ICON,
    "MI": MI_STATE_ICON,
    "MN": MN_STATE_ICON,
    "MS": MS_STATE_ICON,
    "MO": MO_STATE_ICON,
    "MT": MT_STATE_ICON,
    "NE": NE_STATE_ICON,
    "NV": NV_STATE_ICON,
    "NH": NH_STATE_ICON,
    "NJ": NJ_STATE_ICON,
    "NM": NM_STATE_ICON,
    "NY": NY_STATE_ICON,
    "NC": NC_STATE_ICON,
    "ND": ND_STATE_ICON,
    "OH": OH_STATE_ICON,
    "OK": OK_STATE_ICON,
    "OR": OR_STATE_ICON,
    "PA": PA_STATE_ICON,
    "RI": RI_STATE_ICON,
    "SC": SC_STATE_ICON,
    "SD": SD_STATE_ICON,
    "TN": TN_STATE_ICON,
    "TX": TX_STATE_ICON,
    "UT": UT_STATE_ICON,
    "VT": VT_STATE_ICON,
    "VA": VA_STATE_ICON,
    "WA": WA_STATE_ICON,
    "WV": WV_STATE_ICON,
    "WI": WI_STATE_ICON,
    "WY": WY_STATE_ICON,
}


type AttorneyLookup = (attorneyId: string) => string

const STATE_LOOKUP_MAP: Record<string, AttorneyLookup> = {
    'PA': (attorneyId: string) => `https://www.padisciplinaryboard.org/for-the-public/find-attorney/attorney-detail/${attorneyId}`
}

const STATE_DATA = `Alabama,AL,Alaska,AK,American Samoa,AS,Arizona,AZ,Arkansas,AR,California,CA,Colorado,CO,Connecticut,CT,Delaware,DE,District of Columbia,DC,Federated States of Micronesia,FM,Florida,FL,Georgia,GA,Guam,GU,Hawaii,HI,Idaho,ID,Illinois,IL,Indiana,IN,Iowa,IA,Kansas,KS,Kentucky,KY,Louisiana,LA,Maine,ME,Marshall Islands,MH,Maryland,MD,Massachusetts,MA,Michigan,MI,Minnesota,MN,Mississippi,MS,Missouri,MO,Montana,MT,Nebraska,NE,Nevada,NV,New Hampshire,NH,New Jersey,NJ,New Mexico,NM,New York,NY,North Carolina,NC,North Dakota,ND,Northern Mariana Islands,MP,Ohio,OH,Oklahoma,OK,Oregon,OR,Palau,PW,Pennsylvania,PA,Puerto Rico,PR,Rhode Island,RI,South Carolina,SC,South Dakota,SD,Tennessee,TN,Texas,TX,Utah,UT,Vermont,VT,Virgin Islands,VI,Virginia,VA,Washington,WA,West Virginia,WV,Wisconsin,WI,Wyoming,WY`;

/**
 * STATE_MAP is a map from the two letter state ID (uppercase) to the state title.
 */
export const STATE_MAP = (() => {
    const stateMap: Record<string, string> = {};
    const items = STATE_DATA.split(',');
    for (let i = 0; i < items.length; i += 2) {
        stateMap[items[i + 1].toLowerCase()] = items[i];
    }

    return stateMap;
})();

/**
 * Returns the whether the state is known.
 */
export const IsKnownState = (stateId: string) => stateId.toLowerCase() in STATE_MAP

/**
 * Returns the title for a state
 * @param stateId The lowercase two letter ID for the state.
 */
export const StateTitle = (stateId: string) => STATE_MAP[stateId.toLowerCase()]

/**
 * Returns the icon for a state or undefined if none.
 * @param stateId The lowercase two letter ID for the state.
 */
export const StateIcon = (stateId: string) => stateId.toUpperCase() in STATE_ICON_MAP ? STATE_ICON_MAP[stateId.toUpperCase()] : undefined;

/**
 * Returns a link to registered attorney information for an attorney in a state or undefined if none.
 * @param stateId The lowercase two letter ID for the state.
 * @param attorneyId The attorney ID in the state.
 */
export const StateAttorneyLink = (stateId: string, attorneyId: string) => {
    if (!(stateId.toUpperCase() in STATE_LOOKUP_MAP)) {
        return undefined;
    }

    return STATE_LOOKUP_MAP[stateId.toUpperCase()](attorneyId);
};