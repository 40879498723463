import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { SetCaseDocumentNote, SET_CASE_DOCUMENT_NOTE } from "../../queries/documents";
import { useManagedMutation } from "../../queries/lib/hooks";
import { Case } from "../../types/case";
import { CreatedCaseDocument } from "../../types/generateddoc";
import { useConfirmDialog } from "../ConfirmDialogProvider";


interface styleProps {
    hasNote: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        note: {
            color: (props: styleProps) => props.hasNote ? theme.palette.text.primary : theme.palette.text.disabled,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline'
            },
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: '8px',
            alignItems: 'center'
        },
    }));

export function DocumentNoteView(props: { document: CreatedCaseDocument, case?: Case }) {
    const classes = useStyles({ hasNote: !!props.document.note });
    const { showConfirm } = useConfirmDialog();

    const [setCaseDocumentNote] = useManagedMutation<
        any,
        SetCaseDocumentNote
    >(SET_CASE_DOCUMENT_NOTE);

    const handleEditNote = () => {
        (async () => {
            const [value, note] = await showConfirm({
                'title': 'Edit document note',
                'content': 'Enter a note for the document',
                'withPrompt': {
                    value: props.document.note ?? '',
                    maxLength: 25,
                },
                'buttons': [
                    { 'title': 'Cancel', 'value': undefined },
                    { 'title': `Update Note For ${document.title}`, 'value': 'update', 'variant': 'contained', 'color': 'primary' },
                ],
            });

            if (value !== 'update' || note === undefined) {
                return;
            }

            await setCaseDocumentNote({
                variables: {
                    caseId: props.case?.id ?? props.document.case?.id!,
                    documentId: props.document.id,
                    note: note,
                }
            })
        })()
    };

    return <div className={classes.note} onClick={handleEditNote}>
        {!!props.document.note && <FontAwesomeIcon icon={faStickyNote} />}
        {props.document.note ?? '(Enter a note)'}
    </div>
}