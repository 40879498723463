import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { isMissingValue } from "../../services/documentservice";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { ParentListFieldContext } from "./ListField";
import { fieldTitle, ValueFieldProps } from "./valuefield";

interface styleProps {
  missingColor: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    normal: {
      position: "relative",
      "& > fieldset": {
        borderColor: (props: styleProps) =>
          props.missingColor ? props.missingColor : "",
        borderWidth: (props: styleProps) => (props.missingColor ? 2 : 1),
      },
      "&:hover > fieldset": {
        borderColor: theme.palette.text.primary,
      },
    },
    editing: {
      "& > fieldset": {
        borderColor: theme.palette.primary.main + " !important",
        borderWidth: "2px !important",
      },
    },
    edited: {
      "& > fieldset": {
        borderColor: theme.palette.warning.main,
        borderWidth: 2,
      },
    },
    outline: {
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      borderRadius: 4,
    },
    control: {
      padding: theme.spacing(1.5),
      "& .react-tel-input .form-control": {
        backgroundColor: "transparent",
        color: theme.palette.text.primary,
        border: 0,
        fontSize: "100%",
        width: "100%",
      },
      "& .react-tel-input .flag-dropdown": {
        borderColor: theme.palette.divider,
        borderWidth: 0,
        borderRightWidth: 1,
      },
      "& .react-tel-input .flag-dropdown.open": {
        backgroundColor: theme.palette.background.default,
      },
      "& .react-tel-input .flag-dropdown.open .selected-flag": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& .react-tel-input .country-list": {
        backgroundColor: theme.palette.background.paper,
        borderColor: "transparent",
        boxShadow:
          "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      },
      "& .react-tel-input .country-list .country:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .react-tel-input .country-list .country.highlight": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
);

export function PhoneNumberField(props: ValueFieldProps) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const { tracker } = useDocumentTracker();

  const [localIndex, setLocalIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const isSaving = database.applying;
  const fallbackValue = props.fallbackColumn
    ? row.getField(props.fallbackColumn)
    : "";
  const value = row.getField(props.column) || fallbackValue;

  const setValue = (value: string) => {
    if (value === "+") {
      value = "";
    }

    if (props.fallbackColumn && value === fallbackValue) {
      value = "";
    }

    if (props.maxLength !== undefined && value.length > props.maxLength) {
      return;
    }

    const isValidValue = (props.isValidValue || (() => true))(value);
    row.setField(
      props.column,
      { value: value, isValid: isValidValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );

    setLocalIndex(localIndex + 1);
  };

  const { missingColor } = tracker.missingForValue(row, props.column, () =>
    isMissingValue(value)
  );
  const classes = useStyles({
    missingColor: missingColor,
  });

  return (
    <FormControl fullWidth={props.fullWidth}>
      <div
        className={clsx(classes.normal, {
          [classes.edited]: row.hasMutatedValue(props.column),
          [classes.editing]: isEditing,
        })}
      >
        <InputLabel
          style={{ position: "absolute" }}
          shrink={true}
          variant="outlined"
        >
          {props.placeholder ?? props.title}
        </InputLabel>
        <NotchedOutline
          className={classes.outline}
          labelWidth={(props.title || props.placeholder || "").length * 14}
          /*label={props.title}*/ notched={true}
        />
        <div className={classes.control}>
          <PhoneInput
            country={"us"}
            disabled={isSaving}
            value={value}
            inputProps={{
              name: props.column,
            }}
            onFocus={() => setIsEditing(true)}
            onBlur={() => setIsEditing(false)}
            onChange={(phone, country, e, formattedValue) =>
              setValue(formattedValue)
            }
          />
        </div>
      </div>
    </FormControl>
  );
}
