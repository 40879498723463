import React from "react";
import { AuthenticatedUser } from "../queries/user";
import { Case } from "../types/case";

/**
 * CurrentUserContext holds the currently authenticated user.
 */
export const CurrentUserContext = React.createContext<AuthenticatedUser | undefined>(undefined);
CurrentUserContext.displayName = 'CurrentUserContext'

/**
 * CurrentCaseContext holds the current case.
 */
export const CurrentCaseContext = React.createContext<Case | undefined>(undefined);
CurrentCaseContext.displayName = 'CurrentCaseContext'
