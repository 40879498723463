import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect } from "react";
import { LookupCaseData, LOOKUP_CASE } from '../queries/case';
import { ResourceView } from '../queries/lib/components';
import { useManagedQuery } from '../queries/lib/hooks';
import { useRecentCases } from '../services/recentcases';
import CaseTemplatedLayout from './CaseTemplatedLayout';

interface CaseLayoutProps {
    /**
     * caseId is the currently selected case.
     */
    caseId: string

    /**
     * Whether the SideDrawer is currently open or collapsed.
     */
    isSideBarOpen: boolean;

    /**
     * Callback function to toggle the drawer.
     */
    toggleDrawer: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
        },
    }));

export default function CaseLayout(props: CaseLayoutProps) {
    const classes = useStyles();
    const state = useManagedQuery<LookupCaseData>(LOOKUP_CASE, {
        variables: {
            caseId: props.caseId,
        }
    });

    const recentCases = useRecentCases();

    useEffect(() => {
        if (state.data) {
            recentCases.addRecentCase(state.data.caseById);
        }
    }, [state, recentCases]);

    return <div className={classes.root}>
        <ResourceView<LookupCaseData> state={state}>
            {state.data !== undefined &&
                <CaseTemplatedLayout case={state.data.caseById} isSideBarOpen={props.isSideBarOpen} toggleDrawer={props.toggleDrawer} />
            }
        </ResourceView>
    </div >;
}