import { faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { schemeBlues, schemeGreens, schemeOranges, schemePurples, schemeReds } from 'd3-scale-chromatic';
import ordinal from 'ordinal';
import React, { useContext, useState } from "react";
import { DatabaseContext, ResultContext } from '../../database/diffable/context';
import { MutableRow, RowType } from '../../database/diffable/interfaces';
import { useQuery } from '../../database/diffable/query';
import { literal, sql } from '../../database/sql';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                '& > $add': {
                    opacity: '1 !important',
                }
            }
        },
        nested: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            borderLeft: '2px solid transparent',
        },
        nestedContent: {
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        nestedRecursive: {
            paddingLeft: theme.spacing(1)
        },
        conditionalToggle: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        add: {
            opacity: '0.5',
            transition: 'opacity 200ms ease-in-out',
        },
        toolbar: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            marginBottom: theme.spacing(1),
        }
    }));


export interface RecursiveFieldProps<P extends RowType, R extends RowType> {
    recursiveTableName: string
    rootRow: MutableRow<R>

    topIndex?: number
    parentRow?: MutableRow<P>

    rootFieldName: string
    parentFieldName: string

    rowTitle: string
    maximumCount?: number | undefined
    maximumDepth?: number | undefined
    currentDepth?: number | undefined

    empty: Record<string, any> | ((count: number, depth: number) => Record<string, any>)
    nestedTitle?: (row: MutableRow<P> | undefined, depth: number, index: number) => string

    conditionalToggle?: (nestedTitle: string | undefined, update: (value: boolean) => any) => React.ReactNode
    conditionalStartingValue?: (row: MutableRow<P>) => boolean

    children: (row: MutableRow<P>, depth: number, rows: MutableRow<P>[]) => React.ReactNode

    header?: (results: MutableRow<P>[]) => React.ReactNode
}

const COLOR_CODING = [schemeBlues, schemeGreens, schemePurples, schemeOranges, schemeReds];

const NESTING_DESCRIPTIONS: Record<number, string> = {
    0: 'Primary',
    1: 'Secondary',
    2: 'Tertiary',
}

export function levelStringForDepth(currentDepth: number) {
    return currentDepth < 3 ? NESTING_DESCRIPTIONS[currentDepth] : `${ordinal(currentDepth + 1)}-level`;
}

/**
 * RecursiveField defines a field over a recursive relationship between a table and itself.
 */
export function RecursiveField<P extends RowType, R extends RowType>(props: RecursiveFieldProps<P, R>) {
    const database = useContext(DatabaseContext)!;
    const classes = useStyles();

    const [localIndex, setLocalIndex] = useState(0);
    const [conditionalFieldValue, setConditionalFieldValue] = useState(props.conditionalStartingValue && props.parentRow ? props.conditionalStartingValue(props.parentRow) : undefined)

    const currentDepth = props.currentDepth ?? 0;
    const maximumDepth = props.maximumDepth ?? 4;

    const nestingDescription = levelStringForDepth(currentDepth + 1);

    // If there is a valid parent row in the recursive relationship, load from it. Otherwise, load from
    // the root row.
    const { results: childResults } = useQuery<P>(
        props.parentRow !== undefined ?
            sql`select * from ${props.recursiveTableName} where ${literal(props.parentFieldName)} = ${props.parentRow.id()}` :
            sql`select * from ${props.recursiveTableName} where ${literal(props.rootFieldName)} = ${props.rootRow.id()} and ${literal(props.parentFieldName)} is null`);

    const handleAddRow = () => {
        let empty = props.empty;
        if (typeof empty === 'function') {
            empty = empty(childResults?.length ?? 0, currentDepth)
        }

        const linkageData = {
            ...empty,
            [props.rootFieldName]: props.rootRow.id(),
            [props.parentFieldName]: props.parentRow?.id() ?? null,
        };
        database.transaction.insertRow(props.recursiveTableName, linkageData, `Added ${nestingDescription} ${props.rowTitle}`);
        setLocalIndex(localIndex + 1);
    };

    const handleRemoveRow = (id: number) => {
        database.transaction.deleteRow(props.recursiveTableName, id, `Removed ${nestingDescription} ${props.rowTitle}`);
        setLocalIndex(localIndex + 1);
    };

    const handleConditionalToggle = (toggled: boolean) => {
        setConditionalFieldValue(toggled);
    };

    const Nested = () => {
        return <>
            <div>
                {childResults !== undefined && props.header && props.header(childResults)}
                {childResults !== undefined && childResults.map((childRow: MutableRow<P>, index: number) => {
                    const topIndex = props.topIndex ?? index;
                    const colorCode = (COLOR_CODING[topIndex % COLOR_CODING.length])[(maximumDepth + 1) % 9];
                    const nestedTitle = props.nestedTitle ? props.nestedTitle(props.parentRow, currentDepth + 1, index) : undefined;
                    return <ResultContext.Provider value={childRow}>
                        <div className={classes.nested} style={{ borderColor: colorCode[maximumDepth - currentDepth] }}>
                            <div className={classes.toolbar}>
                                {nestedTitle && <Typography variant="subtitle2">{nestedTitle}</Typography>}
                                <IconButton onClick={() => handleRemoveRow(childRow.id())}><FontAwesomeIcon icon={faTrash} /></IconButton>
                            </div>
                            <div className={classes.nestedContent}>
                                {props.children(childRow, currentDepth + 1, childResults)}
                                {props.conditionalToggle &&
                                    <div className={classes.conditionalToggle}>
                                        {props.conditionalToggle(nestedTitle, handleConditionalToggle)}
                                    </div>}
                            </div>
                            <div className={classes.nestedRecursive}>
                                <RecursiveField<P, R>
                                    {...props}
                                    topIndex={topIndex}
                                    rootRow={props.rootRow}
                                    parentRow={childRow}
                                    currentDepth={currentDepth + 1}
                                />
                            </div>
                        </div>
                    </ResultContext.Provider>;
                })}
            </div>
            {childResults !== undefined && childResults.length < (props.maximumCount ?? 1000) && conditionalFieldValue !== false &&
                <div className={classes.add}>
                    <Button startIcon={<FontAwesomeIcon icon={faPlusSquare} />} onClick={handleAddRow}>Add {nestingDescription} {props.rowTitle}</Button>
                </div>
            }
        </>;
    };

    return <div className={classes.root}>
        {currentDepth <= maximumDepth && <Nested />}
    </div>
}