import numberToWords from "number-to-words";
import replaceAll from "string.prototype.replaceall";
import { sql } from "../../database/sql";
import {
  DocumentDataBuilder,
  FirmMemberLookup,
  TrackedDatabase,
} from "../../services/documentservice";
import {
  age,
  currency,
  date,
  documentDate,
  documentShortDate,
  emptyAddress,
  exportAddress,
  firmFields,
  fullAddress,
  joinMultiple,
  scale,
} from "../../shareddata/shareddata";
import { Case } from "../../types/case";
import { BitwiseSet } from "../../util/bitwiseset";
import { enumValues } from "../../util/enum";
import {
  ChildBequest,
  Party,
  PartyRelation,
  PartyRelationDescription,
  PartyRelationKey,
  WillDetails,
} from "./model";

const MAX_COUNT_PER_PARTY_KIND = 15;

const isEstateSettlorRelation = (party: Party, relationName: string) => {
  return party.estateSettlorRelation.toLowerCase() === relationName;
};

const isChild = (party: Party) => {
  return (
    isEstateSettlorRelation(party, "son") ||
    isEstateSettlorRelation(party, "daughter") ||
    isEstateSettlorRelation(party, "step son") ||
    isEstateSettlorRelation(party, "step daughter")
  );
};

const isSpouse = (party: Party) => {
  return (
    isEstateSettlorRelation(party, "wife") ||
    isEstateSettlorRelation(party, "husband") ||
    isEstateSettlorRelation(party, "spouse")
  );
};

export const PartyRelationDefaultValue = {
  [PartyRelation.WITNESS]:
    "_________________________, _________________________",
};

export async function constructDocumentData(
  cse: Case,
  db: TrackedDatabase,
  memberLookup: FirmMemberLookup
): Promise<
  [Record<string, DocumentDataBuilder> | undefined, string | undefined]
> {
  const parties = db.list<Party>(sql`select * from ${"party"}`);

  const beneficiaries = db.list<Party>(
    sql`select * from ${"party"} where willRelation & ${
      PartyRelation.BENEFICIARY
    }`
  );
  const residuaryEstateBequestParty = db.getOrUndefined<Party>(
    sql`select * from ${"party"} where willRelation=${
      PartyRelation.BENEFICIARY
    } and isResiduaryIfNoSurvivingBeneficiaries=1`
  );

  const willDetails = db.get<WillDetails>(sql`select * from ${"willdetails"}`);

  let partyLists = {};
  enumValues(PartyRelation).forEach((value: PartyRelation) => {
    if (!PartyRelationKey[value]) {
      console.log("Missing relation key for value", value);
      return;
    }

    const foundParties = db.list<Party>(
      sql`select * from ${"party"} where willRelation & ${value}`
    );
    partyLists = {
      ...partyLists,
      ...partyList(
        foundParties,
        PartyRelationKey[value],
        PartyRelationDefaultValue[value]
      ),
    };
  });

  const beneficiarySpouseRelation = beneficiaries.find((party: Party) =>
    isSpouse(party)
  );
  const spouseRelation = parties.find((party: Party) => isSpouse(party));

  return [
    {
      // Predefined.
      ...firmFields(cse, db),

      Residuary_Estate_Bequest: async () =>
        residuaryEstateBequestParty
          ? formatName(residuaryEstateBequestParty)
          : "",

      Beneficiary_Spouse_Name: async () =>
        beneficiarySpouseRelation ? formatName(beneficiarySpouseRelation) : "",
      Beneficiary_Spouse_Relation: async () =>
        beneficiarySpouseRelation
          ? `My ${beneficiarySpouseRelation.estateSettlorRelation}`
          : "",

      Nuclear_Family: async () => {
        const toReturn = [];
        if (spouseRelation) {
          toReturn.push(
            `My ${spouseRelation.estateSettlorRelation} is ${formatName(
              spouseRelation
            )}`
          );
        }

        const children = parties.filter(isChild);
        if (children.length > 1) {
          const childText = joinMultiple(children, "", "", (item: Party) => {
            return relationName(item);
          });
          toReturn.push(
            `I have ${numberToWords.toWords(
              children.length
            )} children, ${childText}`
          );
        } else if (children.length === 1) {
          toReturn.push(
            `I have one child my ${
              children[0].estateSettlorRelation
            } ${formatName(children[0])}`
          );
        }

        return toReturn.join(" and ");
      },

      ...beneficiaryFields(beneficiaries, db, 20),

      ...partyNames(parties, db),

      ...partyLists,

      Beneficiary_Tangible_Property_List: async () => {
        const tangible = beneficiaries.filter(
          (beneficiary: Party) => beneficiary.tangiblePersonalProperty === 1
        );
        return joinMultiple(tangible, "", "", (item: Party) => {
          return relationName(item);
        });
      },

      All_Parties_List: async () => {
        return parties
          .map((party: Party) => {
            return `${formatName(party)} (${partyRelationDescriptions(
              party
            )}${relation(party, "; ")}) \nAddress: ${fullAddress(
              party,
              db
            )} \nE-mail: ${party.emailAddress} \nPhone Number: ${
              party.phoneNumber
            } `;
          })
          .join("\n\n");
      },

      ...partyList(
        parties.filter((party: Party) => {
          return (
            isEstateSettlorRelation(party, "husband") ||
            isEstateSettlorRelation(party, "wife")
          );
        }),
        "Spouse"
      ),

      ...partyList(
        parties.filter((party: Party) => {
          return (
            party.willRelation === PartyRelation.PRIMARY_GUARDIAN ||
            party.willRelation === PartyRelation.SECONDARY_GUARDIAN ||
            party.willRelation === PartyRelation.TERTIARY_GUARDIAN
          );
        }),
        "Guardian"
      ),

      ...partyList(parties.filter(isChild), "Children"),

      POA_Date: async () => documentDate(willDetails.poaDate),
      POARealEstate: async () => willDetails.poaRealEstate,
      POARealEstateSalePrice: async () =>
        currency(willDetails.poaRealEstateSalePrice),

      Will_Date: async () => documentDate(willDetails.willDate),
      Trust_Date: async () => documentDate(willDetails.trustDate),
      Will_Short_Date: async () => documentShortDate(willDetails.willDate),
      Trust_Short_Date: async () => documentShortDate(willDetails.trustDate),

      Child_Count: async () => {
        return parties.filter(isChild).length;
      },

      Children_List_Age: async () => {
        return joinMultiple(parties.filter(isChild), "", "", (child: Party) => {
          return `${formatName(child)} (DOB: ${date(child.dob)} / Age: ${age(
            child.dob
          )})`;
        });
      },
    },
    undefined,
  ];
}

function formatBequest(residuaryEstateBequest: string): string {
  if (residuaryEstateBequest === "100%") {
    return "all";
  }

  return residuaryEstateBequest;
}

function formatName({ name }: { name: string }): string {
  return name.toUpperCase();
}

function relationName(party: Party) {
  return `${relation(party, "", " ")}${formatName(party)}`;
}

function relation(party: Party, prefix?: string, suffix?: string) {
  if (!party.estateSettlorRelation || isEstateSettlorRelation(party, "none")) {
    return "";
  }

  return `${prefix ?? ""}my ${party.estateSettlorRelation}${suffix ?? ""}`;
}

function generateBeneficaryDetails(
  beneficiary: Party,
  db: TrackedDatabase
): string {
  if (!db.optional(beneficiary).residuaryEstateBequest) {
    return "";
  }

  const mainDetails = `A. If my ${
    beneficiary.estateSettlorRelation
  } ${formatName(
    beneficiary
  )} survives me, I give, devise and bequeath ${formatBequest(
    beneficiary.residuaryEstateBequest
  )} of the rest, residue and remainder of my estate, real, personal or mixed, and wherever situated, to them, outright${
    beneficiary.isMinor === 1 ? MINOR_SUBJECT_TO : ""
  }.`;
  const childBequests = db.list<ChildBequest>(
    sql`select * from ${"childbequest"} where childBequest.rootPartyId=${
      beneficiary.id
    }`
  );
  if (childBequests.length === 0) {
    return mainDetails;
  }

  const secondaryHeader = `B. If my ${
    beneficiary.estateSettlorRelation
  } ${formatName(
    beneficiary
  )} does not survive me, then my Executor shall distribute the Residue of my Estate as follows:`;
  const secondaryDetails = generateChildBequests(childBequests, null, 1, db);
  return `${mainDetails}\n\n${secondaryHeader}\n${secondaryDetails}`;
}

function generateChildBequests(
  childBequests: ChildBequest[],
  parentBequestId: number | null,
  indentation: number,
  db: TrackedDatabase
): string {
  const parentBequest = childBequests.find(
    (bequest: ChildBequest) => bequest.id === parentBequestId
  );
  if (parentBequest && parentBequest.isPerStirpes === 1) {
    const indentationStr = "\t".repeat(indentation);
    if (parentBequest.bequestPartyId === null) {
      return `${indentationStr}(Missing beneficiary)`;
    }

    const bequestParty = db.get<Party>(
      sql`select * from ${"party"} where id=${parentBequest.bequestPartyId}`
    );
    return `${indentationStr}my ${
      bequestParty.estateSettlorRelation
    } ${formatName(bequestParty)}’s share shall go to my ${
      bequestParty.estateSettlorRelation
    } ${formatName(bequestParty)}’s Issue perstirpes. If my ${
      bequestParty.estateSettlorRelation
    } ${formatName(bequestParty)} has no Issue, then my ${
      bequestParty.estateSettlorRelation
    } ${formatName(bequestParty)}’s bequest shall lapse.`;
  }

  const currentBequests = childBequests.filter(
    (bequest: ChildBequest) =>
      db.optional(bequest).parentChildBequestId === parentBequestId
  );
  return currentBequests
    .map((current: ChildBequest, index: number) => {
      const indentationStr = "\t".repeat(indentation);
      if (current.bequestPartyId === null) {
        return `${indentationStr}(Missing beneficiary)`;
      }

      const bequestParty = db.get<Party>(
        sql`select * from ${"party"} where id=${current.bequestPartyId}`
      );
      const nestedBequestDetails = generateChildBequests(
        childBequests,
        current.id,
        indentation + 1,
        db
      );
      const notSurvive =
        nestedBequestDetails.trim().length > 0
          ? ` If my ${bequestParty.estateSettlorRelation} ${formatName(
              bequestParty
            )} does not survive me:\n${nestedBequestDetails}`
          : "";
      return `${indentationStr}(${scale(index, indentation)}) ${
        current.residuaryEstateBequest
      } to my ${bequestParty.estateSettlorRelation} ${formatName(
        bequestParty
      )} if my ${bequestParty.estateSettlorRelation} ${formatName(
        bequestParty
      )} survives me${
        bequestParty.isMinor === 1 ? MINOR_SUBJECT_TO : ""
      }.${notSurvive}`;
    })
    .join("\n\n");
}

const MINOR_SUBJECT_TO =
  ", subject to the Beneficiary’s Trust provisions of ARTICLE IV";

function beneficiaryFields(
  beneficiaries: Party[],
  db: TrackedDatabase,
  minCount: number
) {
  const obj: Record<string, DocumentDataBuilder> = {};
  beneficiaries.forEach((beneficiary: Party, index: number) => {
    const secondaryBequests = db.list<Party>(
      sql`select * from ${"party"} join childBequest on party.id=childBequest.bequestPartyId where childBequest.rootPartyId=${
        beneficiary.id
      }`
    );

    obj[`Beneficiary_${index + 1}_Specific_Bequest`] = async () =>
      db.optional(beneficiary).specificBequest;
    obj[`Beneficiary_${index + 1}_Residuary_Estate_Bequest`] = async () =>
      formatBequest(db.optional(beneficiary).residuaryEstateBequest);
    obj[`Beneficiary_${index + 1}_Secondary_Bequest`] = async () =>
      joinMultiple(secondaryBequests, "", "", (item: Party) => {
        return relationName(item);
      });
    obj[`Beneficiary_${index + 1}_Second_Bequest_List`] = async () =>
      joinMultiple(secondaryBequests, "", "", (item: Party) => {
        return relationName(item);
      });
    obj[`Beneficiary_${index + 1}_Details`] = async () => {
      return generateBeneficaryDetails(beneficiary, db);
    };
  });

  for (let index = beneficiaries.length; index < minCount; ++index) {
    obj[`Beneficiary_${index + 1}_Specific_Bequest`] = async () => "";
    obj[`Beneficiary_${index + 1}_Residuary_Estate_Bequest`] = async () => "";
    obj[`Beneficiary_${index + 1}_Secondary_Bequest`] = async () => "";
    obj[`Beneficiary_${index + 1}_Second_Bequest_List`] = async () => "";
    obj[`Beneficiary_${index + 1}_Details`] = async () => "";
  }
  return obj;
}

function partyList(parties: Party[], prefix: string, defaultValue?: string) {
  if (!prefix) {
    throw new Error("Empty party prefix");
  }

  const obj: Record<string, DocumentDataBuilder> = {};
  obj[`${prefix}_List`] = async () => {
    return (
      joinMultiple(parties, "", "", (item: Party) => {
        return relationName(item);
      }) ||
      (defaultValue ?? `<<${replaceAll(prefix.toUpperCase(), "_", " ")}>>`)
    );
  };
  return obj;
}

function partyRelationDescriptions(party: Party) {
  return partysRelations(party)
    .map((value: PartyRelation) => PartyRelationDescription[value])
    .join(", ");
}

function partysRelations(party: Party): PartyRelation[] {
  return Object.keys(PartyRelationDescription)
    .filter((valueStr: string) =>
      BitwiseSet.encodedHas(party.willRelation, parseInt(valueStr))
    )
    .map((valueStr: string) => {
      return parseInt(valueStr) as PartyRelation;
    });
}

const singlePartyKinds = new Set<PartyRelation>([
  PartyRelation.TESTATOR,
  PartyRelation.POA_PRINCIPAL,
]);

function partyNames(parties: Party[], db: TrackedDatabase) {
  const obj: Record<string, DocumentDataBuilder> = {};
  const kindCounter: Record<PartyRelation, number> = Object.fromEntries(
    enumValues(PartyRelation).map((value: PartyRelation) => {
      return [value, 0];
    })
  );

  singlePartyKinds.forEach((kind: PartyRelation) => {
    const prefix = replaceAll(PartyRelationDescription[kind], " ", "_");
    obj[`${prefix}_Name`] = async () => "";
    obj[`${prefix}_Relation_Name`] = async () => "";
    obj[`${prefix}_Phone`] = async () => "";
    obj[`${prefix}_Email`] = async () => "";
    obj[`${prefix}_DOB`] = async () => "";
    obj[`${prefix}_Age`] = async () => "";
    obj[`${prefix}_SSN`] = async () => "";
    Object.assign(obj, exportAddress(db, `${prefix}`, emptyAddress));
  });

  parties.forEach((party: Party) => {
    partysRelations(party).forEach((kind: PartyRelation) => {
      kindCounter[kind] += 1;
      const kindIndex = kindCounter[kind];

      const prefix = PartyRelationKey[kind];
      obj[`${prefix}_${kindIndex}_Name`] = async () => formatName(party);
      obj[`${prefix}_${kindIndex}_Relation_Name`] = async () =>
        `${relation(party, "", " ")}${formatName(party)}`;
      obj[`${prefix}_${kindIndex}_Phone`] = async () => party.phoneNumber;
      obj[`${prefix}_${kindIndex}_Email`] = async () => party.emailAddress;
      obj[`${prefix}_${kindIndex}_DOB`] = async () => date(party.dob);
      obj[`${prefix}_${kindIndex}_Age`] = async () => age(party.dob);
      obj[`${prefix}_${kindIndex}_SSN`] = async () => party.ssn;
      Object.assign(obj, exportAddress(db, `${prefix}_${kindIndex}`, party));

      if (singlePartyKinds.has(kind)) {
        obj[`${prefix}_Name`] = async () => formatName(party);
        obj[`${prefix}_Relation_Name`] = async () =>
          `${relation(party, "", " ")}${formatName(party)}`;
        obj[`${prefix}_Phone`] = async () => party.phoneNumber;
        obj[`${prefix}_Email`] = async () => party.emailAddress;
        obj[`${prefix}_DOB`] = async () => date(party.dob);
        obj[`${prefix}_Age`] = async () => age(party.dob);
        obj[`${prefix}_SSN`] = async () => party.ssn;
        Object.assign(obj, exportAddress(db, `${prefix}`, party));
      }
    });
  });

  enumValues(PartyRelation).forEach((kind: PartyRelation) => {
    if (kind !== PartyRelation.UNKNOWN) {
      for (
        let index = kindCounter[kind];
        index < MAX_COUNT_PER_PARTY_KIND;
        ++index
      ) {
        const prefix = PartyRelationKey[kind];
        obj[`${prefix}_${index + 1}_Name`] = async () => "";
        obj[`${prefix}_${index + 1}_Relation_Name`] = async () => "";
        obj[`${prefix}_${index + 1}_Phone`] = async () => "";
        obj[`${prefix}_${index + 1}_Email`] = async () => "";
        obj[`${prefix}_${index + 1}_DOB`] = async () => "";
        obj[`${prefix}_${index + 1}_Age`] = async () => "";
        obj[`${prefix}_${index + 1}_SSN`] = async () => "";
        Object.assign(
          obj,
          exportAddress(db, `${prefix}_${index + 1}`, emptyAddress)
        );
      }
    }
  });

  return obj;
}
