import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

/**
 * Defines the properties for the tab label.
 */
export interface TabLabelProps {
    icon: React.ReactNode;
    title: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
        title: {
            display: 'inline-block',
            marginLeft: theme.spacing(1.5)
        },
    }));


/**
 * TabLabel defines a well-styled label for tabs.
 * @param props The props for the TabelLabel.
 * @example <Tab label={<TabLabel icon={<GroupWork />} title="Groups" />} />
 */
export default function TabLabel(props: TabLabelProps) {
    const classes = useStyles();
    return (
        <span className={classes.root}>{props.icon}<span className={classes.title}>{props.title}</span></span>
    );
}
