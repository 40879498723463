import { Avatar, useTheme } from "@material-ui/core";
import React from "react";
import { CaseTemplate } from "../templates/interfaces";
import { getCaseTemplateColor } from "./CaseReference";

export function CaseTemplateIcon(props: { template: CaseTemplate }) {
    const theme = useTheme();
    const color = getCaseTemplateColor(props.template.id);
    const prefix = props.template.title.split(' ').map((w) => w[0]).join('');
    return <Avatar style={{ backgroundColor: color, color: theme.palette.getContrastText(color) }}>{prefix}</Avatar>
}