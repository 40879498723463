import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { PropsWithChildren } from 'react';


interface ThemedProps {
    color?: string
}

export default function Themed(props: PropsWithChildren<ThemedProps>) {

    const theme = React.useMemo(
        () => {
            const colorOverride = {
                500: props.color,
            };

            return createTheme({
                palette: {
                    type: 'dark',
                    primary: props.color ? colorOverride : undefined,
                },
                overrides: {
                    MuiInputLabel: {
                        root: {
                            "&$focused": {
                                color: "white"
                            }
                        }
                    }
                }
            });
        },
        [props.color],
    );


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    )
}