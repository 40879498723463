import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React from "react";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { ChangeFieldMutation, DeleteRowMutation, InsertRowMutation, MutationKind } from "../database/diffable/mutations";


interface MutationPanelProps {
    database: DiffableDatabase
    isOpen: boolean
    closePanel: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarSpacer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            minWidth: '300px',
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
        },
        panelContent: {
            padding: theme.spacing(1.5),
            position: 'absolute',
            top: '124px',
            left: '10px',
            right: '10px',
            bottom: '0px'
        },
        buttonBar: {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            '& > button': {
                marginLeft: theme.spacing(0.5),
                marginRight: theme.spacing(0.5),
            },
            whiteSpace: 'nowrap'
        },
        empty: {
            color: theme.palette.grey['500'],
            textAlign: 'center',
            height: '80px'
        },
        actions: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '70px',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        action: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.default,
        },
        fieldName: {
            color: theme.palette.grey['500'],
        },
        newValue: {
            whiteSpace: 'pre',
            padding: theme.spacing(1),
        },
        overloaded: {
            opacity: 0.5,
        },
        invalidValue: {
            border: '1px solid red'
        },
        panelContentContainer: {
        }
    }));

export function MutationPanel(props: MutationPanelProps) {
    const classes = useStyles();

    const applyChanges = () => {
        props.database.applyAndSave();
    };

    const undoChanges = () => {
        props.database.revertChanges();
    };

    const mutations = props.database.transaction.allMutations();
    const mutationCount = mutations.length;
    const reversedMutations = mutations.slice().reverse();
    const encountered = new Set();

    return <Drawer anchor="right" open={props.isOpen} variant="persistent">
        <div className={classes.panelContentContainer}>
            <div className={classes.toolbarSpacer} />
            <div className={classes.toolbar}>
                <IconButton onClick={props.closePanel}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <Divider />

            <div className={classes.panelContent}>
                {mutationCount === 0 &&
                    <div className={classes.empty}>No changes pending</div>
                }
                {mutationCount > 0 &&
                    <div className={classes.actions}>
                        {reversedMutations.map((mutation, index) => {
                            switch (mutation.kind()) {
                                case MutationKind.CHANGE_FIELD:
                                    const mutationAction = mutation as ChangeFieldMutation;
                                    const encounteredBefore = encountered.has(mutationAction.rowColumnKey());
                                    encountered.add(mutationAction.rowColumnKey());

                                    return <Paper key={index} variant="outlined" className={clsx(classes.action, { [classes.overloaded]: encounteredBefore, [classes.invalidValue]: !mutationAction.isValidValue() })}>
                                        <Typography className={classes.fieldName} variant="caption">{mutationAction.getFieldTitle()}</Typography>
                                        <Typography className={classes.newValue} variant="subtitle2">{mutationAction.niceValue()}</Typography>
                                    </Paper>;

                                case MutationKind.INSERT_ROW:
                                    const insertAction = mutation as InsertRowMutation;
                                    return <Paper key={index} variant="outlined" className={classes.action}>
                                        {insertAction.description}
                                    </Paper>;

                                case MutationKind.DELETE_ROW:
                                    const removeAction = mutation as DeleteRowMutation;
                                    return <Paper key={index} variant="outlined" className={classes.action}>
                                        {removeAction.description}
                                    </Paper>;

                                default:
                                    return <span>Unknown kind of action</span>;
                            }
                        })}
                    </div>
                }
            </div>
            <div className={classes.buttonBar}>
                <Button disabled={mutationCount === 0} variant="contained" color="primary" onClick={applyChanges}>Save Changes</Button>
                <Button disabled={mutationCount === 0} variant="outlined" color="secondary" onClick={undoChanges}>Undo Changes</Button>
            </div>
        </div>
    </Drawer>;
}