import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { PropsWithChildren, useContext } from "react";
import { DatabaseContext } from '../../database/diffable/context';
import { CurrentCaseContext } from '../CurrentContext';
import { useDocumentTracker } from '../documents/DocumentTracker';


interface styleProps {
    missingColor: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        missingMessage: {
            borderRadius: '6px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            backgroundColor: (props: styleProps) => props.missingColor,
            padding: theme.spacing(1),
            display: 'inline-block',
            marginBottom: '-4px',
        },
        box: {
            borderStyle: 'solid',
            borderColor: (props: styleProps) => props.missingColor ? props.missingColor : '',
            borderWidth: (props: styleProps) => props.missingColor ? 2 : 0,
            borderRadius: '6px'
        },
    }));

export function CustomMissingBorder(props: PropsWithChildren<{ bindKey: string }>) {
    const database = useContext(DatabaseContext)!;
    const { tracker } = useDocumentTracker();
    const cse = useContext(CurrentCaseContext)!;

    const { missingMessage, missingColor, isValid } = tracker.missingForCustomKey(props.bindKey, cse, database);
    const classes = useStyles({ 'missingColor': missingColor });

    return <Box className={classes.root}>
        {missingMessage !== undefined && !isValid && <span className={classes.missingMessage}>{missingMessage}</span>}
        <Box className={classes.box}>
            {props.children}
        </Box>
    </Box>
}