import { gql } from "@apollo/client";
import { DocumentTemplate } from "../types/documentemplate";
import { CreatedCaseDocument } from "../types/generateddoc";
import { PaginatedResponse } from "./lib/base";
import { WithErrors, WITH_ERRORS_FRAGMENT } from "./lib/hooks";

const DOCUMENT_FIELDS_FRAGMENT = `
  id
  title
  createdOn
  wordUploadUrl
  wordUrl
  wordEditUrl
  pdfUrl
  zipUrl
  isStarred
  isLatestDocumentRevision
  note
  previewThumbnail
  createdBy {
    name
    email
    avatarUrl
  }
  case {
    id
    templateId
  }
  caseRevision {
    id
    downloadUrl
    updateUrl
    createdAt
    revisionIndex
  }
`;

/**
 * LOOKUP_CASE_DOCUMENT returns the full metadata for a case document, by ID.
 * @returns LookupCaseDocumentData
 */
export const LOOKUP_CASE_DOCUMENT = gql`
query LookupCaseDocumentByID($documentId: ID!) {
  documentById(documentId: $documentId) {
    ${DOCUMENT_FIELDS_FRAGMENT}
  }
}
`;

/**
* LookupCaseDocumentData is the data returned from a LOOKUP_CASE_DOCUMENT call.
*/
export interface LookupCaseDocumentData {
  /**
   * documentById contains the case document found.
   */
  documentById: CreatedCaseDocument
}

/**
 * LOOKUP_CASE_DOCUMENTS returns the documents in a case.
 * @returns LookupCaseDocumentsData
 */
export const LOOKUP_CASE_DOCUMENTS = {
  gql: gql`
  query LookupCaseDocuments($caseId: ID!, $first: Int!, $after: String, $filter: String, $order: String, $orderBy: String, $includePreviousRevision: Boolean) {
    caseById(caseId: $caseId) {
      id
      generatedDocuments(first: $first, after: $after, filter: $filter, order: $order, orderBy: $orderBy, includePreviousRevision: $includePreviousRevision) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            title
            createdOn
            createdBy {
              name
              email
              avatarUrl
            }
            isStarred
            isLatestDocumentRevision
            note
            previewThumbnail
            caseRevision {
              id
              downloadUrl
              updateUrl
              createdAt
              revisionIndex
            }
          }
        }
      }
    }
  }
  `,
  workingMessage: 'Looking up case document',
  errorMessage: 'Failed to lookup case document',
};


/**
 * LookupCaseDocumentsParams are the parameters for a LOOKUP_CASE_DOCUMENT call.
 */
export interface LookupCaseDocumentsParams {
  /**
   * caseId is the ID of the case.
   */
  caseId: string

  /**
   * first indicates the limit on the number of documents returned, if any.
   */
  first: number

  /**
   * filter is the optional filter for the lookup.
   */
  filter?: string

  /**
   * includePreviousRevision indicates whether older revisions of documents should be included.
   */
  includePreviousRevision: boolean
}


export const DOCUMENT_QUERIES = ['LookupCaseDocuments']

/**
* LookupCaseDocumentsData is the data returned from a LOOKUP_CASE_DOCUMENTS call.
*/
export type LookupCaseDocumentsData = {
  caseById: {
    generatedDocuments: PaginatedResponse<CreatedCaseDocument>
  }
}


/**
 * Mutation: Creates a case document.
 * 
 * @param caseId The ID of the case.
 * @param documentTemplateId The ID of the template for the document.
 * @param title The title for the document.
 * @param revisionId The current database revision for the document.
 * 
 * @returns CreatedDocumentData
 */
export const CREATE_CASE_DOCUMENT = {
  gql: gql`
 mutation CreateCaseDocument($caseId: ID!, $documentTemplateId: ID!, $title: String!, $revisionId: ID!) {
   createCaseDocument(caseId: $caseId, documentTemplateId: $documentTemplateId, title: $title, revisionId: $revisionId) {
     ${WITH_ERRORS_FRAGMENT}
       createdDocument {
         ${DOCUMENT_FIELDS_FRAGMENT}
       }
   }
 }
`,
  workingMessage: 'Generating document',
  errorMessage: 'Failed to generate document',
  refetchQueries: []
};

/**
* The parameters for CREATE_CASE_DOCUMENT.
*/
export interface CreateCaseDocumentData {
  caseId: string
  documentTemplateId: string
  title: string
  parameters: string // JSON map
  revisionId: string
}

/**
 * The return value from CREATE_CASE_DOCUMENT.
 */
export type CreatedDocumentData = {
  createCaseDocument: { createdDocument: CreatedCaseDocument } & WithErrors
};


/**
 * Mutation: Deletes a case document.
 * 
 * @param caseId The ID of the case.
 * @param documentId the ID of the document to delete.
 */
export const DELETE_CASE_DOCUMENT = {
  gql: gql`
 mutation DeleteCaseDocument($caseId: ID!, $documentId: ID!) {
   deleteCaseDocument(caseId: $caseId, documentId: $documentId) {
     ${WITH_ERRORS_FRAGMENT}
   }
 }
`,
  workingMessage: 'Deleting document',
  errorMessage: 'Failed to delete document',
  refetchQueries: DOCUMENT_QUERIES
}

/**
 * The parameters for DELETE_CASE_DOCUMENT.
 */
export interface DeleteCaseDocumentData {
  caseId: string
  documentId: string
}

/**
 * Mutation: Toggles the star status of a document.
 * 
 * @param caseId The ID of the case.
 * @param documentId the ID of the document to toggle.
 */
export const STAR_CASE_DOCUMENT = {
  gql: gql`
 mutation StarCaseDocument($caseId: ID!, $documentId: ID!) {
   starCaseDocument(caseId: $caseId, documentId: $documentId) {
     ${WITH_ERRORS_FRAGMENT}
     document {
       ${DOCUMENT_FIELDS_FRAGMENT}
     }
   }
 }
`,
  workingMessage: 'Toggling star on document',
  errorMessage: 'Failed to star document',
  refetchQueries: DOCUMENT_QUERIES,
}

/**
 * The parameters for STAR_CASE_DOCUMENT.
 */
export interface StarCaseDocumentData {
  caseId: string
  documentId: string
}

/**
 * Mutation: Sets a note for a document.
 * 
 * @param caseId The ID of the case.
 * @param documentId the ID of the document on which to set the note.
 * @param note The note for the document.
 */
export const SET_CASE_DOCUMENT_NOTE = {
  gql: gql`
 mutation SetCaseDocumentNote($caseId: ID!, $documentId: ID!, $note: String!) {
   setCaseDocumentNote(caseId: $caseId, documentId: $documentId, note: $note) {
     ${WITH_ERRORS_FRAGMENT}
     document {
       ${DOCUMENT_FIELDS_FRAGMENT}
     }
   }
 }
`,
  workingMessage: 'Setting note on document',
  errorMessage: 'Failed to set note on document',
}

/**
 * The parameters for SET_CASE_DOCUMENT_NOTE.
 */
export interface SetCaseDocumentNote {
  caseId: string
  documentId: string
  note: string
}


export const DOC_TEMPLATE_FIELDS_FRAGMENT = `id
accessLevel
title
description
tags
fields
previewUrl
updateTemplateUrl
previewThumbnail`;


/**
 * Mutation: Adds a document template.
 * 
 * @param caseTemplateId The ID of the flow for which the document is being added
 * @param title The title of the new document
 * @param description The description of the new document
 */
export const ADD_DOCUMENT_TEMPLATE = {
  gql: gql`
 mutation AddDocumentTemplate($caseTemplateId: ID!, $title: String!, $description: String!) {
   addCaseDocumentTemplate(caseTemplateId: $caseTemplateId, title: $title, description: $description) {
     ${WITH_ERRORS_FRAGMENT}
     docTemplate {
       ${DOC_TEMPLATE_FIELDS_FRAGMENT}
     }
   }
 }
`,
  workingMessage: 'Adding document template',
  errorMessage: 'Failed to add the document template',
  refetchQueries: ['LookupCaseByID'],
}

/**
 * The parameters for ADD_DOCUMENT_TEMPLATE.
 */
export interface AddDocumentTemplateParams {
  caseTemplateId: string
  title: string
  description: string
}

/**
 * The data for ADD_DOCUMENT_TEMPLATE.
 */
export type AddDocumentTemplateData = {
  addCaseDocumentTemplate: { docTemplate: DocumentTemplate } & WithErrors
}



/**
 * Mutation: Deletes a document template.
 * 
 * @param docTemplateId The ID of the document template to delete.
 */
export const DELETE_DOCUMENT_TEMPLATE = {
  gql: gql`
 mutation DeleteDocumentTemplate($docTemplateId: ID!) {
   deleteCaseDocumentTemplate(docTemplateId: $docTemplateId) {
     ${WITH_ERRORS_FRAGMENT}
   }
 }
`,
  workingMessage: 'Delete document template',
  errorMessage: 'Failed to delete the document template',
  refetchQueries: ['LookupCaseByID'],
}

/**
 * The parameters for DELETE_DOCUMENT_TEMPLATE.
 */
export interface DeleteDocumentTemplateParams {
  docTemplateId: string
}