import { createStyles, makeStyles, Theme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { ParentListFieldContext } from "./ListField";
import { fieldTitle, WithTitleOrPlaceholder } from "./valuefield";

export type SelectFieldProps = WithTitleOrPlaceholder<{
  column: string;
  values: Record<string, string>;
  fullWidth?: boolean;
}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    edited: {
      "& fieldset": {
        borderColor: theme.palette.warning.main,
      },
    },
    normal: {},
  })
);

export function SelectField(props: SelectFieldProps) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const classes = useStyles();

  const [uniqueId] = useState(_uniqueId("field-"));
  const [localIndex, setLocalIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = (event.target.value as any).toString();
    const niceValue = props.values[newValue.toString()];
    row.setField(
      props.column,
      { value: newValue, isValid: true, niceValue: niceValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );
    setLocalIndex(localIndex + 1);
  };

  const currentValue = (row.getField(props.column) || "").toString();
  return (
    <FormControl
      variant="outlined"
      fullWidth={props.fullWidth}
      classes={{
        root: row.hasMutatedValue(props.column)
          ? classes.edited
          : classes.normal,
      }}
    >
      <InputLabel id={`${uniqueId}-label`}>
        {props.placeholder || props.title}
      </InputLabel>
      <Select
        labelId={`${uniqueId}-label`}
        label={props.placeholder || props.title}
        variant="outlined"
        id={uniqueId}
        value={currentValue}
        onChange={handleChange}
        inputProps={{
          readOnly: database.applying,
        }}
      >
        {Object.keys(props.values).map((valueStr: string) => {
          return (
            <MenuItem key={valueStr} value={valueStr}>
              {props.values[valueStr]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
