import axios from 'axios';
import { SqlJs } from "sql.js/module";
import { v4 as uuidv4 } from 'uuid';
import { DatabaseOptions, State } from "./hooks";
import { ensureIsSelectQuery, IDed, PreparedQuery } from "./sql";

/**
 * Savepoint represents a savepoint on the database.
 */
export class Savepoint {
    public isActive = true;

    constructor(public id: string, private db: Database) { }

    /**
     * revert reverts the savepoint in the database, returning false
     * if the savepoint was already reverted or applied.
     */
    public revert(): boolean {
        if (!this.isActive) {
            return false;
        }

        this.db.raw(`rollback`);
        this.db.currentSavepoint = undefined;
        this.isActive = false;
        return true;
    }

    /**
     * apply applies the savepoint to the database, returning false
     * if the savepoint was already applied or reverted.
     */
    public apply(): boolean {
        if (!this.isActive) {
            return false;
        }

        this.db.raw(`release savepoint "${this.id}"`);
        this.db.currentSavepoint = undefined;
        this.isActive = false;
        return true;
    }
}

/**
 * Database is a wrapper around the SQL.js database, providing functionality
 * around savepoints and saving.
 */
export class Database {
    private saving: boolean = false;
    private closed: boolean = false;
    public currentSavepoint: Savepoint | undefined = undefined;

    constructor(private db: SqlJs.Database,
        private updateCallback: (state: State) => void,
        public options: DatabaseOptions | undefined) {
    }

    /**
     * savepoint returns the current savepoint for the database. If none exists,
     * one is created.
     */
    public savepoint(): Savepoint {
        if (this.currentSavepoint !== undefined) {
            return this.currentSavepoint;
        }

        const id = uuidv4();
        this.raw(`savepoint "${id}"`);
        this.currentSavepoint = new Savepoint(id, this);
        return this.currentSavepoint;
    }

    /**
     * save's the database to the configured update URL, if any.
     * Returns whether the save was a success.
     */
    public async save(): Promise<boolean> {
        if (this.saving) {
            return false;
        }

        if (this.closed) {
            throw Error('Database is closed');
        }

        this.saving = true;

        // If there is an update URL, upload the database changes.
        if (this.options?.updateURL) {
            // Mark the database as saving.
            this.updateCallback({
                loading: false,
                database: this,
                saving: 0,
            });

            // Perform the upload.
            let bearerToken = null;
            if (this.options?.getAuthorizationToken !== undefined) {
                bearerToken = await this.options.getAuthorizationToken();
            }

            const data = this.db.export();
            const headers: Record<string, string> = {
                'content-type': 'application/octet-stream'
            };

            if (bearerToken) {
                headers['authorization'] = `Bearer ${bearerToken}`;
            }

            try {
                const result = await axios.request({
                    method: "post",
                    url: this.options?.updateURL,
                    data: data,
                    headers: headers,
                    onUploadProgress: (p) => {
                        this.updateCallback({
                            loading: false,
                            database: this,
                            saving: p.loaded / p.total,
                        });
                    }
                });

                if (this.options?.updateComplete !== undefined) {
                    this.options?.updateComplete(result.status / 100 === 2, result.data);
                }
            } catch (e) {
                if (this.options?.updateComplete !== undefined) {
                    this.options?.updateComplete(false, e.response.data || { 'error': 'INTERNAL_ERROR' });
                }
                this.updateCallback({
                    loading: false,
                    database: this,
                    saving: undefined
                });
                this.saving = false;
                return false;
            }

            this.updateCallback({
                loading: false,
                database: this,
                saving: undefined
            });
            this.saving = false;
            return true;
        } else {
            this.updateCallback({
                loading: false,
                database: this,
            });
            this.saving = false;
            return true;
        }
    }

    /**
     * close closes the databases.
     */
    public close() {
        if (this.saving) {
            throw Error('Database is currently saving')
        }

        this.closed = true;
        if (this.closed) { return }
        this.db.close()
    }

    /**
     * raw executes a raw query string. SHOULD ONLY BE USED BY INTERNAL CALLS.
     */
    public raw(query: string): any {
        if (this.saving) {
            throw Error('Database is currently saving')
        }

        if (this.closed) {
            throw Error('Database is closed')
        }

        return this.db.exec(query);
    }

    /**
     * run executes a prepared query in the database.
     */
    public run(query: PreparedQuery) {
        const stmt = this.db.prepare(query.query, query.params);
        stmt.run()
    }

    /**
     * lastInsertedId returns the ID of the last inserted row or undefined if none.
     */
    public lastInsertedId(): number | undefined {
        const results = this.db.exec(`select last_insert_rowid()`);
        if (!results.length) {
            return undefined;
        }

        const values = results[0].values[0];
        if (!values.length) {
            return undefined;
        }

        return (values[0] as number) || undefined;
    }

    /**
     * selectAll selects all the rows found in the given query, invoking the handler for each found.
     * If the handler returns false, further selection is terminated.
     * @param query The select query to run.
     * @param handler The handler to invoke with an object representing the data in each row.
     * @example db.selectAll<RecordType>(sql`select * from table`, (result: RecordType) => {
     *    // do something with the record.
     *    return true;
     * })
     */
    public selectAll<TResult extends IDed = IDed>(query: PreparedQuery, handler: (result: TResult) => boolean) {
        if (this.closed) {
            throw Error('Database is closed')
        }

        ensureIsSelectQuery(query)

        const stmt = this.db.prepare(query.query, query.params);
        while (stmt.step()) {
            const result = (stmt.getAsObject() as any) as TResult;
            if (!handler(result)) {
                stmt.free();
                return;
            }
        }

        stmt.free();
    }

    /**
     * selectAllResults selects all the rows found in the given query, returning them as an array.
     * @param query The select query to run.
     */
    public selectAllResults<TResult extends IDed = IDed>(query: PreparedQuery): TResult[] {
        const results: TResult[] = [];
        this.selectAll<TResult>(query, (result) => {
            results.push(result);
            return true;
        })
        return results;
    }
}
