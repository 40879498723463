import { InitialDataRow } from "../../database/schema";
import { AddressKind, EntityKind, EntityType } from "./model";

export const initialData: InitialDataRow[] = [
    ///// Addresses
    { revision: 1, tableName: 'address', values: { kind: AddressKind.TENANT_NOTICE } }, // Tenant Notice
    { revision: 1, tableName: 'address', values: { kind: AddressKind.LANDLORD_NOTICE } }, // Landlord Notice
    { revision: 1, tableName: 'address', values: { kind: AddressKind.GUARANTOR_NOTICE } }, // Guarantor Notice
    { revision: 1, tableName: 'address', values: { kind: AddressKind.BUILDING } }, // Building
    { revision: 1, tableName: 'address', values: { kind: AddressKind.PREMISES } }, // Premises

    ///// Entities.
    // Tenant.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.TENANT,
            type: EntityType.UNKNOWN,
            addressId: 1,
        }
    },

    // Landlord.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.LANDLORD,
            addressId: 2,
        }
    },

    // Guarantor.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.GUARANTOR,
            addressId: 3,
        }
    },

    ///// CommercialLease
    {
        revision: 1,
        tableName: 'commerciallease',
        values: {
            buildingAddressId: 4,
            premisesAddressId: 5,
        }
    },

];
