import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React, { PropsWithChildren, useContext } from "react";
import { ResultContext } from "../../database/diffable/context";

interface BooleanFieldDisplayProps {
    column: string
    title: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        normal: {
        },
        edited: {
            borderColor: theme.palette.warning.dark,
            borderWidth: '1px',
            borderStyle: 'solid',
            padding: theme.spacing(1),
        },
        unsaved: {
            marginBottom: theme.spacing(0.5),
            color: theme.palette.grey[500],
        }
    }));


export function BooleanFieldDisplay(props: PropsWithChildren<BooleanFieldDisplayProps>) {
    const classes = useStyles();
    const row = useContext(ResultContext)!;

    return <>
        {row.getField(props.column) === 1 &&
            <div className={clsx({ [classes.edited]: row.hasMutatedValue(props.column) })}>
                {row.hasMutatedValue(props.column) ? <Alert severity="info" className={classes.unsaved}>Shown because of unsaved change: {props.title}</Alert> : undefined}
                {props.children}
            </div>
        }
    </>;
}

