import { faSave, faUndo, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import React from "react";
import { useHistory } from "react-router-dom";
import { useKeyboardShortcuts } from "use-keyboard-shortcuts";
import { useMutationWatcher } from "../database/diffable/hooks";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { Case } from "../types/case";
import { MutationPanel } from "./MutationPanel";


interface MutationViewProps {
    case: Case
    database: DiffableDatabase
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    }));

export function MutationView(props: MutationViewProps) {
    const classes = useStyles();

    useKeyboardShortcuts([
        {
            keys: ["ctrl", "s"], onEvent: event => {
                (async () => {
                    applyChanges();
                })();
            }
        },
    ])

    const applyChanges = () => {
        props.database.applyAndSave();
        setOpen(false);
    };

    const undoChanges = () => {
        props.database.revertChanges();
        setOpen(false);
    };

    const mutationInfo = useMutationWatcher(props.database, 100)
    const mutationCount = mutationInfo.currentTransaction.currentMutationCount();

    const [open, setOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const hasMutations = mutationCount !== 0;
    const isDisabled = props.database.applying;
    const history = useHistory();

    const handleFabClicked = () => {
        if (hasMutations) {
            applyChanges();
            return
        }

        history.push(`/c/${props.case.id}/documents?tab=new`);
    };

    return <div className={classes.fab}>
        <Badge color="secondary"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={mutationCount}>
            <SpeedDial
                ariaLabel="Save changes"
                icon={
                    props.database.applying ?
                        <CircularProgress />
                        :
                        (hasMutations ? <FontAwesomeIcon icon={faSave} size="2x" /> : <NoteAddIcon fontSize="large" color="action" />)}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="up"
                FabProps={{ disabled: isDisabled, onClick: handleFabClicked }}
            >
                {!isDisabled && hasMutations && <SpeedDialAction
                    icon={<FontAwesomeIcon icon={faSave} />}
                    tooltipTitle="Save Changes"
                    tooltipOpen
                    onClick={applyChanges}
                />}
                {!isDisabled && hasMutations && <SpeedDialAction
                    icon={<FontAwesomeIcon icon={faUndo} />}
                    tooltipTitle="Undo Changes"
                    tooltipOpen
                    onClick={undoChanges}
                />}
                {!isDisabled && hasMutations && <SpeedDialAction
                    icon={<FontAwesomeIcon icon={faUserEdit} />}
                    tooltipTitle="Review Changes"
                    tooltipOpen
                    onClick={() => setDrawerOpen(true)}
                />}
            </SpeedDial>
        </Badge>
        <MutationPanel database={props.database} isOpen={drawerOpen} closePanel={() => setDrawerOpen(false)} />
    </div >;
}