import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBriefcase,
  faCog,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import React, { useContext, useState } from "react";
import { Link, Route } from "react-router-dom";
import { useDeepCompareMemo } from "use-deep-compare";
import { CaseHomeView } from "../components/CaseHomeView";
import CaseNavLink from "../components/CaseNavLink";
import DocumentManagementPanel from "../components/documents/DocumentManagementPanel";
import DocumentView from "../components/documents/DocumentView";
import {
  AutoFocusManager,
  CurrentAutoFocusManager,
} from "../components/fields/autofocus";
import ListItemNavLink from "../components/ListItemNavLink";
import {
  MISSING_FIRM_CASE_REFERENCE_KEY,
  SettingsPane,
} from "../components/SettingsPane";
import { SideDrawerOpenContext } from "../components/SideDrawer";
import { DatabaseContext } from "../database/diffable/context";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { QueryToWatch, useWatchQueries } from "../database/diffable/query";
import { PreparedQuery } from "../database/sql";
import { Case, CaseDatabaseRevision } from "../types/case";
import { CaseTemplateProps } from "./casetemplate";
import { CaseTemplate } from "./interfaces";

export type FlowUIRenderer = (props: {
  case: Case;
  currentRevision: CaseDatabaseRevision;
}) => JSX.Element;
export type PanelRenderer = (props: { case: Case }) => JSX.Element;

export type DefinedPanel = {
  path: string;
  title: string;
  icon: IconProp;
  renderer: PanelRenderer;
  tableBindings?: PreparedQuery[];
  customBindingKeys?: string[];
};

export type PanelGroup = {
  query: QueryToWatch;
  panels: DefinedPanel[];
  shortTitle: string;
  title: (database: DiffableDatabase) => string | undefined;
  color: (title: string, database: DiffableDatabase) => string;
};

export type Panel = DefinedPanel | PanelGroup;

export const useFlowStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    fieldset: {
      borderWidth: "0px",
      "& > div": {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
      },
    },
    nestedFieldset: {
      borderWidth: "0px",
      padding: "0px",
      "& > div": {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
      },
    },
    header: {
      marginBottom: theme.spacing(1.5),
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    headerContainer: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      alignItems: "center",
      columnGap: theme.spacing(2),
      padding: theme.spacing(1),
    },
    subHeader: {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      alignItems: "center",
      columnGap: theme.spacing(2),
    },
    buttonBar: {
      display: "flex",
      marginBottom: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(3),
    },
    caption: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
    captionDisplay: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      "& strong": {
        display: "block",
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    captionTitle: {
      float: "right",
    },
    chips: {
      "& > div": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

function RenderedRoute(props: {
  case: Case;
  panel: DefinedPanel;
  nextPanelPath: string;
}) {
  const classes = useFlowStyles();
  const [autoFocusManager] = useState(new AutoFocusManager());
  const Renderer = props.panel.renderer;

  return (
    <div className={classes.content}>
      <CurrentAutoFocusManager.Provider value={autoFocusManager}>
        <Renderer case={props.case} />
        <div className={classes.buttonBar}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/c/${props.case.id}/${props.nextPanelPath}`}
          >
            Next
          </Button>
        </div>
      </CurrentAutoFocusManager.Provider>
    </div>
  );
}

/**
 * BuildFlowUIRenderer builds the UI for a flow based on defined panels.
 */
export function BuildFlowUIRenderer(template: CaseTemplate): FlowUIRenderer {
  return function UIRender(props: CaseTemplateProps) {
    const classes = useFlowStyles();

    const definedPanels = template.panels.flatMap((panel: Panel) => {
      if ("path" in panel) {
        return panel;
      }

      return panel.panels;
    });

    return (
      <div>
        <Route exact path="/c/:caseid">
          <CaseHomeView
            case={props.case}
            currentRevision={props.currentRevision}
          />
        </Route>

        {definedPanels.map((panel: DefinedPanel, index: number) => {
          const nextPanelPath =
            index === definedPanels.length - 1
              ? "documents"
              : definedPanels[index + 1].path;
          return (
            <Route key={panel.path} exact path={`/c/:caseid/${panel.path}`}>
              <RenderedRoute
                case={props.case}
                panel={panel}
                nextPanelPath={nextPanelPath}
              />
            </Route>
          );
        })}

        <Route exact path="/c/:caseid/documents">
          <DocumentManagementPanel
            case={props.case}
            currentRevision={props.currentRevision}
          />
        </Route>

        <Route
          exact
          path="/c/:caseid/document/:documentid"
          component={DocumentView}
        />

        <Route exact path="/c/:caseid/settings">
          <div className={classes.content}>
            <SettingsPane case={props.case} />
          </div>
        </Route>
      </div>
    );
  };
}

const PanelLink = (props: {
  panel: DefinedPanel;
  case: Case;
  template: CaseTemplate;
}) => {
  if (props.template.dataAccess[props.panel.path] === undefined) {
    throw Error(`Missing DAI for ${props.panel.path}`);
  }

  const link = (link: string) => `/c/${props.case.id}/${link}`;

  return (
    <CaseNavLink
      key={props.panel.path}
      dataAccess={props.template.dataAccess[props.panel.path]}
      customBinds={props.panel.customBindingKeys}
      tableBindings={props.panel.tableBindings}
      icon={<FontAwesomeIcon icon={props.panel.icon} size="lg" />}
      primary={props.panel.title}
      to={link(props.panel.path)}
    />
  );
};

interface PanelGroupStyleProps {
  color: string;
}

const usePanelGroupStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelGroupTitle: {
      padding: theme.spacing(1),
      textAlign: "center",
      fontWeight: "bold",
      color: (styles: PanelGroupStyleProps) =>
        theme.palette.getContrastText(styles.color),
      backgroundColor: (styles: PanelGroupStyleProps) => styles.color,
    },
    panelGroupPanels: {
      borderWidth: "0px",
      borderLeftWidth: theme.spacing(0.5),
      borderStyle: "solid",
      borderColor: (styles: PanelGroupStyleProps) => styles.color,
    },
  })
);

function PanelGroupRenderer(props: {
  title: string;
  group: PanelGroup;
  case: Case;
  template: CaseTemplate;
  database: DiffableDatabase;
}) {
  const classes = usePanelGroupStyles({
    color: props.group.color(props.title, props.database),
  });
  const isSideDrawerOpen = useContext(SideDrawerOpenContext);

  return (
    <>
      <Divider />
      <div className={classes.panelGroupTitle}>
        {isSideDrawerOpen ? props.title : props.group.shortTitle}
      </div>
      <div className={classes.panelGroupPanels}>
        {props.group.panels.map((p, index) => (
          <PanelLink
            key={index}
            panel={p}
            case={props.case}
            template={props.template}
          />
        ))}
      </div>
    </>
  );
}

/**
 * BuildFlowUISidebarRenderer builds the UI for the sidebar of a flow based on defined panels.
 */
export function BuildFlowUISidebarRenderer(
  template: CaseTemplate
): FlowUIRenderer {
  return function SidebarUIRender(props: CaseTemplateProps) {
    const panels = template.panels;
    const queries = useDeepCompareMemo(() => {
      return panels
        .map((p) => {
          if ("query" in p) {
            return p.query;
          }

          return undefined;
        })
        .filter((q) => !!q);
    }, [panels]) as QueryToWatch[];

    useWatchQueries(queries);

    const database = useContext(DatabaseContext);
    const link = (link: string) => `/c/${props.case.id}/${link}`;

    return (
      <List>
        <ListItemNavLink
          icon={<FontAwesomeIcon icon={faBriefcase} size="lg" />}
          primary="Case Home"
          to={link("")}
        />
        <Divider />
        {template.panels.map((panel: Panel, index: number) => {
          if ("path" in panel) {
            return (
              <PanelLink
                key={index}
                panel={panel}
                case={props.case}
                template={template}
              />
            );
          }

          const title = panel.title(database!);
          if (!title) {
            return undefined;
          }

          return (
            <PanelGroupRenderer
              key={index}
              title={title}
              group={panel}
              case={props.case}
              template={template}
              database={database!}
            />
          );
        })}
        <Divider />
        <ListItemNavLink
          icon={<FontAwesomeIcon icon={faFileWord} size="lg" />}
          primary="Documents"
          to={link("documents")}
        />
        <CaseNavLink
          dataAccess={{}}
          tableBindings={undefined}
          customBinds={[MISSING_FIRM_CASE_REFERENCE_KEY]}
          icon={<FontAwesomeIcon icon={faCog} size="lg" />}
          primary="Settings"
          to={link("settings")}
        />
      </List>
    );
  };
}
