import { Database } from "../database";
import { IDed, PreparedQuery } from "../sql";
import { DiffableDatabase, MutableRow, MutatedCallback, MutatedCallbackHandle, MutationTransaction } from "./interfaces";
import { Mutation } from "./mutations";
import { MutationTransactionImpl } from "./mutationtransaction";

/**
 * DiffableDatabaseImpl is a wrapper around a database that provides automatic
 * differencing based on MutableRow's and use of savepoints to collect
 * changes before applying them.
 */
export class DiffableDatabaseImpl implements DiffableDatabase {
    private mutationCallbackHandleCounter = 0;
    private readonly mutationCallbacks: Record<number, MutatedCallback> = {};

    /**
     * applying indicates whether the current mutation collection is being
     * applied to the database.
     */
    public applying: boolean = false;

    /**
     * transaction holds the current transaction of mutations.
     */
    public transaction: MutationTransaction;

    constructor(private database: Database) {
        this.transaction = new MutationTransactionImpl(this, database, this.markChanged.bind(this));
    }

    /**
     * Registers a new callback to be invoked when a table is changed in some way.
     */
    public registerMutatedCallback(callback: MutatedCallback): MutatedCallbackHandle {
        const handle = this.mutationCallbackHandleCounter++;
        this.mutationCallbacks[handle] = callback;
        return handle;
    }

    /**
     * Unregisters a callback, as referenced by the handle.
     */
    public unregisterMutatedCallback(handle: MutatedCallbackHandle) {
        delete this.mutationCallbacks[handle];
    }

    /**
     * mutableForRow returns the MutableRow wrapper over a database row, for tracking
     * any changes to fields in that row as part of the current savepoint.
     */
    public mutableForRow<TResult extends IDed = IDed>(tableName: string, row: TResult): MutableRow<TResult> {
        return this.transaction.mutableForRow<TResult>(tableName, row)
    }

    /**
     * applyAndSave applies all changes currently contained in the active transaction.
     */
    public async applyAndSave(): Promise<boolean> {
        if (this.applying) { return false; }

        // Apply the changes.
        this.applying = true;
        if (!this.transaction.apply()) {
            this.applying = false;
            return false;
        }

        // Save the database.
        const saved = await this.database.save();
        if (!saved) {
            this.applying = false;
            return false;
        }

        // Create a new transaction.
        this.transaction = new MutationTransactionImpl(this, this.database, this.markChanged.bind(this));
        this.applying = false;
        this.markChanged();
        return true;
    }

    /**
     * revertChanges reverts all changes currently contained in the active transaction.
     */
    public revertChanges() {
        if (this.applying) { return; }

        this.transaction.revert();
        this.transaction = new MutationTransactionImpl(this, this.database, this.markChanged.bind(this));
        this.markChanged();
    }

    /**
     * selectAllResults selects all the rows found in the given query, returning them as an array.
     * @param query The select query to run.
     */
    public selectAllResults<TResult extends IDed = IDed>(query: PreparedQuery): MutableRow<TResult>[] {
        return this.database.selectAllResults<TResult>(query).map((row: TResult) => {
            return this.mutableForRow(query.tableName, row);
        });
    }

    /**
     * lastInsertedId returns the ID of the last inserted row, if any.
     */
    public lastInsertedId(): number | undefined {
        return this.database.lastInsertedId();
    }

    private markChanged(tableName?: string, mutation?: Mutation) {
        Object.values(this.mutationCallbacks).forEach((callback: MutatedCallback) => {
            callback(tableName, mutation);
        });
    }
}
