import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@material-ui/core";
import React from "react";

export function FilterBox(props: {
  placeholder: string;
  label: string;
  filter: string;
  onFilterChanged: (value: string) => void;
}) {
  return (
    <TextField
      value={props.filter}
      onChange={(e) => props.onFilterChanged(e.target.value)}
      InputProps={{
        startAdornment: (
          <div style={{ marginRight: "10px" }}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        ),
      }}
      placeholder={props.placeholder}
      label={props.label}
      variant="outlined"
    />
  );
}
