import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { StateIcon, StateTitle } from "../services/states";
import { FirmMember } from "../types/firmmember";
import { AttorneyLicense } from "../types/license";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        licenseList: {
            padding: 0,
            margin: 0,
            listStyle: 'none',

        },
        license: {
            display: 'inline-grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto',
            marginRight: theme.spacing(1),
        },
        stateIcon: {
            '& svg': {
                display: 'inline-block',
                maxHeight: '2em',
                width: '2em',
                marginRight: theme.spacing(1),
                verticalAlign: 'unset !important',
                '& path': {
                    fill: theme.palette.text.primary,
                }
            }
        },
    }));


/**
* LicensesDisplay is a graphical display of the states in which the member is licensed.
*/
export function LicensesDisplay(props: { member: FirmMember }) {
    const classes = useStyles();
    return <div className={classes.licenseList}>
        {
            props.member.licenses.map((license: AttorneyLicense) => {
                return <LicenseDisplay key={license.stateId} license={license} abbreviations />
            })
        }
    </div>;
}

export function LicenseDisplay(props: { license: AttorneyLicense, abbreviations?: boolean }) {
    const classes = useStyles();
    const license = props.license;
    const AttStateIcon = StateIcon(license.stateId);
    const title = props.abbreviations ? license.stateId.toUpperCase() : StateTitle(license.stateId);
    return <span className={classes.license}>{AttStateIcon && <span className={classes.stateIcon}><AttStateIcon /></span>}{title}</span>;
}