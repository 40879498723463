import React, { useContext, useEffect, useState } from 'react';
import { DiffableDatabase, MutableRow, RowType } from '../../database/diffable/interfaces';
import { DocumentMissing, TrackedValue } from '../../services/documentservice';
import { Case } from '../../types/case';
import { DocumentTemplate } from '../../types/documentemplate';

const COLORS = ['#9c27b0', '#673ab7', '#e91e63', '#2196f3', '#009688', '#4caf50', '#f57f17',
    '#bf360c', '#f48fb1', '#c4d191', '#d18651', '#887fd1',

    // From d3 ordinal 20.
    '#3182bd',
    '#6baed6',
    '#9ecae1',
    '#c6dbef',
    '#e6550d',
    '#fd8d3c',
    '#fdae6b',
    '#fdd0a2',
    '#31a354',
    '#74c476',
    '#a1d99b',
    '#c7e9c0',
    '#756bb1',
    '#9e9ac8',
    '#bcbddc',
    '#dadaeb',
    '#636363',
    '#969696',
    '#bdbdbd',
    '#d9d9d9',
];

export interface DocumentToGenerate {
    docTemplate: DocumentTemplate
    missing: DocumentMissing
    color: string
}

export type Callback = () => void;

function getPMFKey(tableName: string, id: number, columnName: string): string {
    return `${tableName}:${id}::${columnName}`
}

function getRowKey(tableName: string, id: number): string {
    return `${tableName}:${id}`
}

function getColKey(tableName: string, columnName: string): string {
    return `${tableName}::${columnName}`
}

export interface DocumentTrackerState {
    tracker: DocumentTracker
    documents: DocumentToGenerate[]
}

/**
 * useDocumentTracker is a hook which updates whenever the document tracker updates.
 */
export function useDocumentTracker(): DocumentTrackerState {
    const tracker = useContext(DocumentTrackerContext)!;
    const [documents, setDocuments] = useState<DocumentToGenerate[]>(tracker.documents());

    useEffect(() => {
        const removeCallback = tracker.addCallback(() => {
            setDocuments(tracker.documents());
        });

        return () => {
            removeCallback();
        };
    }, [tracker]);

    return {
        'tracker': tracker,
        'documents': documents,
    }
}

/**
 * DocumentTracker is used to track the missing fields for documents that were
 * attempted to be generated.
 */
export class DocumentTracker {
    constructor(private documentKindsByTemplateId: Record<string, DocumentToGenerate> = {},
        private documentByField: Record<string, DocumentToGenerate> = {},
        private documentByColumn: Record<string, DocumentToGenerate> = {},
        private documentByCustomKey: Record<string, DocumentToGenerate> = {},
        private rowMap: Record<string, DocumentToGenerate> = {},
        private callbacks: Callback[] = [],
        private colorIndex = 0) {
    }

    /**
     * missingForValue returns the missing field information for a specific field on a row.
     */
    public missingForValue<T extends RowType = RowType>(row: MutableRow<T>, columnName: string, isValueMissing: () => boolean) {
        const hasMissingValue = isValueMissing();
        const document = this.documentForField(row.tableName, row.id(), columnName);
        return {
            hasMissingValue: hasMissingValue,
            missingColor: hasMissingValue ? document?.color : undefined,
            missingForDocument: document,
        }
    }

    /**
     * missingForCustomKey returns whether a custom keyed field is missing for at least
     * one document in the tracker.
     * @param key The custom field key.
     * @param cse The current case.
     * @param database The current case database.
     */
    public missingForCustomKey(key: string, cse: Case, database: DiffableDatabase) {
        const missingForDocument = this.documentForCustomKey(key);
        let missingMessage = undefined;
        let isValid = false;

        const missingResult = missingForDocument?.missing.lookupCustom(key)?.checker(cse, database);
        if (missingResult) {
            [missingMessage, isValid] = missingResult;
        }
        const missingColor = missingForDocument && !isValid ? missingForDocument.color : undefined;

        return {
            missingColor: missingColor,
            missingMessage: missingMessage,
            isValid: isValid,
        }
    }

    /**
     * countMissingFields returns the count of fields from the DTF that are currently
     * missing in the database.
     */
    public countMissingFields(cse: Case, dtg: DocumentToGenerate, database: DiffableDatabase) {
        // Count any custom checks.
        let customCount = 0;

        for (var custom of dtg.missing.listCustomMissing()) {
            const [, ok] = custom.checker(cse, database);
            if (!ok) {
                customCount += 1;
            }
        }

        return dtg.missing.listMissingValues().filter((field: TrackedValue) => {
            return database.transaction.hasMissingValue(field.tableName, field.rowId, field.columnName);
        }).length + customCount;
    }

    /**
     * addCallback adds a callback to be invoked when the documents tracked change.
     * Returns a callback to invoke to deregister the callback.
     */
    public addCallback(callback: Callback): Callback {
        this.callbacks.push(callback);
        return () => {
            const index = this.callbacks.indexOf(callback, 0);
            if (index > -1) {
                this.callbacks.splice(index, 1);
            }
        };
    }

    /**
     * rowHasMissingField returns if the given row in the given table has a missing
     * field in one or more documents.
     * @param tableName The name of the table for the row.
     * @param id The unique ID for the row in the table.
     */
    public rowHasMissingField(tableName: string, id: number) {
        const key = getRowKey(tableName, id);
        return key in this.rowMap;
    }

    /**
     * documentForCustomKey returns the first document to generate for the given custom key,
     * or undefined if none.
     */
    public documentForCustomKey(key: string): DocumentToGenerate | undefined {
        if (!(key in this.documentByCustomKey)) {
            return undefined;
        }

        return this.documentByCustomKey[key];
    }

    /**
     * documentForRow returns the first document to generate for the given row, or undefined
     * if none.
     * @param tableName The name of the table for the row.
     * @param id The unique ID for the row in the table.
     */
    public documentForRow(tableName: string, id: number): DocumentToGenerate | undefined {
        if (!this.rowHasMissingField(tableName, id)) {
            return undefined;
        }

        const key = getRowKey(tableName, id);
        return this.rowMap[key];
    }

    /**
     * documentForField returns the DTG that contains a the given column, on the given
     * row, as missing.
     * @param tableName The name of the table for the row.
     * @param id The unique ID for the row in the table.
     * @param columnName The name of the column that might be missing.
     */
    public documentForField(tableName: string, id: number, columnName: string): DocumentToGenerate | undefined {
        const key = getPMFKey(tableName, id, columnName);
        if (!(key in this.documentByField)) {
            return undefined;
        }
        return this.documentByField[key];
    }

    /**
     * documentForColumn returns the DTG that contains the given column on the given table,
     * or undefined if none.
     * @param tableName 
     * @param columnName 
     */
    public documentForColumn(tableName: string, columnName: string): DocumentToGenerate | undefined {
        const key = getColKey(tableName, columnName);
        if (!(key in this.documentByColumn)) {
            return undefined;
        }
        return this.documentByColumn[key];
    }

    public hasDocuments(): boolean {
        return this.documents().length > 0;
    }

    public documents(): DocumentToGenerate[] {
        return Object.values(this.documentKindsByTemplateId);
    }

    /**
     * hasDocument returns whether the tracker contains the document.
     */
    public hasDocument(docTemplate: DocumentTemplate) {
        return docTemplate.id in this.documentKindsByTemplateId;
    }

    /**
     * getDocument returns the DTG for the document, if any.
     */
    public getDocument(docTemplate: DocumentTemplate): DocumentToGenerate | undefined {
        if (!this.hasDocument(docTemplate)) {
            return undefined;
        }

        return this.documentKindsByTemplateId[docTemplate.id];
    }

    /**
     * addDocument adds a document of the specific type, with the given missing fields,
     * to the tracker.
     */
    public addDocument(docTemplate: DocumentTemplate, missing: DocumentMissing, skipRefresh?: boolean): DocumentToGenerate {
        let color = COLORS[this.colorIndex % COLORS.length];
        if (docTemplate.id in this.documentKindsByTemplateId) {
            color = this.documentKindsByTemplateId[docTemplate.id].color;
        } else {
            this.colorIndex++;
        }

        const dtg = {
            docTemplate: docTemplate,
            missing: missing,
            color: color,
        };
        this.documentKindsByTemplateId[docTemplate.id] = dtg;
        this.rebuild();

        if (skipRefresh !== true) {
            this.callbacks.forEach((callback: Callback) => callback());
        }
        return dtg;
    }

    public refresh() {
        this.callbacks.forEach((callback: Callback) => callback());
    }

    public removeDocument(docTemplate: DocumentTemplate) {
        delete this.documentKindsByTemplateId[docTemplate.id];
        this.rebuild();
        this.callbacks.forEach((callback: Callback) => callback());
    }

    public removeAllDocuments() {
        this.documentKindsByTemplateId = {};
        this.rebuild();
        this.callbacks.forEach((callback: Callback) => callback());
    }

    private rebuild() {
        this.documentByField = {};
        this.documentByColumn = {};
        this.documentByCustomKey = {};
        this.rowMap = {};

        Object.values(this.documentKindsByTemplateId).forEach((dtg: DocumentToGenerate) => {
            dtg.missing.listMissingValues().forEach((pmf: TrackedValue) => {
                this.documentByField[getPMFKey(pmf.tableName, pmf.rowId, pmf.columnName)] = dtg;
                this.rowMap[getRowKey(pmf.tableName, pmf.rowId)] = dtg;
                this.documentByColumn[getColKey(pmf.tableName, pmf.columnName)] = dtg;
            });

            dtg.missing.customMissingKeys().forEach((key: string) => {
                this.documentByCustomKey[key] = dtg;
            });
        });
    }
}

/**
 * DocumentTrackerContext defines context which holds a reference to the document tracker,
 * which tracks which documents were attempted to be generated and any missing fields for them.
 */
export const DocumentTrackerContext = React.createContext<DocumentTracker | undefined>(undefined);
DocumentTrackerContext.displayName = 'CurrentDocumentTracker'
