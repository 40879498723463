import {
  faExclamationCircle,
  faFileContract,
  faFileInvoice,
  faSignature,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Paper, Typography, useTheme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import React, { useCallback, useState } from "react";
import stc from "string-to-color";
import { CalculatedInterestDisplay } from "../../components/CalculatedInterestDisplay";
import {
  aspect,
  CalculatedTotalDisplay,
} from "../../components/CalculatedTotalDisplay";
import { AddressEditorField } from "../../components/fields/AddressField";
import { AutocompleteField } from "../../components/fields/AutocompleteField";
import { BooleanField } from "../../components/fields/BooleanField";
import { CurrencyField } from "../../components/fields/CurrencyField";
import { CustomMissingBorder } from "../../components/fields/CustomMissingBorder";
import { DateField } from "../../components/fields/DateField";
import { DocumentDateField } from "../../components/fields/DocumentDateField";
import { EmailField } from "../../components/fields/EmailField";
import { EnumField } from "../../components/fields/EnumField";
import {
  ListField,
  ReadOnlyListField,
} from "../../components/fields/ListField";
import { MultiEnumField } from "../../components/fields/MultiEnumField";
import { PercentageField } from "../../components/fields/PercentageField";
import { PhoneNumberField } from "../../components/fields/PhoneNumberField";
import { RadioBooleanField } from "../../components/fields/RadioBooleanField";
import { SelectField } from "../../components/fields/SelectField";
import { SelectRowField } from "../../components/fields/SelectRowField";
import { StateField } from "../../components/fields/StateField";
import { ValueField } from "../../components/fields/valuefield";
import { FilterBox } from "../../components/FilterBox";
import {
  MISSING_SIGNER_KEY,
  PleadingAttorneyManager,
} from "../../components/PleadingAttorneyManager";
import { RowRenderer } from "../../database/diffable/components";
import { ResultContext } from "../../database/diffable/context";
import {
  DiffableDatabase,
  MutableRow,
} from "../../database/diffable/interfaces";
import { useQuery } from "../../database/diffable/query";
import { sql } from "../../database/sql";
import { interestForRowOrUndefined } from "../../shareddata/interest";
import { Address } from "../../sharedschema/address";
import { Case } from "../../types/case";
import { enumSetfromString } from "../../util/enum";
import { Panel, useFlowStyles } from "../uibuilder";
import {
  Affiant,
  ContractTerms,
  LitigationCase,
  LitigationCaseParty,
  Motion,
  MotionEntity,
  MotionEntityType,
  MotionEntityTypeDescription,
  Party,
  PartyForm,
  PartyFormDescription,
  PartyKind,
  PartyKindDescription,
  ServiceAddress,
} from "./model";

export const MISSING_ACTIVE_MOTION_KEY = "litigation_motion";

const caseTitle = (
  row: MutableRow<LitigationCase>,
  parties: MutableRow<Party>[],
  litigationParties: MutableRow<LitigationCaseParty>[],
  defaultValue: string
) => {
  const lps = litigationParties.filter(
    (lcp) => lcp.getField("caseId") === row.id()
  );
  const plaintiffIds = lps
    ?.filter((lcp) => lcp.getField("partyKind") === PartyKind.PLAINTIFF)
    ?.map((lcp) => lcp.getField("partyId"));
  const defendantIds = lps
    ?.filter((lcp) => lcp.getField("partyKind") === PartyKind.DEFENDANT)
    ?.map((lcp) => lcp.getField("partyId"));

  if (plaintiffIds.length && defendantIds.length) {
    let plaintiffs = parties
      ?.filter((p) => plaintiffIds.includes(p.id()))
      ?.map(getPartyDescription);
    let defendants = parties
      ?.filter((p) => defendantIds.includes(p.id()))
      ?.map(getPartyDescription);

    let plaintiffSuffix = "";
    let defendantSuffix = "";

    if (row.getField("showOnlyFirstPartyInCaption") === 1) {
      plaintiffSuffix = plaintiffs.length > 1 ? " et al." : "";
      defendantSuffix = defendants.length > 1 ? " et al." : "";
      plaintiffs = plaintiffs?.slice(0, 1);
      defendants = defendants?.slice(0, 1);
    }

    return `${plaintiffs?.join(", ")}${plaintiffSuffix} v. ${defendants?.join(
      ", "
    )}${defendantSuffix}`;
  }

  const docketNumber = row.getField("docketNumber");
  if (docketNumber) {
    return docketNumber;
  }

  return defaultValue;
};

export const LitigationPanels: Panel[] = [
  {
    path: "parties",
    title: "All Parties",
    icon: faUserPlus,
    renderer: PartyEditor,
    tableBindings: [sql`select * from ${"party"}`],
  },
  {
    path: "contract",
    title: "Contracts",
    icon: faFileContract,
    renderer: ContractEditor,
    tableBindings: [sql`select * from ${"contractterms"}`],
  },
  {
    path: "cases",
    title: "Litigation Cases",
    icon: faFileInvoice,
    renderer: CaptionEditor,
    tableBindings: [sql`select * from ${"litigationcase"}`],
  },
  {
    query: {
      query: sql`select * from ${"litigationcase"}`,
      options: {
        fieldNames: ["isActive"],
      },
    },
    panels: [
      {
        path: "caseparties",
        title: "Case Parties",
        icon: faUsers,
        renderer: CasePartyEditor,
        customBindingKeys: [MISSING_ACTIVE_MOTION_KEY],
      },
      {
        path: "motion",
        title: "Motions",
        icon: faExclamationCircle,
        renderer: MotionEditor,
        customBindingKeys: [MISSING_ACTIVE_MOTION_KEY],
      },
    ],
    shortTitle: "Case",
    title: (database: DiffableDatabase) => {
      const cases = database.selectAllResults<LitigationCase>(
        sql`select * from ${"litigationcase"}`
      );

      const activeCases = cases.filter((c) => c.getField("isActive") === 1);
      if (activeCases.length !== 1) {
        return undefined;
      }

      const parties = database.selectAllResults<Party>(
        sql`select * from ${"party"}`
      );
      const litigationparties = database.selectAllResults<LitigationCaseParty>(
        sql`select * from ${"litigationcaseparty"} where caseId=${activeCases[0].id()}`
      );

      return activeCases.length === 1
        ? caseTitle(activeCases[0], parties, litigationparties, "Active Case")
        : undefined;
    },
    color: (title: string, database: DiffableDatabase) => {
      return stc(title);
    },
  },
  {
    path: "affiant",
    title: "Affiant",
    icon: faSignature,
    renderer: AffiantEditor,
    customBindingKeys: [MISSING_SIGNER_KEY],
  },
];

function PartyIcon(props: { party: MutableRow<Party>; small?: boolean }) {
  const partyColor = stc(getPartyDescription(props.party));
  let style: React.CSSProperties = { backgroundColor: partyColor };
  if (props.small) {
    style = {
      ...style,
      width: "1.5em",
      height: "1.5em",
    };
  }

  return <Avatar style={style}>{getPartyDescription(props.party)[0]}</Avatar>;
}

function getPartyDescription(
  party: MutableRow<Party>,
  opt_defaultValueOrIndex: string | number | undefined = undefined
): string {
  const name = getPartyName(party);
  if (name) {
    return name;
  }

  if (typeof opt_defaultValueOrIndex === "string") {
    return opt_defaultValueOrIndex;
  }

  if (typeof opt_defaultValueOrIndex === "number") {
    return `Party #${opt_defaultValueOrIndex + 1}`;
  }

  return "Party";
}

function getPartyName(party: MutableRow<Party>): string {
  return party.getField("fullName") || party.getField("shortName") || "";
}

function getPartyShortName(party: MutableRow<Party>): string {
  return party.getField("shortName") || "";
}

function PartyEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const [filter, setFilter] = useState("");

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header} variant="h5">
          <FontAwesomeIcon icon={faUsers} size="lg" />
          Parties
        </Typography>
        <FilterBox
          filter={filter}
          onFilterChanged={setFilter}
          placeholder="John Doe"
          label="Filter by Party Name"
        />
      </div>

      <ListField<Party>
        collapsableItems
        itemFilter={filter}
        tableName="party"
        rowTitle="Party"
        empty={{
          fullName: "",
        }}
        addDescription="Added new party"
        removeDescription="Removed party"
        boundSubTables={{
          serviceaddress: "partyId",
          litigationcaseparty: "partyId",
        }}
        filterFunction={(
          row: MutableRow<Party>,
          index: number,
          itemFilter: string
        ) => {
          const name = getPartyName(row);
          const shortName = getPartyShortName(row);
          return (
            !name ||
            name.toLowerCase().indexOf(itemFilter.toLowerCase()) >= 0 ||
            shortName.toLowerCase().indexOf(itemFilter.toLowerCase()) >= 0
          );
        }}
        describeRow={(row: MutableRow<Party>, index: number) => {
          const name = getPartyName(row);
          if (name) {
            return (
              <h3 className={classes.subHeader} style={{ marginLeft: "10px" }}>
                <PartyIcon party={row} small />
                <span>
                  {name} - Party #{index + 1}
                </span>
              </h3>
            );
          }
          return undefined;
        }}
        rowDescription={getPartyDescription}
      >
        {(row: MutableRow<Party>, index: number) => {
          const loadAddressForPreviousParty = (database: DiffableDatabase) => {
            const results = database.selectAllResults<Party>(
              sql`select * from ${"party"} where id < ${row.id()} order by id`
            );
            const previousParty = results[results.length - 1];
            return previousParty as MutableRow<Address>;
          };

          const partyId = row.id();
          const partyDescription = getPartyDescription(row, index);

          const loadAddressForParty = (database: DiffableDatabase) => {
            return row as MutableRow<Party>;
          };

          return (
            <fieldset className={classes.fieldset}>
              <fieldset className={classes.fieldset}>
                <ValueField
                  column="fullName"
                  fullWidth
                  placeholder="Full Name"
                />
                <ValueField
                  column="shortName"
                  fullWidth
                  placeholder="Short Name"
                />
                <MultiEnumField<PartyForm>
                  values={PartyFormDescription}
                  fullWidth
                  column="form"
                  placeholder="Form"
                />
                <StateField
                  column="formationState"
                  fullWidth
                  placeholder="Formation State"
                />
                <AddressEditorField
                  placeholder="Address"
                  copyFromTitle={
                    index > 0 ? "Copy From Previous Party" : undefined
                  }
                  copyFrom={(database: DiffableDatabase) =>
                    loadAddressForPreviousParty(database)
                  }
                />
                <EmailField
                  fullWidth
                  column="emailAddress"
                  placeholder="E-mail address"
                />
                <PhoneNumberField
                  fullWidth
                  column="phoneNumber"
                  placeholder="Phone number"
                />
                <DateField
                  column="dateOfBirthOrFormation"
                  fullWidth
                  placeholder="Date of Birth/Formation"
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Service Addresses
              </Typography>
              <fieldset className={classes.fieldset}>
                <ListField<ServiceAddress>
                  itemFilter={filter}
                  tableName="serviceaddress"
                  rowTitle="Service Address"
                  empty={{
                    partyId: row.id(),
                  }}
                  query={sql`select * from ${"serviceaddress"} where partyId=${partyId}`}
                  addDescription={`Added new service address for party ${partyDescription}`}
                  removeDescription={`Removed new service address for party ${partyDescription}`}
                  maximumCount={5}
                >
                  {(row: MutableRow<ServiceAddress>, index: number) => {
                    return (
                      <div>
                        <AddressEditorField
                          title={`${partyDescription} service address #${
                            index + 1
                          }`}
                          copyFromTitle={"Copy From Party"}
                          copyFrom={(database: DiffableDatabase) =>
                            loadAddressForParty(database)
                          }
                          fullWidth
                        />
                      </div>
                    );
                  }}
                </ListField>
              </fieldset>
            </fieldset>
          );
        }}
      </ListField>
    </div>
  );
}

function ContractEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  const [filter, setFilter] = useState("");

  const describeContract = useCallback(
    (row: MutableRow<ContractTerms>, index: number) => {
      return row.getField("title")
        ? row.getField("title")
        : `Contract #${index + 1}`;
    },
    []
  );

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header} variant="h5">
          <FontAwesomeIcon icon={faFileContract} size="lg" />
          Contracts
        </Typography>
        <FilterBox
          filter={filter}
          onFilterChanged={setFilter}
          placeholder="Some Contract"
          label="Filter by Title"
        />
      </div>

      <ListField<ContractTerms>
        collapsableItems
        itemFilter={filter}
        tableName="contractterms"
        rowTitle="Contract"
        empty={{
          title: "",
        }}
        addDescription="Added new contract"
        removeDescription="Removed contract"
        boundSubTables={{
          litigationcaseparty: "contractId",
        }}
        filterFunction={(
          row: MutableRow<ContractTerms>,
          index: number,
          itemFilter: string
        ) => {
          return (
            row
              .getField("title")
              .toLowerCase()
              .indexOf(itemFilter.toLowerCase()) >= 0
          );
        }}
        describeRow={(row: MutableRow<ContractTerms>, index: number) => {
          return (
            <h3 className={classes.subHeader} style={{ marginLeft: "10px" }}>
              <span />
              <span>{describeContract(row, index)}</span>
            </h3>
          );
        }}
        rowDescription={describeContract}
      >
        {(row: MutableRow<ContractTerms>, index: number) => {
          const copyFromTitle =
            index > 0 ? "Copy From Prior Contract" : undefined;
          const copyFrom = (fieldName: string) => {
            return index > 0
              ? (database: DiffableDatabase) => {
                  const results = database.selectAllResults<Party>(
                    sql`select * from ${"contractterms"} where id < ${row.id()} order by id`
                  );

                  if (results.length === 0) {
                    return undefined;
                  }

                  return results[results.length - 1].getField(fieldName);
                }
              : undefined;
          };

          return (
            <Paper className={classes.paper}>
              <Typography className={classes.subHeader} variant="h6">
                Contract Details
              </Typography>
              <fieldset className={classes.fieldset}>
                <ValueField fullWidth column="title" placeholder="Title" />
                <ValueField
                  fullWidth
                  column="shortTitle"
                  placeholder="Short Title"
                />
                <DateField
                  fullWidth
                  column="date"
                  placeholder="Date"
                  copyFrom={copyFrom("date")}
                  copyFromTitle={copyFromTitle}
                />
                <SelectField
                  fullWidth
                  column="amendments"
                  placeholder="Contract Amendments"
                  values={{
                    "": "(None)",
                    "as amended": "as amended",
                    "as assigned": "as assigned",
                    "as amended and assigned": "as amended and assigned",
                  }}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Principal
              </Typography>
              <fieldset className={classes.fieldset}>
                <CurrencyField
                  fullWidth
                  column="principalAmountDue"
                  placeholder="Principal Amount Due"
                  copyFrom={copyFrom("principalAmountDue")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="principalAmountDueDescription"
                  placeholder="Principal Amount Due Description"
                  multiline
                  copyFrom={copyFrom("principalAmountDueDescription")}
                  copyFromTitle={copyFromTitle}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Interest
              </Typography>
              <fieldset className={classes.fieldset}>
                <PercentageField
                  fullWidth
                  column="interestRate"
                  placeholder="Interest Rate"
                  copyFrom={copyFrom("interestRate")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="interestRateBasis"
                  placeholder="Interest Rate Basis"
                  multiline
                  copyFrom={copyFrom("interestRateBasis")}
                  copyFromTitle={copyFromTitle}
                />
                <DateField
                  fullWidth
                  column="interestStartDate"
                  placeholder="Interest Start Date"
                  copyFrom={copyFrom("interestStartDate")}
                  copyFromTitle={copyFromTitle}
                />
                <DateField
                  fullWidth
                  column="interestEndDate"
                  placeholder="Interest End Date"
                  copyFrom={copyFrom("interestEndDate")}
                  copyFromTitle={copyFromTitle}
                />
                <CalculatedInterestDisplay />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Late Fee
              </Typography>
              <fieldset className={classes.fieldset}>
                <CurrencyField
                  fullWidth
                  column="lateFee"
                  placeholder="Late Fee"
                  copyFrom={copyFrom("lateFee")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="lateFeeBasis"
                  placeholder="Late Fee Basis"
                  multiline
                  copyFrom={copyFrom("lateFeeBasis")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="lateFeeTerm"
                  placeholder="Late Fee Term"
                  copyFrom={copyFrom("lateFeeTerm")}
                  copyFromTitle={copyFromTitle}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Attorney's Fee
              </Typography>
              <fieldset className={classes.fieldset}>
                <CurrencyField
                  fullWidth
                  column="attorneysFee"
                  placeholder="Attorney's Fee"
                  copyFrom={copyFrom("attorneysFee")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="attorneysFeeBasis"
                  placeholder="Attorney's Fee Basis"
                  multiline
                  copyFrom={copyFrom("attorneysFeeBasis")}
                  copyFromTitle={copyFromTitle}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Other Damages
              </Typography>
              <fieldset className={classes.fieldset}>
                <CurrencyField
                  fullWidth
                  column="otherDamages"
                  placeholder="Other Damages"
                  copyFrom={copyFrom("otherDamages")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="otherDamagesBasis"
                  placeholder="Other Damages Basis"
                  multiline
                  copyFrom={copyFrom("otherDamagesBasis")}
                  copyFromTitle={copyFromTitle}
                />
                <ValueField
                  fullWidth
                  column="otherDamagesDescription"
                  placeholder="Other Damages Description"
                  multiline
                  copyFrom={copyFrom("otherDamagesDescription")}
                  copyFromTitle={copyFromTitle}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Total
              </Typography>
              <fieldset className={classes.fieldset}>
                <CalculatedTotalDisplay
                  aspects={[
                    aspect("Principal Amount Due", "principalAmountDue"),
                    aspect("Interest", interestForRowOrUndefined),
                    aspect("Late Fee", "lateFee"),
                    aspect("Attorney's Fees", "attorneysFee"),
                    aspect("Other Damages", "otherDamages"),
                  ]}
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Other Relief
              </Typography>
              <fieldset className={classes.fieldset}>
                <ValueField
                  fullWidth
                  column="otherRelief"
                  placeholder="Other Relief"
                  multiline
                  copyFrom={copyFrom("otherRelief")}
                  copyFromTitle={copyFromTitle}
                />
              </fieldset>
            </Paper>
          );
        }}
      </ListField>
    </div>
  );
}

function MotionEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
        Motions
      </Typography>
      <CustomMissingBorder bindKey={MISSING_ACTIVE_MOTION_KEY}>
        <ListField<Motion>
          tableName="motion"
          rowTitle="Motion"
          empty={(count: number) => {
            return {
              kind: "",
              isActive: count === 0 ? 1 : 0,
            };
          }}
          maximumCount={10}
          addDescription="Added new motion"
          removeDescription="Removed motion"
          describeRow={(row: MutableRow<Motion>, index: number) => {
            return (
              <h3 className={classes.subHeader} style={{ marginLeft: "10px" }}>
                <span />
                <span>Motion #{index + 1}</span>
                <ResultContext.Provider value={row}>
                  <RadioBooleanField
                    fullWidth
                    column="isActive"
                    title="Is Active Motion"
                    isToggle
                  />
                </ResultContext.Provider>
              </h3>
            );
          }}
        >
          {(row: MutableRow<Motion>, index: number) => {
            return (
              <div>
                <fieldset className={classes.fieldset}>
                  <AutocompleteField
                    column="kind"
                    fullWidth
                    placeholder="Motion Kind"
                    prefilledOptions={[
                      "Motion to Compel Discovery Responses",
                      "Motion to Compel Deposition",
                      "Motion for Sanctions",
                      "Motion for Summary Judgment",
                      "Petition to Open or Strike Off Judgment",
                      "Motion for Extraordinary Relief",
                    ]}
                  />

                  <ListField<MotionEntity>
                    tableName="motionentity"
                    rowTitle="Motion Individual"
                    empty={{
                      motionId: row.id(),
                    }}
                    maximumCount={6}
                    collapsableItems
                    addDescription={`Added new motion individual for motion #${
                      index + 1
                    }`}
                    removeDescription={`Removed motion individual for motion #${
                      index + 1
                    }`}
                    query={sql`select * from ${"motionentity"} where motionId=${row.id()}`}
                  >
                    {(
                      entityRow: MutableRow<MotionEntity>,
                      entityIndex: number
                    ) => {
                      return (
                        <div>
                          <fieldset className={classes.fieldset}>
                            <EnumField<MotionEntityType>
                              values={MotionEntityTypeDescription}
                              fullWidth
                              column="type"
                              placeholder="Relation to Motion"
                              title={`Motion individual #${
                                entityIndex + 1
                              } type`}
                            />
                            <ValueField
                              column="name"
                              fullWidth
                              title={`Motion individual #${
                                entityIndex + 1
                              } name`}
                              placeholder="Name"
                            />
                          </fieldset>
                        </div>
                      );
                    }}
                  </ListField>

                  <DateField
                    fullWidth
                    column="complaintDate"
                    title="Complaint Date"
                  />
                  <DateField
                    fullWidth
                    column="answerDate"
                    title="Answer Date"
                  />
                  <DateField
                    fullWidth
                    column="discoveryDate"
                    title="Discovery Date"
                  />
                  <DateField
                    fullWidth
                    column="discoveryEndDate"
                    title="Discovery End Date"
                  />
                  <ValueField
                    fullWidth
                    column="discoverySent"
                    title="Discovery Sent"
                  />
                  <DocumentDateField
                    column="orderDate"
                    fullWidth
                    title="Order Date"
                  />
                </fieldset>
              </div>
            );
          }}
        </ListField>
      </CustomMissingBorder>
    </div>
  );
}

function CaptionEditor(props: { case: Case }) {
  const classes = useFlowStyles();

  const { results: parties } = useQuery<Party>(sql`select * from ${"party"}`);
  const { results: litigationparties } = useQuery<LitigationCaseParty>(
    sql`select * from ${"litigationcaseparty"}`
  );

  const caseDescription = (row: MutableRow<LitigationCase>, index: number) => {
    return caseTitle(row, parties!, litigationparties!, `Case #${index + 1}`);
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <Typography className={classes.header} variant="h5">
          <FontAwesomeIcon icon={faFileInvoice} size="lg" />
          Case Captions
        </Typography>
      </div>

      <ListField<LitigationCase>
        collapsableItems
        tableName="litigationcase"
        rowTitle="Case Caption"
        empty={{
          isActive: 0,
        }}
        boundSubTables={{
          litigationcaseparty: "caseId",
        }}
        addDescription="Added new case caption"
        removeDescription="Removed case caption"
        describeRow={(row: MutableRow<LitigationCase>, index: number) => {
          return (
            <h3 className={classes.subHeader} style={{ marginLeft: "10px" }}>
              <span />
              <span>{caseDescription(row, index)}</span>
              <ResultContext.Provider value={row}>
                <RadioBooleanField
                  column="isActive"
                  title={`${caseDescription(row, index)} is active case`}
                  placeholder="Is Active Case"
                  isToggle
                />
              </ResultContext.Provider>
            </h3>
          );
        }}
      >
        {(row: MutableRow<LitigationCase>, index: number) => {
          const caseId = row.id();
          return (
            <fieldset className={classes.fieldset}>
              <Typography className={classes.subHeader} variant="h6">
                Case Details
              </Typography>
              <fieldset className={classes.fieldset}>
                <ValueField
                  fullWidth
                  column="docketNumber"
                  title="Docket Number"
                />
                <StateField fullWidth column="state" title="Court State" />
                <ValueField fullWidth column="county" title="Court County" />
                <ValueField fullWidth column="court" title="Court Name" />
                <ValueField
                  multiline
                  fullWidth
                  column="barAssociation"
                  title="Bar Association"
                />
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Case Parties
              </Typography>
              <fieldset className={classes.fieldset}>
                <ListField<LitigationCaseParty>
                  tableName="litigationcaseparty"
                  rowTitle="Case Party"
                  empty={{
                    caseId: row.id(),
                  }}
                  query={sql`select * from ${"litigationcaseparty"} where caseId=${caseId}`}
                  addDescription={`Added new party to ${caseDescription}`}
                  removeDescription={`Removed party from ${caseDescription}`}
                >
                  {(prow: MutableRow<LitigationCaseParty>, index: number) => {
                    return (
                      <fieldset className={classes.fieldset}>
                        <SelectRowField<Party>
                          fullWidth
                          column="partyId"
                          tableName="party"
                          placeholder="Party"
                          filter={(party: MutableRow<Party>) => {
                            return !!getPartyName(party);
                          }}
                          render={(party: MutableRow<Party>) => {
                            return (
                              <div className={classes.subHeader}>
                                <PartyIcon party={party} small />
                                {getPartyName(party)}
                              </div>
                            );
                          }}
                          describe={(party: MutableRow<Party>) => {
                            return getPartyName(party);
                          }}
                        />
                        <MultiEnumField<PartyKind>
                          values={PartyKindDescription}
                          fullWidth
                          column="partyKind"
                          title={`Kind for party ${
                            index + 1
                          } for ${caseDescription(row, index)}`}
                          placeholder="Kind"
                          sorted
                        />
                        <SelectRowField<ContractTerms>
                          fullWidth
                          column="contractId"
                          tableName="contractterms"
                          placeholder="Contract"
                          render={(contract: MutableRow<ContractTerms>) => {
                            return (
                              <div className={classes.subHeader}>
                                {contract.getField("title")}
                              </div>
                            );
                          }}
                          describe={(contract: MutableRow<ContractTerms>) => {
                            return contract.getField("title") || "";
                          }}
                        />
                      </fieldset>
                    );
                  }}
                </ListField>
              </fieldset>

              <Typography className={classes.subHeader} variant="h6">
                Case Settings
              </Typography>
              <fieldset className={classes.fieldset}>
                <BooleanField
                  fullWidth
                  column="isJuryTrial"
                  title="This is a Jury Trial"
                />
                <BooleanField
                  fullWidth
                  column="showAddressesInCaption"
                  title="Show Addresses in Captions"
                />
                <BooleanField
                  fullWidth
                  column="showOnlyFirstPartyInCaption"
                  title="Show Only First Party in Caption"
                />
              </fieldset>
            </fieldset>
          );
        }}
      </ListField>
    </div>
  );
}

function CasePartyEditor(props: { case: Case }) {
  const classes = useFlowStyles();

  return (
    <div>
      <WithActiveLitigationCase title="Case Party Information">
        {(
          activeCase: MutableRow<LitigationCase>,
          parties: MutableRow<Party>[],
          litigationParties: MutableRow<LitigationCaseParty>[]
        ) => {
          return (
            <ReadOnlyListField<LitigationCaseParty>
              tableName="litigationcaseparty"
              rowTitle="Case Party"
              describeRow={(row: MutableRow<LitigationCaseParty>) => {
                const party = parties.find(
                  (p) => row.getField("partyId") === p.id()
                );
                if (!party) {
                  return undefined;
                }

                const partyKinds = enumSetfromString(
                  PartyKind,
                  row.getField("partyKind")
                );

                return (
                  <div className={classes.subHeader}>
                    <PartyIcon party={party} small />
                    {getPartyName(party)}
                    <div className={classes.chips}>
                      {partyKinds.map((partyKind) => {
                        return <Chip label={PartyKindDescription[partyKind]} />;
                      })}
                    </div>
                  </div>
                );
              }}
              query={sql`select * from ${"litigationcaseparty"} where caseId=${activeCase.id()}`}
              collapsableItems
            >
              {(prow: MutableRow<LitigationCaseParty>, index: number) => {
                const party = parties.find(
                  (p) => prow.getField("partyId") === p.id()
                );
                if (!party) {
                  return (
                    <div>
                      (Please choose a party for this entry in the Case Party
                      tab)
                    </div>
                  );
                }

                const description = getPartyDescription(party, index);

                const copyFromTitle =
                  index > 0 ? "Copy From Previous Party" : undefined;
                const copyFrom = (fieldName: string) => {
                  return index > 0
                    ? (database: DiffableDatabase) => {
                        const results = database.selectAllResults<Party>(
                          sql`select * from ${"party"} where id < ${prow.id()} order by id`
                        );

                        if (results.length === 0) {
                          return undefined;
                        }

                        const previousParty = results[results.length - 1];
                        const lcpResults =
                          database.selectAllResults<LitigationCaseParty>(
                            sql`select * from ${"litigationcaseparty"} where  id < ${prow.id()} and partyId=${previousParty.id()} order by id`
                          );

                        if (lcpResults.length === 0) {
                          return undefined;
                        }

                        return lcpResults[lcpResults.length - 1].getField(
                          fieldName
                        );
                      }
                    : undefined;
                };

                return (
                  <fieldset className={classes.fieldset}>
                    <Typography className={classes.subHeader} variant="h6">
                      Dates
                    </Typography>
                    <fieldset className={classes.fieldset}>
                      <DateField
                        column="initialServiceDate"
                        placeholder="Initial Service Date"
                        fullWidth
                        title={`Initial Service Date for ${description}`}
                        copyFrom={copyFrom("initialServiceDate")}
                        copyFromTitle={copyFromTitle}
                      />
                      <DateField
                        column="initialResponseDueDate"
                        placeholder="Initial Response Due Date"
                        fullWidth
                        title={`Initial Response Due Date for ${description}`}
                        copyFrom={copyFrom("initialResponseDueDate")}
                        copyFromTitle={copyFromTitle}
                      />
                      <DateField
                        column="noticeOfDefaultDate"
                        placeholder="Notice of Default Date"
                        fullWidth
                        title={`Notice of Default Date for ${description}`}
                        copyFrom={copyFrom("noticeOfDefaultDate")}
                        copyFromTitle={copyFromTitle}
                      />
                      <DateField
                        column="judgementDate"
                        placeholder="Judgment Date"
                        fullWidth
                        title={`Judgment Date for ${description}`}
                        copyFrom={copyFrom("judgementDate")}
                        copyFromTitle={copyFromTitle}
                      />
                    </fieldset>

                    <Typography className={classes.subHeader} variant="h6">
                      Amounts and Fees
                    </Typography>
                    <fieldset className={classes.fieldset}>
                      <CurrencyField
                        column="complaintAmount"
                        placeholder="Complaint Amount"
                        fullWidth
                        title={`Complaint Amount for ${description}`}
                        copyFrom={copyFrom("complaintAmount")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="ongoingAmounts"
                        placeholder="Ongoing Amounts"
                        fullWidth
                        title={`Ongoing Amounts for ${description}`}
                        copyFrom={copyFrom("ongoingAmounts")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="additionalAttorneysFees"
                        placeholder="Additional Attorneys Fees"
                        fullWidth
                        title={`Additional Attorneys Fees for ${description}`}
                        copyFrom={copyFrom("additionalAttorneysFees")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CalculatedTotalDisplay
                        aspects={[
                          aspect("Complaint Amount", "complaintAmount"),
                          aspect("Outgoing Amounts", "ongoingAmounts"),
                          aspect(
                            "Additional Attorneys Fees",
                            "additionalAttorneysFees"
                          ),
                        ]}
                      />
                    </fieldset>

                    <Typography className={classes.subHeader} variant="h6">
                      Writ
                    </Typography>
                    <fieldset className={classes.fieldset}>
                      <ValueField
                        column="writSheriff"
                        placeholder="Writ Sheriff"
                        fullWidth
                        title={`Writ Sheriff for ${description}`}
                        copyFrom={copyFrom("writSheriff")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="writPrincipal"
                        placeholder="Writ Principal"
                        fullWidth
                        title={`Writ Principal for ${description}`}
                        copyFrom={copyFrom("writPrincipal")}
                        copyFromTitle={copyFromTitle}
                      />
                      <PercentageField
                        column="writInterestRate"
                        placeholder="Writ Interest Rate"
                        fullWidth
                        title={`Writ Interest Rate for ${description}`}
                        copyFrom={copyFrom("writInterestRate")}
                        copyFromTitle={copyFromTitle}
                      />
                      <DateField
                        column="writInterestEndDate"
                        placeholder="Writ Interest End Date"
                        fullWidth
                        title={`Writ Interest End Date for ${description}`}
                        copyFrom={copyFrom("writInterestEndDate")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="writInterest"
                        placeholder="Writ Interest"
                        fullWidth
                        title={`Writ Interest for ${description}`}
                        copyFrom={copyFrom("writInterest")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="writCosts"
                        placeholder="Writ Costs"
                        fullWidth
                        title={`Writ Costs for ${description}`}
                        copyFrom={copyFrom("writCosts")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CurrencyField
                        column="writFees"
                        placeholder="Writ Fees"
                        fullWidth
                        title={`Writ Fees for ${description}`}
                        copyFrom={copyFrom("writFees")}
                        copyFromTitle={copyFromTitle}
                      />
                      <CalculatedTotalDisplay
                        aspects={[
                          aspect("Principal Amount Due", "writPrincipal"),
                          aspect("Interest", "writInterest"),
                          aspect("Costs", "writCosts"),
                          aspect("Fees", "writFees"),
                        ]}
                      />
                    </fieldset>
                  </fieldset>
                );
              }}
            </ReadOnlyListField>
          );
        }}
      </WithActiveLitigationCase>
    </div>
  );
}

function WithActiveLitigationCase(props: {
  title: string;
  children: (
    activeCase: MutableRow<LitigationCase>,
    parties: MutableRow<Party>[],
    litigationParties: MutableRow<LitigationCaseParty>[]
  ) => React.ReactChild;
}) {
  const classes = useFlowStyles();

  const { results: litigationcases } = useQuery<LitigationCase>(
    sql`select * from ${"litigationcase"}`
  );
  const activeCases = litigationcases?.filter(
    (lc) => lc.getField("isActive") === 1
  );
  const activeCase = activeCases?.length === 1 ? activeCases[0] : undefined;
  const theme = useTheme();

  const { results: parties } = useQuery<Party>(sql`select * from ${"party"}`);
  const { results: litigationParties } = useQuery<LitigationCaseParty>(
    sql`select * from ${"litigationcaseparty"}`
  );

  return (
    <div style={{ padding: theme.spacing(1) }}>
      {!activeCase && (
        <div>
          Please select a litigation case as <strong>active</strong> to fill in
          this information
        </div>
      )}
      {!!activeCase && !!parties && !!litigationParties && (
        <div>
          <Typography className={classes.subHeader} variant="h5" gutterBottom>
            {props.title} for{" "}
            {caseTitle(
              activeCase,
              parties,
              litigationParties.filter(
                (lp) => lp.getField("caseId") === activeCase.id()
              ),
              "Active Litigation Case"
            )}
          </Typography>
          {props.children(activeCase, parties, litigationParties)}
        </div>
      )}
    </div>
  );
}

function AffiantEditor(props: { case: Case }) {
  const classes = useFlowStyles();
  return (
    <div>
      <Typography className={classes.header} variant="h5">
        <FontAwesomeIcon icon={faSignature} size="lg" />
        Affiant Information
      </Typography>

      <RowRenderer<Affiant> query={sql`select * from ${"affiant"} limit 1`}>
        {(row: Affiant) => {
          return (
            <div>
              <Paper className={classes.paper}>
                <fieldset className={classes.fieldset}>
                  <ValueField
                    fullWidth
                    column="fullName"
                    title="Affiant Full Name"
                  />
                  <ValueField
                    fullWidth
                    column="pronoun"
                    title="Affiant Pronoun"
                  />
                  <ValueField fullWidth column="title" title="Affiant Title" />
                </fieldset>
              </Paper>

              <PleadingAttorneyManager />
            </div>
          );
        }}
      </RowRenderer>
    </div>
  );
}
