import React from "react";
import { DiffableDatabase, MutableRow, RowType } from "./interfaces";

/**
 * DatabaseContext defines context which holds a reference to the current database,
 * once it has been loaded.
 */
export const DatabaseContext = React.createContext<
  DiffableDatabase | undefined
>(undefined);
DatabaseContext.displayName = "CurrentDatabaseContext";

/**
 * ResultContext defines context which holds a reference to the current row.
 */
export const ResultContext = React.createContext<
  MutableRow<RowType> | undefined
>(undefined);
ResultContext.displayName = "CurrentRowResultContext";
