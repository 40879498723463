import { useLazyQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import { HasID, QueryInformation } from './Bound';

export type EnterTextFunction = (enteredText: string) => string | React.ReactNode


/**
* Defines the properties for the BoundAutocomplete.
*/
export interface BoundAutocompleteProps<T> {
    /**
     * The query to execute for filling the autocomplete.
     */
    query: QueryInformation

    /**
     * label is the label string to display for this autocomplete.
     */
    label: string

    /**
     * enterText is the text to display when no options are available.
     */
    enterText: string | React.ReactNode | EnterTextFunction

    /**
     * classes are the classes to apply to the autocmplete.
     */
    classes?: Partial<ClassNameMap<string>>;

    /**
     * fullWidth is whether to display the text field in full width.
     */
    fullWidth?: boolean;

    /**
     * inputClasses are the classes to apply to the input.
     */
    inputClasses?: Partial<ClassNameMap<string>>;

    /**
     * textFieldClasses are the classes to apply to the textfield.
     */
    textFieldClasses?: Partial<ClassNameMap<string>>;

    /**
     * showDropdownSelector indicates whether the dropdown selector should be shown.
     */
    showDropdownSelector?: boolean

    /**
     * resultLabel returns the label for the result.
     */
    resultLabel: (result: T) => string;

    /**
     * renderResult renders the result found.
     */
    renderResult: (result: T) => string | React.ComponentType<any> | React.ReactElement;

    /**
     * resultSelected is invoked when a result is selected in the autocomplete.
     */
    resultSelected: (result: T) => any;

    /**
     * startAdornment is the adornment to put at the start of the autocomplete's
     * text field.
     */
    startAdornment?: React.ReactNode;

    /**
     * placeholder is the placeholder text for the autocomplete.
     */
    placeholder?: string;

    /**
     * autoFocus indicates whether the autocomplete should be auto-focused.
     */
    autoFocus?: boolean;
}

/**
 * BoundAutocomplete defines an autocomplete bound to a *paginated* GraphQL query, for displaying
 * data returned.
 * 
 * @type T The type of data found in the query. Must have an `id` field.
 * @param props The properties for the BoundAutocomplete.
 * @example <BoundAutocomplete<User>
              label="Users"
              query={
                {
                    gql: THE_QUERY_NAME,
                    variables: {
                        someVar: 123,
                        anotherVar: 456,
                    },
                    recordsKey: ['path', 'to', 'the', 'resources']
                }
              }
              ...
        />
 */
export default function BoundAutocomplete<T extends HasID>(props: BoundAutocompleteProps<T>) {
    const [open, setOpen] = React.useState(false);
    const [enterText, setEnterText] = React.useState<string | React.ReactNode>(typeof props.enterText === 'function' ? '' : props.enterText);
    const [inputValue, setInputValue] = React.useState('');
    const [loadedInputValue, setLoadedInputValue] = React.useState('');

    const [loadResults, { loading: loadingResults, data }] = useLazyQuery(props.query.gql);

    const getRecords = function (data: any): T[] {
        let currentData = data;
        props.query.recordsKey.forEach(function (keyName) {
            currentData = currentData[keyName];
        });

        if (currentData === undefined) {
            throw Error('Could not lookup records')
        }

        return currentData;
    };

    const fetch = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: () => void) => {
                (async () => {
                    await loadResults({
                        variables: {
                            ...props.query.variables, prefix: request.input
                        }
                    })
                    setLoadedInputValue(request.input);
                })();
            }, 200),
        [props.query.variables, loadResults],
    );

    const options: T[] = (data && getRecords(data)) || [];

    useEffect(() => {
        if (typeof props.enterText === 'function') {
            setEnterText(props.enterText(inputValue));
        }

        if (!inputValue || inputValue === '') {
            return;
        }

        // If the entered prefix is a subset of the existing prefix, and we already had
        // less than full results, then skip loading more.
        if ((loadedInputValue && inputValue.startsWith(loadedInputValue) && options.length < 5) && inputValue.indexOf('@') <= 0) {
            return;
        }

        // Fetch new results if applicable.
        fetch({ input: inputValue }, () => { });
    },
        // NOTE: We do not want this effect reexecuting on loadedInputValue or options changing.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputValue, fetch]
    );

    const loading = loadingResults && open;
    return (
        <Autocomplete
            clearOnBlur
            clearOnEscape
            blurOnSelect
            classes={props.classes}
            style={props.fullWidth ? undefined : { width: 300 }}
            open={open}
            noOptionsText={enterText}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
                setInputValue('');
            }}
            onChange={(event: any, newValue: T | null) => {
                if (newValue !== null) {
                    props.resultSelected(newValue);
                }
            }}
            value={null}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            renderOption={(option) => props.renderResult(option)}
            getOptionLabel={(option) => props.resultLabel(option)}
            options={options}
            loading={loading}
            fullWidth={props.fullWidth}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth={props.fullWidth}
                    label={props.label}
                    variant="outlined"
                    classes={props.textFieldClasses}
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus}
                    InputProps={{
                        ...params.InputProps,
                        classes: props.inputClasses,
                        startAdornment: props.startAdornment,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {props.showDropdownSelector ? params.InputProps.endAdornment : null}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}