import { ApolloClient, ApolloConsumer } from "@apollo/client";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, createStyles, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import ColorHash from "color-hash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DatabaseContext } from "../database/diffable/context";
import { LookupCaseDocumentsData, LookupCaseDocumentsParams, LOOKUP_CASE_DOCUMENTS } from "../queries/documents";
import { Edge } from "../queries/lib/base";
import { ResourceView } from "../queries/lib/components";
import { useManagedQuery } from "../queries/lib/hooks";
import { WithApplicationConfig } from "../services/confighook";
import { ApplicationConfig } from "../services/configservice";
import { useDocumentCreator } from "../services/documentservice";
import { Case, CaseDatabaseRevision } from "../types/case";
import { DocumentTemplate } from "../types/documentemplate";
import { CreatedCaseDocument } from "../types/generateddoc";
import GeneratedDocumentTile from "./documents/GeneratedDocumentTile";
import EmptyView from "./EmptyView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            borderLeft: '0px'
        },
        recent: {
            padding: theme.spacing(2),
        },
        createDocCardPreview: {
            height: 200,
            backgroundPositionY: '0px',
        },
        avatar: {
            color: 'white',
            width: '26px',
            height: '26px',
            marginTop: '2px',
        },
        templateTitle: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: theme.spacing(1),
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: theme.spacing(4),
            '& svg path': {
                color: theme.palette.divider
            },
            '& h2': {
                marginBottom: theme.spacing(1),
            },
        }
    }));

export function CaseHomeView(props: {
    case: Case,
    currentRevision: CaseDatabaseRevision
}) {
    return <ApolloConsumer>
        {client =>
            <WithApplicationConfig>
                {
                    (appConfig: ApplicationConfig) => <CaseHomeViewInternal {...props} appConfig={appConfig} client={client} />
                }
            </WithApplicationConfig>
        }
    </ApolloConsumer>;
}

function CaseHomeViewInternal(props: {
    case: Case,
    appConfig: ApplicationConfig,
    currentRevision: CaseDatabaseRevision,
    client: ApolloClient<object>,
}) {
    const classes = useStyles();

    const state = useManagedQuery<LookupCaseDocumentsData, LookupCaseDocumentsParams>(LOOKUP_CASE_DOCUMENTS, {
        variables: {
            caseId: props.case.id,
            first: 24,
            includePreviousRevision: false,
        }
    });

    const docs = state.data?.caseById?.generatedDocuments?.edges;
    const database = useContext(DatabaseContext)!;
    const { createDocument } = useDocumentCreator(props.case, props.currentRevision, database, props.appConfig, props.client, true);


    return <div>
        {props.case.docTemplates?.length !== 0 && <Paper className={classes.paper} square variant="outlined">
            <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>Create document</Typography>
            <Grid container spacing={3}>
                {props.case.docTemplates?.slice(0, 6).map((docTemplate: DocumentTemplate) => {
                    return <Grid item lg={2} xs={3}>
                        <DocTemplateCard docTemplate={docTemplate} createDocument={createDocument} />
                    </Grid>
                })}
            </Grid>
        </Paper>}

        {!state.loading && props.case.docTemplates?.length === 0 && docs?.length === 0 && <div className={classes.empty}>
            <FontAwesomeIcon icon={faFileWord} size="6x" />
            <h2>No document templates are available for this case</h2>
            <h3>Click "Add Template" below to add a new document template for cases like this</h3>
            <Button component={Link} variant="contained" color="primary" size="large" to={`/c/${props.case.id}/documents?tab=addtemplate`}>
                Add Template
            </Button>
        </div>}

        {!state.loading && props.case.docTemplates !== undefined && props.case.docTemplates.length > 0 && <div className={classes.recent}>
            <Typography variant="subtitle1" style={{ marginBottom: '10px' }}>Recent documents</Typography>
            <ResourceView state={state}>
                {docs !== undefined && docs.length === 0 &&
                    <EmptyView message="No documents have been created" />
                }

                {docs !== undefined && docs.length > 0 &&
                    <Grid container spacing={3}>
                        {docs.map((doc: Edge<CreatedCaseDocument>) => {
                            return <Grid item lg={2} xs={3}>
                                <GeneratedDocumentTile case={props.case} document={doc.node} />
                            </Grid>
                        })}
                    </Grid>
                }
            </ResourceView>
        </div>}
    </div>
}

function DocTemplateCard(props: { docTemplate: DocumentTemplate, createDocument: (docTemplate: DocumentTemplate) => Promise<void> }) {
    const createDocument = () => {
        (async () => {
            await props.createDocument(props.docTemplate);
        })();
    };

    const classes = useStyles();
    const colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    const docKindColor = colorHash.hex(props.docTemplate.title);

    return <Card variant="outlined" style={{ height: '100%' }}>
        <CardActionArea onClick={createDocument}>
            <CardMedia
                className={classes.createDocCardPreview}
                image={props.docTemplate.previewThumbnail}
                style={{ backgroundColor: docKindColor }}
                title="Document Preview"
            />
            <CardContent>
                <Typography gutterBottom className={classes.templateTitle} variant="h6" component="h2">
                    <Avatar variant="square" className={classes.avatar} style={{ backgroundColor: docKindColor }}>
                        <FontAwesomeIcon icon={faFileWord} size="sm" />
                    </Avatar>
                    {props.docTemplate.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {props.docTemplate.description}
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions>
            <Button size="small" color="primary" onClick={createDocument}>
                Create
            </Button>
        </CardActions>
    </Card>;
}