import { Database } from "../database";
import { sql, Unparametrized } from "../sql";
import { MutableRow } from "./interfaces";

/**
 * MutationKind defines the kinds of mutations contained in a MutationTransaction.
 */
export enum MutationKind {
    CHANGE_FIELD = "change-field",
    INSERT_ROW = "insert-row",
    DELETE_ROW = "delete-row"
}

/**
 * Mutation is the base of all mutations in a transaction.
 */
export abstract class Mutation {
    constructor(private mutationIndex: number) { }

    /**
     * kind is the kind of the mutation.
     */
    abstract kind(): MutationKind;

    /**
     * isValid indicates whether the data contained in the mutation is valid.
     * If not, saving will be prevented.
     */
    abstract isValid(): boolean;
}

/**
 * DeleteRowMutation represents the deletion of a row in a table.
 */
export class DeleteRowMutation extends Mutation {
    constructor(mutationIndex: number,
        public tableName: string,
        public rowId: number,
        public description: string) {
        super(mutationIndex)
    }

    kind(): MutationKind {
        return MutationKind.DELETE_ROW;
    }

    isValid(): boolean {
        return true;
    }

    update(db: Database) {
        const query = `delete from ${this.tableName} where id = ${this.rowId}`;
        db.raw(query);
    }
}

/**
 * InsertRowMutation represents the insertion of a row in a table.
 */
export class InsertRowMutation extends Mutation {
    constructor(mutationIndex: number,
        public tableName: string,
        public rowData: Record<string, any>,
        public description: string) {
        super(mutationIndex)
    }

    kind(): MutationKind {
        return MutationKind.INSERT_ROW;
    }

    isValid(): boolean {
        return true;
    }

    update(db: Database): number {
        const query = `insert into ${this.tableName} (
            ${Object.keys(this.rowData).join(', ')}
        ) values (
            ${Object.keys(this.rowData).map((colName: string) => {
            // TODO: escaping!
            const value = this.rowData[colName];
            if (typeof (value) === 'number') {
                return value;
            }

            if (value === null) {
                return 'null';
            }

            return `"${value}"`;
        })}
        )`;

        db.raw(query);
        return db.lastInsertedId()!;
    }
}

/**
 * FieldValue is the updated value for a field via a ChangeFieldMutation.
 */
export interface FieldValue {
    /**
     * value is the new value for the field.
     */
    value: any

    /**
     * isValid indicates whether the new value is valid for the field.
     */
    isValid: boolean

    /**
     * niceValue is the human-readable nice version of the value. If not specified,
     * the value's `.toString()` is used to display.
     */
    niceValue?: string
}

/**
 * FieldMetadata is any metadata to be attached to a changed field.
 */
export interface FieldMetadata {
    /**
     * title is the human-readable title of the field.
     */
    title: string
}


/**
 * ChangeFieldMutation represents the modification of a specific field's value.
 */
export class ChangeFieldMutation extends Mutation {
    public timestamp: number = new Date().getTime();

    constructor(mutationIndex: number,
        private row: MutableRow,
        public fieldName: string,
        public fieldValue: FieldValue,
        public fieldMetadata: FieldMetadata) {
        super(mutationIndex)
    }

    kind(): MutationKind {
        return MutationKind.CHANGE_FIELD;
    }

    isValid(): boolean {
        return this.fieldValue.isValid;
    }

    rowColumnKey() {
        return `${this.row.id}:${this.fieldName}`
    }

    getFieldName() {
        return this.fieldName;
    }

    getFieldTitle() {
        return this.fieldMetadata?.title || this.fieldName;
    }

    actionRow() {
        return this.row
    }

    setFieldValue(fieldValue: FieldValue) {
        this.fieldValue = fieldValue;
        this.timestamp = new Date().getTime();
    }

    isValidValue(): boolean {
        return this.fieldValue.isValid;
    }

    value(): any {
        return this.fieldValue.value
    }

    niceValue(): any {
        return this.fieldValue.niceValue ?? this.fieldValue.value;
    }

    apply(db: Database) {
        const query = sql`update ${this.actionRow().tableName} set ${new Unparametrized(this.fieldName)} = ${this.fieldValue.value} where id=${this.actionRow().id().toString()}`;
        db.run(query);
    }
}
