/*
 * To regenerate the schema:
 * typescript-json-schema model.ts "*" -o schema.json --required --strictNullChecks
 * 
 * https://github.com/YousefED/typescript-json-schema
 */

import { Address as AddressDef } from '../../sharedschema/address';
import { PleadingAttorney as PleadingAttorneyDef } from '../../sharedschema/pleadingattorney';

export enum AddressKind {
    LEASE_BUILDING = 0,
    TENANT = 1,
    TENANT_NOTICE = 2,
    LANDLORD = 3,
    LANDLORD_NOTICE = 4,
    GUARANTOR = 5,
    GUARANTOR_NOTICE = 6,
}

export type PleadingAttorney = PleadingAttorneyDef
export interface Address extends AddressDef {
    kind: AddressKind
}

export interface ServiceAddress extends AddressDef { }

export enum EntityKind {
    TENANT = 0,
    LANDLORD = 1,
    GUARANTOR = 2
}

export enum EntityType {
    UNKNOWN = 0,
    INDIVIDUAL = 1,
    GENERAL_PARTNERSHIP = 2,
    LIMITED_PARTNERSHIP = 3,
    LLC = 4,
    CORP = 5,
    TRUST = 6,
}

export const EntityTypeDescription = {
    [EntityType.UNKNOWN]: "",
    [EntityType.INDIVIDUAL]: "Individual",
    [EntityType.GENERAL_PARTNERSHIP]: "General Partnership",
    [EntityType.LIMITED_PARTNERSHIP]: "Limited Partnership",
    [EntityType.LLC]: "Limited Liability Company",
    [EntityType.CORP]: "Corporation",
    [EntityType.TRUST]: "Trust",
}

export enum LeaseChange {
    UNKNOWN = 0,
    NONE = 1,
    AS_AMENDED = 2,
    AS_ASSIGNED = 3,
    AS_AMENDED_AND_ASSIGNED = 4
}


export const LeaseChangeDescription = {
    [LeaseChange.NONE]: "(none)",
    [LeaseChange.AS_AMENDED]: "as amended",
    [LeaseChange.AS_ASSIGNED]: "as assigned",
    [LeaseChange.AS_AMENDED_AND_ASSIGNED]: "as amended and assigned",
}


export interface Entity {
    id: number
    type: EntityType
    shortName: string
    fullname: string
    kind: EntityKind
    hasGuarantor: number

    addressId: number
    noticeAddressId: number

    formationState: string
    alias: string
}


export interface Lease {
    id: number
    shortName: string
    title: string
    date: string
    changes: LeaseChange
    premisesDescription: string
    premisesShortName: string
    buildingShortName: string
    defaultNoticeDate: string
    rentDue: number | null
    ongoingRent: number | null
    rentDueThrough: string
    rentDescription: string

    attorneysFeesSection: string
    leaseInterestSection: string

    lateFeeSection: string
    lateFeeBasis: string
    lateFeeAmount: number | null

    tenantCurePeriod: string
    defaultNoticeCurePeriod: string

    buildingAddressId: number
}


export enum InterestKind {
    UNKNOWN = 0,
    LEASE = 1,
    GUARANTY = 2
}

export enum InterestBasis {
    UNKNOWN = 0,
    LEGAL_RATE = 1,
    CONTRACT_RATE = 2,
    DEFAULT_RATE = 3
}

export const InterestBasisDescription = {
    [InterestBasis.UNKNOWN]: "",
    [InterestBasis.LEGAL_RATE]: "legal rate",
    [InterestBasis.CONTRACT_RATE]: "contract rate",
    [InterestBasis.DEFAULT_RATE]: "default rate",
}

export interface Interest {
    id: number
    kind: InterestKind
    rate: number | null
    basis: InterestBasis
}

export enum CommissionKind {
    UNKNOWN = 0,
    LEASE = 1,
    GUARANTY = 2,
}

export interface Commission {
    id: number
    kind: CommissionKind
    commissionAmount: number | null
    basis: string
}

export interface Guaranty {
    id: number
    shortName: string
    document: string
    date: string
    rentDue: number | null
    rentDueThrough: string
    isDifferentFromLease: number
}

export interface Court {
    id: number
    state: string
    county: string
    court: string
    barAssociation: string
    docketNumber: string
}

export interface Affiant {
    id: number
    fullName: string
    pronoun: string
    title: string
}

export interface Litigation {
    id: number

    /** DEPRECATED: DO NOT USE */
    caseNumber: string

    tenantServiceDate: string
    tenantDefaultDate: string
    tenantDefaultNoticeDate: string
    tenantJudgementDate: string

    guarantorServiceDate: string
    guarantorDefaultDate: string
    guarantorDefaultNoticeDate: string
    guarantorJudgementDate: string

    complaintAmount: number | null
    ongoingRent: number | null
    additionalAttorneysFees: number | null
    ongoingRentDescription: string

    writPrincipal: number | null
    writInterest: number | null
    writCosts: number | null
    writFees: number | null
}


export enum MotionKind {
    UNKNOWN = 0,
    DISCOVERY = 1,
}

export const MotionKindDescription = {
    [MotionKind.UNKNOWN]: "",
    [MotionKind.DISCOVERY]: "Motion to Compel Discovery Responses",
}

export interface Motion {
    id: number
    kind: MotionKind

    complaintDate: string
    answerDate: string
    discoveryDate: string
    orderDate: string

    discoveryEndDate: string
    discoverySent: string

    isActive: number
}

export interface OverdueNotice {
    id: number
    motionId: number
    date: string
}

export enum MotionEntityType {
    UNKNOWN = 0,
    MOVANT = 1,
    RESPONDENT = 2,
}

export const MotionEntityTypeDescription = {
    [MotionEntityType.UNKNOWN]: "",
    [MotionEntityType.MOVANT]: "Movant",
    [MotionEntityType.RESPONDENT]: "Respondent",
}

export interface MotionEntity {
    id: number
    motionId: number
    type: MotionEntityType
    name: string
}