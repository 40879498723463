import { faCreditCard, faListOl, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, createStyles, makeStyles, Paper, TextField, Theme, Typography } from "@material-ui/core";
import { PaymentMethod } from "@stripe/stripe-js";
import * as EmailValidator from 'email-validator';
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AcceptInvitationParams, AcceptInvitationResponse, ACCEPT_INVITATION, CreateFirmParams, CREATE_FIRM } from "../queries/firm";
import { useManagedMutation } from "../queries/lib/hooks";
import { AuthenticatedUser } from "../queries/user";
import { CasolioColorInput, CasolioImageInput, CasolioPhoneInput } from "./controls/inputs";
import { FIRM_LOGO_MAX_SIZE } from "./firm/common";
import { NoFirmCheckoutForm } from "./firm/PaymentPanel";
import LoadingView from "./LoadingView";
import StepWizard, { StepWizardStep } from "./StepWizard";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noFirmRoot: {
            minWidth: '320px',
            width: '840px',
            margin: '0 auto 0 auto',
            height: '74vh',
            display: 'flex',
            alignItems: 'center',
        },
        field: {
            padding: theme.spacing(1)
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        noFirmPaper: {
            padding: theme.spacing(3),
            width: '100%',
            textAlign: 'center'

        },
        buttonBar: {
            padding: theme.spacing(2),
            textAlign: 'center'
        },
        noFirmWizard: {
            width: '100%'
        },
        stepPaper: {
            padding: theme.spacing(4),
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: `1px solid ${theme.palette.divider}`
        },
        stepButtonBar: {
            borderTop: `1px solid ${theme.palette.divider}`,
            marginTop: theme.spacing(3),
            marginLeft: -1 * theme.spacing(4),
            marginRight: -1 * theme.spacing(4),
            marginBottom: -1 * theme.spacing(4),
            padding: theme.spacing(2),
        }
    }));

const StepPaper = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();
    return <Paper className={classes.stepPaper}>{props.children}</Paper>
};

const CreateFirmForm = (props: { currentUser: AuthenticatedUser }) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [faxNumber, setFaxNumber] = useState('');
    const [color, setColor] = useState('#ccc');
    const [logoData, setLogoData] = useState<string | undefined>(undefined);

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(undefined);
    const [inviteEmails, setInviteEmails] = useState('');

    const changeHandler = (callback: (value: string) => void) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            callback(e.target.value)
        };
    };

    const isStep0Complete = title && address && phoneNumber && faxNumber;

    const handleGoToSettings = () => {
        if (!isStep0Complete) { return; }
        setActiveStep(1);
    };

    const handleAuthorized = (method: PaymentMethod) => {
        setPaymentMethod(method)
    };

    const inviteEmailAddresses = inviteEmails.split(new RegExp('\\n|,|;')).map((f) => f.trim()).filter((f: string) => {
        return !!f;
    });
    const hasInvalidAddresses = inviteEmailAddresses.filter((f) => !EmailValidator.validate(f)).length > 0;

    const [createFirm, { loading: creating }] = useManagedMutation<
        any,
        CreateFirmParams
    >(CREATE_FIRM);

    const handleCreateFirm = () => {
        (async () => {
            createFirm({
                variables: {
                    title: title,
                    address: address,
                    phone: phoneNumber,
                    fax: faxNumber,
                    color: color,
                    logo: logoData,
                    payment: paymentMethod?.id!,
                    invitations: inviteEmailAddresses,
                }
            })
        })();
    };

    return <div>
        {creating && <LoadingView message="Creating Firm" />}
        <StepWizard className={classes.noFirmWizard} activeStep={activeStep} stepComponent={StepPaper}>
            <StepWizardStep icon={<FontAwesomeIcon icon={faListOl} />} title="Basic Firm Information">
                <Typography variant="h4">Enter Firm Information</Typography>
                <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                    To get started creating a firm, please enter the basic information below:
                </Typography>

                <form style={{ marginTop: '10px' }} onSubmit={handleGoToSettings}>
                    <div className={classes.field}>
                        <TextField value={title} onChange={changeHandler(setTitle)} label="Firm Title" fullWidth variant="outlined" required />
                    </div>
                    <div className={classes.field}>
                        <TextField value={address} onChange={changeHandler(setAddress)} label="Firm Address" fullWidth multiline required variant="outlined" />
                    </div>
                    <div className={classes.field}>
                        <CasolioPhoneInput value={phoneNumber} onChange={setPhoneNumber} label="Phone Number" />
                    </div>
                    <div className={classes.field}>
                        <CasolioPhoneInput value={faxNumber} onChange={setFaxNumber} label="Fax Number" />
                    </div>
                    <div className={classes.field}>
                        <CasolioColorInput
                            value={color}
                            onChange={setColor}
                            label="Firm Color"
                        />
                    </div>
                    <div className={classes.field}>
                        <CasolioImageInput bgColor={color} value={logoData} onChange={setLogoData} label="Firm Logo" maxByteSize={FIRM_LOGO_MAX_SIZE} />
                    </div>
                    <div className={classes.stepButtonBar}>
                        <Button type="submit" variant="contained" color="primary" disabled={!isStep0Complete}>Next</Button>
                    </div>
                </form>
            </StepWizardStep>
            <StepWizardStep icon={<FontAwesomeIcon icon={faCreditCard} />} title="Billing">
                <Typography variant="h4">Setup Billing</Typography>
                <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                    Your firm will be billed monthly for each member of the firm. Please enter your payment information below:
                </Typography>
                <NoFirmCheckoutForm emailAddress={props.currentUser.email} phoneNumber={phoneNumber} authorized={handleAuthorized} />
                <div className={classes.stepButtonBar}>
                    <Button variant="contained" color="primary" disabled={paymentMethod === undefined} onClick={() => setActiveStep(2)}>Next</Button>
                </div>
            </StepWizardStep>
            <StepWizardStep icon={<FontAwesomeIcon icon={faUsers} />} title="Invite Members">
                <Typography variant="h4">Invite Members to Firm</Typography>
                <Typography variant="subtitle1" style={{ marginTop: '20px' }}>
                    Enter zero or more e-mail addresses of other people you'd like to invite to your firm:
                    <TextField multiline fullWidth value={inviteEmails} onChange={changeHandler(setInviteEmails)} disabled={creating} />
                </Typography>
                <div className={classes.stepButtonBar}>
                    <Button variant="contained" color="secondary" size="large" disabled={hasInvalidAddresses || creating} onClick={handleCreateFirm}>Create Firm</Button>
                </div>
            </StepWizardStep>
        </StepWizard>
    </div>;
};

export function NoFirmLanding(props: { currentUser: AuthenticatedUser }) {
    const classes = useStyles();

    const location = useLocation();
    const history = useHistory();
    const parsed = new URLSearchParams(location.search);
    const acceptToken = parsed.get('accept');
    const [showingCreate, setShowingCreate] = useState(false);

    const [acceptInvitation, { loading: accepting, called }] = useManagedMutation<
        AcceptInvitationResponse,
        AcceptInvitationParams
    >(ACCEPT_INVITATION);

    useEffect(() => {
        if (acceptToken && !accepting && !called) {
            (async () => {
                const result = await acceptInvitation({
                    variables: {
                        code: acceptToken,
                    }
                });
                if (result?.acceptInvitation.status) {
                    history.push('/');
                }
            })();
        }
    }, [acceptToken, accepting, called, acceptInvitation, history]);

    return <div>
        {accepting && <div>
            <LoadingView message="Accepting invitation to firm" />
        </div>}
        {!acceptToken && !showingCreate && <div>
            <div className={classes.noFirmRoot}>
                <Paper className={classes.noFirmPaper}>
                    <Typography variant="h3">Welcome to Casolio</Typography>
                    <Typography variant="subtitle1" style={{ marginTop: '10px' }}>
                        To get started with Casolio, you must create a new firm or be invited to an existing firm
                    </Typography>
                    <div className={classes.buttonBar}>
                        <Button variant="contained" color="primary" size="large" onClick={() => setShowingCreate(true)}>Create New Firm</Button>
                    </div>
                </Paper>
            </div>
        </div>}
        {showingCreate && <div>
            <div className={classes.noFirmRoot}>
                <CreateFirmForm currentUser={props.currentUser} />
            </div>
        </div>}
    </div>
}