import { BitwiseSet } from "./bitwiseset";

// From: https://github.com/microsoft/TypeScript/issues/4753#issuecomment-559911668
export function enumEntries<T extends {}>(
  t: T
): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
  const entries = Object.entries(t);
  const plainStringEnum = entries.every(
    ([key, value]) => typeof value === "string"
  );
  return (
    plainStringEnum
      ? entries
      : entries.filter(([k, v]) => typeof v !== "string")
  ) as any;
}

export function enumKeys<T extends {}>(t: T): ReadonlyArray<keyof T> {
  return enumEntries(t).map(([key]) => key);
}

export function enumValues<T extends {}>(t: T): ReadonlyArray<T[keyof T]> {
  const values = Object.values(t);
  const plainStringEnum = values.every((x) => typeof x === "string");
  const constructed = plainStringEnum
    ? values
    : values.filter((x) => typeof x !== "string");
  return constructed as ReadonlyArray<T[keyof T]>;
}

export function enumSetfromString<T extends {}>(
  t: T,
  encodedNumericValue: string | undefined
): ReadonlyArray<T[keyof T]> {
  const bitwiseSet = BitwiseSet.fromString(encodedNumericValue || "");
  return enumValues<T>(t).filter((v) => bitwiseSet.has(v as number));
}
