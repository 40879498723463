import JSZip from "jszip";
import { runNodeSwitch } from "./nodeformatting";

const CONTENT_TYPES_FILENAME = `[Content_Types].xml`;

const DOC_PART_CONTENT_TYPES = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.header+xml',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.footer+xml',
]

const CONTENT_TYPE_SETTINGS = 'application/vnd.openxmlformats-officedocument.wordprocessingml.settings+xml'

/**
 * parseDocx parses a .docx file in the data buffer and returns the ParsedDocx instance
 * or undefined if it could not be properly parsed.
 */
export async function parseDocx(data: ArrayBuffer): Promise<ParsedDocx | undefined> {
    const zip = new JSZip();

    try {
        const loaded = await zip.loadAsync(data);
        if (!(CONTENT_TYPES_FILENAME in loaded.files)) {
            return undefined
        }
    } catch (ex) {
        return undefined;
    }

    return new ParsedDocx(zip)
}

// Reference: http://officeopenxml.com/WPfields.php
interface FieldNode {
    parentPart: DocPart
    elements: Element[]
    fieldName: string
    isSimpleField: boolean
    switch?: FieldSwitch
}

// Reference: http://officeopenxml.com/WPfieldInstructions.php
export interface FieldSwitch {
    control: string
    format: string
}

interface DocPart {
    path: string
    file: JSZip.JSZipObject
    parsed: Document
}

class DocInfo {
    constructor(public parts: DocPart[], public settings: DocPart | undefined) {

    }

    public allParts(): DocPart[] {
        if (!this.settings) {
            return this.parts;
        }

        return [this.settings, ...this.parts];
    }
}

const MAILMERGE_PREFIX = 'MERGEFIELD';
const WORD_NS = 'http://schemas.openxmlformats.org/wordprocessingml/2006/main';

const nsHandler: XPathNSResolver = function (prefix: string | null) {
    if (prefix === 'w') {
        return WORD_NS;
    }

    return null;
};

/**
 * ParsedDocx represents a parsed .docx file.
 */
class ParsedDocx {
    constructor(private zip: JSZip) { }

    /**
     * fieldNames returns the set of mailmerge fields defined in the document.
     */
    public async fieldNames(): Promise<Set<string>> {
        const docInfo = await this.lookupDocParts();
        const nodes = await this.fieldNodes(docInfo.parts);
        return new Set(nodes.map((node: FieldNode) => node.fieldName.substr(`${MAILMERGE_PREFIX} `.length).trim()).filter((n) => !!n))
    }

    /**
     * fillTemplate fills the mailmerge fields in the document with the given values,
     * returning the byte data for the updated document.
     */
    public async fillTemplate(values: Record<string, any>): Promise<ArrayBuffer> {
        const docInfo = await this.lookupDocParts();
        const nodes = await this.fieldNodes(docInfo.parts);

        // Replace each field.
        nodes.forEach((node: FieldNode) => {
            const fieldName = node.fieldName.substring(`${MAILMERGE_PREFIX} `.length);
            if (fieldName in values) {
                // Create a new run element with the text to be merged.
                const runElement = node.parentPart.parsed.createElementNS(WORD_NS, 'r');
                let formatted = values[fieldName]?.toString() ?? '';
                if (node.switch) {
                    formatted = runNodeSwitch(formatted, node.switch)
                }

                const lines = formatted.split('\n');
                lines.forEach((line: string, index: number) => {
                    if (index > 0) {
                        runElement.appendChild(node.parentPart.parsed.createElementNS(WORD_NS, 'br'));
                    }

                    const textElement = node.parentPart.parsed.createElementNS(WORD_NS, 't');
                    textElement.appendChild(node.parentPart.parsed.createTextNode(line));

                    runElement.appendChild(textElement);
                });

                if (node.isSimpleField) {
                    if (node.elements.length !== 1) {
                        throw Error('Simple field should only have a single element')
                    }
                    node.elements[0].replaceWith(runElement);
                } else {
                    // Find the element with the instrText (if any) and replace the instrText with the new text.
                    // All other elements will be removed. This ensures formatting is maintained.
                    let replaced = false;
                    for (var i = node.elements.length - 1; i >= 0; --i) {
                        if (!replaced) {
                            const iterator = node.parentPart.parsed.evaluate('./w:instrText', node.elements[i], nsHandler, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
                            const current = iterator.iterateNext();
                            if (current !== null) {
                                (current as Element).replaceWith(runElement);
                                replaced = true;
                                continue;
                            }
                        }

                        node.elements[i].remove();
                    }

                }
            }
        });

        // Find and remove any mailmerge settings.
        if (docInfo.settings) {
            Array.from(docInfo.settings.parsed.getElementsByTagName('w:mailMerge')).forEach((elem: Element) => {
                elem.remove();
            })
        }

        // Serialize back to XML.
        const serializer = new XMLSerializer();
        docInfo.allParts().forEach((docPart: DocPart) => {
            const serialized = serializer.serializeToString(docPart.parsed);
            this.zip.file(docPart.path, serialized);
        });

        return await this.zip.generateAsync({ type: "arraybuffer" })
    }

    private async lookupDocParts(): Promise<DocInfo> {
        const parser = new DOMParser();

        // Load the content types file.
        const contentTypes = await this.zip.files[CONTENT_TYPES_FILENAME].async("string");
        const dom = parser.parseFromString(contentTypes, "application/xml");
        const docPartNames = Array.from(dom.getElementsByTagName('Override')).map((tag) => {
            if (DOC_PART_CONTENT_TYPES.indexOf(tag.getAttribute('ContentType') ?? '') >= 0) {
                return tag.getAttribute('PartName') ?? '';
            }
            return undefined;
        }).filter((partName: string | undefined) => !!partName) as string[];

        const settingsPartNames = Array.from(dom.getElementsByTagName('Override')).map((tag) => {
            if (tag.getAttribute('ContentType') === CONTENT_TYPE_SETTINGS) {
                return tag.getAttribute('PartName') ?? '';
            }
            return undefined;
        }).filter((partName: string | undefined) => !!partName) as string[];

        const docParts: DocPart[] = [];
        for (const docPartName of docPartNames) {
            // Strip the `/` from the beginning of the name.
            const path = docPartName.substr(1);
            const file = this.zip.files[path];
            const contents = await file.async("string");
            const parsed = parser.parseFromString(contents, "application/xml");
            docParts.push({
                path: path,
                file: file,
                parsed: parsed,
            });
        }

        let settingsPart: DocPart | undefined = undefined;
        if (settingsPartNames.length === 1) {
            const path = settingsPartNames[0].substr(1);
            const file = this.zip.files[path];
            const contents = await file.async("string");
            const parsed = parser.parseFromString(contents, "application/xml");

            settingsPart = {
                path: path,
                file: file,
                parsed: parsed,
            };
        }

        return new DocInfo(docParts, settingsPart);
    }

    private async fieldNodes(docParts: DocPart[]): Promise<FieldNode[]> {
        // For each valid doc part, load and parse.
        const fieldNodes: FieldNode[] = [];
        for (const docPart of docParts) {
            const parsed = docPart.parsed;

            // Add simple fields.
            Array.from(parsed.getElementsByTagName('w:fldSimple')).forEach((elem: Element) => {
                const fieldName = elem.getAttribute('w:instr') ?? '';
                if (fieldName) {
                    fieldNodes.push({
                        parentPart: docPart,
                        elements: [elem],
                        fieldName: fieldName.trim(),
                        isSimpleField: true,
                    })
                }
            });

            // Add complex fields.
            const iterator = parsed.evaluate('//w:fldChar', parsed, nsHandler, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
            while (true) {
                const current = iterator.iterateNext();
                if (current === null) { break }

                const fldCharElement = (current as Element);
                if (fldCharElement.getAttribute('w:fldCharType') !== 'begin') {
                    continue;
                }

                const containerElement = fldCharElement.parentElement?.parentElement;
                if (!containerElement) { continue }

                const children = Array.from(containerElement.children);

                // Find the index of the begin element.
                const startIndex = children.indexOf(fldCharElement.parentElement!);

                // Find the index of the end element.
                const endIndex = children.findIndex((child: Element, index: number) => {
                    if (index <= startIndex) {
                        return false;
                    }

                    let foundEnd = false;
                    child.childNodes.forEach((child: Node) => {
                        if (child.nodeType === child.ELEMENT_NODE && child.nodeName === 'w:fldChar') {
                            foundEnd = (child as Element).getAttribute('w:fldCharType') === 'end';
                        }
                    });
                    return foundEnd;
                });

                if (endIndex < 0) {
                    continue;
                }

                // Collect the instrText for the elements.
                const elements = children.slice(startIndex, endIndex + 1);
                const instrText = elements.map((element: Element) => {
                    const textIterator = parsed.evaluate('./w:instrText', element, nsHandler, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
                    let instrText = '';
                    while (true) {
                        const currentText = textIterator.iterateNext();
                        if (currentText === null) { break }

                        instrText += currentText.textContent;
                    }
                    return instrText;
                }).join('').trim();

                if (instrText) {
                    const fieldParts = instrText.split(' ').filter((p) => !!p);
                    if (fieldParts.length >= 2) {
                        const fswitch = fieldParts.length === 4 && fieldParts[2].startsWith('\\') ? {
                            control: fieldParts[2],
                            format: fieldParts[3],
                        } : undefined;
                        fieldNodes.push({
                            parentPart: docPart,
                            elements: elements,
                            fieldName: fieldParts.slice(0, 2).join(' '), // Strip off any switches.
                            switch: fswitch,
                            isSimpleField: false,
                        })
                    }
                }
            }
        }

        return fieldNodes.filter((node: FieldNode) => node.fieldName.startsWith(`${MAILMERGE_PREFIX} `));
    }
}
