import { useEffect, useRef, useState } from "react";
import * as initSqlJs from "sql.js";
import { Database } from "./database";

/**
 * State represents the state kept in the useDatabase hook.
 */
export interface State {
    downloading?: "unknown" | number | undefined
    loading: boolean
    database: Database | undefined
    error?: string | undefined
    saving?: number | undefined
}

export interface DatabaseOptions {
    /**
     * databaseURL, if specified, is the URL from which to download the database.
     * If unspecified, a new empty database is created.
     */
    databaseURL?: string

    /**
     * updateURL, if specified, is the URL to which updates to the database will
     * be uploaded.
     */
    updateURL?: string

    /**
     * getAuthorizationToken, if specified, indicates the async method to invoke to
     * return the Bearer token for database load and save operations.
     */
    getAuthorizationToken?: () => Promise<string | null>

    /**
     * updateComplete is a callback invoked once the update/save of the database has
     * completed.
     */
    updateComplete?: (success: boolean, data: any) => any
}

/**
 * useDatabase is a React hook which loads a SQLite database in-memory and provides
 * a nice accessor around it.
 * @example const { loading, database } = useDatabase();
 *          <DatabaseContext.Provider value={database}>...</DatabaseContext>
 */
export function useDatabase(options?: DatabaseOptions) {
    const [state, setState] = useState<State>({
        downloading: options?.databaseURL !== undefined ? 0 : undefined,
        loading: true,
        database: undefined,
    })

    const db = useRef<Database | undefined>(undefined);

    useEffect(() => {
        (async () => {
            // Initialize the SQL.js library.
            const SQL = await initSqlJs({
                // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
                // You can omit locateFile completely when running in node
                locateFile: (file: string) => {
                    return `/wasm/${file}`;
                }
            });

            let bearerToken = null;
            if (options?.getAuthorizationToken !== undefined) {
                bearerToken = await options.getAuthorizationToken();
            }

            // If there is a download URL, download the contents.
            if (options?.databaseURL !== undefined) {
                const headers = new Headers();
                headers.append('pragma', 'no-cache');
                headers.append('cache-control', 'no-cache');

                if (bearerToken) {
                    headers.append('authorization', `Bearer ${bearerToken}`);
                }

                const databaseUrl = `${options?.databaseURL}?_rand=${Math.round(Math.random()*10000)}`;
                let response = null;
                try {
                    response = await fetch(databaseUrl, {
                        headers: headers
                    });
                } catch (e) {
                    setState({
                        downloading: undefined,
                        loading: false,
                        database: undefined,
                        error: 'Could not fetch database'
                    });
                    return;
                }

                const contentLength = parseInt(response.headers.get("content-length") || '0');
                const reader = response.body?.getReader();
                if (response.status !== 200 || !reader) {
                    setState({
                        downloading: undefined,
                        loading: false,
                        database: undefined,
                        error: 'Could not download database'
                    });
                    return
                }

                setState({
                    downloading: contentLength ? 0 : "unknown",
                    loading: false,
                    database: undefined,
                });

                let { value: chunk, done: readerDone } = await reader.read();
                let data = new Uint8Array();
                for (; ;) {
                    if (readerDone) {
                        break;
                    }

                    let newData = new Uint8Array(chunk!.length + data.length)
                    newData.set(data);
                    newData.set(chunk!, data.length);
                    data = newData;

                    setState({
                        downloading: contentLength ? (data.length / contentLength) : "unknown",
                        loading: false,
                        database: undefined,
                    });

                    ({ value: chunk, done: readerDone } = await reader.read());
                }

                // Load the database.
                const sqldb = new SQL.Database(data);
                try {
                    sqldb.exec('select name from sqlite_master');
                } catch (e) {
                    setState({
                        downloading: undefined,
                        loading: false,
                        database: undefined,
                        error: 'Database is corrupted '
                    });
                    return;
                }

                sqldb.exec('PRAGMA foreign_keys = ON;')

                db.current = new Database(sqldb, setState, options);
            } else {
                // Load the database.
                db.current = new Database(new SQL.Database(), setState, options);
            }

            setState({
                downloading: undefined,
                loading: false,
                database: db.current,
            });
        })();

        return () => {
            if (db.current !== undefined) {
                db.current.close();
            }
        };

        // NOTE: We purposefully leave the database URL empty here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (db.current !== undefined) {
        db.current.options = options;
    }

    return state;
}