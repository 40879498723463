import { DatabaseMigration } from '../../database/schema';
import { CaseTemplate } from '../interfaces';
import * as dataAccess from './dataaccess.json';
import { constructDocumentData } from './documentdata';
import { initialData } from './initialdata';
import * as migrations from './migrations.json';
import * as schema from './schema.json';
import { WillPanels } from "./ui";

/**
 * Defines a Will flow.
 */
export const WillTemplateDefinition: CaseTemplate = {
    id: 'will',
    title: 'Estate Planning',
    schema: {
        $schema: schema.$schema,
        databaseSchemaRevision: schema.databaseSchemaRevision,
        definitions: schema.definitions,
        initialData: initialData,
        migrations: migrations['migrations'] as DatabaseMigration[],
    },
    description: 'Standard Estate Planning and Will creation',
    panels: WillPanels,
    documentDataConstructor: constructDocumentData,
    dataAccess: dataAccess['dai'],
};