import { faCogs, faCreditCard, faRulerCombined, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, createStyles, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import clsx from 'clsx';
import React, { PropsWithChildren } from "react";
import { Firm } from "../../types/firm";
import useHistoricalTabs from "../historicaltabs";
import TabLabel from "../TabLabel";
import FirmMemberEditor from "./FirmMemberEditor";
import { FirmSettingsPanel } from "./FirmSettingsPanel";
import { FlowManagementPanel } from "./FlowManagementPanel";
import { PaymentPanel } from "./PaymentPanel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@global": {
            '.cl-themed-card': {
                backgroundColor: `${theme.palette.background.paper} !important`,
            },
            '.cl-list-item:hover': {
                backgroundColor: `${theme.palette.background.default} !important`,
            },
        },
        root: {
        },
        clerkView: {
            '& input': {
                color: 'black'
            },
        },
        licenses: {
        }
    }));

function Panel(props: PropsWithChildren<{ title: string, subtitle: string }>) {
    const classes = useStyles();
    return <div className="cl-component cl-user-profile">
        <div className={clsx('cl-main', classes.licenses)}>
            <div className="cl-page-heading">
                <div className="cl-text-container">
                    <h2 className="cl-title" style={{ margin: '0' }}>{props.title}</h2>
                    <p className="cl-subtitle">{props.subtitle}</p>
                </div>
            </div>
            {props.children}
        </div>
    </div>;
}

export function FirmManagementPanel(props: { firm: Firm }) {
    const { currentTabName, handleChangeTab } = useHistoricalTabs(['details', 'members', 'casetypes', 'billing']);
    const classes = useStyles();
    return <div className={classes.root}>
        <AppBar position="static" color="default">
            <Tabs value={currentTabName} onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary">
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faCogs} size="lg" />} title="Firm Details" />} value="details" />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faUsers} size="lg" />} title="Firm Members" />} value="members" />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faRulerCombined} size="lg" />} title="Case Types" />} value="casetypes" />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faCreditCard} size="lg" />} title="Billing" />} value="billing" />
            </Tabs>
        </AppBar>

        {currentTabName === 'details' && <div>
            <Panel title="Firm settings and details" subtitle="Configure the name, logo and other details for the firm" />
            <div style={{
                minWidth: '320px',
                width: '840px',
                margin: '0 auto 0 auto'
            }}>
                <FirmSettingsPanel firm={props.firm} />
            </div>
        </div>}

        {currentTabName === 'members' && <div>
            <Panel title="Firm Members" subtitle="Add and Remove members of the firm" />
            <div style={{
                minWidth: '320px',
                width: '840px',
                margin: '0 auto 0 auto'
            }}>
                <FirmMemberEditor firm={props.firm} />
            </div>
        </div>}

        {currentTabName === 'casetypes' && <div>
            <Panel title="Configure Case Types" subtitle="Configure the types of cases that can be created">
                <FlowManagementPanel firm={props.firm} />
            </Panel>
        </div>}

        {currentTabName === 'billing' && <div>
            <Panel title="Billing" subtitle="Billing settings for the firm">
                <PaymentPanel firm={props.firm} />
            </Panel>
        </div>}
    </div>
}