import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useRef, useState } from "react";
import { ResultContext } from "../../database/diffable/context";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { CurrentAutoFocusManager, useScrollIntoView } from "./autofocus";
import { ParentListFieldContext } from "./ListField";
import {
  CopyableFieldProps,
  CopyButtonForProps,
  fieldTitle,
  WithTitleOrPlaceholder,
} from "./valuefield";

export type DateFieldProps = WithTitleOrPlaceholder<{
  column: string;
  description?: string;
  fullWidth?: boolean;
}> &
  CopyableFieldProps;

interface styleProps {
  missingColor: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    edited: {
      "& > div > fieldset": {
        borderColor: theme.palette.warning.main,
      },
    },
    normal: {
      "& > div > fieldset": {
        borderColor: (props: styleProps) =>
          props.missingColor ? props.missingColor : "",
        borderWidth: (props: styleProps) =>
          props.missingColor ? 2 : undefined,
      },
    },
  })
);

export function DateField(props: DateFieldProps) {
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const { tracker } = useDocumentTracker();

  const [uniqueId] = useState(_uniqueId("field-"));
  const [localIndex, setLocalIndex] = useState(0);

  const handleDateChange = (date: Date | null) => {
    const value = date ? date.toDateString() : "";
    setValue(value);
  };

  const setValue = (value: string) => {
    row.setField(
      props.column,
      { value: value, isValid: value !== "Invalid Date" },
      {
        title: fieldTitle(props, parentListField),
      }
    );
    setLocalIndex(localIndex + 1);
  };

  const value = row.getField(props.column);
  const selectedDate = (value ? new Date(value) : null) || null;

  const { missingColor, hasMissingValue } = tracker.missingForValue(
    row,
    props.column,
    () => value === ""
  );
  const classes = useStyles({
    missingColor: missingColor,
  });

  const autoFocusManager = useContext(CurrentAutoFocusManager);
  const shouldAutoFocus = autoFocusManager.shouldAutoFocus(
    uniqueId,
    row.hasInvalidValue(props.column),
    hasMissingValue
  );

  const inputRef = useRef<HTMLDivElement>(null);
  useScrollIntoView(inputRef, shouldAutoFocus);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={
          row.hasMutatedValue(props.column) ? classes.edited : classes.normal
        }
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        margin="none"
        id={uniqueId}
        autoFocus={shouldAutoFocus}
        label={props.placeholder ?? props.title}
        value={selectedDate}
        onChange={handleDateChange}
        fullWidth={props.fullWidth}
        InputAdornmentProps={{ position: "start" }}
        inputRef={inputRef}
        InputProps={{
          endAdornment: <CopyButtonForProps {...props} setValue={setValue} />,
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
