import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React, { PropsWithChildren } from "react";
import LoadingView from "../../components/LoadingView";
import { ManagedQueryState } from "./hooks";

/**
 * ResourceViewProps is the props for the ResourceView.
 */
export interface ResourceViewProps<TData> {
    /**
     * state is the state returned from useManagedQuery.
     */
    state: ManagedQueryState<TData>
}

/**
 * ResourceView displays common resouce UI, such as a loading when loading, an error
 * display if the query errors, and the contents of the view otherwise.
 * @example
 *    const state = useManagedQuery<SomeData>(SOME_QUERY);
 *    <ResourceView state={myQueryState}>content to display on success here</ResourceView>
 */
export function ResourceView<TData>(props: PropsWithChildren<ResourceViewProps<TData>>) {
    if (!props.state.isMounted) {
        return <span />;
    }

    return <>
        {props.state.apolloError &&
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={true}
            >
                <Alert severity="error">
                    {props.state.query.errorMessage}
                </Alert>
            </Snackbar>
        }

        {props.state.loading &&
            <LoadingView message={props.state.query.workingMessage} />
        }

        {props.children}
    </>;
}