import { createStyles, makeStyles, Theme } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { MutableRow, RowType } from "../../database/diffable/interfaces";
import { useQuery } from "../../database/diffable/query";
import { sql } from "../../database/sql";
import { ParentListFieldContext } from "./ListField";
import { fieldTitle, WithTitleOrPlaceholder } from "./valuefield";

export type SelectRowFieldProps<T extends RowType> = WithTitleOrPlaceholder<{
  column: string;
  tableName: string;
  render: (row: MutableRow<T>) => React.ReactNode;
  describe: (row: MutableRow<T>) => string;
  filter?: (row: MutableRow<T>) => boolean;
  fullWidth?: boolean;
}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    edited: {
      "& fieldset": {
        borderColor: theme.palette.warning.main,
      },
    },
    normal: {},
  })
);

/**
 * SelectRowField is a field that allows selection of a row in another table,
 * and stores the ID of that row in the bound column.
 */
export function SelectRowField<T extends RowType>(
  props: SelectRowFieldProps<T>
) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const classes = useStyles();
  const { loading, results } = useQuery<T>(
    sql`select * from ${props.tableName}`
  );

  const [uniqueId] = useState(_uniqueId("field-"));
  const [localIndex, setLocalIndex] = useState(0);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedRowId = event.target.value as number;
    const selectedRow = results?.find(
      (row: MutableRow<T>) => row.id() === selectedRowId
    )!;

    const niceValue = props.describe(selectedRow);
    row.setField(
      props.column,
      { value: selectedRowId, isValid: true, niceValue: niceValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );
    setLocalIndex(localIndex + 1);
  };

  let currentValue = (row.getField(props.column) || "").toString();
  if (currentValue === "0") {
    currentValue = "";
  }

  const filter = props.filter ?? (() => true);
  const filteredResults = results?.filter(filter);
  const label = filteredResults?.length
    ? props.placeholder || props.title || ""
    : `(None to be selected)`;
  return (
    <FormControl
      variant="outlined"
      fullWidth={props.fullWidth}
      classes={{
        root: row.hasMutatedValue(props.column)
          ? classes.edited
          : classes.normal,
      }}
    >
      <InputLabel id={`${uniqueId}-label`}>{label}</InputLabel>
      <Select
        labelId={`${uniqueId}-label`}
        label={label}
        variant="outlined"
        id={uniqueId}
        value={currentValue}
        onChange={handleChange}
        inputProps={{
          readOnly: database.applying || loading,
        }}
        disabled={!filteredResults?.length}
      >
        {filteredResults?.map((row: MutableRow<T>, index: number) => {
          return (
            <MenuItem key={row.id()} value={row.id()}>
              {props.render(row)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
