import { MutableRow, MutationTransaction, RowType } from "./interfaces";
import { FieldMetadata, FieldValue } from "./mutations";
import { rowKey } from "./mutationtransaction";

/**
 * MutableRowImpl represents a dynamic and mutable view over a row in the database.
 */
export class MutableRowImpl<TRow extends RowType = RowType> implements MutableRow<TRow> {
    constructor(public readonly tableName: string,
        public readonly row: TRow,
        private transaction: MutationTransaction) { }

    /**
     * id is the ID of the row in the table, representing the `id` column.
     */
    public id(): number {
        return this.row.id
    }

    /**
     * key is the row key for this mutable row.
     */
    public key(): string {
        return rowKey(this.tableName, this.row.id)
    }

    /**
     * hasMutatedValue returns true if the field with the given name has a mutated
     * value under the current mutation transaction.
     */
    public hasMutatedValue(fieldName: string): boolean {
        return this.transaction.hasMutatedValue(this, fieldName) && this.transaction.getMutatedValue(this, fieldName) !== this.row[fieldName]
    }

    /**
     * hasInvalidValue returns true if the field with the given name currently has an invalid
     * value under the current mutation transaction.
     */
    public hasInvalidValue(fieldName: string): boolean {
        const isValidValue = this.transaction.getMutatedValueEntry(this, fieldName)?.isValidValue();
        if (isValidValue === undefined) { return false; }
        return !isValidValue;
    }

    /**
     * setFields set a new value for the current row's field.
     * @param fieldName The name of the field whose value to update.
     * @param fieldValue The new value for the field.
     * @param fieldMetadata Metadata for the new value and the field.
     */
    public setField(fieldName: string, fieldValue: FieldValue, fieldMetadata: FieldMetadata) {
        this.transaction.addChange(this, fieldName, fieldValue, fieldMetadata)
    }

    /**
     * isInsertedRow returns true if the mutable row is inserted in the current transaction.
     */
    public isInsertedRow(): boolean {
        return this.transaction.isInsertedRow(this.tableName, this.row.id);
    }

    /**
     * getField returns the value for the field with the given name on this row, or undefined
     * if none.
     */
    public getField<T = any>(fieldName: string): T | undefined {
        // First check for a mutated value and return that.
        if (this.hasMutatedValue(fieldName)) {
            return this.transaction.getMutatedValue(this, fieldName)
        }

        if (!(fieldName in this.row)) {
            return undefined;
        }

        return this.row[fieldName] as T;
    }
}

