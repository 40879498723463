import React from "react";
import { Link } from "react-router-dom";

export function Logo(props: { className?: string }) {
    return <Link to="/" className={props.className} style={{
        fontFamily: "Cinzel",
        fontWeight: 'bold',
        fontSize: '185%',
        WebkitFontSmoothing: 'auto',
        textDecoration: 'none'
    }}>CASOLIO</Link>
}