import { ApolloClient } from "@apollo/client";
import { ApolloConsumer } from "@apollo/client/react";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CardElement, Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod, Stripe, StripeCardElementChangeEvent, StripeElements } from '@stripe/stripe-js';
import React, { useState } from "react";
import PaymentIcon from 'react-payment-icons';
import { BillingInfo, LOOKUP_FIRM_BILLING_INFO, LOOKUP_FIRM_BILLING_INFO_WITH_PORTAL, UpdatePaymentMethodData, UPDATE_PAYMENT_METHOD } from "../../queries/firm";
import { ResourceView } from "../../queries/lib/components";
import { useManagedMutation, useManagedQuery } from "../../queries/lib/hooks";
import AppConfig, { ApplicationConfig } from "../../services/configservice";
import { Firm } from "../../types/firm";
import { useAlert } from "../AlertProvider";


const stripePromise = AppConfig().then((config: ApplicationConfig) => loadStripe(config.stripe.publishable_stripe_key))

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
        cardElement: {
            width: '600px',
            padding: theme.spacing(2),
        },
        buttonBar: {
            '& button': {
                marginRight: `${theme.spacing(1)}px !important`
            }
        }
    }));


export const NoFirmCheckoutForm = (props: { emailAddress: string, phoneNumber: string, authorized: (method: PaymentMethod) => void }) => {
    return <Elements stripe={stripePromise}>
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <NoFirmCheckoutFormInternal stripe={stripe!} elements={elements!} {...props} />
            )}
        </ElementsConsumer>
    </Elements>;
};


const NoFirmCheckoutFormInternal = (props: {
    stripe: Stripe,
    elements: StripeElements,
    emailAddress: string,
    phoneNumber: string,
    authorized: (method: PaymentMethod) => void,
}) => {
    const theme = useTheme();
    const { showAlert } = useAlert();
    const [isValid, setIsValid] = useState(false);
    const [isAuthorizing, setIsAuthorizing] = useState(false);

    const handleSubmit = async (event: any) => {
        if (!isValid) {
            return;
        }

        event.preventDefault();
        setIsAuthorizing(true);

        const { stripe, elements } = props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)!,
            billing_details: {
                email: props.emailAddress,
                phone: props.phoneNumber,
            }
        });

        setIsAuthorizing(false);
        if (error) {
            showAlert({
                'title': 'Cannot set payment method',
                'content': error.message,
                'buttonTitle': 'Okay'
            });
            return;
        }

        props.authorized(paymentMethod)
    };

    const handleChange = (event: StripeCardElementChangeEvent) => {
        setIsValid(event.complete);
    };

    const classes = useStyles();

    return <form onSubmit={handleSubmit}>
        <div className={classes.cardElement}>
            <CardElement
                onChange={handleChange}
                options={{
                    style: {
                        base: {
                            fontSize: `${theme.typography.fontSize + 5}px`,
                            color: theme.palette.text.primary,
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
        </div>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || isAuthorizing}>
            Set Payment Card
        </Button>
    </form >;
};

const CheckoutForm = (props: { firm: Firm, stripe: Stripe, elements: StripeElements }) => {
    const theme = useTheme();
    const { showAlert } = useAlert();
    const [isValid, setIsValid] = useState(false);
    const [isAuthorizing, setIsAuthorizing] = useState(false);

    const [updatePaymentMethod, { loading: processing }] = useManagedMutation<
        any,
        UpdatePaymentMethodData
    >(UPDATE_PAYMENT_METHOD);

    const handleSubmit = async (event: any) => {
        if (!isValid) {
            return;
        }

        event.preventDefault();
        setIsAuthorizing(true);

        const { stripe, elements } = props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)!,
            billing_details: {
                email: props.firm.emailAddress,
                phone: props.firm.phoneNumber,
            }
        });

        setIsAuthorizing(false);
        if (error) {
            showAlert({
                'title': 'Cannot set payment method',
                'content': error.message,
                'buttonTitle': 'Okay'
            });
            return;
        }

        await updatePaymentMethod({
            variables: {
                firmId: props.firm.id,
                paymentMethodId: paymentMethod?.id!,
            }
        });
    };

    const handleChange = (event: StripeCardElementChangeEvent) => {
        setIsValid(event.complete);
    };

    const classes = useStyles();

    return <form onSubmit={handleSubmit}>
        <div className={classes.cardElement}>
            <CardElement
                onChange={handleChange}
                options={{
                    style: {
                        base: {
                            fontSize: `${theme.typography.fontSize + 5}px`,
                            color: theme.palette.text.primary,
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
        </div>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid || processing || isAuthorizing}>
            Set Payment Card
        </Button>
    </form >;
};

const InjectedCheckoutForm = (props: { firm: Firm }) => {
    return <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm firm={props.firm} stripe={stripe!} elements={elements!} />
        )}
    </ElementsConsumer>;
};

export function PaymentPanel(props: { firm: Firm }) {
    return <ApolloConsumer>
        {client => <PaymentPanelWithClient firm={props.firm} client={client} />}
    </ApolloConsumer>;
}

function PaymentPanelWithClient(props: { firm: Firm, client: ApolloClient<object> }) {
    const classes = useStyles();
    const [showingEditor, setShowingEditor] = useState(false);

    const state = useManagedQuery<BillingInfo>(LOOKUP_FIRM_BILLING_INFO, {
        variables: {
            firmId: props.firm.id,
        }
    });

    const handleViewInvoices = () => {
        (async () => {
            const result = await props.client.query<BillingInfo>({
                query: LOOKUP_FIRM_BILLING_INFO_WITH_PORTAL.gql,
                variables: {
                    firmId: props.firm.id,
                },
            });

            const portalUrl = result.data.firmById.billing?.portalUrl;
            if (portalUrl) {
                window.location.assign(portalUrl);
            }
        })();
    };

    return <div>
        <ResourceView state={state}>
            {!state.loading && <>
                {state.data?.firmById.billing !== undefined && !showingEditor && <>
                    <Paper className={classes.panel}>
                        <Typography variant="subtitle1">Active Credit Card for Payment</Typography>
                        <Typography variant="subtitle2">
                            <PaymentIcon
                                id={state.data.firmById.billing.cardBrand}
                                style={{ margin: 10, width: 40, transparent: true, verticalAlign: 'middle' }}
                            />
                            <code>{state.data.firmById.billing.last4}</code></Typography>
                    </Paper>
                    <div className={classes.buttonBar}>
                        <Button variant="contained" color="primary" onClick={handleViewInvoices}>View Invoices</Button>
                        <Button onClick={() => setShowingEditor(true)}>Update Credit Card</Button>
                    </div>
                </>}

                {(showingEditor || state.data?.firmById.billing === undefined) &&
                    <>
                        <Paper className={classes.panel}>
                            <Typography variant="subtitle1">Enter a credit card to use for future payments</Typography>
                            <Elements stripe={stripePromise}>
                                <InjectedCheckoutForm firm={props.firm} />
                            </Elements>
                        </Paper>
                        {showingEditor && <Button onClick={() => setShowingEditor(false)} startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}>Back</Button>}
                    </>}
            </>
            }
        </ResourceView>
    </div>;
}