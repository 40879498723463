/*
 * To regenerate the schema:
 * typescript-json-schema model.ts "*" -o schema.json --required --strictNullChecks
 *
 * https://github.com/YousefED/typescript-json-schema
 */

import { Address } from "../../sharedschema/address";

// NOTE: This is a bitwise set enum. All values must be a power of 2.
export enum PartyRelation {
  UNKNOWN = 0,
  TESTATOR = 1 << 0,
  BENEFICIARY = 1 << 1,
  PRIMARY_FIDUCIARY = 1 << 2,
  SECONDARY_FIDUCIARY = 1 << 3,
  WITNESS = 1 << 4,
  POA_PRINCIPAL = 1 << 5,
  POA_AGENT = 1 << 6,
  POA_WITNESS = 1 << 7,
  POA_MEDICAL_AGENT = 1 << 8,

  // NOTE: 1 << 9 was Settlor.

  TERTIARY_FIDUCIARY = 1 << 10,
  PRIMARY_TRUSTEE = 1 << 11,
  SECONDARY_TRUSTEE = 1 << 12,
  PRIMARY_GUARDIAN = 1 << 13,
  SECONDARY_GUARDIAN = 1 << 14,
  TERTIARY_GUARDIAN = 1 << 15,
}

export const PartyRelationDescription = {
  [PartyRelation.UNKNOWN]: "",
  [PartyRelation.TESTATOR]: "Testator",
  [PartyRelation.BENEFICIARY]: "Beneficiary",

  [PartyRelation.PRIMARY_FIDUCIARY]: "Primary Fiduciary",
  [PartyRelation.SECONDARY_FIDUCIARY]: "Secondary Fiduciary",
  [PartyRelation.TERTIARY_FIDUCIARY]: "Tertiary Fiduciary",

  [PartyRelation.PRIMARY_TRUSTEE]: "Primary Trustee",
  [PartyRelation.SECONDARY_TRUSTEE]: "Secondary Trustee",

  [PartyRelation.PRIMARY_GUARDIAN]: "Primary Guardian",
  [PartyRelation.SECONDARY_GUARDIAN]: "Secondary Guardian",
  [PartyRelation.TERTIARY_GUARDIAN]: "Tertiary Guardian",

  [PartyRelation.WITNESS]: "Witness",

  [PartyRelation.POA_PRINCIPAL]: "POA Principal",
  [PartyRelation.POA_AGENT]: "POA Agent",
  [PartyRelation.POA_WITNESS]: "POA Witness",
  [PartyRelation.POA_MEDICAL_AGENT]: "POA Medical Agent",
};

export const PartyRelationKey = {
  [PartyRelation.UNKNOWN]: "",
  [PartyRelation.TESTATOR]: "Testator",
  [PartyRelation.BENEFICIARY]: "Beneficiary",

  [PartyRelation.PRIMARY_FIDUCIARY]: "Primary_Fiduciary",
  [PartyRelation.SECONDARY_FIDUCIARY]: "Secondary_Fiduciary",
  [PartyRelation.TERTIARY_FIDUCIARY]: "Tertiary_Fiduciary",

  [PartyRelation.PRIMARY_TRUSTEE]: "Primary_Trustee",
  [PartyRelation.SECONDARY_TRUSTEE]: "Secondary_Trustee",

  [PartyRelation.PRIMARY_GUARDIAN]: "Primary_Guardian",
  [PartyRelation.SECONDARY_GUARDIAN]: "Secondary_Guardian",
  [PartyRelation.TERTIARY_GUARDIAN]: "Tertiary_Guardian",

  [PartyRelation.WITNESS]: "Witness",

  [PartyRelation.POA_PRINCIPAL]: "POA_Principal",
  [PartyRelation.POA_AGENT]: "POA_Agent",
  [PartyRelation.POA_WITNESS]: "POA_Witness",
  [PartyRelation.POA_MEDICAL_AGENT]: "POA_Medical_Agent",
};

export interface WillDetails {
  id: number;

  willDate: string;
  trustDate: string;
  poaDate: string;
  poaRealEstate: string;
  poaRealEstateSalePrice: number | undefined;
}

export interface Party extends Address {
  willRelation: PartyRelation;
  name: string;
  phoneNumber: string;
  emailAddress: string;
  estateSettlorRelation: string;
  dob: string;
  ssn: string;
  isMinor: number;

  // Beneficiary Details
  tangiblePersonalProperty: number;
  specificBequest: string;
  residuaryEstateBequest: string;

  isResiduaryIfNoSurvivingBeneficiaries: number;
}

export interface ChildBequest {
  id: number;

  rootPartyId: Party | number;
  parentChildBequestId: ChildBequest | number | null;

  bequestPartyId: Party | number | null;
  residuaryEstateBequest: string;
  isPerStirpes: number;
}

// DEPRECATED
export interface SecondaryBequest {
  id: number;
  sourcePartyId: number;
  targetPartyId: number;
}
