import { ApolloClient } from "@apollo/client";
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { DatabaseContext } from "../../database/diffable/context";
import { parseDocx } from "../../docx/docx";
import {
  AddDocumentTemplateData,
  AddDocumentTemplateParams,
  ADD_DOCUMENT_TEMPLATE,
} from "../../queries/documents";
import { useManagedMutation } from "../../queries/lib/hooks";
import { useAuthenticationService } from "../../services/authentication";
import { ApplicationConfig } from "../../services/configservice";
import { generateDocumentParameters } from "../../services/documentservice";
import { TEMPLATES } from "../../templates";
import { Case } from "../../types/case";
import { useAlert } from "../AlertProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    section: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
    field: {
      padding: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    buttonBar: {
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(2),
      borderTop: "1px solid transparent",
      borderColor: theme.palette.divider,
    },
    step: {
      display: "grid",
      alignItems: "center",
      gridTemplateColumns: "auto 1fr",
      columnGap: theme.spacing(1),
    },
    stepNumber: {
      width: "34px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      fontSize: "20px",
      borderRadius: "50%",
    },
    link: {
      color: theme.palette.text.primary,
    },
  })
);

export function AddTemplate(props: {
  case: Case;
  appConfig: ApplicationConfig;
  client: ApolloClient<object>;
  templateAdded: () => void;
}) {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  const database = useContext(DatabaseContext)!;
  const { showAlert } = useAlert();

  const [addDocumentTemplate, state] = useManagedMutation<
    AddDocumentTemplateData,
    AddDocumentTemplateParams
  >(ADD_DOCUMENT_TEMPLATE);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleFileSelected = async (filelist: FileList | null) => {
    setError(undefined);
    setSelectedFile(null);

    if (!filelist || filelist.length === 0) {
      return;
    }

    const parsed = await parseDocx(await filelist[0].arrayBuffer());
    const fieldNames = await parsed?.fieldNames();
    if (!fieldNames?.size) {
      setError(
        "The selected template is not a valid MS Word document with mail merge fields"
      );
      return;
    }

    const generated = await generateDocumentParameters(
      props.appConfig,
      props.client,
      props.case,
      /* all fields */ undefined,
      database!
    );

    // Ensure all the field names are defined.
    const missing = Array.from(fieldNames).filter((fieldName: string) => {
      if (generated.error) {
        return false;
      }

      return !(fieldName in (generated.data || {}));
    });
    if (missing.length) {
      setError(
        "The following fields were found in the template, but do not exist: " +
          missing.slice(0, 4).join(", ")
      );
      return;
    }

    setSelectedFile(filelist[0]);
    setError(undefined);
  };

  const { getToken } = useAuthenticationService();

  const handleAddTemplate = async () => {
    const result = await addDocumentTemplate({
      variables: {
        caseTemplateId: props.case.templateId!,
        title: title,
        description: description,
      },
    });
    if (result?.addCaseDocumentTemplate.docTemplate) {
      setIsUpdating(true);

      const bearerToken = await getToken();

      const headers: Record<string, string> = {
        "content-type": "application/octet-stream",
        authorization: `Bearer ${bearerToken}`,
      };

      try {
        const uploadResult = await axios.request({
          method: "post",
          url: `${props.appConfig.endpoint}${result.addCaseDocumentTemplate.docTemplate.updateTemplateUrl}`,
          data: await selectedFile!.arrayBuffer(),
          headers: headers,
        });
        if (uploadResult.status / 100 !== 2) {
          await showAlert({
            title: "Document update failed",
            content: (
              <div>
                The update of the document failed. Please try again shortly.
              </div>
            ),
            buttonTitle: "Okay",
          });
          setIsUpdating(false);
          return;
        }

        // Refetch the case to pull the updated template document.
        props.client.refetchQueries({
          include: ["LookupCaseByID"],
        });

        props.templateAdded();

        setError(undefined);
        setSelectedFile(null);
        setIsUpdating(false);
      } catch (e) {
        await showAlert({
          title: "Document update failed",
          content: (
            <div>
              The update of the document failed. Please try again shortly.
            </div>
          ),
          buttonTitle: "Okay",
        });
        setIsUpdating(false);
        return;
      }
    }
  };

  // NOTE: This is done so that reselecting the same file calls onChange
  // See: https://stackoverflow.com/a/54632736
  const clearFileInput = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setError(undefined);
    setSelectedFile(null);

    const element = event.target as HTMLInputElement;
    element.value = "";
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5">Add Document Template</Typography>
      <Typography variant="subtitle1">
        Follow the instructions below to add a new template for generating a
        document to <code>{TEMPLATES[props.case.templateId!].title}</code>{" "}
        cases.
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
        <Alert severity="info">
          Need some help with this?{" "}
          <a className={classes.link} href="mailto:support@casolio.com">
            Contact Casolio support
          </a>
        </Alert>
      </Typography>

      <div className={classes.step}>
        <h2 className={classes.stepNumber}>1</h2>
        <Typography variant="h6">
          Write the document in Microsoft Word
        </Typography>
      </div>
      <Paper className={classes.section}>
        To begin, write the document in Microsoft Word, containing all the text
        and content that you would like to appear in the final document
      </Paper>

      <div className={classes.step}>
        <h2 className={classes.stepNumber}>2</h2>
        <Typography variant="h6">
          Add mail merge fields to the Microsoft Word document
        </Typography>
      </div>
      <Paper className={classes.section}>
        Add one or more{" "}
        <Link to="?tab=fields" className={classes.link}>
          mail merge fields
        </Link>{" "}
        to your document, representing the data from the Casolio case to include
        in your document
      </Paper>

      <div className={classes.step}>
        <h2 className={classes.stepNumber}>3</h2>
        <Typography variant="h6">
          Upload the Microsoft Word document file (<code>.docx</code>)
        </Typography>
      </div>
      <Paper className={classes.section}>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={(e) => handleFileSelected(e.target.files)}
          onClick={clearFileInput}
        />
        {error && (
          <Alert severity="error" style={{ marginTop: "20px" }}>
            {error}
          </Alert>
        )}
      </Paper>

      <div className={classes.step}>
        <h2 className={classes.stepNumber}>4</h2>
        <Typography variant="h6">
          Enter details for the new document template
        </Typography>
      </div>
      <Paper className={classes.section}>
        <div className={classes.field}>
          <Typography variant="subtitle1" className={classes.title}>
            Enter a title for the documents this template will create:
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Document Title"
            value={title}
            onChange={handleTitleChange}
            fullWidth
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div className={classes.field}>
          <Typography variant="subtitle1" className={classes.title}>
            Enter a description of the kinds of documents this template will
            create:
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Document Description"
            value={description}
            onChange={handleDescriptionChange}
            inputProps={{ maxLength: 1024 }}
            fullWidth
          />
        </div>
      </Paper>

      <div className={classes.buttonBar}>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !title ||
            !description ||
            !selectedFile ||
            state.loading ||
            isUpdating
          }
          startIcon={
            state.loading || isUpdating ? (
              <CircularProgress size="1em" />
            ) : undefined
          }
          onClick={handleAddTemplate}
        >
          Add Template
        </Button>
      </div>
    </div>
  );
}
