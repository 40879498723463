import { gql } from "@apollo/client";
import { Firm } from "../types/firm";
import { User } from "../types/firmmember";
import { AttorneyLicense } from "../types/license";
import { WITH_ERRORS_FRAGMENT } from "./lib/hooks";

const USER_FRAGMENT = `
  authenticatedUser {
    id
    name
    email
    avatarUrl
    valid
    firm {
      id
      title
      logoData
      color
      allowedFlowIds
      phoneNumber
      faxNumber
      address
    }
    licenses {
      id
      stateId
      attorneyId
    }
  }`;

/**
 * LOOKUP_USER returns information about the currently authenticated user.
 */
export const LOOKUP_USER = gql`
  query GetAuthenticatedUser {
    ${USER_FRAGMENT}
  }
`;

export type AuthenticatedUser = User & {
  firm: Firm | null | undefined
  licenses: AttorneyLicense[]
}

export interface UserData {
  authenticatedUser: AuthenticatedUser
}


/**
 * Mutation: Sets the user's name.
 * 
 * @param name The new name for the user.
 */
export const SET_USER_NAME = {
  gql: gql`
 mutation SetUserName($name: String!) {
   setUserName(name: $name) {
     ${WITH_ERRORS_FRAGMENT}
   }
 }
`,
  workingMessage: 'Generating document',
  errorMessage: 'Failed to generate document',
  refetchQueries: ['GetAuthenticatedUser']
};

export interface SetUserNameParams {
  name: string
}