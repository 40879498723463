import { ApolloClient } from '@apollo/client';
import { faCog, faDatabase, faFileDownload, faFileUpload, faFileWord, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import MaterialTable, { Icons } from 'material-table';
import React, { forwardRef, useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { DatabaseContext } from '../../database/diffable/context';
import { parseDocx } from '../../docx/docx';
import { DeleteDocumentTemplateParams, DELETE_DOCUMENT_TEMPLATE } from '../../queries/documents';
import { useManagedMutation } from '../../queries/lib/hooks';
import { useAuthenticationService } from '../../services/authentication';
import { useApplicationConfig } from '../../services/confighook';
import { ApplicationConfig, RunEnvironment } from '../../services/configservice';
import { generateDocumentParameters, useDocumentCreator } from '../../services/documentservice';
import { Case, CaseDatabaseRevision } from '../../types/case';
import { DocumentTemplate } from '../../types/documentemplate';
import { useAlert } from '../AlertProvider';
import { useConfirmDialog } from '../ConfirmDialogProvider';
import { useDocumentTracker } from './DocumentTracker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(8)
        },
        tabPanel: {
            padding: theme.spacing(2)
        },
        documentGrid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            columnGap: '10px',
            rowGap: '10px',
            width: '100%'
        },
        creating: {
            display: 'flex',
            alignItems: 'center'
        },
        spinner: {
            marginRight: theme.spacing(2)
        },
        documentTypeCheckboxContainer: {
            position: 'relative'
        },
        documentTypeCheckboxSpinner: {
            position: 'absolute',
            top: '0px',
            left: '0px'
        },
        link: {
            color: theme.palette.text.primary,
        }
    }));


/**
 * CreateDocumentPane is a panel for creating/generating a new document for a case.
 * @returns 
 */
export function CreateDocumentPane(props: {
    case: Case,
    appConfig: ApplicationConfig,
    currentRevision: CaseDatabaseRevision,
    client: ApolloClient<object>,
}) {
    // Database and document tracker.
    const database = useContext(DatabaseContext)!;
    const { tracker } = useDocumentTracker();

    // Helper values.
    const documentTemplates: DocumentTemplate[] = (props.case.docTemplates ?? []).map((dt: DocumentTemplate) => {
        return { ...dt }; // Clone so MaterialTable can extend.
    });
    const allChecked = documentTemplates.filter((docTemplate: DocumentTemplate) => tracker.hasDocument(docTemplate)).length === documentTemplates.length;

    // Various hooks.
    const classes = useStyles();
    const history = useHistory();
    const { showAlert } = useAlert();

    const { createDocument } = useDocumentCreator(props.case, props.currentRevision, database, props.appConfig, props.client, true);

    // State.
    const [addingAllDocuments, setAddingAllDocuments] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [previewingDoc, setPreviewingDoc] = useState<DocumentTemplate | undefined>(undefined);
    const [previewingDocIndex, setPreviewingDocIndex] = useState(0);
    const [updatingDoc, setUpdatingDoc] = useState<DocumentTemplate | undefined>(undefined);
    const [updatingDocIndex, setUpdatingDocIndex] = useState(0);
    const [updatedDocTemplate, setUpdatedDocTemplate] = useState<DocumentTemplate | undefined>();

    const handleCreateDocument = (docTemplate: DocumentTemplate) => {
        (async () => {
            setGenerating(true);
            await createDocument(docTemplate);
            setGenerating(false);
        })();
    };

    const handlePreviewDocument = (docTemplate: DocumentTemplate) => {
        setPreviewingDoc(docTemplate);
        setPreviewingDocIndex(previewingDocIndex + 1);
    };

    const handleUpdateTemplate = (docTemplate: DocumentTemplate) => {
        setUpdatingDoc(docTemplate);
        setUpdatingDocIndex(updatingDocIndex + 1);
    };

    const addDocument = async (docTemplate: DocumentTemplate, skipRefresh?: boolean) => {
        const generated = await generateDocumentParameters(props.appConfig, props.client, props.case, docTemplate, database!);
        if (generated.error === undefined) {
            tracker.addDocument(docTemplate, generated.missing, skipRefresh);
            return true;
        }

        showAlert({
            'title': 'Cannot add document',
            'content': generated.error,
            'buttonTitle': 'Okay'
        });
        return false;
    };

    const handleDocumentCheckChange = (docTemplate: DocumentTemplate) => {
        (async () => {
            if (tracker.hasDocument(docTemplate)) {
                tracker.removeDocument(docTemplate);
            } else {
                await addDocument(docTemplate);
            }
        })();
    };


    const handleToggleAll = () => {
        if (allChecked) {
            tracker.removeAllDocuments();
        } else {
            setAddingAllDocuments(true);
            (async () => {
                for (const docTemplate of documentTemplates) {
                    if (tracker.hasDocument(docTemplate)) {
                        continue;
                    }

                    if (!(await addDocument(docTemplate, true))) {
                        setAddingAllDocuments(false);
                        return;
                    }
                }

                tracker.refresh();
                setAddingAllDocuments(false);
            })();
        }
    };

    const handleTemplateUpdated = (docTemplate: DocumentTemplate) => {
        setUpdatingDoc(undefined);
        setUpdatedDocTemplate(docTemplate);

        // Refetch templates.
        props.client.reFetchObservableQueries()
    };

    const showDocumentDebugPanel = (docTemplate: DocumentTemplate) => {
        history.push(`/c/${props.case.id}/documents?tab=fields`, {
            forTemplate: docTemplate,
        });
    }

    const tableIcons: Icons = materialTableIcons();

    return <div>
        {previewingDoc !== undefined &&
            <DocumentPreviewView
                key={previewingDocIndex}
                docTemplate={previewingDoc}
                onClose={() => setPreviewingDoc(undefined)} />}

        {updatingDoc !== undefined && <DocumentUpdateView
            key={updatingDocIndex}
            docTemplate={updatingDoc}
            appConfig={props.appConfig}
            client={props.client}
            case={props.case}
            templateUpdated={handleTemplateUpdated}
            onClose={() => setUpdatingDoc(undefined)} />}

        {!generating && <MaterialTable
            icons={tableIcons}
            columns={[
                {
                    title: <div className={classes.documentTypeCheckboxContainer}><Checkbox
                        onChange={handleToggleAll}
                        disabled={addingAllDocuments}
                        checked={allChecked}
                        color="default"
                    />
                        {addingAllDocuments && <CircularProgress className={classes.documentTypeCheckboxSpinner} />
                        }
                    </div>, field: "", render: (data: DocumentTemplate) => {
                        const dtg = tracker.getDocument(data);
                        return <div className={classes.documentTypeCheckboxContainer}>
                            <Checkbox checked={dtg !== undefined}
                                disabled={addingAllDocuments}
                                color="default"
                                style={{ backgroundColor: dtg?.color }}
                                onChange={() => handleDocumentCheckChange(data)}
                            />
                        </div>;
                    },
                    cellStyle: {
                        width: '0px',
                    }
                },
                {
                    title: "Kind of Document", field: "title",
                    cellStyle: {
                        whiteSpace: 'nowrap',
                        width: '100%',
                    },
                    render: (data: DocumentTemplate) => {
                        return <div>
                            <Typography variant="subtitle2">{data.title}</Typography>
                            <Typography variant="caption">{data.description}</Typography>
                        </div>;
                    }
                },
                {
                    title: "", field: "description", render: (data: DocumentTemplate) => {
                        return <span />;
                    }
                },
                {
                    title: "", field: "tags", render: (data: DocumentTemplate) => {
                        return <span />;
                    }
                },
                {
                    title: "", field: "create", render: (data: DocumentTemplate) => {
                        return <div style={{ whiteSpace: 'nowrap' }}>
                            <Button variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faFileWord} />} onClick={() => handleCreateDocument(data)}>Create Document</Button>
                        </div>;
                    }
                },
                {
                    title: "", field: "settings", render: (data: DocumentTemplate) => {
                        return <TemplateSettings
                            template={data}
                            appConfig={props.appConfig}
                            showDocumentPreview={handlePreviewDocument}
                            showDocumentDebugPanel={showDocumentDebugPanel}
                            showUpdateTemplate={handleUpdateTemplate}
                        />
                    }
                },
            ]}
            data={documentTemplates}
            options={{ pageSize: 10 }}
            title="Available Document Types"
        />}
        {generating && <div className={classes.creating}>
            <CircularProgress className={classes.spinner} /> Please Wait... Creating Document...
        </div>}
    </div>;
}

export function materialTableIcons(): Icons {
    return {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };
}

function DocumentUpdateView(props: {
    case: Case,
    appConfig: ApplicationConfig,
    client: ApolloClient<object>,
    docTemplate: DocumentTemplate,
    templateUpdated: (docTemplate: DocumentTemplate) => void,
    onClose: () => void
}) {
    const [isOpen, setIsOpen] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const classes = useDebugStyles();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);

    const database = useContext(DatabaseContext)!;
    const { showAlert } = useAlert();

    const handleFileSelected = (filelist: FileList | null) => {
        if (!filelist || filelist.length === 0) {
            setSelectedFile(null);
            setError(undefined);
            return
        }

        (async () => {
            const parsed = await parseDocx(await filelist[0].arrayBuffer())
            const fieldNames = await parsed?.fieldNames();
            if (!fieldNames?.size) {
                setError('The selected template is not a valid MS Word document with mail merge fields');
                return;
            }

            const generated = await generateDocumentParameters(props.appConfig, props.client, props.case, /* all fields */undefined, database!);

            // Ensure all the field names are defined.
            const missing = Array.from(fieldNames).filter((fieldName: string) => {
                if (generated.error) {
                    return false;
                }

                return !(fieldName in (generated.data || {}))
            });
            if (missing.length) {
                setError('The following fields were found in the template, but do not exist: ' + missing.slice(0, 4).join(', '));
                return;
            }

            setSelectedFile(filelist[0]);
            setError(undefined);
        })();
    };

    const { getToken } = useAuthenticationService();

    const updateTemplate = () => {
        setIsUpdating(true);

        (async () => {
            if (!props.docTemplate.updateTemplateUrl) {
                await showAlert({
                    'title': 'Document update not allowed',
                    'content': <div>
                        The specified document is built-in and cannot be modified
                    </div>,
                    'buttonTitle': 'Okay',
                });
    
                return;
            }
    
            const bearerToken = await getToken();

            const headers: Record<string, string> = {
                'content-type': 'application/octet-stream',
                'authorization': `Bearer ${bearerToken}`,
            };

            try {
                const uploadResult = await axios.request({
                    method: "post",
                    url: `${(props.appConfig.endpoint)}${props.docTemplate.updateTemplateUrl}`,
                    data: await selectedFile!.arrayBuffer(),
                    headers: headers,
                });
                if (uploadResult.status / 100 !== 2) {
                    await showAlert({
                        'title': 'Document update failed',
                        'content': <div>
                            The update of the document failed. Please try again shortly.
                        </div>,
                        'buttonTitle': 'Okay',
                    });
                    setIsUpdating(false);
                    return;
                }
            } catch (e) {
                await showAlert({
                    'title': 'Document update failed',
                    'content': <div>
                        The update of the document failed. Please try again shortly.
                    </div>,
                    'buttonTitle': 'Okay',
                });
                setIsUpdating(false);
                return;
            }

            props.templateUpdated(props.docTemplate);
            setIsOpen(false);
        })();
    };

    return <Dialog
        open={isOpen}
        onClose={() => {
            setIsOpen(false);
            props.onClose();
        }}
    >
        <DialogTitle>
            <Typography color="textSecondary">Update template for </Typography> {props.docTemplate.title}
            <IconButton className={classes.closeButton} aria-label="close" onClick={() => setIsOpen(false)}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {error && <Alert severity="error" style={{ marginBottom: '20px' }}>{error}</Alert>}
            <form>
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e) => handleFileSelected(e.target.files)}
                    disabled={isUpdating}
                />
            </form>
        </DialogContent>
        <DialogActions>
            <Button aria-label="close" disabled={isUpdating} onClick={() => setIsOpen(false)}>
                Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={selectedFile === null || isUpdating} onClick={updateTemplate}>
                Update Template
            </Button>
        </DialogActions>
    </Dialog>;
};


function DocumentPreviewView(props: { docTemplate: DocumentTemplate, onClose: () => void }) {
    const [isOpen, setIsOpen] = useState(true);
    const classes = useDebugStyles();

    const appConfig = useApplicationConfig();
    const wordUrl = (appConfig?.endpoint ?? '') + props.docTemplate.previewUrl;
    const encodedWordURI = encodeURIComponent(wordUrl);

    return <Dialog
        fullWidth
        maxWidth="xl"
        open={isOpen}
        onClose={() => {
            setIsOpen(false);
            props.onClose();
        }}
    >
        <DialogTitle>
            <Typography color="textSecondary">Preview of</Typography> {props.docTemplate.title}
            <IconButton className={classes.closeButton} aria-label="close" onClick={() => setIsOpen(false)}>
                <Close />
            </IconButton>
        </DialogTitle>
        <iframe title="Document Preview" className={classes.previewFrame} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodedWordURI}`} />
    </Dialog>;
};

const useDebugStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarSpacer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            minWidth: '300px',
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
        },
        previewFrame: {
            width: '100%',
            height: '100%',
            minHeight: '600px',
            border: '0px'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }));


function TemplateSettings(props: {
    template: DocumentTemplate,
    appConfig: ApplicationConfig,
    showDocumentDebugPanel: (template: DocumentTemplate) => void,
    showUpdateTemplate: (template: DocumentTemplate) => void,
    showDocumentPreview: (template: DocumentTemplate) => void,
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowDebugData = () => {
        props.showDocumentDebugPanel(props.template);
        setAnchorEl(null);
    };

    const handleShowUpdateTemplate = () => {
        props.showUpdateTemplate(props.template);
        setAnchorEl(null);
    };

    const handleViewPreview = () => {
        props.showDocumentPreview(props.template);
        setAnchorEl(null);
    };

    const { showConfirm } = useConfirmDialog();
    const [deleteTemplate] = useManagedMutation<
        any,
        DeleteDocumentTemplateParams
    >(DELETE_DOCUMENT_TEMPLATE);

    const handleAskDelete = () => {
        (async () => {
            const [confirmResult] = await showConfirm({
                'title': 'Delete document template?',
                'content': `Are you sure you wish to delete "${props.template.title}"?`,
                'buttons': [
                    { 'title': 'Cancel', 'value': undefined },
                    { 'title': `Delete ${props.template.title}`, 'value': 'delete', 'variant': 'contained', 'color': 'secondary' },
                ]
            });
            if (confirmResult !== 'delete') {
                return;
            }

            await deleteTemplate({
                variables: {
                    docTemplateId: props.template.id,
                }
            })
        })();
    };

    const wordUrl = (props.appConfig?.endpoint ?? '') + props.template.previewUrl;

    return <div>
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <FontAwesomeIcon icon={faCog} size="sm" />
        </IconButton>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleViewPreview}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faSearch} />
                </ListItemIcon>
                <ListItemText primary="View Preview" />
            </MenuItem>

            {!!props.template.updateTemplateUrl &&
                <MenuItem onClick={handleShowUpdateTemplate}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileUpload} />
                    </ListItemIcon>
                    <ListItemText primary="Update Document Template (Advanced)" />
                </MenuItem>
            }

            {props.appConfig.runEnvironment !== RunEnvironment.PRODUCTION &&
                <MenuItem onClick={handleShowDebugData}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faDatabase} />
                    </ListItemIcon>
                    <ListItemText primary="View Mail Merge Fields For Template (Advanced)" />
                </MenuItem>}

            <MenuItem component="a" href={wordUrl} download="template.docx">
                <ListItemIcon>
                    <FontAwesomeIcon icon={faFileDownload} />
                </ListItemIcon>
                <ListItemText primary="Download Document Template (Advanced)" />
            </MenuItem>

            {!!props.template.updateTemplateUrl && <MenuItem onClick={handleAskDelete}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                </ListItemIcon>
                <ListItemText primary="Delete Document Template (Advanced)" />
            </MenuItem>}
        </Menu>
    </div>;
}