// Based on: https://gist.github.com/romannurik/8d6681ccf55dc44f09caf63ec13945da
import clsx from "clsx";
import React, {
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";

const [DURMIN, DURMAX] = [0.1, 0.8];
const dur = (f: number) => Math.min(DURMAX, (1 - f) * DURMIN + f * DURMAX);

export interface ExpandoProps {
  className?: string;
  open: boolean;
}

export function Expando(props: PropsWithChildren<ExpandoProps>) {
  const node = useRef<HTMLDivElement | null>(null);
  const firstOpen = useRef(props.open);
  const lastOpen = useRef(props.open);
  const duration = useRef(0.5);

  const animPhase = useCallback(
    (stage: string) => {
      if (node.current !== null) {
        if (stage === "end") {
          node.current.style.transition = "";
          node.current.style.maxHeight = props.open ? "none" : "0";
        } else {
          // prep or start
          const contentHeight =
            (node.current?.firstChild as HTMLElement | null)?.offsetHeight || 0;
          duration.current = dur(contentHeight / 1000);
          node.current.style.transition =
            `max-height ${duration.current}s ease` +
            (props.open ? "" : `, visibility 0s linear ${duration.current}s`);
          node.current.style.maxHeight =
            ((stage === "prep") === props.open ? 0 : contentHeight) + "px";

          // eslint-disable-next-line no-self-assign
          node.current.scrollTop = node.current.scrollTop; // force reflow
        }
      }
    },
    [props.open]
  );

  if (node.current && props.open !== lastOpen.current) {
    animPhase("prep"); // just before render
  }

  lastOpen.current = props.open;

  useLayoutEffect(() => {
    const current = node.current;
    if (current === null) {
      return;
    }

    // just after render
    const end = (ev: Event) => ev.target === current && animPhase("end");
    current.addEventListener("transitionend", end);

    // if the expando begins open, then prep and start its animation to open it.
    if (firstOpen.current) {
      animPhase("prep");
      animPhase("start");
    } else {
      animPhase("start");
    }

    return () => current.removeEventListener("transitionend", end);
  }, [props.open, animPhase]);

  return (
    <div
      className={clsx(props.className)}
      ref={node}
      style={{
        overflow: "hidden",
        visibility: props.open ? "visible" : "hidden",
      }}
    >
      <div
        style={{
          transform: props.open ? "none" : "translateY(-100%)",
          transition: `transform ${duration.current}s ease`,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
