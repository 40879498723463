import { ApolloClient, createHttpLink, DocumentNode, InMemoryCache, LazyQueryHookOptions, OperationVariables, useLazyQuery } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/link-context';
import { useEffect } from 'react';

const buildClient = (apiEndpoint: string, getToken: () => Promise<string | null>) => {
    const httpLink = createHttpLink({
        uri: `${apiEndpoint}/graphql`,
    });

    const authLink = setContext(async (_, { headers }) => {
        const token = await getToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        generatedDocuments: relayStylePagination()
                    }
                }
            }
        }),
        /*        defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                    },
                },
                */
    });
};

export default buildClient;


/**
 * useFixedQuery is a wrapper around useQuery that fixes the issue associated with de-registration
 * of queries currently in Apollo. Instead of using useQuery, useFixedQuery makes use of
 * useLazyQuery, which is invoked via a useEffect hook as soon as the component is mounted, and we
 * check if the component is still mounted before any additional calls.
 */
export function useFixedQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode, options?: LazyQueryHookOptions<TData, TVariables>) {
    const [loadData, { loading, error, data, fetchMore, refetch }] = useLazyQuery(query, options);

    // NOTE: Changed to use lazyQuery based on: https://github.com/apollographql/react-apollo/issues/3635#issuecomment-621070899
    let isMounted = true;
    useEffect(() => {
        if (isMounted) {
            loadData();
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isMounted = false;
        };
    }, []);

    return { loading, error, data, fetchMore, refetch, isMounted }
};