import * as config from '../config.json';

/**
 * SentryConfig holds the configuration for Sentry.
 */
interface SentryConfig {
    /**
     * dsn is the DSN for Sentry, if any.
     */
    dsn: string | null;
}

/**
 * ClerkConfig holds the configuration for Clerk.
 */
interface ClerkConfig {
    /**
     * frontend_api_key is the frontend api key.
     */
    frontend_api_key: string;
}

export enum RunEnvironment {
    DEVELOPMENT = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production'
}

/**
 * MixpanelConfig is the configuration for Mixpanel.
 */
interface MixpanelConfig {
    /**
     * token is the Mixpanel token.
     */
    token: string;
}

/**
 * StripeConfig is the configuration for Stripe.
 */
export interface StripeConfig {
    /**
     * publishable_stripe_key is the publishable key for Stripe.
     */
    publishable_stripe_key: string;
}

/**
 * ApplicationConfig is the overall configuration for the application.
 */
export interface ApplicationConfig {
    /**
     * clerk is the Clerk configuration.
     */
    clerk: ClerkConfig;

    /**
     * mixpanel is the Mixpanel configuration.
     */
    mixpanel: MixpanelConfig;

    /**
     * endpoint is the endpoint of the backend. Must not end in /.
     */
    endpoint: string;

    /**
     * documentEnding is the endpoint of the backend for viewing documents. Must not end in /.
     */
    documentEndpoint: string;

    /**
     * sentry is the Sentry configuration.
     */
    sentry: SentryConfig;

    /**
     * runEnvironment is the run environment.
     */
    runEnvironment: RunEnvironment;

    /**
     * stripe is the Stripe configuration.
     */
    stripe: StripeConfig;
}

async function LoadAppConfig(): Promise<ApplicationConfig> {
    let typed: ApplicationConfig = {
        clerk: config.clerk,
        mixpanel: config.mixpanel,
        sentry: config.sentry,
        endpoint: config.endpoint,
        documentEndpoint: config.documentEndpoint,
        stripe: config.stripe,
        runEnvironment: RunEnvironment.DEVELOPMENT,
    };

    if (process.env.REACT_APP_RUN_ENVIRONMENT) {
        typed.runEnvironment = process.env.REACT_APP_RUN_ENVIRONMENT as RunEnvironment;
    }

    if (window.location.hostname === 'app.casolio.com' && typed.runEnvironment !== RunEnvironment.PRODUCTION) {
        throw Error('Got invalid run enviroment for production deployment')
    }

    const overrideConfigPath = process.env.REACT_APP_OVERRIDE_CONFIG_PATH;
    if (overrideConfigPath) {
        console.log('Loading override configuration');
        const result = await fetch(overrideConfigPath);
        if (!result.ok) {
            throw Error('Could not load configuration')
        }

        try {
            const json = await result.json();
            if (json !== undefined) {
                typed = json as ApplicationConfig;
            }
        } catch (e) {
            console.log(e)
            throw Error('Could not parse configuration')
        }
    }

    if (!typed.stripe || !typed.stripe.publishable_stripe_key.startsWith("pk_")) {
        throw Error('Missing or invalid Stripe configuration')
    }

    if (!typed.clerk) {
        throw Error('Missing Clerk configuration')
    }

    if (!typed.clerk.frontend_api_key) {
        throw Error('Missing Clerk frontend API key')
    }

    if (!typed.endpoint) {
        throw Error('Missing endpoint')
    }

    if (typed.endpoint.endsWith('/')) {
        throw Error('Endpoint must not end in /')
    }

    if (typed.documentEndpoint.endsWith('/')) {
        throw Error('Document endpoint must not end in /')
    }

    return typed;
}

let cachedConfig: ApplicationConfig | undefined = undefined;

/**
 * CachedAppConfig returns the cached ApplicationConfig, if any.
 */
export function CachedAppConfig(): ApplicationConfig | undefined {
    return cachedConfig;
}

/**
 * AppConfig returns the ApplicationConfig.
 */
export default async function AppConfig(): Promise<ApplicationConfig> {
    if (cachedConfig !== undefined) {
        return cachedConfig
    }

    cachedConfig = await LoadAppConfig();
    return cachedConfig
}