import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { InviteMemberToFirmParams, INVITE_MEMBER_TO_FIRM, LIST_FIRM_MEMBERS, RemoveMemberFromFirmParams, REMOVE_MEMBER_FROM_FIRM } from "../../queries/firm";
import { useManagedMutation } from "../../queries/lib/hooks";
import { Firm } from "../../types/firm";
import { FirmMember } from "../../types/firmmember";
import BoundTable from "../BoundTable";
import { useConfirmDialog } from "../ConfirmDialogProvider";
import { LicensesDisplay } from "../LicenseDisplay";
import UserView from "../UserView";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            width: '100%',
            padding: theme.spacing(1),
        },
    }));

/**
 * FirmMemberEditor is the editor for firm membership.
 */
export default function FirmMemberEditor(props: { firm: Firm }) {
    const classes = useStyles();
    const { showConfirm } = useConfirmDialog();

    const [inviteMember, { loading: inviting }] = useManagedMutation<
        any,
        InviteMemberToFirmParams
    >(INVITE_MEMBER_TO_FIRM);

    const [invitedAddress, setInvitedAddress] = useState<string | undefined>(undefined);

    const [removeMember, { loading: removing }] = useManagedMutation<
        any,
        RemoveMemberFromFirmParams
    >(REMOVE_MEMBER_FROM_FIRM);

    const handleShowInvite = () => {
        (async () => {
            const [result, email] = await showConfirm({
                'title': 'Invite a member to the firm',
                'content': 'Enter the e-mail address of the user to invite to this firm',
                'withPrompt': 'email@address.com',
                'buttons': [
                    { 'title': 'Cancel', 'value': undefined },
                    { 'title': 'Invite to Firm', 'value': 'invite', 'variant': 'contained', 'color': 'secondary' },
                ]
            });
            if (result !== 'invite' || !email) {
                return;
            }

            await inviteMember({
                variables: {
                    firmId: props.firm.id,
                    email: email,
                }
            })

            setInvitedAddress(email);
        })();
    };

    const askRemoveMember = (member: FirmMember) => {
        (async () => {
            const [result] = await showConfirm({
                'title': 'Remove the member from the firm?',
                'content': `Are you sure you want to remove ${member.name} from this firm? They will no longer have access to Casolio`,
                'buttons': [
                    { 'title': 'Cancel', 'value': undefined },
                    { 'title': `Remove ${member.name}`, 'value': 'remove', 'variant': 'contained', 'color': 'secondary' },
                ]
            });
            if (result !== 'remove') {
                return;
            }

            await removeMember({
                variables: {
                    firmId: props.firm.id,
                    memberId: member.id,
                }
            })
        })();
    };

    return <div>
        {!!invitedAddress && <Alert severity="info" style={{ marginBottom: '10px' }}>
            {invitedAddress} has been invited to join the firm. Please have them check their email.
        </Alert>}

        <BoundTable<FirmMember>
            toolbar={
                <div className={classes.toolbar}>
                    <Button variant="contained" color="primary" size="large" disabled={inviting} onClick={handleShowInvite}>Invite Member To the firm</Button>
                </div>
            }
            query={{
                gql: LIST_FIRM_MEMBERS,
                variables: {
                    firmId: props.firm.id,
                },
                recordsKey: ['firmById', 'members'],
            }}
            searchQueryField="filter"
            columns={[
                {
                    id: "name",
                    title: "Member",
                    isPrimary: true,
                    render: (data: FirmMember) => {
                        return <UserView user={data} hideEmail={true} />
                    }
                },
                {
                    id: "email",
                    title: "E-mail Address",
                    render: (data: FirmMember) => {
                        return data.email;
                    }
                },
                {
                    id: "license",
                    title: "Licensed In",
                    render: (data: FirmMember) => {
                        return <LicensesDisplay member={data} />;
                    }
                },
                {
                    id: "tools",
                    title: "",
                    render: (data: FirmMember) => {
                        if (data.isCreator) {
                            return <span />;
                        }
                        return <Button onClick={() => askRemoveMember(data)} disabled={removing}>Remove Member</Button>
                    }
                },
            ]}
        />
    </div>;
}