import { CommercialLeaseTemplateDefinition } from "./commerciallease";
import { CaseTemplate } from "./interfaces";
import { LitigationTemplateDefinition } from "./litigation";
import { RealEstateTemplateDefinition } from "./realestate";
import { WillTemplateDefinition } from "./will";

/**
 * TEMPLATES defines the supported set of templates.
 */
export const TEMPLATES: Record<string, CaseTemplate> = {
  realestate: RealEstateTemplateDefinition,
  commerciallease: CommercialLeaseTemplateDefinition,
  will: WillTemplateDefinition,
  litigation: LitigationTemplateDefinition,
};
