import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";

interface BooleanFieldProps {
  column: string;
  title: string;
  localTitle?: string;
  fullWidth?: boolean;
  onChange?: (value: boolean) => any;
}

export function BooleanField(props: BooleanFieldProps) {
  const [uniqueId] = useState(_uniqueId("field-"));
  const database = useContext(DatabaseContext);
  const row = useContext(ResultContext)!;

  const [localIndex, setLocalIndex] = useState(0);

  const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    row.setField(
      props.column,
      {
        value: event.target.checked ? 1 : 0,
        niceValue: event.target.checked ? "yes" : "no",
        isValid: true,
      },
      {
        title: props.title,
      }
    );

    setLocalIndex(localIndex + 1);

    if (props.onChange) {
      props.onChange(event.target.checked);
    }
  };

  const fgl = (
    <FormControlLabel
      control={
        <Checkbox
          name={uniqueId}
          color={row.hasMutatedValue(props.column) ? "secondary" : "primary"}
          disabled={database?.applying}
          checked={row.getField(props.column) === 1}
          onChange={handleChanged}
        />
      }
      label={props.localTitle || props.title}
    />
  );

  return props.fullWidth ? <div>{fgl}</div> : <span>{fgl}</span>;
}
