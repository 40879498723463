import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useRef, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { isMissingValue } from "../../services/documentservice";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { CurrentAutoFocusManager, useScrollIntoView } from "./autofocus";
import { ParentListFieldContext } from "./ListField";
import { fieldTitle, useFieldStyles, ValueFieldProps } from "./valuefield";

export type AutocompleteFieldProps = ValueFieldProps & {
  prefilledOptions: string[];
};

export function AutocompleteField(props: AutocompleteFieldProps) {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const { tracker } = useDocumentTracker();

  const fallbackValue = props.fallbackColumn
    ? row.getField(props.fallbackColumn)
    : "";
  const value = row.getField(props.column) || fallbackValue;

  const setValue = (value: string) => {
    if (props.fallbackColumn && value === fallbackValue) {
      value = "";
    }

    if (props.maxLength !== undefined && value.length > props.maxLength) {
      return;
    }

    const isValidValue = (props.isValidValue || (() => true))(value);
    row.setField(
      props.column,
      { value: value, isValid: isValidValue },
      {
        title: fieldTitle(props, parentListField),
      }
    );

    setLocalIndex(localIndex + 1);
  };

  const isSaving = database.applying;
  const [open, setOpen] = useState(false);
  const [localIndex, setLocalIndex] = useState(0);

  const { missingColor, missingForDocument, hasMissingValue } =
    tracker.missingForValue(row, props.column, () => isMissingValue(value));
  const classes = useFieldStyles({
    missingColor: missingColor,
  });

  const [uniqueId] = useState(_uniqueId("field-"));

  const autoFocusManager = useContext(CurrentAutoFocusManager);
  const shouldAutoFocus = autoFocusManager.shouldAutoFocus(
    uniqueId,
    hasMissingValue
  );

  const inputRef = useRef<HTMLDivElement>(null);
  useScrollIntoView(inputRef, shouldAutoFocus);

  return (
    <Autocomplete
      clearOnEscape
      blurOnSelect
      freeSolo
      style={props.fullWidth ? undefined : { width: 300 }}
      open={open}
      disabled={isSaving}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event: any, newValue: string | null) => {}}
      value={value}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      getOptionSelected={(option, value) => option === value}
      renderOption={(option) => option}
      getOptionLabel={(option) => option}
      options={props.prefilledOptions}
      fullWidth={props.fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          inputProps={{
            ...params["inputProps"],
            autoComplete: props.column,
            maxLength: props.maxLength,
            "data-missing": hasMissingValue
              ? missingForDocument?.docTemplate.id
              : undefined,
          }}
          classes={{
            root: row.hasMutatedValue(props.column)
              ? classes.edited
              : classes.normal,
          }}
          autoFocus={shouldAutoFocus}
          variant="outlined"
          label={props.placeholder || props.title}
          fullWidth={props.fullWidth}
        />
      )}
    />
  );
}
