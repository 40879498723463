import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as EmailValidator from 'email-validator';
import React from "react";
import { ValueField, ValueFieldProps } from './valuefield';


export function EmailField(props: ValueFieldProps) {
    const ensureEmail = (value: string) => {
        return EmailValidator.validate(value);
    };

    const buildErrorMessage = (value: string) => {
        return `Please enter a valid e-mail address`;
    };

    return <ValueField {...props}
        isValidValue={ensureEmail}
        buildErrorMessage={buildErrorMessage}
        startAdornment={<InputAdornment position="start">
            <FontAwesomeIcon icon={faEnvelope} />
        </InputAdornment>}
        labelWidth={60} />
}