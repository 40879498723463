import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";

/**
 * ConfirmDialogButton is a button in the confirm dialog.
 */
export interface ConfirmDialogButton {
    /**
     * title is the title of the button.
     */
    title: React.ReactNode

    /**
     * value is the value to given to the callback if the button is clicked.
     */
    value: any

    /**
     * color is the color of the button. Default is `default`.
     */
    color?: "default" | "primary" | "secondary"

    /**
     * variant is the variant of the button. Default is `text`.
     */
    variant?: "text" | "outlined" | "contained"
}

export type ConfirmCallback = (value: any, promptValue?: string) => any;

/**
 * ConfirmDialogProps are the props for the confirm dialog.
 */
export interface ConfirmDialogProps {
    /**
     * isOpen indicates whether the ConfirmDialog is currently open.
     */
    isOpen: boolean

    /**
     * handleComplete sets the state that is bound to `isOpen` to false and
     * returns the value clicked. If the [X] is clicked, the `value` will be
     * undefined.
     */
    handleComplete: ConfirmCallback

    /**
     * title is the title of the confirm.
     */
    title: React.ReactNode

    /**
     * content is the content of the confirm.
     */
    content: React.ReactNode

    /**
     * buttons are the buttons on the confirm dialog.
     */
    buttons: ConfirmDialogButton[]

    /**
     * withPrompt, if specified, indicates a prompt should be displayed with
     * the given string as the placeholder.
     */
    withPrompt?: string | WithPromptOptions;
}


/**
 * WithPromptOptions are extended options for confirm dialogs with prompts.
 */
export interface WithPromptOptions {
    value: string
    maxLength?: number
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        prompt: {
            marginTop: theme.spacing(1),
            width: '100%',
        },
    }));


/**
 * ConfigDialog provides a simple confirm-style dialog.
 * @param props The props for the confirm dialog.
 * @example <ConfirmDialog isOpen={showConfirm}
 *                         handleComplete={(value: any) => setShowConfirm(false)}
 *                         title="My confirm" content="Hi there!"
 *                         buttons={[{"title": "Close", "value": undefined}]}/>
 */
export function ConfirmDialog(props: ConfirmDialogProps) {
    const [promptValue, setPromptValue] = useState('');
    const classes = useStyles();

    const handleComplete = (value: any) => {
        props.handleComplete([value, promptValue]);
        setPromptValue('');
    };

    return <Dialog
        open={props.isOpen}
        onClose={() => handleComplete(undefined)}
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.content}
                {props.withPrompt !== undefined &&
                    <TextField placeholder={typeof props.withPrompt === 'string' ? props.withPrompt : props.withPrompt.value}
                        className={classes.prompt}
                        variant="outlined"
                        value={promptValue}
                        inputProps={{ maxLength: typeof props.withPrompt === 'string' ? undefined : props.withPrompt.maxLength }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPromptValue(event.target.value)} />
                }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {props.buttons.map((button: ConfirmDialogButton, index: number) => {
                return <Button key={index} variant={button.variant}
                    onClick={() => handleComplete(button.value)}
                    color={button.color}
                    disabled={props.withPrompt && !promptValue.length && button.value}
                    autoFocus={index === props.buttons.length - 1}>
                    {button.title}
                </Button>;
            })}
        </DialogActions>
    </Dialog>;
}