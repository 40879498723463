import React, { useContext, useState } from "react";
import { RowRenderer } from "../../database/diffable/components";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import {
  DiffableDatabase,
  MutableRow,
} from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import { Address } from "../../sharedschema/address";
import { CollapseField } from "./CollapseField";
import { ParentListFieldContext } from "./ListField";
import { fieldTitle, ValueField, WithTitleOrPlaceholder } from "./valuefield";

export type AddressFieldProps = AddressEditorFieldProps & {
  column: string;
};

export type AddressEditorFieldProps = WithTitleOrPlaceholder<{
  fullWidth?: boolean;

  copyFromTitle?: string;
  copyFrom?: (database: DiffableDatabase) => MutableRow<Address>;
}>;

/**
 * AddressField represents a field over an Address.
 */
export function AddressField(props: AddressFieldProps) {
  const row = useContext(ResultContext)!;
  const addressId = row!.getField(props.column);

  return (
    <RowRenderer<Address>
      query={sql`select * from ${"address"} where id=${addressId}`}
    >
      {(row: Address) => {
        return <AddressEditorField {...props} />;
      }}
    </RowRenderer>
  );
}

/**
 * AddressEditorField represents an editor over an address.
 */
export function AddressEditorField(props: AddressEditorFieldProps) {
  const database = useContext(DatabaseContext);
  const row = useContext(ResultContext)!;
  const parentListField = useContext(ParentListFieldContext);

  const [localIndex, setLocalIndex] = useState(0);

  const summarize = (row: MutableRow<Address>) => {
    const address = row.getField("address");
    const address2 = row.getField("address2");
    const city = row.getField("city");
    const county = row.getField("county");
    const state = row.getField("state");
    const zipcode = row.getField("zipcode");

    return [address, address2, city, county, state, zipcode]
      .filter((p: string) => !!p)
      .join(", ");
  };
  const handleCopy = () => {
    if (props.copyFrom === undefined) {
      return;
    }

    const address = props.copyFrom(database!);
    const title = fieldTitle(props, parentListField);

    row.setField(
      "address",
      { value: address.getField("address"), isValid: true },
      {
        title: title,
      }
    );

    row.setField(
      "address2",
      { value: address.getField("address2"), isValid: true },
      {
        title: title,
      }
    );

    row.setField(
      "city",
      { value: address.getField("city"), isValid: true },
      {
        title: title,
      }
    );

    row.setField(
      "county",
      { value: address.getField("county"), isValid: true },
      {
        title: title,
      }
    );

    row.setField(
      "state",
      { value: address.getField("state"), isValid: true },
      {
        title: title,
      }
    );

    row.setField(
      "zipcode",
      { value: address.getField("zipcode"), isValid: true },
      {
        title: title,
      }
    );

    setLocalIndex(localIndex + 1);
  };

  return (
    <CollapseField<Address>
      fullWidth={props.fullWidth}
      summarize={summarize}
      buttonTitle={props.copyFromTitle}
      buttonHandler={handleCopy}
      {...props}
    >
      <ValueField
        fullWidth
        column="address"
        placeholder="Address"
        title={`${props.title}: Address`}
      />
      <ValueField
        fullWidth
        column="address2"
        placeholder="Address 2"
        title={`${props.title}: Address 2`}
      />
      <ValueField
        fullWidth
        column="city"
        placeholder="City"
        title={`${props.title}: City`}
      />
      <ValueField
        fullWidth
        column="county"
        placeholder="County"
        title={`${props.title}: County`}
      />
      <ValueField
        fullWidth
        column="state"
        placeholder="State"
        title={`${props.title}: State`}
      />
      <ValueField
        fullWidth
        column="zipcode"
        placeholder="Zip Code"
        title={`${props.title}: Zip Code`}
      />
    </CollapseField>
  );
}
