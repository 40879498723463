import { faArrowCircleDown, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import moment from 'moment';
import ordinal from 'ordinal';
import React, { useContext, useState } from "react";
import { DatabaseContext, ResultContext } from "../../database/diffable/context";
import { useMutationWatcher } from '../../database/diffable/hooks';
import { useDocumentTracker } from '../documents/DocumentTracker';
import { Expando } from './../Expando';

/**
 * DocumentDateFieldProps are the props for a document date field.
 */
export interface DocumentDateFieldProps {
    /**
     * title is the title of the document date field.
     */
    title: string

    /**
     * column is the name of the bound column.
     */
    column: string

    /**
     * fullWidth is whether the field should be displayed in full width.
     */
    fullWidth?: boolean
}


interface styleProps {
    missingColor: string | undefined
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        normal: {
            position: 'relative',
            '& > fieldset': {
                borderColor: (props: styleProps) => props.missingColor ? props.missingColor : '',
                borderWidth: (props: styleProps) => props.missingColor ? 2 : 1,
            }
        },
        editing: {
            '& > fieldset': {
                borderColor: theme.palette.primary.light,
                borderWidth: 2,
            }
        },
        edited: {
            '& > fieldset': {
                borderColor: theme.palette.warning.main,
                borderWidth: 2,
            }
        },
        outline: {
            borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            borderRadius: 4
        },
        summary: {
            padding: theme.spacing(2),
            fontSize: '1.1876em',
            cursor: 'pointer',
            paddingRight: theme.spacing(6),
            borderRadius: 4,
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        emptySummary: {
            color: 'transparent'
        },
        summaryOpen: {
            backgroundColor: theme.palette.background.default,
        },
        content: {
            padding: theme.spacing(2),
            '& > div': {
                marginRight: theme.spacing(1.5),
            }
        },
        adornment: {
            position: 'absolute',
            top: theme.spacing(1.85),
            right: theme.spacing(2),
        },
        adornmentButton: {
            height: '2em',
            marginTop: theme.spacing(-0.15),
            marginRight: theme.spacing(2)
        }
    }));


function serializeToString(month: number, day: number, year: number): string {
    const newValue: ParsedDocDate = { 'm': month, 'd': day, 'y': year };
    return JSON.stringify(newValue);
}

export const summarizeDocDate = (month: number, day: number, year: number) => {
    return `${day ? ordinal(day) : '__'} day of ${month ? moment.months()[month - 1] : '______'}, ${year || '____'}`;
};

export function deserializeFromString(value: string): ParsedDocDate {
    try {
        return JSON.parse(value);
    } catch (e) {
        return {
            m: 0,
            d: 0,
            y: 0
        }
    }
}

interface ParsedDocDate {
    m: number
    d: number
    y: number
}

function range(start: number, end: number): number[] {
    const r = [];
    for (var i = start; i <= end; ++i) {
        r.push(i);
    }
    return r;
}


/**
 * DocumentDateField defines a field for defining a document date, which may be
 * fully specified or have portions left intentionally blank.
 */
export function DocumentDateField(props: DocumentDateFieldProps) {
    const { tracker } = useDocumentTracker();

    const mutableRow = useContext(ResultContext)!;
    const database = useContext(DatabaseContext)!;

    // Ensure we get updated on database mutations.
    useMutationWatcher(database!, 50);

    const [open, setOpen] = React.useState(false);
    const [localIndex, setLocalIndex] = useState(0);

    const currentValue = deserializeFromString(mutableRow.getField(props.column) ?? '');
    const summary = summarizeDocDate(currentValue.m, currentValue.d, currentValue.y);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            setOpen(!open);
        }
    };

    // Check for highlighting based on a missing child.
    let missingColor = undefined;
    const found = tracker.documentForField(mutableRow.tableName, mutableRow.id(), props.column);
    if (found) {
        missingColor = found.color;
    }

    const hasMutatedValue = mutableRow.hasMutatedValue(props.column);
    const classes = useStyles({ missingColor: missingColor });

    const handleMonthChange = (event: any, value: number | null) => {
        update(currentValue.y, value ?? 0, currentValue.d);
    };

    const handleDayChange = (event: any, value: number | null) => {
        update(currentValue.y, currentValue.m, value ?? 0);
    };

    const handleYearChange = (event: any, value: number | null) => {
        update(value ?? 0, currentValue.m, currentValue.d);
    };

    const update = (year: number, month: number, day: number) => {
        day = month > 0 && year > 0 ? day : 0;
        const serialized = serializeToString(month, day, year);
        mutableRow.setField(props.column, {
            'value': serialized,
            'niceValue': summarizeDocDate(month, day, year),
            'isValid': true
        }, {
            title: props.title,
        });
        setLocalIndex(localIndex + 1);
    };

    const handleMakeToday = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const now = moment();
        update(now.year(), now.month() + 1, now.date());
    };

    const handleMakeThisMonth = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const now = moment();
        update(now.year(), now.month() + 1, 0);
    };

    const dayRange = currentValue.y !== 0 && currentValue.m !== 0 ? range(1, moment(`${currentValue.y}-${currentValue.m}`, 'YYYY-MM').daysInMonth()) : [];

    return <div
        className={clsx(classes.normal, {
            [classes.edited]: hasMutatedValue,
            [classes.editing]: open,
        })}
    >
        <InputLabel style={{ position: 'absolute' }} shrink={!!summary.length} variant="outlined">
            {props.title}
        </InputLabel>
        <div tabIndex={0} className={clsx(classes.summary, { [classes.summaryOpen]: open, [classes.emptySummary]: !summary, })} onKeyPress={handleKeyPress} onClick={toggleOpen} >{summary || '|'}</div>
        <NotchedOutline className={classes.outline} labelWidth={props.title.length * 14} /*label={props.title}*/ notched={!!summary.length} />
        <Expando open={open}>
            <div className={clsx(classes.content)}>
                <Autocomplete
                    fullWidth={false}
                    value={currentValue.y}
                    onChange={handleYearChange}
                    style={{ width: 120, display: 'inline-block' }}
                    options={[0, ...range(moment().year() - 50, moment().year() + 10)]}
                    getOptionLabel={(option) => option === 0 ? '(None)' : option.toString()}
                    renderInput={(params) => <TextField {...params} label="Year" />}
                />
                <Autocomplete
                    fullWidth={false}
                    value={currentValue.m}
                    onChange={handleMonthChange}
                    style={{ width: 150, display: 'inline-block' }}
                    options={[0, ...range(1, 12)]}
                    getOptionLabel={(option) => option === 0 ? '(None)' : moment.months()[option - 1]}
                    renderInput={(params) => <TextField {...params} label="Month" />}
                />
                <Autocomplete
                    fullWidth={false}
                    value={currentValue.d}
                    onChange={handleDayChange}
                    style={{ width: 120, display: 'inline-block' }}
                    options={[0, ...dayRange]}
                    getOptionLabel={(option) => option === 0 ? '(None)' : option.toString()}
                    renderInput={(params) => <TextField {...params} label="Day" />}
                />
            </div>
        </Expando>
        <div className={classes.adornment} onClick={toggleOpen} >
            <Button className={classes.adornmentButton} variant="contained" color="primary" onClick={handleMakeToday}>Make Today</Button>
            <Button className={classes.adornmentButton} variant="contained" color="primary" onClick={handleMakeThisMonth}>Make This Month</Button>
            <FontAwesomeIcon icon={open ? faArrowCircleDown : faArrowCircleRight} size="lg" />
        </div>
    </div>;
}