import { gql } from "@apollo/client";
import { WithErrors, WITH_ERRORS_FRAGMENT } from "./lib/hooks";


/**
 * Sets the payment method for the firm.
 */
export const UPDATE_PAYMENT_METHOD = {
  gql: gql`
 mutation UpdatePaymentMethod($firmId: ID!, $paymentMethodId: String!) {
  updatePaymentMethod(firmId: $firmId, paymentMethodId: $paymentMethodId) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Setting billing information',
  errorMessage: 'Could not update payment method',
  refetchQueries: ['LookupFirmBilling'],
};


export interface UpdatePaymentMethodData {
  firmId: string
  paymentMethodId: string
}

/**
 * Performs lookup of the billing info for the firm.
 */
export const LOOKUP_FIRM_BILLING_INFO = {
  gql: gql`
 query LookupFirmBilling($firmId: ID!) {
  firmById(firmId: $firmId) {
    id
    billing {
      cardBrand
      last4
      portalUrl
    }
  }
 }`,
  workingMessage: 'Looking up billing information',
  errorMessage: 'Could not lookup billing information',
};


/**
 * Performs lookup of the billing info for the firm.
 */
export const LOOKUP_FIRM_BILLING_INFO_WITH_PORTAL = {
  gql: gql`
 query LookupFirmBilling($firmId: ID!) {
  firmById(firmId: $firmId) {
    id
    billing {
      cardBrand
      last4
      portalUrl
    }
  }
 }`,
  workingMessage: 'Looking up billing information',
  errorMessage: 'Could not lookup billing information',
};


export interface BillingInfo {
  firmById: {
    billing?: {
      cardBrand: string
      last4: string
      portalUrl?: string
    }
  }
}


/**
 * Lists the members of a firm.
 * 
 * @param firmId The ID of the firm.
 */
export const LIST_FIRM_MEMBERS = gql`
 query ListFirmMembers($firmId: ID!, $first: Int!, $after: String, $filter: String) {
   firmById(firmId: $firmId) {
     members(first: $first, after: $after, filter: $filter) {
       pageInfo {
         hasNextPage
         endCursor
       }
       edges {
         node {
           id
           name
           email
           avatarUrl
           isCreator
           licenses {
             id
             stateId
             attorneyId
           }
         }
       }
     }
   }
 }  
`;

/**
* Performs lookup of a member of a firm.
*/
export const LOOKUP_FIRM_MEMBER = gql`
query LookupFirmMember($memberId: ID!) {
 firmMemberById(memberId: $memberId) {
   id
   name
   email
   avatarUrl
   licenses {
     id
     stateId
     attorneyId
   }
 }
}  
`;


/**
* Performs autocompletion over the attorneys in a firm.
*/
export const AUTOCOMPLETE_FIRM_ATTORNEYS = gql`
query ListFirmMembers($firmId: ID!, $prefix: String!) {
 firmById(firmId: $firmId) {
   attorneys(prefix: $prefix) {
     id
     name
     email
     avatarUrl
     licenses {
       id
       stateId
       attorneyId
     }
   }
 }
}  
`;

/**
* Mutation: Adds a license.
* 
* @param stateId The state ID for the license.
* @param attorenyId the attorney ID for the license.
*/
export const ADD_LICENSE = {
  gql: gql`
 mutation AddLicense($stateId: String!, $attorneyId: String!) {
   addAttorneyLicense(stateId: $stateId, attorneyId: $attorneyId) {
     ${WITH_ERRORS_FRAGMENT}
   }
 }
`,
  workingMessage: 'Adding license',
  errorMessage: 'Could not add license',
  refetchQueries: ['GetAuthenticatedUser'],
};

export interface AddLicenseData {
  attorneyId: string
  stateId: string
}


/**
* Mutation: Removes a license.
* 
* @param stateId The state ID for the license.
*/
export const REMOVE_LICENSE = {
  gql: gql`
 mutation RemoveLicense($stateId: String!) {
   removeAttorneyLicense(stateId: $stateId) {
     ${WITH_ERRORS_FRAGMENT}
   }
 }
`,
  workingMessage: 'Remove license',
  errorMessage: 'Could not remove license',
  refetchQueries: ['GetAuthenticatedUser'],
};

export interface RemoveLicenseData {
  stateId: string
}


/**
 * Updates the details for the firm.
 */
export const UPDATE_FIRM_DETAILS = {
  gql: gql`
 mutation UpdateFirmDetails($firmId: ID!, $color: String!, $logoData: String, $title: String!, $address: String!, $phone: String!, $fax: String!) {
  updateFirmDetails(firmId: $firmId, color: $color, logoData: $logoData, title: $title, address: $address, phone: $phone, fax: $fax) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Updating firm details information',
  errorMessage: 'Could not update firm details',
  refetchQueries: ['GetAuthenticatedUser'],
};


export interface UpdateFirmDetailsParams {
  firmId: string
  color: string
  logoData: string | undefined
  title: string
  address: string
  phone: string
  fax: string
}


/**
 * Updates the allowed flows for a firm.
 */
export const UPDATE_ALLOWED_FLOWS = {
  gql: gql`
 mutation UpdateAllowedFlows($firmId: ID!, $flowIds: [String!]!) {
  updateAllowedFlows(firmId: $firmId, flowIds: $flowIds) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Updating allowed case types',
  errorMessage: 'Could not update allowed case types',
  refetchQueries: ['GetAuthenticatedUser'],
};


export interface UpdateAllowedFlowsParams {
  firmId: string
  flowIds: string[]
}

/**
 * Invites a member to a firm.
 */
export const INVITE_MEMBER_TO_FIRM = {
  gql: gql`
 mutation InviteMemberToFirm($firmId: ID!, $email: String!) {
  inviteMemberToFirm(firmId: $firmId, email: $email) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Inviting member to firm',
  errorMessage: 'Could not invite member to a firm',
};


export interface InviteMemberToFirmParams {
  firmId: string
  email: string
}

/**
 * Removes a member from a firm.
 */
export const REMOVE_MEMBER_FROM_FIRM = {
  gql: gql`
 mutation RemoveMemberFromFirm($firmId: ID!, $memberId: String!) {
  removeMemberFromFirm(firmId: $firmId, memberId: $memberId) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Removing member from firm',
  errorMessage: 'Could not remove member from the firm',
  refetchQueries: ['ListFirmMembers'],
};


export interface RemoveMemberFromFirmParams {
  firmId: string
  memberId: string
}


/**
 * Accepts an invitation to join a firm.
 */
export const ACCEPT_INVITATION = {
  gql: gql`
 mutation AcceptInvitation($code: String!) {
  acceptInvitation(code: $code) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Accepting invitation to join firm',
  errorMessage: 'Could not accept invitation',
  refetchQueries: ['GetAuthenticatedUser'],
};


export interface AcceptInvitationParams {
  code: string
}

export type AcceptInvitationResponse = {
  acceptInvitation: WithErrors
}


/**
 * Creates a new firm.
 */
export const CREATE_FIRM = {
  gql: gql`
 mutation CreateFirm($title: String!, $address: String!, $phone: String!, $fax: String!, $color: String!, $logo: String, $payment: String!, $invitations: [String!]!) {
  createFirm(title: $title, address: $address, phone: $phone, fax: $fax, color: $color, logo: $logo, payment: $payment, invitations: $invitations) {
    ${WITH_ERRORS_FRAGMENT}
  }
 }`,
  workingMessage: 'Creating firm',
  errorMessage: 'Could not create firm',
  refetchQueries: ['GetAuthenticatedUser'],
};


export interface CreateFirmParams {
  title: string
  address: string
  phone: string
  fax: string
  color: string
  logo: string | undefined
  payment: string
  invitations: string[]
}
