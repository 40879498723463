import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ColorHash from "color-hash";
import React from "react";
import { RecentCaseReference } from '../services/recentcases';
import { Case, CaseAccess } from '../types/case';

/**
 * Defines the properties for the CaseReference.
 */
interface CaseReferenceProps {
    case: RecentCaseReference | Case
    variant?: "subtitle2" | "subtitle1"
}

interface styleProps {
    caseColor: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userView: {
            display: 'flex',
            alignItems: 'center'
        },
        avatar: {
            backgroundColor: (props: styleProps) => props.caseColor,
            color: (props: styleProps) => theme.palette.getContrastText(props.caseColor),
            width: '1.5rem',
            height: '1.5rem',
            marginRight: theme.spacing(1),
        },
        restricted: {
            marginLeft: theme.spacing(1)
        }
    }));

export function getCaseTemplateColor(templateId: string) {
    const colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    return colorHash.hex(templateId);
}

export function CaseIcon(props: CaseReferenceProps) {
    const caseColor = props.case.color ?? getCaseTemplateColor(props.case.templateId);
    const classes = useStyles({ 'caseColor': caseColor });

    return <Avatar variant="rounded" aria-label="case icon" className={classes.avatar}>
        {props.case.title[0].toUpperCase()}
    </Avatar>;
}

/**
 * CaseReference displays an icon and username for the case.
 */
export default function CaseReference(props: CaseReferenceProps) {
    const caseColor = props.case.color ?? getCaseTemplateColor(props.case.templateId);
    const classes = useStyles({ 'caseColor': caseColor });
    return <div className={classes.userView}>
        <CaseIcon case={props.case} />
        <Typography variant={props.variant ?? "subtitle2"}>{props.case.title}</Typography>
        {props.case.caseAccess === CaseAccess.RESTRICTED &&
            <FontAwesomeIcon className={classes.restricted} icon={faLock} />}
    </div>;
}
