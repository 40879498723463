import { DiffableDatabase, MutableRow } from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import { Address, Entity, EntityKind } from "./model";

/**
 * loadAddressForEntity loads the address from the database for an entity of the given kind.
 * @param database The database from which to load the address.
 * @param entityKind The kind of entity for which to load the associated address row.
 */
export function loadAddressForEntity(database: DiffableDatabase, entityKind: EntityKind): MutableRow<Address> {
    const entity = database.selectAllResults<Entity>(sql`select * from ${'entity'} where kind=${entityKind}`)[0];
    return database.selectAllResults<Address>(sql`select * from ${'address'} where id=${entity.row.addressId}`)[0];
}