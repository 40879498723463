import { rowsForBindings } from "../components/CaseNavLink";
import { DocumentTracker } from "../components/documents/DocumentTracker";
import { MISSING_FIRM_CASE_REFERENCE_KEY } from "../components/SettingsPane";
import { DiffableDatabase } from "../database/diffable/interfaces";
import { TEMPLATES } from "../templates";
import { Panel } from "../templates/uibuilder";
import { Case } from "../types/case";
import { DocumentTemplate } from "../types/documentemplate";

const CUSTOM_PANEL_MAPPINGS: Record<string, string> = {
    [MISSING_FIRM_CASE_REFERENCE_KEY]: 'settings'
}

/**
 * firstPanelForMissing returns the first panel found for the given case
 * that has a field currently marked as missing in the tracker.
 * @returns The panel found or undefined if none.
 */
export function firstPanelForMissing(docTemplate: DocumentTemplate, cse: Case, tracker: DocumentTracker, database: DiffableDatabase): { path: string } | undefined {
    if (!tracker.hasDocuments()) {
        return undefined;
    }

    const foundCustom = Object.keys(CUSTOM_PANEL_MAPPINGS).find((customKey: string) => {
        return tracker.getDocument(docTemplate)?.missing.hasCustomMissing(customKey);
    });
    if (foundCustom !== undefined) {
        return { 'path': CUSTOM_PANEL_MAPPINGS[foundCustom] };
    }

    const template = TEMPLATES[cse.templateId!];
    return template.panels.find((panel: Panel) => {
        const found = panel.customBindingKeys?.find((key: string) => {
            return tracker.getDocument(docTemplate)?.missing.hasCustomMissing(key);
        });
        if (found !== undefined) {
            return true;
        }

        const daiTables = template.dataAccess[panel.path];
        if (daiTables === undefined) {
            return false;
        }

        for (const dai of Object.values(daiTables)) {
            if (dai.isFullTableAccess) {
                // Check general access.
                for (const fieldName of dai.fieldsAccessed) {
                    const dtg = tracker.documentForColumn(dai.tableName, fieldName);
                    if (dtg !== undefined) {
                        return true;
                    }
                }
            } else {
                // Check specific access.
                const rows = rowsForBindings(panel.tableBindings, database)[dai.tableName];
                if (rows?.length > 0) {
                    for (const fieldName of dai.fieldsAccessed) {
                        for (const row of rows) {
                            const dtg = tracker.documentForField(dai.tableName, row.id(), fieldName);
                            if (dtg !== undefined) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    });
}
