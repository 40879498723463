/**
 * PreparedQuery holds the results of a sql template string.
 */
export interface PreparedQuery {
    /**
     * query is the query string, with values replaced with `:param` references.
     */
    query: string

    /**
     * params are the named parameters for the query string. For each parameter found
     * in this map, there should be a reference in the query string of the form `:key`.
     */
    params: Record<string, any>

    /**
     * tableName is the name over which the query is executing.
     */
    tableName: string
}

/**
 * IDed ensures that a result has an `id` field.
 */
export interface IDed {
    id: number
}

export class Unparametrized {
    constructor(public name: string) {

    }
}

export function literal(value: string) {
    return new Unparametrized(value)
}

/**
 * sql is a template string producer for creating escaped SQL statements.
 * *MAKE SURE* the table name is the first placeholder.
 * @example sql`select * from ${'table'} where field == ${value}`
 */
export function sql(literals: TemplateStringsArray, ...placeholders: any[]): PreparedQuery {
    let query = '';
    let params: Record<string, any> = {};

    literals.forEach((queryString, index) => {
        query += queryString

        if (index === 0) {
            query += placeholders[0]
        } else if (index < placeholders.length) {
            if (placeholders[index] instanceof Unparametrized) {
                query += placeholders[index].name
            } else {
                const paramName = `:param${index}`
                params[paramName] = placeholders[index]
                query += paramName
            }
        }
    });

    return {
        tableName: placeholders[0],
        query: query,
        params: params,
    }
}

/**
 * Ensures that the given query is a select query.
 */
export function ensureIsSelectQuery(query: PreparedQuery) {
    if (!query.query.toLowerCase().startsWith('select ')) {
        throw Error(`Must be used with a select query. Found: ${query.query}`);
    }
}