import React, { RefObject, useEffect, useState } from "react";

export class AutoFocusManager {
  constructor(private hasFocused: string | undefined = undefined) {}

  public shouldAutoFocus(componentId: string, ...args: boolean[]): boolean {
    if (this.hasFocused && this.hasFocused !== componentId) {
      return false;
    }

    for (let value of args) {
      if (value) {
        this.hasFocused = componentId;
        return true;
      }
    }

    return false;
  }
}

/**
 * CurrentAutoFocusManager holds the current auto focus manager.
 */
export const CurrentAutoFocusManager = React.createContext<AutoFocusManager>(
  new AutoFocusManager()
);
CurrentAutoFocusManager.displayName = "CurrentAutoFocusManager";

export function useScrollIntoView(
  inputRef: RefObject<HTMLDivElement>,
  autofocused: boolean
) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (
      inputRef.current &&
      autofocused &&
      !scrolled &&
      inputRef.current.scrollIntoView // Can be undefined in tests
    ) {
      setScrolled(true);
      inputRef.current.scrollIntoView();
    }
  }, [inputRef, autofocused, scrolled]);
}
