import InputAdornment from '@material-ui/core/InputAdornment';
import React from "react";
import { ValueField, ValueFieldProps } from './valuefield';

export function PercentageField(props: ValueFieldProps) {
    const ensurePercentage = (value: string) => {
        const parsed = Number(value);
        if (isNaN(parsed) || value.endsWith(".")) {
            return false;
        }

        return parsed >= 0 && parsed <= 100;
    };

    const buildErrorMessage = (value: string) => {
        return `Please enter a valid percentage, between 0 and 100 (without the percent sign)`;
    };

    return <ValueField {...props}
        isValidValue={ensurePercentage}
        buildErrorMessage={buildErrorMessage}
        startAdornment={<InputAdornment position="start">%</InputAdornment>}
        labelWidth={60} />
}