import { MISSING_SIGNER_KEY } from "../../components/PleadingAttorneyManager";
import {
  DiffableDatabase,
  MutableRow,
} from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import {
  DocDataResult,
  DocumentDataBuilder,
  estr,
  FirmMemberLookup,
  TrackedDatabase,
  TrackedDatabaseState,
} from "../../services/documentservice";
import { IsKnownState, StateTitle } from "../../services/states";
import { interestForRowOrUndefined } from "../../shareddata/interest";
import {
  combinedCurrency,
  currency,
  date,
  documentDate,
  exportAddress,
  exportAllAddresses,
  firmFields,
  fullMultilineAddress,
  joinMultiple,
  percentage,
} from "../../shareddata/shareddata";
import { Address } from "../../sharedschema/address";
import { Case } from "../../types/case";
import { AttorneyLicense } from "../../types/license";
import { BitwiseSet } from "../../util/bitwiseset";
import { enumValues } from "../../util/enum";
import {
  Affiant,
  ContractTerms,
  LitigationCase,
  LitigationCaseParty,
  Motion,
  MotionEntity,
  MotionEntityType,
  Party,
  PartyFormDescription,
  PartyKind,
  PartyKindDescription,
  PleadingAttorney,
} from "./model";
import { MISSING_ACTIVE_MOTION_KEY } from "./ui";

function signerCheck(cse: Case, db: DiffableDatabase): [string, boolean] {
  const current = db.selectAllResults<PleadingAttorney>(
    sql`select * from ${"pleadingattorney"}`
  );
  const signer = current.filter(
    (att: MutableRow<PleadingAttorney>) => att.getField("isSigner") === 1
  );
  if (signer.length > 0) {
    return ["", true];
  }

  return ["Please select at least one attorney as a signer", false];
}

async function getSignerFullName(
  db: TrackedDatabase,
  memberLookup: FirmMemberLookup
): Promise<DocDataResult> {
  const signer = db.getOrUndefined<PleadingAttorney>(
    sql`select * from ${"pleadingattorney"} where isSigner=1`
  );
  if (signer === undefined) {
    db.addCustomMissingField({
      key: MISSING_SIGNER_KEY,
      checker: signerCheck,
    });
    return "";
  }

  const signerData = await memberLookup(signer.userId);
  if (signerData === undefined) {
    return {
      message: `Signer ${signer.fullName} is no longer a valid member of the firm`,
    };
  }

  if (!signerData.name) {
    return "";
  }

  return `${signerData.name}, Esquire`;
}

async function getAttorneyId(
  db: TrackedDatabase,
  activeLitigationCase: LitigationCase | undefined,
  memberLookup: FirmMemberLookup
): Promise<DocDataResult> {
  if (!activeLitigationCase) {
    return "";
  }

  const courtState = activeLitigationCase.state;
  if (!IsKnownState(courtState)) {
    return {
      message: `Entered court state ${courtState} is not valid`,
    };
  }

  const signer = db.getOrUndefined<PleadingAttorney>(
    sql`select * from ${"pleadingattorney"} where isSigner=1`
  );
  if (signer === undefined) {
    db.addCustomMissingField({
      key: MISSING_SIGNER_KEY,
      checker: signerCheck,
    });
    return "";
  }

  const signerData = await memberLookup(signer.userId);
  if (signerData === undefined) {
    if (db.state() === TrackedDatabaseState.HAS_UNSAVED_CHANGED) {
      return "";
    }

    return {
      message: `Signer ${signer.fullName} is no longer a valid member of the firm`,
    };
  }

  // Ensure the signer has a license in that state.
  const foundLicenses = signerData.licenses.filter(
    (license: AttorneyLicense) =>
      license.stateId.toLowerCase() === courtState.toLowerCase()
  );
  if (!foundLicenses.length) {
    if (db.state() === TrackedDatabaseState.HAS_UNSAVED_CHANGED) {
      return "";
    }

    return {
      message: `Chosen signer ${
        signerData.name
      } is not marked as licensed in ${StateTitle(courtState)}`,
    };
  }

  const foundLicense = foundLicenses[0];
  return foundLicense.attorneyId;
}

export async function constructDocumentData(
  cse: Case,
  db: TrackedDatabase,
  memberLookup: FirmMemberLookup
): Promise<
  [Record<string, DocumentDataBuilder> | undefined, string | undefined]
> {
  const activeLitigationCase = db.getOrUndefined<LitigationCase>(
    sql`select * from ${"litigationcase"} where isActive=1`
  );

  if (!activeLitigationCase) {
    return [undefined, "Please mark a case as active to generate documents"];
  }

  const parties = db.list<Party>(sql`select * from ${"party"}`);
  const affiant = db.get<Affiant>(sql`select * from ${"affiant"}`);

  return [
    {
      Attorney_ID: async () =>
        await getAttorneyId(db, activeLitigationCase, memberLookup),
      AttorneySignerName: async () => await getSignerFullName(db, memberLookup),
      AttorneySigner: async () =>
        estr`${await getSignerFullName(
          db,
          memberLookup
        )} (#${await getAttorneyId(db, activeLitigationCase, memberLookup)})`,
      AttorneyFilers: async () =>
        estr`${await getSignerFullName(
          db,
          memberLookup
        )} (#${await getAttorneyId(db, activeLitigationCase, memberLookup)})`,

      ...firmFields(cse, db),
      ...litigationCaseData(db, activeLitigationCase, parties),
      ...exportMotion(db),
      Jury_Trial: async () =>
        activeLitigationCase?.isJuryTrial ? "Jury Trial Demanded" : "",

      // Affiant.
      Affiant_Name: async () => affiant.fullName,
      Affiant_Title: async () => affiant.title,
      Affiant_Pronoun: async () => affiant.pronoun,
    },
    undefined,
  ];
}

function exportMotion(
  db: TrackedDatabase
): Record<string, DocumentDataBuilder> {
  const getActiveMotion = () => {
    const motions = db.listMutable<Motion>(sql`select * from ${"motion"}`);
    const found = motions.filter(
      (m: MutableRow<Motion>) => m.getField("isActive") === 1
    );
    return found.length === 1 ? found[0].row : undefined;
  };

  const activeMotion = getActiveMotion();
  if (activeMotion === undefined) {
    return {
      Complaint_Date: async () => {
        db.addCustomMissingField({
          key: MISSING_ACTIVE_MOTION_KEY,
          checker: () => {
            const active = getActiveMotion();
            if (active === undefined) {
              return ["Missing single active motion", false];
            }

            return ["", true];
          },
        });
        return "";
      },
      Answer_Date: async () => "",
      Discovery_Date: async () => "",
      Discovery_End_Date: async () => "",
      Order_Date: async () => "",
      Movant_List: async () => "",
      Respondent_List: async () => "",
    };
  }

  const movants = db.list<MotionEntity>(
    sql`select * from ${"motionentity"} where type=${
      MotionEntityType.MOVANT
    } and motionId=${activeMotion.id}`
  );
  const respondents = db.list<MotionEntity>(
    sql`select * from ${"motionentity"} where type=${
      MotionEntityType.RESPONDENT
    } and motionId=${activeMotion.id}`
  );
  return {
    Complaint_Date: async () => date(activeMotion.complaintDate),
    Answer_Date: async () => date(activeMotion.answerDate),
    Discovery_Date: async () => date(activeMotion.discoveryDate),
    Discovery_End_Date: async () => date(activeMotion.discoveryEndDate),
    Order_Date: async () => documentDate(activeMotion.orderDate),
    Movant_List: async () =>
      joinMultiple(movants, "", "", (item: MotionEntity) => {
        return item.name;
      }),
    Respondent_List: async () =>
      joinMultiple(respondents, "", "", (item: MotionEntity) => {
        return item.name;
      }),
  };
}

function litigationCaseData(
  db: TrackedDatabase,
  activeLitigationCase: LitigationCase | undefined,
  parties: Party[]
): Record<string, DocumentDataBuilder> {
  if (!activeLitigationCase) {
    return {};
  }

  const litigationCaseParties = db.list<LitigationCaseParty>(
    sql`select * from ${"litigationcaseparty"} where caseId=${
      activeLitigationCase.id
    }`
  );

  const partiesById = Object.fromEntries(parties.map((p) => [p.id, p]));
  const caseData: Record<string, DocumentDataBuilder> = {};

  caseData["Court_State"] = async () => activeLitigationCase.state;
  caseData["Court_County"] = async () => activeLitigationCase.county;
  caseData["Court_Name"] = async () => activeLitigationCase.court;
  caseData["Docket_Number"] = async () => activeLitigationCase.docketNumber;
  caseData["Bar_Association"] = async () => activeLitigationCase.barAssociation;

  enumValues(PartyKind).forEach((kind: PartyKind) => {
    if (kind === PartyKind.UNKNOWN) {
      return;
    }

    const matchingCaseParties = litigationCaseParties.filter((lcp) =>
      BitwiseSet.encodedHas(lcp.partyKind, kind)
    );

    const matchingParties = matchingCaseParties.map(
      (lcp) => partiesById[lcp.partyId]
    );

    const title = PartyKindDescription[kind];
    caseData[`${title}_List_Caption`] = listCaption(
      db,
      matchingParties,
      activeLitigationCase.showAddressesInCaption === 1,
      activeLitigationCase.showOnlyFirstPartyInCaption === 1
    );

    caseData[`${title}_List`] = async () =>
      matchingParties.map((p) => p.fullName).join(" and ");

    for (let i = matchingCaseParties.length; i < 20; i++) {
      matchingCaseParties.push({
        id: -1,
        caseId: activeLitigationCase.id,
        partyId: -1,
        contractId: -1,

        partyKind: kind,

        initialServiceDate: "",
        initialResponseDueDate: "",
        noticeOfDefaultDate: "",
        judgementDate: "",

        complaintAmount: null,
        ongoingAmounts: null,
        additionalAttorneysFees: null,

        writSheriff: "",
        writPrincipal: null,
        writInterestRate: null,
        writInterestEndDate: "",
        writInterest: null,
        writCosts: null,
        writFees: null,
      });
    }

    matchingCaseParties.forEach((lcp, index) => {
      const p = lcp.partyId > 0 ? partiesById[lcp.partyId] : undefined;

      const serviceAddresses = db.list<Address>(
        sql`select * from ${"serviceaddress"} where partyId=${lcp.partyId}`
      );

      const kindIndex = index + 1;
      caseData[`${title}_${kindIndex}`] = async () => p?.fullName ?? "";

      caseData[`${title}_${kindIndex}_Form`] = async () =>
        p ? PartyFormDescription[p.form] : "";
      caseData[`${title}_${kindIndex}_Name`] = async () => p?.fullName ?? "";
      caseData[`${title}_${kindIndex}_Short_Name`] = async () =>
        p?.shortName ?? "";
      caseData[`${title}_${kindIndex}_Phone`] = async () =>
        p?.phoneNumber ?? "";
      caseData[`${title}_${kindIndex}_Email`] = async () =>
        p?.emailAddress ?? "";
      caseData[`${title}_${kindIndex}_DOB_Formation`] = async () =>
        date(p?.dateOfBirthOrFormation);
      caseData[`${title}_${kindIndex}_Form_State`] = async () =>
        p?.formationState ?? "";
      Object.assign(caseData, exportAddress(db, `${title}_${kindIndex}`, p));

      Object.assign(
        caseData,
        exportAllAddresses(
          db,
          serviceAddresses,
          `${title}_${kindIndex}_Service_Address`,
          5
        )
      );

      caseData[`${title}_${kindIndex}_Initial_Service_Date`] = async () =>
        date(lcp.initialServiceDate);
      caseData[`${title}_${kindIndex}_Initial_Response_Due_Date`] = async () =>
        date(lcp.initialResponseDueDate);
      caseData[`${title}_${kindIndex}_Notice_of_Default_Date`] = async () =>
        date(lcp.noticeOfDefaultDate);
      caseData[`${title}_${kindIndex}_Judgment_Date`] = async () =>
        date(lcp.judgementDate);

      caseData[`${title}_${kindIndex}_Complaint_Amount`] = async () =>
        currency(lcp.complaintAmount);
      caseData[`${title}_${kindIndex}_Ongoing_Amounts`] = async () =>
        currency(lcp.ongoingAmounts);
      caseData[`${title}_${kindIndex}_Additional_Attorneys_Fees`] = async () =>
        currency(lcp.additionalAttorneysFees);
      caseData[`${title}_${kindIndex}_Judgment_Total`] = async () =>
        combinedCurrency([
          lcp.complaintAmount,
          lcp.ongoingAmounts,
          lcp.additionalAttorneysFees,
        ]);

      caseData[`${title}_${kindIndex}_Writ_Sheriff`] = async () =>
        lcp.writSheriff;
      caseData[`${title}_${kindIndex}_Writ_Principal`] = async () =>
        currency(lcp.writPrincipal);
      caseData[`${title}_${kindIndex}_Writ_Interest_Rate`] = async () =>
        percentage(lcp.writInterestRate, 100);
      caseData[`${title}_${kindIndex}_Writ_Interest_End`] = async () =>
        date(lcp.writInterestEndDate);
      caseData[`${title}_${kindIndex}_Writ_Interest`] = async () =>
        currency(lcp.writInterest);
      caseData[`${title}_${kindIndex}_Writ_Costs`] = async () =>
        currency(lcp.writCosts);
      caseData[`${title}_${kindIndex}_Writ_Fees`] = async () =>
        currency(lcp.writFees);
      caseData[`${title}_${kindIndex}_Writ_Total`] = async () =>
        combinedCurrency([
          lcp.writPrincipal,
          lcp.writInterest,
          lcp.writCosts,
          lcp.writFees,
        ]);

      const contract =
        lcp.contractId > 0
          ? db.getOrUndefined<ContractTerms>(
              sql`select * from ${"contractterms"} where id=${lcp.contractId}`
            )
          : undefined;

      const withContract = (
        fn: (c: ContractTerms) => Promise<any>
      ): DocumentDataBuilder => {
        if (!contract) {
          return async () => "(No contract found for the active case)";
        }
        return () => fn(contract);
      };

      caseData[`${title}_${kindIndex}_Contract_Title`] = withContract(
        async (c) => c.title
      );
      caseData[`${title}_${kindIndex}_Contract_Short_Title`] = withContract(
        async (c) => c.shortTitle
      );

      caseData[`${title}_${kindIndex}_Contract_Date`] = withContract(
        async (c) => date(c.date)
      );
      caseData[`${title}_${kindIndex}_Contract_Amendments`] = withContract(
        async (c) => {
          if (c.amendments) {
            return `${c.title} dated ${date(c.date)}, as amended`;
          }

          return `${c.title} dated ${date(c.date)}`;
        }
      );
      caseData[`${title}_${kindIndex}_Contract_And_Amendments`] = withContract(
        async (c) => c.amendments ?? ""
      );
      caseData[`${title}_${kindIndex}_Principal_Amount_Due`] = withContract(
        async (c) => currency(c.principalAmountDue)
      );
      caseData[`${title}_${kindIndex}_Principal_Amount_Due_Description`] =
        withContract(async (c) => c.principalAmountDueDescription);
      caseData[`${title}_${kindIndex}_Interest_Rate`] = withContract(
        async (c) => percentage(c.interestRate, 100)
      );
      caseData[`${title}_${kindIndex}_Interest_Rate_Basis`] = withContract(
        async (c) => c.interestRateBasis
      );
      caseData[`${title}_${kindIndex}_Interest_Start_Date`] = withContract(
        async (c) => date(c.interestStartDate)
      );
      caseData[`${title}_${kindIndex}_Interest_End_Date`] = withContract(
        async (c) => date(c.interestEndDate)
      );
      caseData[`${title}_${kindIndex}_Interest_Total`] = withContract(
        async (c) => currency(interestForRowOrUndefined(c)?.interestTotal)
      );
      caseData[`${title}_${kindIndex}_Late_Fee`] = withContract(async (c) =>
        currency(db.optional(c).lateFee)
      );
      caseData[`${title}_${kindIndex}_Late_Fee_Basis`] = withContract(
        async (c) => c.lateFeeBasis
      );
      caseData[`${title}_${kindIndex}_Late_Fee_Provision`] = withContract(
        async (c) => c.lateFeeTerm
      );
      caseData[`${title}_${kindIndex}_Attorneys_Fee`] = withContract(
        async (c) => currency(c.attorneysFee)
      );
      caseData[`${title}_${kindIndex}_Attorneys_Fee_Basis`] = withContract(
        async (c) => c.attorneysFeeBasis
      );
      caseData[`${title}_${kindIndex}_Other_Damages`] = withContract(
        async (c) => currency(db.optional(c).otherDamages)
      );
      caseData[`${title}_${kindIndex}_Other_Damages_Basis`] = withContract(
        async (c) => c.otherDamagesBasis
      );
      caseData[`${title}_${kindIndex}_Other_Relief`] = withContract(
        async (c) => c.otherRelief
      );
      caseData[`${title}_${kindIndex}_Other_Damages_Description`] =
        withContract(async (c) => c.otherDamagesDescription);
      caseData[`${title}_${kindIndex}_Total_Amount_Due`] = withContract(
        async (c) => {
          const interestTotal = interestForRowOrUndefined(c)?.interestTotal;
          return combinedCurrency([
            interestTotal,
            c.principalAmountDue,
            db.optional(c).lateFee,
            c.attorneysFee,
            db.optional(c).otherDamages,
          ]);
        }
      );
    });
  });

  return caseData;
}

function listCaption(
  db: TrackedDatabase,
  matchingParties: Party[],
  showAddressesInCaption: boolean,
  showOnlyFirstPartyInCaption: boolean
): DocumentDataBuilder {
  const filteredParties = matchingParties.filter(
    (p, index) => !showOnlyFirstPartyInCaption || index === 0
  );

  if (filteredParties.length === 0) {
    return async () => "";
  }

  return async () =>
    `${filteredParties
      .map((mp) => {
        if (!showAddressesInCaption) {
          return mp.fullName.toUpperCase();
        }

        return `${mp.fullName.toUpperCase()}\n${fullMultilineAddress(mp, db)}`;
      })
      .join("\nAND\n")}${
      showOnlyFirstPartyInCaption && matchingParties.length > 0 ? " ET AL." : ""
    }`;
}
