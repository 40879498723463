import React, { PropsWithChildren, useContext, useState } from "react";
import { ConfirmCallback, ConfirmDialog, ConfirmDialogButton, WithPromptOptions } from "./ConfirmDialog";

export interface ConfirmProps {
    /**
     * title is the title of the confirm.
     */
    title: React.ReactNode

    /**
     * content is the content of the confirm.
     */
    content: React.ReactNode

    /**
     * buttons are the buttons on the confirm dialog.
     */
    buttons: ConfirmDialogButton[]

    /**
     * withPrompt, if specified, indicates a prompt should be displayed with
     * the given string as the placeholder.
     */
    withPrompt?: string | WithPromptOptions;
}


export type ShowConfirm = (props: ConfirmProps, callback: ConfirmCallback) => any;
export type ShowConfirmAsync = (props: ConfirmProps) => Promise<[any, (string | undefined)?]>;

const ConfirmDialogContext = React.createContext<ShowConfirm | undefined>(undefined);

/**
 * ConfirmDialogProvider provides the confirm dialog UI.
 */
export function ConfirmDialogProvider(props: PropsWithChildren<any>) {
    const [confirmProps, setConfirmProps] = useState<ConfirmProps>({ 'title': '', 'content': '', 'buttons': [] });
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState<{ 'callback': ConfirmCallback | undefined }>({ 'callback': undefined });

    const showConfirm = (props: ConfirmProps, callback: ConfirmCallback) => {
        setConfirmProps(props);
        setConfirmCallback({ 'callback': callback });
        setIsConfirmOpen(true);
    };

    const handleCompleted = (value: any) => {
        if (confirmCallback.callback !== undefined) {
            confirmCallback.callback(value);
        }
        setIsConfirmOpen(false);
    };

    return <>
        <ConfirmDialog isOpen={isConfirmOpen} handleComplete={handleCompleted} {...confirmProps} />
        <ConfirmDialogContext.Provider value={showConfirm}>
            {props.children}
        </ConfirmDialogContext.Provider>
    </>;
}

/**
 * useConfirmDialog provides a hook for displaying a confirm dialog. Requires the ConfirmProvider
 * to be installed in the parent DOM tree.
 * @example
 *      const {showConfirm} = useConfirmDialog();
 *      const result = await showConfirm({
 *          'title': 'Title',
 *          'content': 'Content',
 *          'buttons': [....]
 *      })
 */
export function useConfirmDialog() {
    const showConfirmCallback = useContext(ConfirmDialogContext);
    if (showConfirmCallback === undefined) {
        throw Error('Missing ConfirmDialogProvider')
    }

    const showConfirm: ShowConfirmAsync = (props: ConfirmProps) => {
        const promise = new Promise<[any, string?]>((resolve: (value: [any, string?] | PromiseLike<[any, string?]>) => void) => {
            showConfirmCallback(props, resolve);
        });

        return promise;
    };

    return { showConfirm }
}