import InputAdornment from '@material-ui/core/InputAdornment';
import React from "react";
import { IsKnownState } from '../../services/states';
import { ValueField, ValueFieldProps } from './valuefield';

export function StateField(props: ValueFieldProps) {
    const ensureState = (value: string) => {
        return IsKnownState(value);
    };

    const buildErrorMessage = (value: string) => {
        return `"${value}" is not a valid two-letter state ID`;
    };

    return <ValueField {...props}
        isValidValue={ensureState}
        maxLength={2}
        buildErrorMessage={buildErrorMessage}
        startAdornment={<InputAdornment position="start">Two Letter State ID</InputAdornment>}
    />
}