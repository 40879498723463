import { InitialDataRow } from "../../database/schema";
import { AddressKind, CommissionKind, EntityKind, EntityType, InterestKind } from "./model";

export const realestateInitialData: InitialDataRow[] = [
    ///// Litigation
    {
        revision: 1,
        tableName: 'litigation',
    },

    ///// Guaranty
    {
        revision: 1,
        tableName: 'guaranty',
        values: {
            shortName: 'Guaranty'
        }
    },

    ///// Affiant
    {
        revision: 1,
        tableName: 'affiant',
    },

    ///// Court
    {
        revision: 1,
        tableName: 'court',
    },

    ///// Commission
    {
        revision: 1,
        tableName: 'commission',
        values: { kind: CommissionKind.LEASE },
    },
    {
        revision: 1,
        tableName: 'commission',
        values: { kind: CommissionKind.GUARANTY },
    },

    ///// Interest
    {
        revision: 1,
        tableName: 'interest',
        values: { kind: InterestKind.LEASE },
    },
    {
        tableName: 'interest',
        revision: 1,
        values: { kind: InterestKind.GUARANTY },
    },

    ///// Addresses
    { revision: 1, tableName: 'address', values: { kind: AddressKind.LEASE_BUILDING } }, // Lease Buildng
    { revision: 1, tableName: 'address', values: { kind: AddressKind.TENANT } }, // Tenant
    { revision: 1, tableName: 'address', values: { kind: AddressKind.TENANT_NOTICE } }, // Tenant Notice
    { revision: 1, tableName: 'address', values: { kind: AddressKind.LANDLORD } }, // Landlord
    { revision: 1, tableName: 'address', values: { kind: AddressKind.LANDLORD_NOTICE } }, // Landlord Notice
    { revision: 1, tableName: 'address', values: { kind: AddressKind.GUARANTOR } }, // Guarantor
    { revision: 1, tableName: 'address', values: { kind: AddressKind.GUARANTOR_NOTICE } }, // Guarantor Notice

    ///// Lease
    {
        revision: 1,
        tableName: 'lease',
        values: {
            shortName: 'Lease',
            premisesShortName: 'Premises',
            buildingAddressId: 1,
        }
    },

    ///// Entities.
    // Tenant.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.TENANT,
            type: EntityType.UNKNOWN,
            shortName: 'Tenant',
            addressId: 2,
            noticeAddressId: 3,
        }
    },

    // Landlord.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.LANDLORD,
            type: EntityType.UNKNOWN,
            shortName: 'Landlord',
            addressId: 4,
            noticeAddressId: 5,
        }
    },

    // Guarantor.
    {
        revision: 1,
        tableName: 'entity',
        values: {
            kind: EntityKind.GUARANTOR,
            type: EntityType.UNKNOWN,
            shortName: 'Guarantor',
            addressId: 6,
            noticeAddressId: 7,
        }
    }
];
