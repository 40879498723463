import { Button, Checkbox, CircularProgress, createStyles, FormLabel, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { UpdateAllowedFlowsParams, UPDATE_ALLOWED_FLOWS } from "../../queries/firm";
import { useManagedMutation } from "../../queries/lib/hooks";
import { TEMPLATES } from "../../templates";
import { CaseTemplate } from "../../templates/interfaces";
import { Firm } from "../../types/firm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        alert: {
            marginBottom: theme.spacing(2)
        },
        paper: {
            padding: theme.spacing(2),
            paddingBottom: 0,
            paddingLeft: theme.spacing(1),
            marginBottom: theme.spacing(2)
        },
        label: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            gridTemplateRows: 'auto auto',
            alignItems: 'center',
            paddingBottom: theme.spacing(2),
            columnGap: theme.spacing(1),
        },
        checkbox: {
        }
    }));

export function FlowManagementPanel(props: { firm: Firm }) {
    const classes = useStyles();
    const [allowedFlowIds, setAllowedFlowIds] = useState(props.firm.allowedFlowIds);

    const toggleFlow = (id: string) => {
        const updated = Array.from(allowedFlowIds);
        if (allowedFlowIds.includes(id)) {
            updated.splice(updated.indexOf(id), 1);
        } else {
            updated.push(id);
        }
        setAllowedFlowIds(updated);
    };

    const [updateAllowedFlows, { loading: updating }] = useManagedMutation<
        any,
        UpdateAllowedFlowsParams
    >(UPDATE_ALLOWED_FLOWS);

    const handleChangeTypes = () => {
        (async () => {
            updateAllowedFlows({
                variables: {
                    firmId: props.firm.id,
                    flowIds: allowedFlowIds,
                }
            })
        })();
    };

    return <div className={classes.root}>
        <Alert severity="info" className={classes.alert}>
            Don't see the kind of case your firm needs? <a href="mailto:support@casolio.com">Contact Casolio</a> to request a custom case template,  designed with experts specifically for your firm's needs.
        </Alert>
        <Paper className={classes.paper}>
            {Object.values(TEMPLATES).map((ct: CaseTemplate) => {
                return <div>
                    <FormLabel className={classes.label}>
                        <Checkbox color="default" disabled={updating} className={classes.checkbox} checked={allowedFlowIds.includes(ct.id)} onChange={() => toggleFlow(ct.id)} style={{ gridRow: '1/3', gridColumn: '1/2' }} />
                        <span style={{ gridRow: '1/2', gridColumn: '2/3' }}>{ct.title}</span>
                        <Typography variant="caption" style={{ gridRow: '2/3', gridColumn: '2/3' }}>{ct.description}</Typography>
                    </FormLabel>
                </div>
            })}
        </Paper>
        <Button variant="contained" color="primary"
            onClick={handleChangeTypes}
            startIcon={updating ? <CircularProgress size="1em" /> : undefined}
            disabled={allowedFlowIds.length === 0}>
            Update Case Types
        </Button>
    </div>
}