import { useCookies } from "react-cookie";
import { CaseAccess } from "../types/case";

export interface RecentCaseReference {
    /**
     * id is the unique ID for the case.
     */
    id: string

    /**
     * title is the title of the case.
     */
    title: string

    /**
     * templateId is the ID of the frontend template used for this case.
     */
    templateId: string

    /**
     * color, if defined, is the custom color for the case.
     */
    color?: string | undefined

    /**
     * caseAccess is the access level for the case, if any.
     */
    caseAccess?: CaseAccess | undefined
}

export interface RecentCasesService {
    listRecentCases(): RecentCaseReference[]
    addRecentCase(caseReference: RecentCaseReference): void
}

/**
 * useRecentCases returns the recent cases service.
 */
export function useRecentCases(): RecentCasesService {
    const [cookies, setCookie] = useCookies(['recent-cases']);

    return {
        listRecentCases: () => {
            let recentCases: RecentCaseReference[] = cookies['recent-cases'] ?? [];
            if (!Array.isArray(recentCases)) {
                recentCases = [];
            }

            return recentCases.reverse();
        },
        addRecentCase: (caseReference: RecentCaseReference) => {
            let recentCases: RecentCaseReference[] = cookies['recent-cases'] ?? [];
            if (!Array.isArray(recentCases)) {
                recentCases = [];
            }

            const index = recentCases.findIndex((current: RecentCaseReference) => {
                return current.id === caseReference.id
            });
            if (index >= 0 && index === recentCases.length - 1) {
                return;
            }

            recentCases = recentCases.filter((current: RecentCaseReference) => {
                return current.id !== caseReference.id;
            });

            recentCases.push({
                id: caseReference.id,
                title: caseReference.title,
                color: caseReference.color,
                caseAccess: caseReference.caseAccess,
                templateId: caseReference.templateId,
            })

            setCookie('recent-cases', recentCases);
        }
    }
}