import { ApolloClient, ApolloConsumer } from '@apollo/client';
import { faDatabase, faFileCode, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import React from "react";
import { WithApplicationConfig } from '../../services/confighook';
import { ApplicationConfig } from '../../services/configservice';
import { Case, CaseDatabaseRevision } from "../../types/case";
import { useAlert } from '../AlertProvider';
import useHistoricalTabs from '../historicaltabs';
import TabLabel from '../TabLabel';
import TabPanel from '../TabPanel';
import { AddTemplate } from './AddTemplate';
import { CreateDocumentPane } from './CreateDocumentPane';
import { DocumentFieldsViewer } from './DocumentFieldsViewer';
import { GeneratedDocumentsPane } from './GeneratedDocumentsPane';

interface DocumentPaneProps {
    /**
     * case is the currently loaded case.
     */
    case: Case

    /**
     * currentRevision is the current database revision for the case.
     */
    currentRevision: CaseDatabaseRevision
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(8)
        },
        tabPanel: {
            padding: theme.spacing(2)
        },
    }));

/**
 * DocumentManagementPanel exposes the panel for creating documents and viewing generated documents.
 */
export default function DocumentManagementPanel(props: DocumentPaneProps) {
    return <ApolloConsumer>
        {client =>
            <WithApplicationConfig>
                {
                    (appConfig: ApplicationConfig) => <DocumentManagementPanelImpl {...props} appConfig={appConfig} client={client} />
                }
            </WithApplicationConfig>
        }
    </ApolloConsumer>;
}

function DocumentManagementPanelImpl(props: DocumentPaneProps & { appConfig: ApplicationConfig, client: ApolloClient<object> }) {
    const classes = useStyles();
    const {showAlert} = useAlert();
    
    const { currentTabName, handleChangeTab, setCurrentTab } = useHistoricalTabs(
        [{
            'created': !!props.case.hasGeneratedDocuments,
            'new': props.case.docTemplates?.length !== 0,
            'addtemplate': true,
            'fields': true,
        }],
        !!props.case.hasGeneratedDocuments ? 'created' : (props.case.docTemplates?.length !== 0 ? 'new' : 'addtemplate'),
    );
    const handleTemplateAdded = () => {
        (async () => {
            await showAlert({
                'title': 'Document template created',
                'content': <div>
                    The new document template has been created and can now be used to create documents
                </div>,
                'buttonTitle': 'Okay',
            });    

            setCurrentTab('new');
        })();
    };

    return <div className={classes.root}>
        <AppBar position="static" color="default">
            <Tabs value={currentTabName} onChange={handleChangeTab} aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary">
                {!!props.case.hasGeneratedDocuments && <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faLayerGroup} size="lg" />} title="Created Documents" />} value="created" />}
                {props.case.docTemplates?.length !== 0 && <Tab label={<TabLabel icon={<NoteAddIcon />} title="New Document" />} value="new" />}
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faFileCode} size="lg" />} title="Add Document Template (Advanced)" />} value="addtemplate" wrapped={true} />
                <Tab label={<TabLabel icon={<FontAwesomeIcon icon={faDatabase} size="lg" />} title="Mail Merge Fields (Advanced)" />} value="fields" wrapped={true} />
            </Tabs>
        </AppBar>
        <TabPanel className={classes.tabPanel} value={currentTabName} index="created">
            <GeneratedDocumentsPane case={props.case} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={currentTabName} index="new">
            <CreateDocumentPane
                case={props.case}
                appConfig={props.appConfig}
                currentRevision={props.currentRevision}
                client={props.client} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={currentTabName} index="fields">
            <DocumentFieldsViewer
                case={props.case}
                appConfig={props.appConfig}
                client={props.client} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={currentTabName} index="addtemplate">
            <AddTemplate
                case={props.case}
                appConfig={props.appConfig}
                client={props.client}
                templateAdded={handleTemplateAdded} />
        </TabPanel>
    </div >;
}
