import { faBuilding, faCalendarAlt, faFileContract, faFileImport, faFileInvoice, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useState } from "react";
import { AddressField } from '../../components/fields/AddressField';
import { BooleanField } from '../../components/fields/BooleanField';
import { BooleanFieldDisplay } from '../../components/fields/BooleanFieldDisplay';
import { CurrencyField } from '../../components/fields/CurrencyField';
import { CustomMissingBorder } from '../../components/fields/CustomMissingBorder';
import { DateField } from '../../components/fields/DateField';
import { DocumentDateField } from '../../components/fields/DocumentDateField';
import { EnumField } from '../../components/fields/EnumField';
import { ListField } from '../../components/fields/ListField';
import { NumericField } from '../../components/fields/NumericField';
import { RadioBooleanField } from '../../components/fields/RadioBooleanField';
import { StateField } from '../../components/fields/StateField';
import { ValueField } from '../../components/fields/valuefield';
import { RowRenderer } from '../../database/diffable/components';
import { MutableRow } from '../../database/diffable/interfaces';
import { sql } from '../../database/sql';
import { currency } from '../../shareddata/shareddata';
import { Case } from '../../types/case';
import { Panel, useFlowStyles } from '../uibuilder';
import { AddressKind, CommercialLease, Entity, EntityKind, EntityType, EntityTypeDescription, LeaseExtension, LeaseTerm, Modification } from './model';

export const MISSING_ACTIVE_MODIFICATION_KEY = 'commerciallease_modification'

export const CommercialLeasePanels: Panel[] = [
    {
        path: 'basic',
        title: 'Basic Information',
        icon: faFileContract,
        renderer: BasicInformationEditor,
        tableBindings: [
            sql`select * from ${'entity'} where kind=${EntityKind.LANDLORD}`,
            sql`select * from ${'address'} where kind=${AddressKind.LANDLORD_NOTICE}`,
            sql`select * from ${'entity'} where kind=${EntityKind.TENANT}`,
            sql`select * from ${'address'} where kind=${AddressKind.TENANT_NOTICE}`,
            sql`select * from ${'entity'} where kind=${EntityKind.GUARANTOR}`,
            sql`select * from ${'address'} where kind=${AddressKind.GUARANTOR_NOTICE}`,
        ]
    },
    {
        path: 'building',
        title: 'Building and Premises Information',
        icon: faBuilding,
        renderer: BuildingInfoEditor,
        tableBindings: [
            sql`select * from ${'address'} where kind=${AddressKind.BUILDING}`,
            sql`select * from ${'address'} where kind=${AddressKind.PREMISES}`,
        ]
    },
    {
        path: 'permitted',
        title: 'Permitted Use and Important Dates',
        icon: faCalendarAlt,
        renderer: PermittedEditor
    },
    {
        path: 'rental',
        title: 'Rental Terms',
        icon: faFileInvoiceDollar,
        renderer: RentalTermsEditor
    },
    {
        path: 'misc',
        title: 'Miscellaneous Terms',
        icon: faFileInvoice,
        renderer: MiscTermsEditor
    },
    {
        path: 'modification',
        title: 'Lease Modifications',
        icon: faFileImport,
        renderer: ModificationEditor,
        customBindingKeys: [MISSING_ACTIVE_MODIFICATION_KEY]
    },
];

export function BasicInformationEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faFileContract} size="lg" />
            Basic Lease Information
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <div>
                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <ValueField fullWidth column="documentTitle" title="Document Title" />
                            <ValueField fullWidth column="documentShortName" title="Document Short Name" />
                            <ValueField fullWidth column="initialShortName" title="Document Initial Short Name" />
                            <DocumentDateField fullWidth column="documentDate" title="Document Date" />
                        </fieldset>
                    </Paper>

                    <RowRenderer<Entity> query={sql`select * from ${'entity'} where kind=${EntityKind.LANDLORD}`}>
                        {(row: Entity) => <EntityView title="Landlord" />}
                    </RowRenderer>
                    <RowRenderer<Entity> query={sql`select * from ${'entity'} where kind=${EntityKind.TENANT}`}>
                        {(row: Entity) => <EntityView title="Tenant" />}
                    </RowRenderer>
                    <RowRenderer<Entity> query={sql`select * from ${'entity'} where kind=${EntityKind.GUARANTOR}`}>
                        {(row: Entity) => <EntityView title="Guarantor" simple />}
                    </RowRenderer>

                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <ValueField fullWidth column="broker" title="Broker" />
                        </fieldset>
                    </Paper>

                </div>;
            }}
        </RowRenderer>
    </div>;
}

function EntityView(props: { title: string, simple?: boolean }) {
    const classes = useFlowStyles();
    return <Paper className={classes.paper}>
        <Typography className={classes.header} variant="h6">
            {props.title}
        </Typography>

        <fieldset className={classes.fieldset}>
            <ValueField fullWidth column="name" title={`${props.title} Name`} placeholder="Name" />
            {!props.simple && <EnumField<EntityType> values={EntityTypeDescription} fullWidth column="type" title={`${props.title} Type`} placeholder="Type" />}
            {!props.simple && <StateField fullWidth column="formationState" title={`${props.title} Formation State`} placeholder="Formation State" />}
            <AddressField fullWidth column="addressId" title={`${props.title} Address`} />
            {!props.simple && <ValueField fullWidth column="signer" title={`${props.title} Authorized Signer`} placeholder="Authorized Signer" />}
            {!props.simple && <ValueField fullWidth column="signerTitle" title={`${props.title} Authorized Signer Title`} placeholder="Authorized Signer Title" />}
            {!props.simple && <ValueField fullWidth column="attorneyForNotice" title={`${props.title} Attorney For Notice`} placeholder="Attorney For Notice" multiline />}
        </fieldset>
    </Paper>;
}


export function BuildingInfoEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faBuilding} size="lg" />
            Building Information
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <div>
                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <NumericField fullWidth column="premisesSquareFeet" title="Premises Square Feet" />
                            <ValueField fullWidth column="premisesUnitNumber" title="Premises Unit Number" />
                            <AddressField column="premisesAddressId" title="Premises Address" />
                        </fieldset>
                    </Paper>

                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <NumericField fullWidth column="buildingSquareFeet" title="Building Square Feet" />
                            <AddressField column="buildingAddressId" title="Building Address" />
                        </fieldset>
                    </Paper>
                </div>;
            }}
        </RowRenderer>
    </div>;
}

export function PermittedEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
            Permitted Use and Important Dates
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <div>
                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <DateField fullWidth column="commencementDate" title="Commencement Date" />
                            <DateField fullWidth column="rentCommencementDate" title="Rent Commencement Date" />
                            <DateField fullWidth column="possessionDate" title="Possession Date" />
                            <ValueField fullWidth column="permittedUse" title="Permitted Use" multiline />
                        </fieldset>
                    </Paper>
                </div>;
            }}
        </RowRenderer>
    </div>;
}

export function RentalTermsEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />
            Rental Terms
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <div>
                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <ValueField fullWidth column="leaseTerm" title="Lease Term" />
                            <DateField fullWidth column="leaseEndDate" title="Lease End Date" />
                            <CurrencyField fullWidth column="initialRentPSF" title="Initial Rent (Per Square Foot)" />
                            <BooleanField fullWidth column="tripleNet" title="Lease is a triple-net Lease." />
                        </fieldset>
                    </Paper>

                    <Paper className={classes.paper}>
                        <ListField<LeaseTerm>
                            tableName="leaseterm"
                            rowTitle="Lease Term"
                            maximumCount={10}
                            empty={{
                                'term': ''
                            }}
                            addDescription="Added new lease term"
                            removeDescription="Removed lease term">
                            {(row: MutableRow<LeaseTerm>, index: number) => {
                                return <fieldset className={classes.fieldset}>
                                    <ValueField fullWidth column="term" title="Term" />
                                    <CurrencyField fullWidth column="rentPSF" title="Rent (Per Square Foot)" />
                                </fieldset>
                            }}
                        </ListField>
                    </Paper>
                </div>;
            }}
        </RowRenderer>
    </div>;
}

export function MiscTermsEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faFileInvoice} size="lg" />
            Miscellaneous Terms
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <LeaseMiscEditor case={props.case} row={row} />;
            }}
        </RowRenderer>
    </div>;
}

export function LeaseMiscEditor(props: { case: Case, row: CommercialLease }) {
    const classes = useFlowStyles();
    const [, setHasAddendum] = useState(props.row.hasAddendum === 1);
    const initialAnnualRent = (props.row.initialRentPSF ?? 0) * (props.row.premisesSquareFeet ?? 0);
    const initialMonthlyRent = initialAnnualRent / 12;

    return <div>
        <Paper className={classes.paper}>
            <fieldset className={classes.fieldset}>
                <CurrencyField fullWidth column="securityDeposit" title="Security Deposit" />
                <CurrencyField fullWidth column="firstMonthsRent" title="First Month's Rent"
                    clickToFill={initialMonthlyRent > 0 ? {
                        title: `Calculated: ${currency(initialMonthlyRent)}`,
                        value: initialMonthlyRent.toString()
                    } : undefined}
                />
            </fieldset>
        </Paper>

        <Paper className={classes.paper}>
            <fieldset className={classes.fieldset}>
                <BooleanField fullWidth column="hasAddendum" title="Has Addendum" onChange={setHasAddendum} />
                <BooleanFieldDisplay title="Lease has addendum" column="hasAddendum">
                    <ValueField fullWidth column="addendumText" title="Addendum" multiline />
                </BooleanFieldDisplay>
            </fieldset>
        </Paper>
    </div>;
}

export function ModificationEditor(props: { case: Case }) {
    const classes = useFlowStyles();
    return <div>
        <Typography className={classes.header} variant="h5">
            <FontAwesomeIcon icon={faFileImport} size="lg" />
            Lease Modification
        </Typography>

        <RowRenderer<CommercialLease> query={sql`select * from ${'commerciallease'} limit 1`}>
            {(row: CommercialLease) => {
                return <div>
                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <ValueField fullWidth column="guarantyTitle" title="Guaranty Title" />
                            <DateField fullWidth column="guarantyDate" title="Guaranty Date" />
                        </fieldset>
                    </Paper>

                    <Paper className={classes.paper}>
                        <fieldset className={classes.fieldset}>
                            <ValueField fullWidth column="baseRentTerm" title="Base Rent Term" />
                            <DateField fullWidth column="leaseExtensionStartDate" title="Lease Extension Start Date" />
                            <DateField fullWidth column="leaseExtensionEndTermDate" title="Lease Extension End Term Date" />
                            <DateField fullWidth column="leaseTerminationDate" title="Lease Termination Date" />
                            <ValueField fullWidth column="amendmentsText" title="Amendments to initial lease" multiline />
                        </fieldset>
                    </Paper>

                    <Paper className={classes.paper}>
                        <ListField<LeaseExtension>
                            tableName="leaseextension"
                            rowTitle="Lease Extension"
                            maximumCount={5}
                            empty={{
                                'term': ''
                            }}
                            addDescription="Added new lease extension"
                            removeDescription="Removed lease extension">
                            {(row: MutableRow<LeaseExtension>, index: number) => {
                                return <fieldset className={classes.fieldset}>
                                    <ValueField fullWidth column="term" title="Extension Term" />
                                    <CurrencyField fullWidth column="rentPSF" title="Rent (Per Square Foot)" />
                                </fieldset>
                            }}
                        </ListField>
                    </Paper>

                    <Paper className={classes.paper}>
                        <CustomMissingBorder bindKey={MISSING_ACTIVE_MODIFICATION_KEY}>
                            <ListField<Modification>
                                tableName="modification"
                                rowTitle="Lease Modification"
                                maximumCount={5}
                                empty={{
                                    'title': ''
                                }}
                                addDescription="Added new lease modification"
                                removeDescription="Removed lease modification">
                                {(row: MutableRow<Modification>, index: number) => {
                                    return <fieldset className={classes.fieldset}>
                                        <ValueField fullWidth column="title" title="Modification Title" />
                                        <ValueField fullWidth column="shortTitle" title="Modification Short Title" />
                                        <DocumentDateField fullWidth column="date" title="Modification Document Date" />
                                        <RadioBooleanField fullWidth column="isActive" title="Is Active Modification" />
                                    </fieldset>
                                }}
                            </ListField>
                        </CustomMissingBorder>
                    </Paper>
                </div>;
            }}
        </RowRenderer>
    </div>;
}
