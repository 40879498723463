import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { PropsWithChildren } from 'react';
import { useAuthenticationService } from '../services/authentication';
import { useApplicationConfig } from '../services/confighook';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userError: {
            display: 'block',
            width: '100%',
            padding: theme.spacing(5),
            textAlign: 'center'
        },
        buttonBar: {
            width: '100%',
            marginTop: theme.spacing(2)
        }
    }));

interface UserIssueDisplayProps {
    severity: "error" | "info"
}

export function UserIssueDisplay(props: PropsWithChildren<UserIssueDisplayProps>) {
    const classes = useStyles();
    const auth = useAuthenticationService();
    const appConfig = useApplicationConfig();

    const handleSignout = () => {
        auth.signOut();
    };

    return <div className={classes.userError}>
        <Alert variant="filled" severity={props.severity}>{props.children}</Alert>
        <div className={classes.buttonBar}>
            <Button variant="contained" onClick={handleSignout}>Sign Out</Button>
        </div>
    </div>
}