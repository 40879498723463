import Alert from "@material-ui/lab/Alert/Alert";
import React, { ReactNode } from "react";
import { IDed, PreparedQuery } from "../sql";
import { ResultContext } from "./context";
import { MutableRow } from "./interfaces";
import { useQuery } from "./query";

/**
 * QueryRendererProps are the props for the query renderer.
 */
interface QueryRendererProps<TResult extends IDed = IDed> {
    /**
     * query is the query to execute. Must be be a select query.
     */
    query: PreparedQuery

    /**
     * children is the render function to run for each row found.
     */
    children: (result: MutableRow<TResult>) => ReactNode

    /**
     * empty is the content rendered if the query finds no results.
     */
    empty?: React.ReactNode
}

/**
 * QueryRenderer runs a specific query and executes the render function for each row found.
 * Also sets the ResultContext for all children.
 * 
 * @example <QueryRenderer<SomeRecord> query={sql`Select * from SomeTable where a >= 0`}>{
    (result: MutableRow<SomeRecord>) => <div key={result.row.a}>{result.row.a} = {result.row.b}</div>
  }
  </QueryRenderer>
 */
export function QueryRenderer<TResult extends IDed = IDed>(props: QueryRendererProps<TResult>) {
    const { loading, results } = useQuery<TResult>(props.query);
    return <div>
        {loading && 'Loading'}
        {!loading && results?.map((result: MutableRow<TResult>) => {
            return <div key={result.row.id}>
                <ResultContext.Provider value={result}>
                    {props.children(result)}
                </ResultContext.Provider>
            </div>;
        })}
        {!loading && results?.length === 0 && props.empty}
    </div>;
}


/**
 * MutableRowRenderer runs a specific query and executes the render function for the single row found.
 * Also sets the ResultContext for the child. If the query does not have a result (or has more tha
 * one result), displays an error.
 * 
 * @example <MutableRowRenderer<SomeRecord> query={sql`Select * from SomeTable where a >= 0`}>{
    (result: MutableRow<SomeRecord>) => <div key={result.row.a}>{result.row.a} = {result.row.b}</div>
  }
  </MutableRowRenderer>
 */
export function MutableRowRenderer<TResult extends IDed = IDed>(props: QueryRendererProps<TResult>) {
    const { loading, results } = useQuery<TResult>(props.query);
    return <>
        {loading && 'Loading'}
        {!loading && results?.length === 0 && <Alert severity="warning">No results found for query</Alert>}
        {!loading && results !== undefined && results.length > 1 && <Alert severity="error">Multiple results found for query</Alert>}
        {!loading && results !== undefined && results.length === 1 && <React.Fragment key={results[0].row.id}>
            <ResultContext.Provider value={results[0]}>
                {props.children(results[0])}
            </ResultContext.Provider>
        </React.Fragment>}
    </>;
}

/**
 * RowRendererProps are the props for the row renderer.
 */
interface RowRendererProps<TResult extends IDed = IDed> {
    /**
     * query is the query to execute. Must be be a select query.
     */
    query: PreparedQuery

    /**
     * children is the render function to run for each row found.
     */
    children: (result: TResult) => ReactNode
}

/**
 * RowRenderer is like MutableRowRenderer, but it only returns the underlying row.
 */
export function RowRenderer<TResult extends IDed = IDed>(props: RowRendererProps<TResult>) {
    return <MutableRowRenderer<TResult> query={props.query}>
        {
            (result: MutableRow<TResult>) => props.children(result.row)
        }
    </MutableRowRenderer>
}