import { Button, CircularProgress, createStyles, makeStyles, Paper, TextField, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { UpdateFirmDetailsParams, UPDATE_FIRM_DETAILS } from "../../queries/firm";
import { useManagedMutation } from "../../queries/lib/hooks";
import { Firm } from "../../types/firm";
import { CasolioColorInput, CasolioImageInput, CasolioPhoneInput } from "../controls/inputs";
import { FIRM_LOGO_MAX_SIZE } from "./common";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        imagePreview: {
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            '& img': {
                maxHeight: '200px',
                border: `1px solid ${theme.palette.divider}`
            },
        },
        field: {
            padding: theme.spacing(1)
        },
        normal: {
            position: 'relative',
            '& > fieldset': {
                borderColor: '',
                borderWidth: 1,
            },
            '&:hover > fieldset': {
                borderColor: theme.palette.text.primary,
            }
        },
        editing: {
            '& > fieldset': {
                borderColor: theme.palette.primary.main + ' !important',
                borderWidth: '2px !important',
            }
        },
        edited: {
            '& > fieldset': {
                borderColor: theme.palette.warning.main,
                borderWidth: 2,
            }
        },
        outline: {
            borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
            borderRadius: 4
        },
        control: {
            padding: theme.spacing(1.5),
            '& .react-tel-input .form-control': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                border: 0,
                fontSize: '100%',
                width: '100%'
            },
            '& .react-tel-input .flag-dropdown': {
                borderColor: theme.palette.divider,
                borderWidth: 0,
                borderRightWidth: 1,
            },
            '& .react-tel-input .flag-dropdown.open': {
                backgroundColor: theme.palette.background.default,
            },
            '& .react-tel-input .flag-dropdown.open .selected-flag': {
                backgroundColor: theme.palette.primary.dark,
            },
            '& .react-tel-input .country-list': {
                backgroundColor: theme.palette.background.paper,
                borderColor: 'transparent',
                boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
            },
            '& .react-tel-input .country-list .country:hover': {
                backgroundColor: theme.palette.action.hover
            },
            '& .react-tel-input .country-list .country.highlight': {
                backgroundColor: theme.palette.action.hover
            },
        }
    }));


export function FirmSettingsPanel(props: { firm: Firm }) {
    const classes = useStyles();

    const [title, setTitle] = useState(props.firm.title);
    const [address, setAddress] = useState(props.firm.address);
    const [phoneNumber, setPhoneNumber] = useState(props.firm.phoneNumber);
    const [faxNumber, setFaxNumber] = useState(props.firm.faxNumber);
    const [color, setColor] = useState(props.firm.color ?? '#ccc');
    const [logoData, setLogoData] = useState(props.firm.logoData);

    const changeHandler = (callback: (value: string) => void) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            callback(e.target.value)
        };
    };

    const [updateDetails, { loading: updating }] = useManagedMutation<
        any,
        UpdateFirmDetailsParams
    >(UPDATE_FIRM_DETAILS);


    const disabled = !title || !address || !phoneNumber || !faxNumber || updating;

    const handleSubmit = (e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();

        (async () => {
            updateDetails({
                variables: {
                    firmId: props.firm.id,
                    title: title,
                    address: address,
                    phone: phoneNumber,
                    fax: faxNumber,
                    color: color,
                    logoData: logoData ?? undefined
                }
            })
        })();
    };

    return <div>
        <form onSubmit={handleSubmit}>
            <Paper className={classes.paper}>
                <div className={classes.field}>
                    <TextField value={title} onChange={changeHandler(setTitle)} label="Firm Title" fullWidth variant="outlined" required />
                </div>
                <div className={classes.field}>
                    <CasolioColorInput
                        value={color}
                        onChange={setColor}
                        label="Firm Color"
                    />
                </div>
                <div className={classes.field}>
                    <CasolioImageInput bgColor={color} value={logoData} onChange={setLogoData} label="Firm Logo" maxByteSize={FIRM_LOGO_MAX_SIZE} />
                </div>
            </Paper>

            <Paper className={classes.paper}>
                <div className={classes.field}>
                    <TextField value={address} onChange={changeHandler(setAddress)} label="Firm Address" fullWidth multiline required variant="outlined" />
                </div>
                <div className={classes.field}>
                    <CasolioPhoneInput value={phoneNumber} onChange={setPhoneNumber} label="Phone Number" />
                </div>
                <div className={classes.field}>
                    <CasolioPhoneInput value={faxNumber} onChange={setFaxNumber} label="Fax Number" />
                </div>
            </Paper>

            <Button variant="contained" color="primary" type="submit"
                startIcon={updating ? <CircularProgress size="1em" /> : undefined}
                disabled={disabled}>
                Update Firm Details
            </Button>
        </form>
    </div>
}