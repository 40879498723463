import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ColorHash from "color-hash";
import React from "react";
import { Link } from 'react-router-dom';
import { Case } from '../../types/case';
import { CreatedCaseDocument } from '../../types/generateddoc';
import { CompactUserView } from '../UserView';
import { DocumentIcon } from './DocumentIcon';

/**
 * Defines the properties for the GeneratedDocumentTile.
 */
interface GeneratedDocumentTileProps {
    /**
     * case is the current case.
     */
    case: Case

    /**
     * document is the current document.
     */
    document: CreatedCaseDocument
}


interface styleProps {
    docKindColor: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        media: {
            height: 400,
            objectFit: 'cover',
            backgroundColor: (props: styleProps) => props.docKindColor,
        },
        title: {
            marginTop: theme.spacing(1),
        },
        createdOn: {
            marginTop: theme.spacing(1),
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto auto auto 1fr',
            columnGap: theme.spacing(1),
        },
        createdOnDate: {
            flex: '1',
        },
        dbIcon: {
            height: '16px!important' as any
        },
        chip: {
        },
    }));

/**
 * GeneratedDocumentTile displays a Tile/Card for a generated document.
 */
export default function GeneratedDocumentTile(props: GeneratedDocumentTileProps) {
    const colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    const docKindColor = colorHash.hex(props.document.title);

    const classes = useStyles({ 'docKindColor': docKindColor });

    return <Card className={classes.root}>
        <CardActionArea component={Link} to={`/c/${props.case.id}/document/${props.document.id}`}>
            <CardMedia
                className={classes.media}
                image={props.document.previewThumbnail}
                title="Document Preview"
            />
            <CardContent>
                <Typography className={classes.title} gutterBottom variant="h6" component="h2">
                    {props.document.title}
                </Typography>
                {props.document.note && <div>
                    {props.document.note}

                </div>}
                <Typography component='div' className={classes.createdOn} variant="body2" color="textSecondary">
                    <DocumentIcon document={props.document} size="sm" compact />
                    <span className={classes.createdOnDate}>{new Date(props.document.createdOn).toLocaleDateString()}</span>
                    by
                    <CompactUserView user={props.document.createdBy} />
                    <span />
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>;
}
