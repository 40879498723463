import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';
import ColorHash from "color-hash";
import React from "react";
import { User } from '../types/firmmember';

/**
 * Defines the properties for the UserView.
 */
interface UserViewProps {
    user: User
    hideEmail?: boolean
    hideUsername?: boolean
}

interface styleProps {
    userColor: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userView: {
            display: 'inline-flex',
            alignItems: 'center',
            textAlign: 'left'
        },
        avatar: {
            backgroundColor: (props: styleProps) => props.userColor,
            color: (props: styleProps) => theme.palette.getContrastText(props.userColor),
            width: '1.75rem',
            height: '1.75rem',
            marginRight: theme.spacing(1),
        },
    }));

export function UserIcon(props: { id: string, name: string | undefined, className?: string, avatarUrl: string | undefined }) {
    const colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    const userColor = colorHash.hex(props.id);
    const classes = useStyles({ 'userColor': userColor });

    const username = props.name || props.id || 'U';
    return <Avatar aria-label="user icon" src={props.avatarUrl || ''} className={clsx(classes.avatar, props.className)}>
        {username[0] ? username[0].toUpperCase() : 'U'}
    </Avatar>;
}


export function CompactUserView(props: { user: User }) {
    const username = props.user.name || props.user.id;
    return <Tooltip title={props.user.name ?? props.user.email}>
        <span>
            <UserIcon id={props.user.id} name={username} avatarUrl={props.user.avatarUrl} />
        </span>
    </Tooltip>;
}

/**
 * UserView displays an icon and username for the user. If the user has an avatar image, it is
 * used (and clipped to a circle). Otherwise, a color-generated Avatar is used.
 */
export default function UserView(props: UserViewProps) {
    const classes = useStyles({ 'userColor': '#fff' });
    const username = props.user.name || props.user.id;

    return <div className={classes.userView}>
        <UserIcon id={props.user.id} name={username} avatarUrl={props.user.avatarUrl} />
        <Box>
            {props.hideUsername !== true && <span>{username}</span>}
            {props.hideEmail !== true && <Typography variant="caption">{props.user.email}</Typography>}
        </Box>
    </div>;
}
