import { faCalendar, faDownload, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LookupCaseDocumentData, LOOKUP_CASE_DOCUMENT } from '../../queries/documents';
import { useFixedQuery } from '../../services/apollo';
import { useAuthenticationService } from '../../services/authentication';
import { WithApplicationConfig } from '../../services/confighook';
import { ApplicationConfig } from '../../services/configservice';
import { TEMPLATES } from '../../templates';
import { CreatedCaseDocument } from '../../types/generateddoc';
import MultiOptionButton from '../MultiOptionButton';
import { SideDrawerOpenContext } from '../SideDrawer';
import { DocumentIcon } from './DocumentIcon';
import { DocumentNoteView } from './DocumentNoteView';
import { DocumentStarControl } from './DocumentStarControl';

interface DocumentViewProps {
    caseid: string;
    documentid: string;
}

interface styleProps {
    sideDrawerOpen: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inlineFrame: {
            width: '100%',
            height: '100%',
            border: 0
        },
        frameContainer: {
            position: 'absolute',
            top: '140px',
            left: (props: styleProps) => props.sideDrawerOpen ? '240px' : '57px',
            right: '0px',
            bottom: '0px',
            transition: theme.transitions.create('left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        header: {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto auto 1fr auto',
            marginBottom: theme.spacing(1.5),
            paddingTop: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            columnGap: theme.spacing(2),
        },
        headerIcon: {
            '& svg': {
                marginRight: theme.spacing(1),
            },
        },
        buttonGroup: {
            justifyContent: 'flex-end',
        },
        dbIcon: {
            height: '16px!important' as any
        },
        title: {
            justifyContent: 'flex-start'

        },
        newerRevision: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: '8px',
            alignItems: 'center',
            padding: '2px',
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.dark,
            paddingLeft: '6px',
            borderRadius: '4px',
        },
        note: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: '4px',
            fontSize: 'normal',
            alignItems: 'center',
        },
        generatedText: {
            display: 'grid',
            gridTemplateColumns: 'auto auto 1fr',
            columnGap: '14px',
        },
        createdOn: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            columnGap: '8px',
            fontSize: 'normal',
            alignItems: 'center',
        }
    }));


export default function DocumentView(props: RouteComponentProps<DocumentViewProps>) {
    return <WithApplicationConfig>
        {
            (appConfig: ApplicationConfig) => {
                return <DocumentViewWithAppConfig appConfig={appConfig} {...props} />;
            }
        }
    </WithApplicationConfig>;
}

function DocumentViewWithAppConfig(props: RouteComponentProps<DocumentViewProps> & { appConfig: ApplicationConfig }) {
    const { getToken } = useAuthenticationService();
    const appConfig = props.appConfig;

    const startDownload = (urlPrefix: string) => {
        (async () => {
            const token = await getToken();
            if (!token) {
                return;
            }

            const url = `${appConfig.endpoint}/${urlPrefix}?token=${token}`;
            window.location.replace(url);
        })();
    };

    const openWordDocument = (document: CreatedCaseDocument) => {
        const url = `ms-word:ofe|u|${appConfig.endpoint + document.wordEditUrl}`;
        window.location.replace(url);
    };

    const sideDrawerOpen = useContext(SideDrawerOpenContext);
    const classes = useStyles({ 'sideDrawerOpen': sideDrawerOpen });
    const { loading, data: documentData, error } = useFixedQuery<LookupCaseDocumentData>(LOOKUP_CASE_DOCUMENT, {
        variables: {
            documentId: props.match.params.documentid
        }
    })

    const caseTemplate = documentData?.documentById?.case?.templateId ? TEMPLATES[documentData?.documentById.case.templateId] : undefined;
    const wordUrl = documentData !== undefined ? appConfig.documentEndpoint + documentData.documentById.wordUrl : undefined;
    const encodedWordURI = wordUrl !== undefined ? encodeURIComponent(wordUrl) : undefined;

    return <div>
        {(loading) && <CircularProgress />}
        {
            error !== undefined && <Snackbar>
                <Alert severity="error">
                    Could not load document
                </Alert>
            </Snackbar>
        }
        {documentData !== undefined && caseTemplate !== undefined && <div>
            <div className={classes.header}>
                <DocumentStarControl document={documentData.documentById} />
                <DocumentIcon document={documentData.documentById} size="lg" />
                <div className={classes.title}>
                    <Typography variant="h6">{documentData.documentById.title}</Typography>
                    <div className={classes.generatedText}>
                        <Typography className={classes.createdOn} variant="body2" color="textSecondary" component="p">
                            <FontAwesomeIcon icon={faCalendar} />
                            {new Date(documentData.documentById.createdOn).toLocaleDateString()}
                        </Typography>
                        <DocumentNoteView document={documentData.documentById} />
                        {!documentData.documentById.isLatestDocumentRevision &&
                            <Typography className={classes.newerRevision} variant="body2" component="p">
                                A newer version of this document exists
                            </Typography>
                        }
                    </div>
                </div>
                <MultiOptionButton className={classes.buttonGroup} color="default" options={[
                    {
                        icon: <FontAwesomeIcon icon={faFileWord} />,
                        title: 'Open in Microsoft Word',
                        callback: () => openWordDocument(documentData.documentById)
                    },
                    {
                        icon: <FontAwesomeIcon icon={faFilePdf} />,
                        title: 'Convert To PDF',
                        callback: () => startDownload(documentData.documentById.pdfUrl)
                    },
                    {
                        icon: <FontAwesomeIcon icon={faDownload} />,
                        title: 'Download Word & PDF',
                        callback: () => startDownload(documentData.documentById.zipUrl)
                    }
                ]} />
            </div>

            <div className={classes.frameContainer}>
                <iframe title="Document View" className={classes.inlineFrame} src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodedWordURI}`} />
            </div>
        </div>
        }
    </div >;
}