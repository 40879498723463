import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';

export interface MultiOptionButtonAction {
    icon: React.ReactNode;
    title: string;
    callback: () => void;
}

export interface MultiOptionButtonProps {
    options: MultiOptionButtonAction[]
    className?: string
    color?: "default" | "primary"
    variant?: "contained"
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuList: {
            zIndex: 9999
        },
        option: {
            '& svg': {
                marginRight: theme.spacing(1),
            },
        },
    }));

/**
 * MultiOptionButton is a button group that shows a single option at a time, and the rest
 * in a dropdown menu.
 * @example
 * <MultiOptionButton className={classes.buttonGroup} color="default" options={[
        {
            icon: <FontAwesomeIcon icon={faFileWord} />,
            title: 'Open in Microsoft Word',
            callback: () => openWordDocument(documentData.documentById)
        },
        {
            icon: <FontAwesomeIcon icon={faFilePdf} />,
            title: 'Convert To PDF',
            callback: () => startDownload(documentData.documentById.pdfUrl)
        },
        {
            icon: <FontAwesomeIcon icon={faDownload} />,
            title: 'Download Word & PDF',
            callback: () => startDownload(documentData.documentById.zipUrl)
        }
    ]} />
 */
export default function MultiOptionButton(props: MultiOptionButtonProps) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        props.options[selectedIndex].callback();
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        props.options[index].callback();
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    // Based on: https://material-ui.com/components/button-group/
    return <div>
        <ButtonGroup className={props.className} variant={props.variant ?? 'contained'} color={props.color ?? 'primary'} ref={anchorRef} aria-label="split button">
            <Button className={classes.option} startIcon={props.options[selectedIndex].icon} onClick={handleClick}>{props.options[selectedIndex].title}</Button>
            <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper className={classes.menuList} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                {props.options.map((option, index) => (
                                    <MenuItem
                                        className={classes.option}
                                        key={index}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option.icon}
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>
}