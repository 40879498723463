import dayjs from "dayjs";
import { MutableRow } from "../database/diffable/interfaces";

/**
 * computeInterest computes interest on a per diem basis.
 */
export function computeInterest(
  interestDays: number,
  interestRate: number,
  principalAmountDue: number
) {
  const dailyInterestRate = interestRate / 100 / 365;
  const interestPerDiem = dailyInterestRate * principalAmountDue;
  const interestTotal = interestPerDiem * interestDays;

  return {
    interestDays: interestDays,
    dailyInterestRate: dailyInterestRate,
    interestPerDiem: interestPerDiem,
    interestTotal: interestTotal,
    currencyTotal: interestTotal,
  };
}

export type InterestBearingRow = {
  principalAmountDue: number | null;
  interestRate: number | null;
  interestStartDate: string;
  interestEndDate: string;
};

export function interestForRowOrUndefined(
  row: MutableRow | InterestBearingRow
) {
  const result = interestForRow(row);
  if (result === undefined) {
    return undefined;
  }

  if ("datesOutOfOrder" in result) {
    return undefined;
  }

  return result;
}

/**
 * interestForRow computes the interest for the given row.
 */
export function interestForRow(row: MutableRow | InterestBearingRow) {
  let principalAmountDueValue: string | null | undefined = undefined;
  let interestRateValue: string | undefined | null = undefined;
  let interestRateStartDateValue: string | undefined = undefined;
  let interestRateEndDateValue: string | undefined = undefined;

  if ("principalAmountDue" in row) {
    principalAmountDueValue = row.principalAmountDue?.toString();
    interestRateValue = row.interestRate?.toString();
    interestRateStartDateValue = row.interestStartDate;
    interestRateEndDateValue = row.interestEndDate;
  } else {
    principalAmountDueValue = row.getField("principalAmountDue");
    interestRateValue = row.getField("interestRate");
    interestRateStartDateValue = row.getField("interestStartDate");
    interestRateEndDateValue = row.getField("interestEndDate");
  }

  if (
    !principalAmountDueValue ||
    !interestRateValue ||
    !interestRateStartDateValue ||
    !interestRateEndDateValue
  ) {
    return undefined;
  }

  const interestRateStartDate = dayjs(interestRateStartDateValue);
  const interestRateEndDate = dayjs(interestRateEndDateValue);

  if (interestRateEndDate.isBefore(interestRateStartDate)) {
    return {
      interestRateStartDate: interestRateStartDate,
      interestRateEndDate: interestRateEndDate,
      datesOutOfOrder: true,
    };
  }

  const interestDays = interestRateEndDate.diff(interestRateStartDate, "day");

  const interestRate = parseFloat(interestRateValue);
  if (isNaN(interestRate) || interestRate <= 0 || interestRate > 100) {
    return undefined;
  }

  const principalAmountDue = parseFloat(principalAmountDueValue);
  if (isNaN(principalAmountDue) || principalAmountDue <= 0) {
    return undefined;
  }

  const computed = computeInterest(
    interestDays,
    interestRate,
    principalAmountDue
  );

  return computed;
}
