import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { StarCaseDocumentData, STAR_CASE_DOCUMENT } from "../../queries/documents";
import { useManagedMutation } from "../../queries/lib/hooks";
import { Case } from "../../types/case";
import { CreatedCaseDocument } from "../../types/generateddoc";


interface styleProps {
    isStarred: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        star: {
            color: (props: styleProps) => props.isStarred ? 'gold' : theme.palette.divider,
            cursor: 'pointer',
        },
    }));

export function DocumentStarControl(props: { document: CreatedCaseDocument, case?: Case }) {
    const classes = useStyles({ isStarred: props.document.isStarred });

    const [starCaseDocument] = useManagedMutation<
        any,
        StarCaseDocumentData
    >(STAR_CASE_DOCUMENT);

    const handleToggleStar = () => {
        (async () => {
            await starCaseDocument({
                variables: {
                    caseId: props.case?.id ?? props.document.case?.id!,
                    documentId: props.document.id,
                }
            })
        })();
    };

    return <div className={classes.star} onClick={handleToggleStar}>
        <FontAwesomeIcon icon={faStar} />
    </div>
}