import {
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import { Alert } from "@material-ui/lab";
import React, { useContext } from "react";
import { DatabaseContext, ResultContext } from "../database/diffable/context";
import { useMutationWatcher } from "../database/diffable/hooks";
import { interestForRow } from "../shareddata/interest";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      borderRadius: "6px",
      backgroundColor: theme.palette.background.default,
    },
    outline: {
      borderRadius: "6px",
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
    },
    content: {
      display: "inline-grid",
      gridTemplateColumns: "auto auto auto",
      alignItems: "center",
      "&:hover": {
        "& $calculation": {
          opacity: 1,
        },
      },
      cursor: "default",
    },
    calculation: {
      display: "inline-grid",
      gridTemplateColumns: "auto auto auto auto auto auto auto",
      alignItems: "center",
      columnGap: theme.spacing(1),
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: "350ms opacity ease-in-out",
    },
    description: {
      fontSize: "80%",
      color: theme.palette.text.hint,
    },
  })
);

export function CalculatedInterestDisplay() {
  const database = useContext(DatabaseContext)!;
  const row = useContext(ResultContext)!;
  const classes = useStyles();

  useMutationWatcher(database, 100);

  const interest = interestForRow(row);
  if (interest === undefined) {
    return <div />;
  }

  if ("datesOutOfOrder" in interest) {
    return (
      <Alert severity="error">
        Entered Interest End Date occurs <strong>before</strong> entered
        Interest Start Date
      </Alert>
    );
  }

  return (
    <FormControl fullWidth className={classes.root}>
      <Typography variant="subtitle1">
        <InputLabel
          style={{ position: "absolute" }}
          shrink={true}
          variant="outlined"
        >
          Total Interest
        </InputLabel>
        <NotchedOutline
          className={classes.outline}
          labelWidth={100}
          notched={true}
        />
        <div className={classes.content}>
          <InputAdornment position="start">$</InputAdornment>
          <span>{interest.interestTotal.toFixed(3)}</span>
          <div className={classes.calculation}>
            <span>=</span>
            <span>${interest.interestPerDiem.toFixed(6)}</span>
            <span className={classes.description}>interest per diem</span>
            <span>×</span>
            <span>{interest.interestDays} days</span>
            <span className={classes.description}>number of days</span>
          </div>
        </div>
      </Typography>
    </FormControl>
  );
}
