import { isMissingValue } from "../../services/documentservice";
import { Database, Savepoint } from "../database";
import { IDed, sql } from "../sql";
import { DiffableDatabase, MutableRow, MutationTransaction } from "./interfaces";
import { MutableRowImpl } from "./mutablerow";
import { ChangeFieldMutation, DeleteRowMutation, FieldMetadata, FieldValue, InsertRowMutation, Mutation } from "./mutations";

const COMBINE_ACTION_DELAY = 5 * 1000 // seconds

export const rowKey = (tableName: string, rowId: number): string => `${tableName}:${rowId}`;
export const columnKey = (row: MutableRow, fieldName: string): string => `${row.key()}:${fieldName}`;

/**
 * MutationTransactionImpl tracks all the changes in a single savepoint.
 */
export class MutationTransactionImpl implements MutationTransaction {
    /**
     * savepoint is the underlying savepoint for this transaction.
     */
    private readonly savepoint: Savepoint;

    /**
     * lastChangeByColumnKey is a map from columnKey(...) to the last ChangeFieldMutation
     * on that column, if any.
     */
    private readonly lastChangeByColumnKey: Record<string, ChangeFieldMutation> = {};

    /**
     * insertedRowsByRowKey is a Set of rowKey(...) to indicate a row has been inserted in this transaction.
    */
    private readonly insertedRowsByRowKey: Set<string> = new Set();

    /**
     * mutations are the full set of mutations tracked transaction.
     */
    private readonly mutations: Mutation[] = [];

    /**
     * nonChangeMutationCount is the number of non-change mutations applied.
     */
    private nonChangeMutationCount: number = 0;

    /**
     * mutableByRowKey is a map from rowKey(...) to the MutableRow instance for that
     * row.
     */
    private readonly mutableByRowKey: Record<string, MutableRow> = {};

    /**
     * frozen indicates whether the transaction has been frozen. The transaction is
     * frozen during application or reversion.
     */
    private frozen: boolean = false;

    constructor(private readonly diffable: DiffableDatabase,
        private readonly database: Database,
        private readonly markChangedCallback: (tableName?: string, mutation?: Mutation) => void) {
        this.savepoint = database.savepoint();
    }

    /**
     * mutableForRow returns the MutableRow for the given tableName and table row.
     */
    public mutableForRow<TResult extends IDed = IDed>(tableName: string, row: TResult): MutableRow<TResult> {
        const key = rowKey(tableName, row.id);
        if (key in this.mutableByRowKey) {
            return this.mutableByRowKey[key] as MutableRow<TResult>
        }

        const newRow = new MutableRowImpl<TResult>(tableName, row, this);
        this.mutableByRowKey[key] = newRow
        return newRow;
    }

    /**
     * runUpdate runs a raw query against the savepoint.
     */
    public runUpdate(query: string) {
        this.ensureNotFrozen();
        this.database.raw(query);
    }

    /**
     * currentMutationIndex returns the current index for the mutations in the transaction.
     */
    public currentMutationIndex(): number {
        return this.mutations.length;
    }

    /**
     * currentMutationCount returns the number of mutations pending in the transaction.
     */
    public currentMutationCount(): number {
        return Object.keys(this.lastChangeByColumnKey).length + this.nonChangeMutationCount;
    }

    /**
     * allMutations returns the mutations in this transaction.
     */
    public allMutations(): readonly Mutation[] {
        return Object.freeze(this.mutations.slice());
    }

    /**
     * insertRow inserts a new row into the mutation transaction.
     * @param tableName The name of the table in which to insert the row.
     * @param rowData The data for the row.
     * @param description The description of the insertion, for display to users.
     * @returns The ID of the inserted row.
     */
    public insertRow(tableName: string, rowData: Record<string, any>, description: string): number {
        this.ensureNotFrozen();

        // Add the mutation.
        const mutation = new InsertRowMutation(this.mutations.length, tableName, rowData, description)
        this.mutations.push(mutation);
        this.nonChangeMutationCount++;

        // Write the row immediately to the database.
        const rowId = mutation.update(this.database);

        // Mark the row as inserted.
        this.insertedRowsByRowKey.add(rowKey(tableName, rowId));

        // Raise the change.
        this.markChangedCallback(tableName, mutation);

        return rowId;
    }

    /**
     * deleteRow removes a row from the mutation transaction.
     * @param tableName The name of the table in which the row exists.
     * @param rowId The ID of the row to be removed.
     * @param description The description of the deletion, for display to users.
     */
    public deleteRow(tableName: string, rowId: number, description: string) {
        this.ensureNotFrozen();

        // Add the mutation.
        const mutation = new DeleteRowMutation(this.mutations.length, tableName, rowId, description)
        this.mutations.push(mutation);
        this.nonChangeMutationCount++;

        // Remove the row immediately from the database.
        mutation.update(this.database);

        // Raise the change.
        this.markChangedCallback(tableName, mutation);
    }

    /**
     * isInsertedRow returns true if the row in the given table, with the given id,
     * has been inserted as part of this transaction.
     */
    public isInsertedRow(tableName: string, id: number): boolean {
        return this.insertedRowsByRowKey.has(rowKey(tableName, id));
    }

    /**
     * hasMutatedValue returns whether there is a mutated value on the given field
     * in the given row.
     * @param row The row to check for the mutated value.
     * @param fieldName The field name.
     */
    public hasMutatedValue(row: MutableRow, fieldName: string): boolean {
        const key = columnKey(row, fieldName)
        return key in this.lastChangeByColumnKey
    }

    /**
     * hasMissingValue returns true if the given column on the row with the given ID has a missing
     * value.
     */
    public hasMissingValue(tableName: string, id: number, columnName: string): boolean {
        const key = rowKey(tableName, id);
        if (!(key in this.mutableByRowKey)) {
            // If the row is not being tracked in the mutation, load it, track it, and then
            // check for a missing value.
            const results = this.database.selectAllResults<IDed>(sql`select * from ${tableName} where id=${id} limit 1`);
            if (results.length === 0) {
                return false;
            }

            // Create the mutable row entry for the row.
            this.mutableForRow<IDed>(tableName, results[0]);
        }

        return isMissingValue(this.mutableByRowKey[key].getField(columnName));
    }

    /**
     * addChange adds a change on a field in a mutable row.
     * @param row The row containing the changed field.
     * @param fieldName The name of the field changed.
     * @param fieldValue The new value for the field.
     * @param fieldMetadata Metadata describing the field and the change.
     */
    public addChange(row: MutableRow, fieldName: string, fieldValue: FieldValue, fieldMetadata: FieldMetadata) {
        this.ensureNotFrozen();

        // Check for an existing action within COMBINE_ACTION_DELAY. If found, simply overwrite.
        const lastMutation = this.lastChangeByColumnKey[columnKey(row, fieldName)];
        const currentTimestamp = new Date().getTime();
        if (lastMutation !== undefined && currentTimestamp - lastMutation.timestamp <= COMBINE_ACTION_DELAY) {
            lastMutation.setFieldValue(fieldValue);
            this.markChangedCallback(row.tableName, lastMutation);
            return;
        }

        // Otherwise, add the new change mutation and raise that the table has been changed.
        const mutation = new ChangeFieldMutation(this.mutations.length, row, fieldName, fieldValue, fieldMetadata);
        this.mutations.push(mutation);
        this.lastChangeByColumnKey[columnKey(row, fieldName)] = mutation;
        this.markChangedCallback(row.tableName, mutation);
    }

    /**
     * getMutatedValueEntry returns the ChangeFieldMutation for the given field on the given
     * row, if any, or undefined if none exists.
     * @param row The row to lookup.
     * @param fieldName The field to lookup for the row.
     */
    public getMutatedValueEntry(row: MutableRow, fieldName: string): ChangeFieldMutation | undefined {
        const key = columnKey(row, fieldName)
        if (!(key in this.lastChangeByColumnKey)) {
            return undefined;
        }

        return this.lastChangeByColumnKey[key]
    }

    /**
     * getMutatedValue returns the current mutated value for the given field on the given row,
     * if any, or undefined if none.
     * @param row The row to lookup.
     * @param fieldName The field to lookup for the row.
     */
    public getMutatedValue(row: MutableRow, fieldName: string): any {
        const key = columnKey(row, fieldName)
        if (!(key in this.lastChangeByColumnKey)) {
            return undefined;
        }

        return this.lastChangeByColumnKey[key].value()
    }

    /**
     * apply applies the transaction, freezing the transaction to any further changes.
     */
    public apply(): boolean {
        if (this.frozen) {
            return false;
        }

        // Ensure there are no invalid field values. If any are found, we cannot proceed to
        // saving.
        const foundIssue = Object.values(this.lastChangeByColumnKey).filter((mutation: ChangeFieldMutation) => {
            return !mutation.isValid();
        }).length > 0;

        if (foundIssue) {
            return false;
        }

        // Freeze the transaction.
        this.frozen = true;

        // Apply any field changes.
        Object.values(this.lastChangeByColumnKey).forEach((mutation: ChangeFieldMutation) => {
            mutation.apply(this.database);
        });

        this.savepoint.apply();
        return true;
    }

    /**
     * revert reverts the transaction, freezing the transaction to any further changes.
     */
    public revert(): boolean {
        if (this.frozen) {
            return false;
        }

        this.frozen = true;
        this.savepoint.revert();
        return true;
    }

    private ensureNotFrozen() {
        if (this.frozen) {
            throw Error('MutationTransaction is already frozen!')
        }
    }
}
