import React, { ReactNode, useEffect, useState } from "react";
import LoadingView from '../components/LoadingView';
import AppConfig, { ApplicationConfig, CachedAppConfig } from "./configservice";


/**
 * useApplicationConfig defines a simple hook for retrieving the application's configuration.
 */
export function useApplicationConfig(): ApplicationConfig | undefined {
    const [appConfig, setAppConfig] = useState<ApplicationConfig | undefined>(CachedAppConfig());
    useEffect(() => {
        if (appConfig !== undefined) {
            return;
        }

        (async () => {
            const config = await AppConfig();
            setAppConfig(config);
        })();
    }, [appConfig]);

    return appConfig;
}

interface WithApplicationConfigProps {
    /**
     * children is the function to run once the config has been loaded.
     */
    children: (appConfig: ApplicationConfig) => ReactNode
}

/**
 * Component which wraps a render function that is invoked once the application config
 * has been loaded.
 */
export function WithApplicationConfig(props: WithApplicationConfigProps) {
    const appConfig = useApplicationConfig();
    if (appConfig === undefined) {
        return <LoadingView message="Loading" />;
    }

    return <>{props.children(appConfig)}</>
}