import React from "react";
import { ValueField, ValueFieldProps } from './valuefield';

export function NumericField(props: ValueFieldProps) {
    const ensureNumeric = (value: string) => {
        const parsed = Number(value);
        if (isNaN(parsed) || value.endsWith(".")) {
            return false;
        }

        return true;
    };

    const buildErrorMessage = (value: string) => {
        return `Please enter a valid number`;
    };

    return <ValueField {...props}
        isValidValue={ensureNumeric}
        buildErrorMessage={buildErrorMessage}
        labelWidth={60} />
}