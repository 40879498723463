import {
  faArrowCircleDown,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import InputLabel from "@material-ui/core/InputLabel";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import clsx from "clsx";
import React, { PropsWithChildren, useContext } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { useMutationWatcher } from "../../database/diffable/hooks";
import { MutableRow, RowType } from "../../database/diffable/interfaces";
import { useDocumentTracker } from "../documents/DocumentTracker";
import { Expando } from "../Expando";
import { WithTitleOrPlaceholder } from "./valuefield";

/**
 * CollapseFieldProps are the props for a collapsable field.
 */
export type CollapseFieldProps<T extends RowType> = WithTitleOrPlaceholder<{
  /**
   * fullWidth is whether the field should be displayed in full width.
   */
  fullWidth?: boolean;

  /**
   * summarize is a function that takes the MutableRow over which the field operates
   * and summarizes the results for display in the collapsed state.
   */
  summarize: (row: MutableRow<T>) => any;

  /**
   * summarizeIf checks that the given columns have values on the row. If all are empty,
   * the summarize method is not invoked.
   */
  summarizeIf?: string[];

  /**
   * buttonTitle is the title of the adornment button, if any.
   */
  buttonTitle?: string;

  /**
   * buttonHandler is the handler to invoke when the button is clicked, if any.
   */
  buttonHandler?: () => any;
}>;

interface styleProps {
  missingColor: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    normal: {
      position: "relative",
      "& > fieldset": {
        borderColor: (props: styleProps) =>
          props.missingColor ? props.missingColor : "",
        borderWidth: (props: styleProps) => (props.missingColor ? 2 : 1),
      },
      "&:hover > fieldset": {
        borderColor: theme.palette.text.primary,
      },
    },
    editing: {
      "& > fieldset": {
        borderColor: theme.palette.primary.light,
        borderWidth: 2,
      },
    },
    edited: {
      "& > fieldset": {
        borderColor: theme.palette.warning.main,
        borderWidth: 2,
      },
    },
    outline: {
      borderColor:
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)",
      borderRadius: 4,
    },
    summary: {
      padding: theme.spacing(2),
      fontSize: "1.1876em",
      cursor: "pointer",
      paddingRight: theme.spacing(6),
      borderRadius: 4,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    emptySummary: {
      color: "transparent",
    },
    summaryOpen: {
      backgroundColor: theme.palette.background.default,
    },
    content: {
      padding: theme.spacing(3),
      "& > div": {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
      },
    },
    adornment: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
    adornmentButton: {
      height: "2em",
      marginRight: theme.spacing(2),
    },
  })
);

/**
 * CollapseField defines a field over a set of other fields, aggregating their results together
 * in the UI.
 */
export function CollapseField<T extends RowType>(
  props: PropsWithChildren<CollapseFieldProps<T>>
) {
  const { tracker } = useDocumentTracker();

  const mutableRow = useContext(ResultContext)!;
  const database = useContext(DatabaseContext)!;

  // Ensure we get updated on database mutations.
  useMutationWatcher(database!, 50);

  const summary = (() => {
    if (props.summarizeIf !== undefined) {
      const someFound = props.summarizeIf.some((fieldName: string) => {
        return !!mutableRow.getField(fieldName);
      });
      if (!someFound) {
        return "";
      }
    }

    return props.summarize(mutableRow as MutableRow<T>);
  })();

  const toggleOpen = () => {
    setOpen(!open);
  };

  let hasMutatedValue = false;
  if (props.children && Array.isArray(props.children)) {
    hasMutatedValue = props.children.some((child: any) => {
      const column = child.props?.column;
      return column ? mutableRow.hasMutatedValue(column) : false;
    });
  }

  const handleButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    if (props.buttonHandler !== undefined) {
      props.buttonHandler();
    }
    event.stopPropagation();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setOpen(!open);
    }
  };

  // Check for highlighting based on a missing child.
  let missingColor = undefined;
  if (
    Array.isArray(props.children) &&
    tracker.rowHasMissingField(mutableRow.tableName, mutableRow.id())
  ) {
    props.children.forEach((child: any) => {
      if (typeof child === "object" && "props" in child && child.props.column) {
        const found = tracker.documentForField(
          mutableRow.tableName,
          mutableRow.id(),
          child.props.column
        );
        if (found) {
          missingColor = found.color;
        }
      }
    });
  }

  const [open, setOpen] = React.useState(!!missingColor);

  const classes = useStyles({ missingColor: missingColor });
  return (
    <div
      className={clsx(classes.normal, {
        [classes.edited]: hasMutatedValue,
        [classes.editing]: open,
      })}
    >
      <InputLabel
        style={{ position: "absolute" }}
        shrink={!!summary.length}
        variant="outlined"
      >
        {props.placeholder ?? props.title}
      </InputLabel>
      <div
        tabIndex={0}
        className={clsx(classes.summary, {
          [classes.summaryOpen]: open,
          [classes.emptySummary]: !summary,
        })}
        onKeyPress={handleKeyPress}
        onClick={toggleOpen}
      >
        {summary || "|"}
      </div>
      <NotchedOutline
        className={classes.outline}
        labelWidth={(props.title || props.placeholder || "").length * 14}
        /*label={props.title}*/ notched={!!summary.length}
      />
      <Expando open={open}>
        <div className={clsx(classes.content)}>{props.children}</div>
      </Expando>
      <div className={classes.adornment} onClick={toggleOpen}>
        {props.buttonTitle !== undefined && (
          <Button
            className={classes.adornmentButton}
            variant="contained"
            color="primary"
            onClick={handleButtonClicked}
          >
            {props.buttonTitle}
          </Button>
        )}
        <FontAwesomeIcon
          icon={open ? faArrowCircleDown : faArrowCircleRight}
          size="lg"
        />
      </div>
    </div>
  );
}
