import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _uniqueId from "lodash/uniqueId";
import React, { useContext, useState } from "react";
import {
  DatabaseContext,
  ResultContext,
} from "../../database/diffable/context";
import { useMutationWatcher } from "../../database/diffable/hooks";
import { MutableRow } from "../../database/diffable/interfaces";
import { IDed, sql } from "../../database/sql";

interface ValueFieldProps {
  column: string;
  title: string;
  placeholder?: string;
  fullWidth?: boolean;
  onChange?: (value: boolean) => any;
  isToggle?: boolean;
}

export function RadioBooleanField(props: ValueFieldProps) {
  const [uniqueId] = useState(_uniqueId("field-"));
  const database = useContext(DatabaseContext);
  const row = useContext(ResultContext)!;

  const [localIndex, setLocalIndex] = useState(0);

  // Ensure we get updated on database mutations.
  useMutationWatcher(database!, 50);

  const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Mark all existing rows as unchecked.
    const rows = database?.selectAllResults<IDed>(
      sql`select * from ${row.tableName}`
    );

    rows?.forEach((r: MutableRow<IDed>) => {
      if (r.id() === row.id() || r.getField(props.column) === 0) {
        return;
      }

      r.setField(
        props.column,
        {
          value: 0,
          niceValue: "no",
          isValid: true,
        },
        {
          title: props.title,
        }
      );
    });

    row.setField(
      props.column,
      {
        value: event.target.checked ? 1 : 0,
        niceValue: event.target.checked ? "yes" : "no",
        isValid: true,
      },
      {
        title: props.title,
      }
    );

    setLocalIndex(localIndex + 1);

    if (props.onChange) {
      props.onChange(event.target.checked);
    }
  };

  const Control = props.isToggle ? Switch : Checkbox;

  return (
    <FormControlLabel
      control={
        <Control
          name={uniqueId}
          color={row.hasMutatedValue(props.column) ? "secondary" : "primary"}
          disabled={database?.applying}
          checked={row.getField(props.column) === 1}
          onChange={handleChanged}
        />
      }
      label={props.placeholder ?? props.title}
    />
  );
}
