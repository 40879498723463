import { UserButton } from "@clerk/clerk-react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Divider, Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import clsx from 'clsx';
import React from "react";
import { useHistory } from "react-router-dom";
import { LookupCaseData, LOOKUP_CASE } from "../queries/case";
import { useFixedQuery } from "../services/apollo";
import { RecentCaseReference, useRecentCases } from "../services/recentcases";
import { Firm } from "../types/firm";
import { User } from "../types/firmmember";
import CaseReference from "./CaseReference";
import FirmView from "./FirmView";
import { Logo } from "./Logo";


interface AuthedAppBarProps {
    /**
     * user is the currently authenticated user.
     */
    user: User

    /**
     * firm is the firm for the currently authenticated user, if any.
     */
    firm: Firm | undefined | null

    /**
     * currentCaseId is the ID of the currently selected case, if any. If none, will
     * be an empty string.
     */
    currentCaseId: string

    /**
     * shiftWithDrawer if specified and true, will shift the AppBar when the side
     * drawer is open.
     */
    shiftWithDrawer?: boolean;

    /**
     * Whether the SideDrawer is currently open or collapsed.
     */
    isSideBarOpen: boolean;

    /**
     * Callback function to toggle the drawer.
     */
    toggleDrawer: () => any;
}

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@global": {
            '.cl-user-button-popup': {
                backgroundColor: `${theme.palette.background.paper} !important`,
                border: `1px solid ${theme.palette.divider}`,
            }
        },
        grow: {
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolbar: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(2),
            gridTemplateColumns: 'auto auto 1fr auto auto',
            alignItems: 'center',
            display: 'grid',
            columnGap: theme.spacing(2)
        },
        title: {

        },
        logo: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            paddingLeft: theme.spacing(1)
        },
        sectionDesktop: {
            display: 'flex',
            alignItems: 'center'
        },
        currentCase: {
            marginLeft: theme.spacing(2),
        },
        menuButton: {
            marginLeft: 0,
            marginRight: 0,
        },
        currentUser: {
            borderRadius: 0,
            padding: 0,
            margin: 0
        },
        caseSelector: {
            borderRadius: 0,
            padding: theme.spacing(1),
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0,
            fontSize: '125%'
        },
        recentCaseMenuItem: {
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            columnGap: theme.spacing(1),
            alignItems: 'center'
        }
    }));

function LazyCaseReference(props: { caseId: string }) {
    const classes = useStyles();
    const { data } = useFixedQuery<LookupCaseData>(LOOKUP_CASE.gql, {
        variables: {
            caseId: props.caseId,
        }
    });

    return <div className={classes.currentCase}>{
        (data) ? <CaseReference case={data?.caseById!} variant="subtitle1" /> : <CircularProgress />
    }
    </div>;
}

export default function AuthedAppBar(props: AuthedAppBarProps) {
    const classes = useStyles();
    const history = useHistory();
    const recentCases = useRecentCases();

    const [caseMenuAnchorEl, setCaseMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleCaseMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setCaseMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setCaseMenuAnchorEl(null);
    };

    const handleChangeCase = () => {
        history.push('/');
    };

    const handleGoToCase = (e: React.MouseEvent, ref: RecentCaseReference, newTab?: boolean) => {
        if (newTab) {
            e.preventDefault();
            e.stopPropagation();
            window.open(`/c/${ref.id}`);
            return
        }

        // TODO(jschorr): Figure out why this is necessary.
        history.push('/empty');
        setTimeout(() => {
            history.push(`/c/${ref.id}`);
        }, 50);
    };

    const recentCaseItems = recentCases.listRecentCases().filter((c) => props.currentCaseId !== c.id);
    const isCaseMenuOpen = Boolean(caseMenuAnchorEl);
    const caseMenuId = 'case-menu';
    const renderCaseMenu = (
        <Menu
            elevation={0}
            anchorEl={caseMenuAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{
                vertical: "top", horizontal: "right"
            }}
            id={caseMenuId}
            keepMounted
            open={isCaseMenuOpen}
            onClose={handleMenuClose}
        >
            {recentCaseItems.map((ref: RecentCaseReference) => {
                return <MenuItem className={classes.recentCaseMenuItem} onClick={(e) => handleGoToCase(e, ref)}>
                    <Typography variant="inherit">
                        <CaseReference case={ref} />
                    </Typography>
                    <IconButton onClick={(e) => handleGoToCase(e, ref, true)} size="small"><FontAwesomeIcon icon={faExternalLinkAlt} size="xs"/></IconButton>
                </MenuItem>
            })}

            {recentCaseItems.length > 0 && <Divider />}
            <MenuItem onClick={handleChangeCase}>
                <Typography variant="inherit">Select or Create a case</Typography>
            </MenuItem>
        </Menu >
    );

    return <div>
        <AppBar position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.shiftWithDrawer === true && props.isSideBarOpen,
            })}>
            <Toolbar className={classes.toolbar}>
                {props.currentCaseId === '' && <>
                    <Logo className={classes.logo} />
                    <span />
                </>}

                {props.currentCaseId !== '' &&
                    <>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.toggleDrawer}
                        >
                            {props.isSideBarOpen ? <MenuOpenIcon /> : <MenuIcon />}
                        </IconButton>
                        <IconButton
                            className={classes.caseSelector}
                            edge="end"
                            aria-label="current case"
                            aria-controls={caseMenuId}
                            aria-haspopup="true"
                            onClick={handleCaseMenuOpen}
                            color="inherit">
                            <LazyCaseReference caseId={props.currentCaseId} />
                            <ArrowDropDownIcon />
                        </IconButton>
                    </>}

                <div className={classes.grow} />

                {props.firm && <FirmView firm={props.firm} />}
                <UserButton />
                {renderCaseMenu}
            </Toolbar>
        </AppBar>
    </div >;
}