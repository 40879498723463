import { Database } from "./database";
import { DatabaseMigration, DatabaseSchemaDefinition, InitialDataRow, MigrationOperation, schemaToCreateColumnStatement, schemaToCreateStatement, TableSchema, VERSION_TABLE_NAME } from "./schema";

export type ProgressReporter = (progressPercent: number) => void;

/**
 * migrateDatabase performs *schema* migration for a database.
 * @param database The database to migrate.
 * @param schema The HEAD schema for the database.
 * @param currentRevision The current revision of the database.
 * @param reporter An optional reporter for progress.
 */
export async function migrateDatabaseSchema(database: Database, schema: DatabaseSchemaDefinition, currentRevision: number, reporter?: ProgressReporter) {
    for (var index = currentRevision; index < schema.databaseSchemaRevision; ++index) {
        await runMigration(database, schema, schema.migrations[index - 1]);
        if (reporter) {
            reporter((index / schema.databaseSchemaRevision) * 100);
        }
    }
}

/**
 * initializeDatabaseSchema initializes the schema for an empty database.
 */
export async function initializeDatabaseSchema(database: Database, schema: DatabaseSchemaDefinition, reporter?: ProgressReporter) {
    // Create each of the tables.
    Object.keys(schema.definitions).forEach((definitionName: string, index: number) => {
        const definition = schema.definitions[definitionName];
        if (definition.type === "object") {
            // Table.
            const createStatement = schemaToCreateStatement(definitionName.toLowerCase(), definition as TableSchema);
            database.raw(createStatement);
            if (reporter) {
                reporter((index / Object.keys(schema.definitions).length) * 100);
            }
        }
    });

    // Insert the version.
    database.raw(`insert into ${VERSION_TABLE_NAME} values (${schema.databaseSchemaRevision})`);
    return schema.databaseSchemaRevision;
}

/**
 * initializeDatabaseData initializes the data in the database, based on the current revision.
 * @param database The database in which to initialize the data.
 * @param schema The database's schema.
 * @param currentRevision The current revision of the database. Only initial data at the current revision
 *  will be written.
 */
export async function initializeDatabaseData(database: Database, schema: DatabaseSchemaDefinition, currentRevision: number | undefined) {
    schema.initialData.forEach((data: InitialDataRow) => {
        if (currentRevision !== undefined && data.revision > currentRevision) {
            throw Error('Got revision higher than the schema version');
        }

        if (currentRevision !== undefined && data.revision !== currentRevision) {
            return
        }

        if (data.values === undefined || Object.keys(data.values).length === 0) {
            const statement = `insert into ${data.tableName} DEFAULT VALUES `;
            database.raw(statement);
            return
        }

        const values = data.values!;
        const statement = `insert into ${data.tableName} (
            ${Object.keys(values).join(', ')}
        ) values (
            ${Object.keys(values).map((colName: string) => {
            const value = values[colName];
            if (typeof (value) === 'number') {
                return value;
            }

            return `"${value}"`;
        })}
        )`;
        database.raw(statement);
    });
}

/**
 * runMigration runs a specific DatabaseMigration on the database.
 */
async function runMigration(database: Database, schema: DatabaseSchemaDefinition, migration: DatabaseMigration) {
    // Run all migration operations.
    migration.operations.forEach((operation: MigrationOperation) => {
        switch (operation.kind) {
            case "add-column":
                const csql = schemaToCreateColumnStatement(operation.tableName, operation.columnName, operation.columnSchema);
                database.raw(csql);
                break;

            case "add-table":
                const sql = schemaToCreateStatement(operation.tableName, schema.definitions[operation.tableName] as TableSchema);
                database.raw(sql);
                break;

            case "query":
                database.raw(operation.query);
                break;

            default:
                throw Error(`Unknown migration kind ${operation}`)
        }
    });

    // Update the version stored in the database.
    database.raw(`update ${VERSION_TABLE_NAME} set version=${migration.revision}`);
}
