import { MutableRow } from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import { DocumentDataBuilder, FirmMemberLookup, TrackedDatabase } from "../../services/documentservice";
import { IsKnownState, StateTitle } from "../../services/states";
import { compareDocumentDates, currency, date, documentDate, exportAddress, exportDocumentDate, firmFields, joinMultiple, niceNumber, percentage } from "../../shareddata/shareddata";
import { Case } from "../../types/case";
import { Address, CommercialLease, Entity, EntityKind, EntityTypeDescription, LeaseExtension, LeaseTerm, Modification } from "./model";
import { TermOrExtension } from "./shared";
import { MISSING_ACTIVE_MODIFICATION_KEY } from "./ui";


export async function constructDocumentData(cse: Case, db: TrackedDatabase, memberLookup: FirmMemberLookup): Promise<[Record<string, DocumentDataBuilder> | undefined, string | undefined]> {
    const tenant = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.TENANT}`);
    const landlord = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.LANDLORD}`);
    const guarantor = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.GUARANTOR}`);
    const lease = db.get<CommercialLease>(sql`select * from ${'commerciallease'}`);

    const terms = db.list<LeaseTerm>(sql`select * from ${'leaseterm'}`);
    const extensions = db.list<LeaseExtension>(sql`select * from ${'leaseextension'}`);

    const premisesAddress = db.get<Address>(sql`select * from ${'address'} where id=${lease.premisesAddressId}`);
    const buildingAddress = db.get<Address>(sql`select * from ${'address'} where id=${lease.buildingAddressId}`);

    return [{
        // Predefined.
        ...firmFields(cse, db),

        'Document_Title': async () => lease.documentTitle,
        'Document_Short_Name': async () => lease.documentShortName,
        ...exportDocumentDate(lease.documentDate, 'Document'),

        // Entities.
        ...exportEntity(landlord, 'Landlord', db),
        ...exportEntity(tenant, 'Tenant', db),
        ...exportEntity(guarantor, 'Guarantor', db),

        'Broker': async () => lease.broker,

        ...exportAddress(db, 'Premises', premisesAddress),
        ...exportAddress(db, 'Building', buildingAddress),

        'Premises_Unit_No': async () => lease.premisesUnitNumber,
        'Premises_Square_Feet': async () => niceNumber(lease.premisesSquareFeet ?? 0),
        'Building_Square_Feet': async () => niceNumber(lease.buildingSquareFeet ?? 0),
        'Tenants_Proportionate_Share': async () => percentage(lease.premisesSquareFeet, lease.buildingSquareFeet),
        'Permitted_Use': async () => lease.permittedUse,
        'Commencement_Date': async () => date(lease.commencementDate),
        'Rent_Commencement_Date': async () => date(lease.rentCommencementDate),
        'Possession_Date': async () => date(lease.possessionDate),
        'Triple_Net': async () => lease.tripleNet === 1 ? 'NNN' : '',
        'Security_Deposit': async () => currency(lease.securityDeposit),
        'First_Months_Rent': async () => currency(lease.firstMonthsRent),
        'Total_Due_on_Signing': async () => currency((lease.securityDeposit ?? 0) + (lease.firstMonthsRent ?? 0)),
        'Lease_Term': async () => lease.leaseTerm,
        'Lease_End_Date': async () => date(lease.leaseEndDate),
        'Initial_Rent_PSF': async () => currency(lease.initialRentPSF),

        'Addendum_Text': async () => lease.hasAddendum === 1 ? lease.addendumText : '',
        'Lease_Extension_Term_End_Date': async () => date(lease.leaseExtensionEndTermDate),
        'Guaranty_Title': async () => lease.guarantyTitle,
        'Guaranty_Date': async () => lease.guarantyDate,
        'Base_Rent_Term': async () => lease.baseRentTerm,
        'Lease_Termination_Date': async () => date(lease.leaseTerminationDate),

        'Lease_And_Amendments_Summary': async () => {
            const modifications = db.list<Modification>(sql`select * from ${'modification'}`);
            const modifiedText = joinMultiple(modifications, ', as modified ', ` (collectively, the "${lease.documentShortName}")`, (m: Modification) => {
                return `by the ${m.title} dated ${documentDate(m.date)} ("${m.shortTitle}")`
            }, (left: Modification, right: Modification) => {
                return compareDocumentDates(left.date, right.date)
            });
            return `${lease.documentTitle} dated ${documentDate(lease.documentDate)} ("${lease.initialShortName}")${modifiedText}`;
        },

        // Terms (10)
        ...exportTerms('Rental_Terms', terms, 10, lease),

        // Extensions (5)
        ...exportTerms('Ext_', extensions, 5, lease),

        // Modification
        ...exportModification(db),
    }, undefined];
}

function exportEntity(entity: Entity, prefix: string, db: TrackedDatabase): Record<string, DocumentDataBuilder> {
    const address = db.get<Address>(sql`select * from ${'address'} where id=${entity.addressId}`);
    return {
        [`${prefix}`]: async () => entity.name,
        [`${prefix}_State`]: async () => {
            const stateId = entity.formationState;
            if (!IsKnownState(stateId)) {
                return {
                    'message': `Unknown state "${stateId}"`
                }
            }

            return StateTitle(stateId);
        },
        [`${prefix}_Signer`]: async () => entity.signer,
        [`${prefix}_Signer_Title`]: async () => entity.signerTitle,
        [`${prefix}_Type`]: async () => EntityTypeDescription[entity.type],
        [`${prefix}_Attorney_for_Notice`]: async () => entity.attorneyForNotice,
        ...exportAddress(db, prefix, address),
    };
}

function exportModification(db: TrackedDatabase): Record<string, DocumentDataBuilder> {
    const getActiveModification = () => {
        const modifications = db.listMutable<Modification>(sql`select * from ${'modification'}`);
        const found = modifications.filter((m: MutableRow<Modification>) => m.getField('isActive') === 1);
        return found.length === 1 ? found[0].row : undefined;
    };

    const activeModification = getActiveModification();
    if (activeModification === undefined) {
        return {
            'Modification_Title': async () => {
                db.addCustomMissingField({
                    'key': MISSING_ACTIVE_MODIFICATION_KEY,
                    'checker': () => {
                        const active = getActiveModification();
                        if (active === undefined) {
                            return ['Missing single active modification', false];
                        }

                        return ['', true];
                    }
                });
                return '';
            },
            'Modification_Short_Title': async () => '',
            'Modification_Month': async () => '',
            'Modification_Day': async () => '',
            'Modification_Year': async () => '',
        };
    }

    return {
        'Modification_Title': async () => activeModification.title,
        'Modification_Short_Title': async () => activeModification.shortTitle,
        ...exportDocumentDate(activeModification.date, 'Modification')
    }
}

function exportTerms(prefix: string, terms: TermOrExtension[], expectedCount: number, lease: CommercialLease): Record<string, DocumentDataBuilder> {
    let finalObject = {};
    terms.forEach((term: TermOrExtension, index: number) => {
        finalObject = {
            ...finalObject,
            [`${prefix}Term_Y${index + 1}`]: async () => term.term,
            [`${prefix}Rent_PSF_Y${index + 1}`]: async () => `${currency(term.rentPSF ?? 0)}`,
            [`${prefix}Monthly_Base_Rent_Y${index + 1}`]: async () => currency((term.rentPSF ?? 0) * (lease.premisesSquareFeet ?? 0) / 12),
            [`${prefix}Annual_Base_Rent_Y${index + 1}`]: async () => currency((term.rentPSF ?? 0) * (lease.premisesSquareFeet ?? 0)),
        }
    });

    if (expectedCount !== undefined && terms.length < expectedCount) {
        for (var i = terms.length; i <= expectedCount; ++i) {
            finalObject = {
                ...finalObject,
                [`${prefix}Term_Y${i + 1}`]: async () => '',
                [`${prefix}Rent_PSF_Y${i + 1}`]: async () => '',
                [`${prefix}Monthly_Base_Rent_Y${i + 1}`]: async () => '',
                [`${prefix}Annual_Base_Rent_Y${i + 1}`]: async () => '',
            }
        }
    }

    return finalObject;
}
