import { Action, Location } from 'history';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

let initialized = false;

/**
 * useMixpanel is a hook which allows for easy reporting of events via Mixpanel.
 * This hook will also register a listen on the react router to report route
 * changes.
 * @param token The mixpanel token, if any.
 */
export function useMixpanel(token?: string | undefined) {
    const history = useHistory();

    useEffect(() => {
        if (token) {
            // Initialize mixpanel.
            mixpanel.init(token, {
                debug: process.env.NODE_ENV === "development",
                ignore_dnt: true
            });
            initialized = true;
        }
    }, [token]);

    useEffect(() => {
        if (!token) { return; }
        return history.listen((location: Location<any>, action: Action) => {
            mixpanel.track('router_page_view', { 'path': location.pathname });
        });
    }, [history, token]);

    return {
        'trackEvent': (eventName: string, data: Record<string, any>) => {
            if (!initialized) { return }
            mixpanel.track(eventName, data);
        },
        'registerProperties': (data: Record<string, any>) => {
            if (!initialized) { return }
            mixpanel.register_once(data);
        },
        'setGroup': (groupKind: string, groupIds: string[]) => {
            if (!initialized) { return }
            mixpanel.set_group(groupKind, groupIds);
        },
        'identifyUser': (userId: string) => {
            if (!initialized) { return }
            mixpanel.identify(userId);
        },
        'setUserProperties': (userProperties: Record<string, any>) => {
            if (!initialized) { return }
            if (!mixpanel.people) { return; }
            mixpanel.people.set_once(userProperties);
        },
    }
}