/*
 * To regenerate the schema:
 * typescript-json-schema model.ts "*" -o schema.json --required --strictNullChecks
 *
 * https://github.com/YousefED/typescript-json-schema
 */

import { Address } from "../../sharedschema/address";

import { PleadingAttorney as PleadingAttorneyDef } from "../../sharedschema/pleadingattorney";

export type PleadingAttorney = PleadingAttorneyDef;

// NOTE: This is a bitwise set enum. All values must be a power of 2.
export enum PartyForm {
  UNKNOWN = 0,
  INDIVIDUAL = 1 << 0,
  LLC = 1 << 1,
  LP = 1 << 2,
  CORPORATION = 1 << 3,
  NON_PROFIT = 1 << 4,
}

export const PartyFormDescription: Record<PartyForm, string> = {
  [PartyForm.UNKNOWN]: "",

  [PartyForm.INDIVIDUAL]: "Individual",
  [PartyForm.LLC]: "Limited Liability Company",
  [PartyForm.LP]: "Limited Partnership",
  [PartyForm.CORPORATION]: "Corporation",
  [PartyForm.NON_PROFIT]: "Non-Profit",
};

// NOTE: This is a bitwise set enum. All values must be a power of 2.
export enum PartyKind {
  UNKNOWN = 0,

  PLAINTIFF = 1 << 0,
  DEFENDANT = 1 << 1,
  LANDLORD = 1 << 2,
  TENANT = 1 << 3,
  GARNISHEE = 1 << 4,
  MOVANT = 1 << 5,
  RESPONDANT = 1 << 6,
  WITNESS = 1 << 7,
  APPELLANT = 1 << 8,
  APPELLEE = 1 << 9,
  PETITIONER = 1 << 10,
  GUARANTOR = 1 << 11,
}

export const PartyKindDescription: Record<PartyKind, string> = {
  [PartyKind.UNKNOWN]: "",

  [PartyKind.PLAINTIFF]: "Plaintiff",
  [PartyKind.DEFENDANT]: "Defendant",
  [PartyKind.LANDLORD]: "Landlord",
  [PartyKind.TENANT]: "Tenant",
  [PartyKind.GARNISHEE]: "Garnishee",
  [PartyKind.MOVANT]: "Movant",
  [PartyKind.RESPONDANT]: "Respondant",
  [PartyKind.WITNESS]: "Witness",
  [PartyKind.APPELLANT]: "Appellant",
  [PartyKind.APPELLEE]: "Appellee",
  [PartyKind.PETITIONER]: "Petitioner",
  [PartyKind.GUARANTOR]: "Guarantor",
};

export interface Party extends Address {
  form: PartyForm;

  fullName: string;
  shortName: string;

  formationState: string;
  phoneNumber: string;
  emailAddress: string;
  dateOfBirthOrFormation: string;
}

export interface ServiceAddress extends Address {
  partyId: number;
}

export interface Motion {
  id: number;
  kind: string;

  complaintDate: string;
  answerDate: string;
  discoveryDate: string;
  orderDate: string;

  discoveryEndDate: string;
  discoverySent: string;

  isActive: number;
}

export interface LitigationCase {
  id: number;

  state: string;
  county: string;
  court: string;
  barAssociation: string;
  docketNumber: string;

  showAddressesInCaption: number;
  showOnlyFirstPartyInCaption: number;
  isJuryTrial: number;

  isActive: number;
}

export interface LitigationCaseParty {
  id: number;
  caseId: number;
  partyId: number;
  contractId: number;

  partyKind: PartyKind;

  initialServiceDate: string;
  initialResponseDueDate: string;
  noticeOfDefaultDate: string;
  judgementDate: string;

  complaintAmount: number | null;
  ongoingAmounts: number | null;
  additionalAttorneysFees: number | null;

  writSheriff: string;
  writPrincipal: number | null;
  writInterestRate: number | null;
  writInterestEndDate: string;
  writInterest: number | null;
  writCosts: number | null;
  writFees: number | null;
}

export interface ContractTerms {
  id: number;

  title: string;
  shortTitle: string;
  date: string;

  amendments: string | null; // predefined values from the sheet

  principalAmountDue: number | null;
  principalAmountDueDescription: string;

  interestRate: number | null;
  interestRateBasis: string;
  interestStartDate: string;
  interestEndDate: string;

  lateFee: number | null;
  lateFeeBasis: string;
  lateFeeTerm: string;

  attorneysFee: number | null;
  attorneysFeeBasis: string;

  otherDamages: number | null;
  otherDamagesBasis: string;
  otherDamagesDescription: string;
  otherRelief: string;
}

export interface Affiant {
  id: number;
  fullName: string;
  pronoun: string;
  title: string;
}

export enum MotionEntityType {
  UNKNOWN = 0,
  MOVANT = 1,
  RESPONDENT = 2,
}

export const MotionEntityTypeDescription = {
  [MotionEntityType.UNKNOWN]: "",
  [MotionEntityType.MOVANT]: "Movant",
  [MotionEntityType.RESPONDENT]: "Respondent",
};

export interface MotionEntity {
  id: number;
  motionId: number;
  type: MotionEntityType;
  name: string;
}
