import { useEffect, useState } from "react";
import { DatabaseOptions, State, useDatabase } from "./hooks";
import { initializeDatabaseData, initializeDatabaseSchema, migrateDatabaseSchema } from "./migration";
import { DatabaseSchemaDefinition, VERSION_TABLE_NAME } from "./schema";


/**
 * VersionedState is a database State, with additional migration properties. 
 */
export type VersionedState = State & {
    migrating: boolean
    migrationProgress: number | undefined
}

/**
 * VersionedDatabaseState reprensents states of the versioned database.
 */
enum VersionedDatabaseState {
    UNCHECKED = 0,
    CHECKING = 1,
    MIGRATING = 2,
    VALID = 3
}

/**
 * useVersionedDatabase is a React hook which loads a versionined SQLite database
 * in-memory and provides a nice accessor around it.
 * @example const { loading, database } = useVersionedDatabase(theSchemaDefinition);
 *          <DatabaseContext.Provider value={database}>...</DatabaseContext>
 */
export function useVersionedDatabase(schema: DatabaseSchemaDefinition, options: DatabaseOptions): VersionedState {
    const databaseState = useDatabase(options);

    const { loading, database } = databaseState;
    const [versionedState, setVersionedState] = useState(VersionedDatabaseState.UNCHECKED);
    const [migrationProgress, setMigrationProgress] = useState<number | undefined>(0);

    useEffect(() => {
        if (loading || !database) {
            return;
        }

        if (versionedState !== VersionedDatabaseState.UNCHECKED) {
            return;
        }

        // Set that we're checking on the database.
        setVersionedState(VersionedDatabaseState.CHECKING);

        (async () => {
            const rows = database.raw('select name from sqlite_master');
            if (!rows.length) {
                setVersionedState(VersionedDatabaseState.MIGRATING);

                // Create a savepoint for the changes.
                const savepoint = database.savepoint();

                // Add an empty version table to indicate we are migrating.
                database.raw(`create table ${VERSION_TABLE_NAME} (version integer)`);

                // Empty DB. Initialize from the schema.    
                const revision = await initializeDatabaseSchema(database, schema, (progressPercent: number) => setMigrationProgress(progressPercent));

                // Add the initial data.
                await initializeDatabaseData(database, schema, undefined);

                // Apply the savepoint.
                savepoint.apply();
                console.log('Initialized database at version', revision);

                setVersionedState(VersionedDatabaseState.VALID);
                return;
            }

            const tables = rows[0]['values'];
            const tableNames = tables.map((tableInfo: any) => tableInfo[0]);
            if (!tableNames.includes(VERSION_TABLE_NAME)) {
                // Bad database.
                console.log(tables);
                throw Error('Database is invalid')
            }

            // Make sure the database's version matches that of the schema. If not, we
            // need to migrate.
            const version = database.raw(`select * from ${VERSION_TABLE_NAME}`)[0].values[0][0];
            if (version !== schema.databaseSchemaRevision) {
                setVersionedState(VersionedDatabaseState.MIGRATING);

                // Migrate the database forward and apply additional inital data.
                const savepoint = database.savepoint();

                await migrateDatabaseSchema(database, schema, version, (progressPercent: number) => setMigrationProgress(progressPercent));
                await initializeDatabaseData(database, schema, schema.databaseSchemaRevision);

                // Apply the savepoint.
                savepoint.apply();

                console.log('Migrated to version', schema.databaseSchemaRevision, 'from version', version)
                setVersionedState(VersionedDatabaseState.VALID);
                return;
            }

            // If the version matches, then we have a valid database.
            console.log('Loaded database at version', version);
            setVersionedState(VersionedDatabaseState.VALID);
        })();
    }, [loading, database, versionedState, schema]);

    return {
        ...databaseState,
        loading: loading || versionedState !== VersionedDatabaseState.VALID,
        migrating: versionedState === VersionedDatabaseState.MIGRATING,
        migrationProgress: migrationProgress,
    };
}