import { ApolloClient } from '@apollo/client/core';
import { ApolloConsumer } from '@apollo/client/react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import React from "react";
import { useMutationWatcher } from '../../database/diffable/hooks';
import { DiffableDatabase } from '../../database/diffable/interfaces';
import { WithApplicationConfig } from '../../services/confighook';
import { ApplicationConfig } from '../../services/configservice';
import { useDocumentCreator } from '../../services/documentservice';
import { Case, CaseDatabaseRevision } from '../../types/case';
import { DocumentToGenerate, useDocumentTracker } from './DocumentTracker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tracker: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(13),
            left: '200px',
            textAlign: 'right',
        },
        documentChip: {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        avatar: {
            marginLeft: '2px !important',
        }
    }));

/**
 * TrackedDocumentView displays the tracked documents and the number of missing fields
 * for them, on the bottom of the screen.
 */
export function TrackedDocumentView(props: { database: DiffableDatabase, case: Case, currentRevision: CaseDatabaseRevision }) {
    return <ApolloConsumer>
        {client => <WithApplicationConfig>
            {
                (appConfig: ApplicationConfig) => <TrackedDocumentViewInternal {...props} appConfig={appConfig} client={client} />
            }
        </WithApplicationConfig>}
    </ApolloConsumer>;
}

function TrackedDocumentViewInternal(props: { database: DiffableDatabase, case: Case, currentRevision: CaseDatabaseRevision, client: ApolloClient<any>, appConfig: ApplicationConfig }) {
    const classes = useStyles();
    const theme = useTheme();
    const { tracker, documents } = useDocumentTracker();

    // NOTE: The hook ensures we get re-rendered on changes.
    useMutationWatcher(props.database, 100);

    const handleDelete = (dtg: DocumentToGenerate) => {
        tracker.removeDocument(dtg.docTemplate);
    };

    const { createDocument } = useDocumentCreator(props.case, props.currentRevision, props.database, props.appConfig, props.client, false);

    const handleGenerate = (dtg: DocumentToGenerate) => {
        (async () => {
            await createDocument(dtg.docTemplate);
        })();
    };

    return <div className={classes.tracker}>
        {documents.length !== 0 &&
            documents.map((togen: DocumentToGenerate) => {
                const StyleChip = withStyles({
                    root: {
                        backgroundColor: togen.color,
                        color: theme.palette.getContrastText(togen.color)
                    },
                })(Chip);

                const missingFieldCount = tracker.countMissingFields(props.case, togen, props.database);
                const avatar = (missingFieldCount > 0 ?
                    <Avatar className={classes.avatar} style={{ fontWeight: 'bold', backgroundColor: theme.palette.warning.main, color: theme.palette.getContrastText(theme.palette.warning.main) }}>
                        {missingFieldCount}
                    </Avatar> :
                    <Avatar className={classes.avatar} style={{ backgroundColor: theme.palette.success.main }}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Avatar>);

                return <span key={togen.docTemplate.id}>
                    <StyleChip
                        className={classes.documentChip}
                        style={{
                            border: '1px solid transparent',
                            borderColor: missingFieldCount === 0 ? theme.palette.success.main : theme.palette.warning.main,
                        }}
                        color='default'
                        size="small"
                        avatar={avatar}
                        label={documents.length > 5 ? togen.docTemplate.title.split(' ').slice(0, 2).join(' ') : togen.docTemplate.title}
                        onClick={() => handleGenerate(togen)}
                        onDelete={() => handleDelete(togen)}
                    />
                </span>
            })
        }
    </div>;
}