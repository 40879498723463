
export const VERSION_TABLE_NAME = '_casolio_database_version';

/**
 * DatabaseSchemaDefinition defines the structure of a database schema for use
 * by the useVersionedDatabase hook.
 */
export interface DatabaseSchemaDefinition {
    /**
     * Ensures that the schema is a JSON schema.
     */
    "$schema": string

    /**
     * databaseSchemaRevision defines the current revision of the database schema.
     */
    databaseSchemaRevision: number

    /**
     * definitions are the definitions of the tables and other types in the schema.
     */
    definitions: Record<string, Definition>

    /**
     * initialData defines the data to be added initially to the database on initialization.
     */
    initialData: InitialDataRow[]

    /**
     * migrations are the set of database migrations to run from one schema revision
     * to another.
     */
    migrations: DatabaseMigration[]
}

/**
 * DatabaseMigration defines a single migration from a revision to another revision.
 */
export interface DatabaseMigration {
    /**
     * revision is the revision to which this migration will bring the database.
     */
    revision: number

    /**
     * operations are the operations to run as part of this migration.
     */
    operations: MigrationOperation[]
}

/**
 * MigrationOperation are the various kinds of database migration support.
 */
export type MigrationOperation = {
    kind: "query",
    query: string
} | {
    kind: "add-table"
    tableName: string
} | {
    kind: "add-column"
    tableName: string
    columnName: string
    columnSchema: ColumnDefinition
}

/**
 * InitialDataRow is an initial row to be added to the database when it is initialized.
 */
export interface InitialDataRow {
    /**
     * revision is the revision at which the database must be to add the data.
     */
    revision: number

    /**
     * tableName is the name of the table in which to add the row.
     */
    tableName: string

    /**
     * values are the values for the new row. If empty or undefined, an empty row will be created.
     */
    values?: Record<string, any>
}

/**
 * Definition is a definition found in the schema.json file; these must match the forms
 * generated by the JSON schema from TypeScript generator.
 */
type Definition = TableSchema | IntEnumSchema | UnknownSchema;

interface UnknownSchema {
    type: string
}

export interface IntEnumSchema {
    enum: number[]
    type: "number"
}

type ColumnDefinition = DirectColumnDefinition | ForeignKeyColumn

interface ForeignKeyColumn {
    "anyOf": ForeignKeyColumnRef[]
}

type ForeignKeyColumnRef = {
    "$ref": string
} | {
    "type": string | string[]
}

type DirectColumnDefinition = {
    type?: "string" | "number" | ["null", "string"] | ["null", "number"] | string[]
    "$ref"?: string
    "enum"?: number[]
}

export interface TableSchema {
    properties: Record<string, ColumnDefinition>
    required: string[]
    type: "object"
}

export function schemaToCreateColumnStatement(tableName: string, columnName: string, columnSchema: ColumnDefinition): string {
    return `ALTER TABLE ${tableName.toLowerCase()} ADD ${schemaToColumnDefinition(columnName, columnSchema)}`;
}

export function schemaToColumnForeignKey(columnName: string, columnSchema: ColumnDefinition): string | undefined {
    if ('anyOf' in columnSchema) {
        if (columnSchema.anyOf.length === 2) {
            let foreignTableName = undefined;
            columnSchema.anyOf.forEach((entry: ForeignKeyColumnRef) => {
                if ('$ref' in entry) {
                    // #/definitions/Party
                    const pieces = entry.$ref.split('/');
                    if (pieces.length == 3 && pieces[0] === '#' && pieces[1] === 'definitions') {
                        foreignTableName = pieces[2];
                    }
                }
            });

            if (foreignTableName) {
                return `FOREIGN KEY(${columnName}) REFERENCES ${foreignTableName}(id) ON DELETE CASCADE`;
            }
        }
    }

    return undefined
}

export function schemaToColumnDefinition(columnName: string, columnSchema: ColumnDefinition): string {
    if (columnName === 'id') {
        return 'id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT';
    }

    let columnType = 'integer';
    let defaultValue = '0';
    let isNullable = false;

    if (columnName.endsWith('Id')) {
        if ('anyOf' in columnSchema) {
            if (columnSchema.anyOf.length === 2) {
                columnSchema.anyOf.forEach((entry: ForeignKeyColumnRef) => {
                    if (!('$ref' in entry)) {
                        const types = Array.isArray(entry.type) ? entry.type : [entry.type];
                        isNullable = types.indexOf('null') >= 0;
                    }
                });
            }
        }
        columnType = 'integer';
        defaultValue = '0';
    } else {
        const directColumnSchema = columnSchema as DirectColumnDefinition;
        let type: any = directColumnSchema.type;
        if (Array.isArray(type)) {
            if (type[0] !== "null") {
                throw Error("Invalid column type")
            }

            isNullable = true;
            type = type[1];
        }

        switch (type) {
            case "string":
                columnType = 'text';
                defaultValue = '""';
                break

            case "number":
                columnType = 'real';
                defaultValue = '0';
                break

            case undefined:
                // This is an enum ref.
                columnType = 'integer';
                defaultValue = '0';
                break

            default:
                console.log(directColumnSchema.type)
                throw Error('Unknown column type')
        }
    }

    const def = `${columnName} ${columnType} ${isNullable ? '' : 'NOT NULL'} default ${isNullable ? "null" : defaultValue}`;
    return def;
}

export function schemaToCreateStatement(tableName: string, tableSchema: TableSchema): string {
    const columnDefs = Object.keys(tableSchema.properties).map((columnName: string) => {
        return schemaToColumnDefinition(columnName, tableSchema.properties[columnName]);
    }).join(', ');

    const foreignKeys = Object.keys(tableSchema.properties).map((columnName: string) => {
        return schemaToColumnForeignKey(columnName, tableSchema.properties[columnName]);
    }).filter((sql: string | undefined) => !!sql).join(', ');

    return `CREATE TABLE ${tableName.toLowerCase()} (${columnDefs}${foreignKeys ? ',' : ''}${foreignKeys})`;
}
