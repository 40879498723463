import { MISSING_SIGNER_KEY } from "../../components/PleadingAttorneyManager";
import { DiffableDatabase, MutableRow } from "../../database/diffable/interfaces";
import { sql } from "../../database/sql";
import { DocDataResult, DocumentDataBuilder, estr, FirmMemberLookup, TrackedDatabase, TrackedDatabaseState } from "../../services/documentservice";
import { IsKnownState, StateTitle } from "../../services/states";
import { append, currency, date, documentDate, emptyAddress, exportAddress, exportAddresses, exportRequiredAddress, firmFields, joinMultiple } from "../../shareddata/shareddata";
import { Case } from "../../types/case";
import { AttorneyLicense } from "../../types/license";
import { Address, Affiant, Commission, CommissionKind, Court, Entity, EntityKind, EntityTypeDescription, Guaranty, Interest, InterestKind, Lease, Litigation, Motion, MotionEntity, MotionEntityType, OverdueNotice, PleadingAttorney } from "./model";
import { MISSING_ACTIVE_MOTION_KEY } from "./ui";

function signerCheck(cse: Case, db: DiffableDatabase): [string, boolean] {
    const current = db.selectAllResults<PleadingAttorney>(sql`select * from ${'pleadingattorney'}`);
    const signer = current.filter((att: MutableRow<PleadingAttorney>) => att.getField('isSigner') === 1);
    if (signer.length > 0) {
        return ['', true];
    }

    return ['Please select at least one attorney as a signer', false];
}

async function getSignerFullName(db: TrackedDatabase, memberLookup: FirmMemberLookup): Promise<DocDataResult> {
    const signer = db.getOrUndefined<PleadingAttorney>(sql`select * from ${'pleadingattorney'} where isSigner=1`);
    if (signer === undefined) {
        db.addCustomMissingField({
            'key': MISSING_SIGNER_KEY,
            'checker': signerCheck
        });
        return '';
    }

    const signerData = await memberLookup(signer.userId);
    if (signerData === undefined) {
        return {
            'message': `Signer ${signer.fullName} is no longer a valid member of the firm`
        }
    }

    if (!signerData.name) {
        return '';
    }

    return `${signerData.name}, Esquire`;
}

async function getAttorneyId(db: TrackedDatabase, memberLookup: FirmMemberLookup): Promise<DocDataResult> {
    // Load the court state.
    const court = db.get<Court>(sql`select * from ${'court'}`);
    if (!court.state) {
        return '';
    }

    if (!IsKnownState(court.state)) {
        return {
            'message': `Entered court state ${court.state} is not valid`
        }
    }

    const signer = db.getOrUndefined<PleadingAttorney>(sql`select * from ${'pleadingattorney'} where isSigner=1`);
    if (signer === undefined) {
        db.addCustomMissingField({
            'key': MISSING_SIGNER_KEY,
            'checker': signerCheck
        });
        return '';
    }

    const signerData = await memberLookup(signer.userId);
    if (signerData === undefined) {
        if (db.state() === TrackedDatabaseState.HAS_UNSAVED_CHANGED) {
            return '';
        }

        return {
            'message': `Signer ${signer.fullName} is no longer a valid member of the firm`
        }
    }

    // Ensure the signer has a license in that state.
    const foundLicenses = signerData.licenses.filter((license: AttorneyLicense) => license.stateId.toLowerCase() === court.state.toLowerCase());
    if (!foundLicenses.length) {
        if (db.state() === TrackedDatabaseState.HAS_UNSAVED_CHANGED) {
            return '';
        }

        return {
            'message': `Chosen signer ${signerData.name} is not marked as licensed in ${StateTitle(court.state)}`
        }
    }

    const foundLicense = foundLicenses[0];
    return foundLicense.attorneyId;
}


export async function constructDocumentData(cse: Case, db: TrackedDatabase, memberLookup: FirmMemberLookup): Promise<[Record<string, DocumentDataBuilder> | undefined, string | undefined]> {
    const tenant = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.TENANT}`);
    const landlord = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.LANDLORD}`);
    const guarantor = db.get<Entity>(sql`select * from ${'entity'} where kind=${EntityKind.GUARANTOR}`);
    const court = db.get<Court>(sql`select * from ${'court'}`);
    const lease = db.get<Lease>(sql`select * from ${'lease'}`);
    const leaseCommission = db.get<Commission>(sql`select * from ${'commission'} where kind=${CommissionKind.LEASE}`);
    const leaseInterest = db.get<Interest>(sql`select * from ${'interest'} where kind=${InterestKind.LEASE}`);
    const affiant = db.get<Affiant>(sql`select * from ${'affiant'}`);
    const guaranty = db.get<Guaranty>(sql`select * from ${'guaranty'}`);
    const litigation = db.get<Litigation>(sql`select * from ${'litigation'}`);

    const getAddress = (addressId: number) => {
        return db.get<Address>(sql`select * from ${'address'} where id=${addressId}`);
    }

    const landlordAddress = getAddress(landlord.addressId);
    const tenantAddress = getAddress(tenant.addressId);
    const tenantNoticeAddress = getAddress(tenant.noticeAddressId);
    const guarantorAddress = getAddress(guarantor.addressId);
    const guarantorNoticeAddress = getAddress(guarantor.noticeAddressId);
    const buildingAddress = getAddress(lease.buildingAddressId);

    const hasGuarantor = tenant.hasGuarantor === 1;

    let guarantorData = {
        'Guarantor': async () => '',
        ...exportAddress(db, 'Guarantor', emptyAddress),
        ...exportAddress(db, 'Guarantor_NA', emptyAddress, 'Guarantor_Notice'),
        'Guarantor_Type': async () => '',
        'Guarantor_Short_Name': async () => '',

    };
    if (hasGuarantor) {
        guarantorData = {
            'Guarantor': async () => append(guarantor.fullname, db.optional(guarantor).alias),
            ...exportAddress(db, 'Guarantor', guarantorAddress),
            ...exportAddress(db, 'Guarantor_NA', guarantorNoticeAddress, 'Guarantor_Notice'),
            'Guarantor_Type': async () => EntityTypeDescription[guarantor.type],
            'Guarantor_Short_Name': async () => guarantor.shortName,
        };
    }

    return [{
        // Predefined.
        ...firmFields(cse, db),

        // Landlord
        'Landlord': async () => append(landlord.fullname, db.optional(landlord).alias),
        ...exportRequiredAddress(db, 'Landlord', landlordAddress),
        'Landlord_Short_Name': async () => landlord.shortName,
        'Landlord_Type': async () => EntityTypeDescription[landlord.type],

        // Tenant
        'Tenant': async () => append(tenant.fullname, db.optional(tenant).alias),
        ...exportRequiredAddress(db, 'Tenant', tenantAddress),
        ...exportAddress(db, 'Tenant_NA', tenantNoticeAddress, 'Tenant_Notice'),
        'Tenant_Type': async () => EntityTypeDescription[tenant.type],
        'Tenant_Short_Name': async () => tenant.shortName,

        // Guarantor
        ...guarantorData,

        // Guaranty.
        'Guaranty_Short_Name': async () => guaranty.shortName,
        'Guaranty_Title': async () => guaranty.document,
        'Guaranty_Date': async () => date(guaranty.date),
        'Guaranty_Total_Demand': async () => currency(guaranty.rentDue),

        // Case
        'Case_Caption': async () => `${court.court}\n${court.county}\n${court.state}`,
        'Docket_No': async () => court.docketNumber,

        // Attorney and Firm
        'Attorney_ID': async () => await getAttorneyId(db, memberLookup),
        'AttorneySignerName': async () => await getSignerFullName(db, memberLookup),
        'AttorneySigner': async () => estr`${await getSignerFullName(db, memberLookup)} (#${await getAttorneyId(db, memberLookup)})`,
        'AttorneyFilers': async () => estr`${await getSignerFullName(db, memberLookup)} (#${await getAttorneyId(db, memberLookup)})`,
        'Bar_Association': async () => court.barAssociation,

        // Money.
        'Total_Demand': async () => currency((lease.rentDue ?? 0) + (leaseCommission.commissionAmount ?? 0)),
        'Rent_Due': async () => currency(lease.rentDue),
        'Balance_Due_Thru': async () => date(lease.rentDueThrough),
        'Attorneys_Fee_Basis': async () => leaseCommission.basis,
        'Attorneys_Fee': async () => currency(leaseCommission.commissionAmount),
        'Rent_Description': async () => lease.rentDescription,

        // Lease.
        'Lease_Title': async () => lease.title,
        'Lease_Date': async () => date(lease.date),
        'Lease_Short_Name': async () => lease.shortName,
        'Lease_Interest_Rate': async () => `${leaseInterest.rate}%`,
        'Default_Notice_Date': async () => date(lease.defaultNoticeDate),
        'Lease_Interest_Per_Day': async () => currency(((lease.rentDue ?? 0) * ((leaseInterest.rate ?? 0) / 100)) / 365),

        'Premises_Description': async () => lease.premisesDescription,
        'Premises_Short_Name': async () => lease.premisesShortName,

        'Tenant_Cure_Period': async () => lease.tenantCurePeriod,
        'Default_Notice_Cure_Period': async () => lease.defaultNoticeCurePeriod,

        'Late_Fee_Section': async () => lease.lateFeeSection,
        'Late_Fee_Amount': async () => currency(lease.lateFeeAmount),
        'Late_Fee_Basis': async () => lease.lateFeeBasis,

        'Attorneys_Fee_Section': async () => lease.attorneysFeesSection,
        'Lease_Interest_Section': async () => lease.leaseInterestSection,

        ...exportRequiredAddress(db, 'Building', buildingAddress),
        'Building_Short_Name': async () => lease.buildingShortName,

        // Service Address(es):
        ...exportAddresses(db, 'serviceaddress', 'Service', 6),

        // Affiant.
        'Affiant_Name': async () => affiant.fullName,
        'Affiant_Title': async () => affiant.title,
        'Affiant_Pronoun': async () => affiant.pronoun,

        // Litigation.
        'Tenant_Service': async () => date(litigation.tenantServiceDate),
        'Tenant_Default_Date': async () => date(litigation.tenantDefaultDate),
        'Tenant_Default_Notice_Date': async () => date(litigation.tenantDefaultNoticeDate),
        'Tenant_Judgment_Date': async () => date(litigation.tenantJudgementDate),

        'Guarantor_Service': async () => date(litigation.guarantorServiceDate),
        'Guarantor_Default_Date': async () => date(litigation.guarantorDefaultDate),
        'Guarantor_Default_Notice_Date': async () => date(litigation.guarantorDefaultNoticeDate),
        'Guarantor_Judgment_Date': async () => date(litigation.guarantorJudgementDate),

        'Complaint_Amount': async () => currency(litigation.complaintAmount),

        'Ongoing_Rent': async () => currency(litigation.ongoingRent),
        'Ongoing_Rent_Amount': async () => currency(litigation.ongoingRent),

        'Judgment_Total': async () => currency((litigation.complaintAmount ?? 0) + (litigation.ongoingRent ?? 0)),
        'Judgment_Total_Rent': async () => currency((litigation.complaintAmount ?? 0) + (litigation.ongoingRent ?? 0)),

        'Additional_Attorneys_Fees': async () => currency(litigation.additionalAttorneysFees),
        'Additional_Attorneys_Fees_and_Costs': async () => currency(litigation.additionalAttorneysFees),
        'Ongoing_Rent_Description': async () => litigation.ongoingRentDescription,
        'Total_Judgment_Demand': async () => currency(
            (litigation.complaintAmount ?? 0) +
            (litigation.ongoingRent ?? 0) +
            (litigation.additionalAttorneysFees ?? 0)
        ),
        'Writ_Principal': async () => currency(litigation.writPrincipal),
        'Writ_Interest': async () => currency(litigation.writInterest),
        'Writ_Costs': async () => currency(litigation.writCosts),
        'Writ_Fees': async () => currency(litigation.writFees),
        'Writ_Total': async () => currency(
            (litigation.writPrincipal ?? 0) +
            (litigation.writInterest ?? 0) +
            (litigation.writCosts ?? 0) +
            (litigation.writFees ?? 0)
        ),

        ...exportMotion(db),
    }, undefined];
}



function exportMotion(db: TrackedDatabase): Record<string, DocumentDataBuilder> {
    const getActiveMotion = () => {
        const motions = db.listMutable<Motion>(sql`select * from ${'motion'}`);
        const found = motions.filter((m: MutableRow<Motion>) => m.getField('isActive') === 1);
        return found.length === 1 ? found[0].row : undefined;
    };

    const activeMotion = getActiveMotion();
    if (activeMotion === undefined) {
        return {
            'Complaint_Date': async () => {
                db.addCustomMissingField({
                    'key': MISSING_ACTIVE_MOTION_KEY,
                    'checker': () => {
                        const active = getActiveMotion();
                        if (active === undefined) {
                            return ['Missing single active motion', false];
                        }

                        return ['', true];
                    }
                });
                return '';
            },
            'Answer_Date': async () => '',
            'Discovery_Date': async () => '',
            'Discovery_End_Date': async () => '',
            'Order_Date': async () => '',
            'Movant_List': async () => '',
            'Respondent_List': async () => '',
            'Discovery_Sent': async () => '',
            'Overdue_Notice': async () => '',
            'Overdue_Notice_1': async () => '',
            'Overdue_Notice_2': async () => '',
            'Overdue_Notice_3': async () => '',
            'Overdue_Notice_4': async () => '',
            'Overdue_Notice_5': async () => '',
        };
    }

    const movants = db.list<MotionEntity>(sql`select * from ${'motionentity'} where type=${MotionEntityType.MOVANT} and motionId=${activeMotion.id}`);
    const respondents = db.list<MotionEntity>(sql`select * from ${'motionentity'} where type=${MotionEntityType.RESPONDENT} and motionId=${activeMotion.id}`);
    const notices = db.list<OverdueNotice>(sql`select * from ${'overduenotice'} where motionId=${activeMotion.id}`)

    return {
        'Complaint_Date': async () => date(activeMotion.complaintDate),
        'Answer_Date': async () => date(activeMotion.answerDate),
        'Discovery_Date': async () => date(activeMotion.discoveryDate),
        'Discovery_End_Date': async () => date(activeMotion.discoveryEndDate),
        'Order_Date': async () => documentDate(activeMotion.orderDate),
        'Movant_List': async () => joinMultiple(movants, '', '', (item: MotionEntity) => {
            return item.name;
        }),
        'Respondent_List': async () => joinMultiple(respondents, '', '', (item: MotionEntity) => {
            return item.name;
        }),
        'Discovery_Sent': async () => activeMotion.discoverySent,
        'Overdue_Notice': async () => joinMultiple(notices, '', '', (item: OverdueNotice) => {
            return date(item.date)
        }),
        ...Object.fromEntries(notices.map((notice: OverdueNotice, index: number) => {
            return [`Overdue_Notice_${index + 1}`, async () => date(notice.date)];
        })),
        ...Object.fromEntries(range(5 - notices.length).map((value: any, index: number) => {
            return [`Overdue_Notice_${index + 1 + notices.length}`, async () => ''];
        })),
    }
}

const range = (length: number) => {
    const values = [];
    for (var i = 0; i < length; ++i) {
        values.push(i)
    }
    return values;
};