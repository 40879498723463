import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import debounce from 'lodash/throttle';
import React, { useState } from 'react';
import { Edge, PaginatedResponse } from '../queries/lib/base';
import { useFixedQuery } from '../queries/lib/hooks';
import { HasID, QueryInformation } from './Bound';
import EmptyView from './EmptyView';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        rootPaper: {
            padding: theme.spacing(2)
        },
        table: {

        },
        visuallyHidden: {
            display: 'none'
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        filterClear: {
        },
        adornVisible: {
        },
        throbber: {
            margin: theme.spacing(2),
            color: theme.palette.text.primary,
        },
        throbberContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }));

/**
 * Defines the properties for the BoundTable.
 */
export interface BoundTableProps<T, Q> {
    /**
     * The query to execute for filling the table.
     */
    query: QueryInformation

    /**
     * The columns of the table.
     */
    columns: (ColumnDefinition<T, Q> | undefined)[]

    /**
     * The message to display instead of the table, if the table is empty.
     */
    emptyMessage?: string | React.ComponentType<any> | React.ReactElement;

    /**
     * The toolbar to include in the table.
     */
    toolbar?: string | React.ComponentType<any> | React.ReactElement;

    /**
     * precompute, if specified, is the function to run to precompute metadata passed to column
     * rendering functions.
     */
    precompute?: (records: Edge<T>[]) => Q;

    /**
     * searchQueryField, if specified, is the field to send the the query for additional
     * filtering based on a user-entered query. If this field is specified, a search
     * box will be displayed in the toolbar.
     */
    searchQueryField?: string;
}

/**
 * Defines a column in the table.
 */
export interface ColumnDefinition<T, Q> {
    /**
     * id is the ID of the column, to be sent to the query for sorting.
     */
    id: string

    /**
     * title is the title of the table.
     */
    title: string;

    /**
     * render renders the column's view of the given data.
     */
    render: (data: T, precomputed: Q | undefined) => string | React.ComponentType<any> | React.ReactElement;

    /**
     * cellStyle are any custom styles for the column header.
     */
    cellStyle?: CSSProperties;

    /**
     * isNumeric indicates whether the column holds numeric data.
     */
    isNumeric?: boolean;

    /**
     * isPrimary indicates whether the column is considered the primary column
     * in the table.
     */
    isPrimary?: boolean;

    /**
     * isSortable indicates whether the column supports sorting.
     */
    isSortable?: boolean;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    headCells: HeadCell[];
}

interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
    cellStyle?: CSSProperties;
    sortable: boolean;
}

// Based on: https://material-ui.com/components/tables/#sorting-amp-selecting
function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, headCells, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={headCell.cellStyle}
                    >
                        {headCell.sortable &&
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        }
                        {!headCell.sortable && headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const rowsPerPage = 10;

/**
 * BoundTable defines a table bound to a *paginated* GraphQL query, for displaying
 * data returned.
 * 
 * @type T The type of data found in the query. Must have an `id` field.
 * @param props The properties for the BoundTable.
 * @example <BoundTable<SomeData>
                columns={[
                {
                    id: "icon",
                    title: "",
                    isSortable: false,
                    cellStyle: {
                        width: '1rem',
                    },
                    render: (data: SomeData) => {
                        return <img src={data.icon}>
                    }
                },
                {
                    id: "title",
                    title: "Something",
                    isPrimary: true,
                    cellStyle: {
                        width: '100%',
                    },
                    render: (data: SomeData) => {
                        return data.title
                    }
                },
            ]}
            query={
                {
                    gql: THE_QUERY_NAME,
                    variables: {
                        someVar: 123,
                        anotherVar: 456,
                    },
                    recordsKey: ['path', 'to', 'the', 'resources']
                }
            }
        />
 */
export default function BoundTable<T extends HasID, Q = any>(props: BoundTableProps<T, Q>) {
    const classes = useStyles();

    // Setup the state for the table.
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState(0);
    const [searchFilter, setSearchFilter] = useState("");
    const [loadingSearch, setLoadingSearch] = useState(false);

    // Bind the query for loading data. Note that we load the first set of results
    // immediately, and bind to the variables given in the `query` block of the props.
    const { loading, error, data, fetchMore, isMounted } = useFixedQuery(props.query.gql, {
        variables: { ...props.query.variables, first: rowsPerPage, after: null, orderBy: orderBy, order: order }
    });

    const fetchForSearch = React.useMemo(
        () =>
            debounce((request: { input: string }, callback: () => void) => {
                (async () => {
                    if (fetchMore === undefined || !isMounted) return;
                    await fetchMore({
                        variables: { ...props.query.variables, [props.searchQueryField!]: request.input, first: rowsPerPage, after: null, orderBy: orderBy, order: order },
                        updateQuery: (previousResult: any, { fetchMoreResult }) => {
                            return fetchMoreResult;
                        }
                    });
                    setPage(0);
                    setLoadingSearch(false);
                })();
            }, 1000, { trailing: true }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.query.variables, fetchMore, isMounted, order, orderBy],
    );

    const handleSearchFilterChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchFilter(event.target.value);
    }

    const updateSearchFilter = (filter: string) => {
        setLoadingSearch(true);
        setSearchFilter(filter);
        fetchForSearch({ input: filter }, () => { });
    };

    /*
        TODO(jschorr): Warning from React:
        Please convert updateQuery functions to field policies with appropriate
        read and merge functions, or use/adapt a helper function (such as
        concatPagination, offsetLimitPagination, or relayStylePagination) from
        @apollo/client/utilities.
    */

    // WARNING: This is a long explanation.
    //
    // In the Apollo GraphQL client, the method `fetchMore` is used to fetch additional
    // results of a query. Once a `fetchMore` call has completed, it is the responsibility
    // of the *caller* to update the in-memory Apollo data cache, to append the returned
    // results to the record set. In addition, the in-memory data cache must have the Relay-style
    // parameters (hasNextPage, endCursor) updated as well.
    //
    // Therefore, in order for this table to be a generic component:
    //
    // The `query` block under the props defines a string array named `recordsKey`, which is
    // a set of JavaScript attribute names for accessing the PaginatedResponse<T> Relay-style
    // records *under* the returned GraphQL response.
    //
    // As a concrete example, if the GraphQL data being returned is of the form:
    // ```
    // {
    //    parentCollectionById {
    //       subcollection {
    //         edges { node { ... T data here ... } }
    //         pageInfo { endCursor hasNextPage }
    //       }
    //    }
    // }
    // ```
    // then the `recordsKey` will be `['parentCollectionById', 'subcollection'] to
    // point to the Relay-style records instance.
    //
    // The following methods (getRecords, appendRecords, replaceProperty) handle
    // the necessary lookup (getRecords) and modification (appendRecords w/replaceProperty)
    // to ensure the cache is used and updated properly.
    const getRecords = function (data: any): PaginatedResponse<T> {
        let currentData = data;
        props.query.recordsKey.forEach(function (keyName) {
            currentData = currentData[keyName];
        });
        return currentData;
    };

    const appendRecords = function (data: any, newRecords: PaginatedResponse<T>): any {
        // To append the records, we need to override the records key in the data
        // and replace it with the newRecords, but with its `edges` array prepended
        // with the existing data.
        //
        // Yeah...

        // Step 1: Create a new version of this final object, with the Relay-style data
        // (hasNextPage, endCursor) copied over, and the `edges` consisting of the new
        // data appended to the existing data. A new object is required because the
        // existing cache object is frozen.
        let newRecordsWithAppendedEdges = {
            ...newRecords,
            'edges': [...getRecords(data).edges, ...newRecords.edges]
        }

        // Step 2: Replace the records object in the existing cache object with our new one,
        // so that it now reflects the merged data.
        return replaceProperty(data, props.query.recordsKey, newRecordsWithAppendedEdges);
    }

    const replaceProperty = function (data: any, keyPath: string[], newValue: any): any {
        let keyName = keyPath[0];
        let updated = {} as Record<string, any>;
        if (keyPath.length === 1) {
            updated[keyName] = newValue;
        } else {
            updated[keyName] = replaceProperty(data[keyName], keyPath.slice(1), newValue);
        }
        return { ...data, ...updated }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        // If data has not yet been loaded, nothing more to do.
        if (data === undefined || !isMounted) { return }

        // TODO: sorting support once its supported on the backend.

        // Determine whether we need to load more data, based on the data found in the
        // cache and the requested page ID.
        let requiredCount = newPage * rowsPerPage;
        let records = getRecords(data);
        if (requiredCount >= records.edges.length) {
            // If there are no additional pages, we're done.
            if (!records.pageInfo.hasNextPage) {
                return
            }

            // Otherwise, we need to fetch more records.
            (async () => {
                // Fetch more data for the cache.
                if (fetchMore === undefined || !isMounted) return;
                await fetchMore({
                    variables: { ...props.query.variables, first: rowsPerPage, after: records.pageInfo.endCursor },
                    updateQuery: (previousResult: any, { fetchMoreResult }) => {
                        // Append the new data to the cache.
                        let newRecord = getRecords(fetchMoreResult);
                        return appendRecords({
                            ...previousResult
                        }, newRecord);
                    }
                });

                if (!isMounted) { return; }
                setPage(newPage);
            })();
        } else {
            // Otherwise, we already have the page's data in the GraphQL cache,
            // so just switch to that page.
            setPage(newPage)
        };
    };

    const handleChangeRowsPerPage = () => null;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        if (orderBy === property) {
            setOrder(order === "asc" ? "desc" : "asc");
        } else {
            setOrderBy(property);
        }

        // Set the page back to the beginning, which will also reload the data
        // as needed.
        setPage(0);

        fetchForSearch({ input: searchFilter }, () => { });
    };
    const headCells: HeadCell[] = props.columns.filter((column) => column !== undefined).map((col, index) => {
        const column = col!;
        return {
            label: column.title,
            id: column.id,
            numeric: column.isNumeric !== undefined ? column.isNumeric : false,
            disablePadding: column.isPrimary !== undefined ? column.isPrimary : false,
            sortable: column.isSortable !== undefined ? column.isSortable : false, // TODO: change to default `true` once our GraphQL supports sorting
            cellStyle: column.cellStyle,
        };
    })

    let records: PaginatedResponse<T> | undefined = data && getRecords(data);
    if (loadingSearch) {
        records = undefined;
    }

    const precomputed = props.precompute && records ? props.precompute(records.edges) : undefined;
    return <div className={classes.root}>
        <Paper variant="outlined" className={classes.rootPaper}>
            {<div>
                {(props.toolbar || props.searchQueryField) &&
                    <div className={classes.toolbar}>
                        {props.toolbar !== undefined && props.toolbar}
                        {props.searchQueryField !== undefined &&
                            <div>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FontAwesomeIcon icon={faSearch} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment className={clsx(classes.filterClear, { [classes.adornVisible]: searchFilter })} position="end">
                                                <IconButton disabled={!searchFilter} onClick={() => updateSearchFilter('')}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    value={searchFilter}
                                    onChange={handleSearchFilterChanged}
                                />
                            </div>}
                    </div>
                }
                <Table
                    className={classes.table}
                    size="medium"
                    aria-label="table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {records && records.edges.slice(page * rowsPerPage, ((page + 1) * rowsPerPage)).map((edge) => {
                            let node = edge.node;
                            return <TableRow
                                hover
                                tabIndex={-1}
                                key={node.id}
                            >
                                {props.columns.filter((column) => column !== undefined).map((col, index) => {
                                    const column = col!;
                                    return <TableCell key={`${node.id}-${index}`}>
                                        {column.render(node, precomputed)}
                                    </TableCell>;
                                })}
                            </TableRow>;
                        })
                        }
                    </TableBody>
                </Table>
                {(loading || loadingSearch) && <div className={classes.throbberContainer}>
                    <CircularProgress size="2em" className={classes.throbber} />
                </div>}
                {records && <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={!records ? 0 : (records.pageInfo.hasNextPage ? -1 : records.edges.length)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
            </div>}
            {records && (records.edges.length === 0 && props.emptyMessage !== undefined) && <EmptyView message={props.emptyMessage} />}
            {error && <Alert severity="error">Error loading data</Alert>}
        </Paper>
    </div>;
}