import { DatabaseMigration } from '../../database/schema';
import { CaseTemplate } from '../interfaces';
import * as dataAccess from './dataaccess.json';
import { constructDocumentData } from './documentdata';
import { realestateInitialData } from './initialdata';
import * as realestatemigrations from './migrations.json';
import * as realestateschema from './schema.json';
import { RealEstatePanels } from './ui';

/**
 * Defines a Lease Tenant flow.
 */
export const RealEstateTemplateDefinition: CaseTemplate = {
    id: 'realestate',
    title: 'Lease Enforcement',
    schema: {
        $schema: realestateschema.$schema,
        databaseSchemaRevision: realestateschema.databaseSchemaRevision,
        definitions: realestateschema.definitions,
        initialData: realestateInitialData,
        migrations: realestatemigrations['migrations'] as DatabaseMigration[],
    },
    description: 'Action to enforce a real estate lease',
    panels: RealEstatePanels,
    documentDataConstructor: constructDocumentData,
    dataAccess: dataAccess['dai'],
};